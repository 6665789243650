import React, { useContext } from "react";
import { IoMdAddCircle, IoMdClipboard } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import Joyride from "react-joyride";
import translations from "../common/Translations";

const WorkflowMainToolbar = () => {
  const { theme, selectedOrgId ,langMode ,workflow ,runTour,userType } = useContext(AppContext);
  const navigate = useNavigate();
  const handleWorkflowscreen = () => {
    if (!selectedOrgId && userType === "super_admin") {
      toast.warn("select orgnization first");
      return;
    }
    navigate("/workflow-form");
    console.log(selectedOrgId)
  };
  const handleNavigate = () => {
    navigate("/guidelines");
  };
  return (
    <>
    <Joyride
        steps={workflow}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
          },
          tooltip: {
            borderRadius: "10px",
            transition: "transform 0.3s ease, opacity 0.3s ease",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
            transition: "background-color 0.3s ease",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
          spotlight: {
            transition: "box-shadow 0.5s ease",
          },
        }}
      
      />
      <div className="flex justify-between items-center">
        <div className="flex items-center ps-4  dark:border-gray-700"></div>
        <div className="flex items-center gap-2">
            <button
              type="button"
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleNavigate}
            >
              <IoMdClipboard className="w-5 h-5 mr-2" />  {translations[langMode].viewGuideline}
            </button>
            <button
              type="button"
              className="createworkflows px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleWorkflowscreen}
            >
              <IoMdAddCircle className="w-5 h-5 mr-2" /> {translations[langMode].createWorkflow}
            </button>
        </div>
      </div>
    </>
  );
};

export default WorkflowMainToolbar;
