import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { fetchCompletedTasks } from "../../../../service/UserDashboard";
import AppContext from "../../../../context/AppContext";
import ChartModal from "./ChartModal";
import translations from "../../../common/Translations";
import { IoCalendar, IoCheckmarkDoneCircle, IoEye } from "react-icons/io5";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { DateFilter } from "./DateFilter";

const CompletedTask = ({ selectedOrgId }) => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const itemsPerPage = 3;
  const { theme, langMode } = useContext(AppContext);
  const colors = ["#399918", "#EF5A6F", "#674188", "#FFD433", "#FF0000"];
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("bar"); // Default chart view
  const [dateFilter, setDateFilter] = useState(false);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const currentPage = Math.floor(currentIndex / itemsPerPage) + 1;

  useEffect(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const defaultFromDate = `${yyyy}-${mm}-${dd}T00:00`;
    const defaultToDate = `${yyyy}-${mm}-${dd}T23:59`;
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchCompletedTasks(
          selectedOrgId,
          fromDate,
          toDate
        );
        setData(Array.isArray(fetchedData) ? fetchedData : []);
      } catch (error) {
        console.error("Error fetching completed tasks:", error);
      }
    };
    getData();
  }, [selectedOrgId, fromDate, toDate]);

  const paginateData = () => {
    if (Array.isArray(data)) {
      return data.slice(currentIndex, currentIndex + itemsPerPage);
    }
    return [];
  };

  
  const categories = paginateData().map(
    (item) => item.userName || "Unknown User"
  );
  const series = paginateData().map((item) => item.taskCount || 0);

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: colors,
    xaxis: {
      categories,
      labels: {
        rotate: -45, // Rotates labels to prevent overlap
        style: {
          fontSize: "11px",
        },
      },
    },
    
    dataLabels: {
      enabled: true,
      style: {
        colors:
          theme === "dark"
            ? ["#fff"]
            : theme === "high-contrast"
            ? ["#000"]
            : ["#333"],
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333",
      },
    },
    tooltip: {
      theme: theme === "dark" ? "dark" : "light",
    },
  };

  // Chart components
  const ChartView = ({ series, options }) => (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options,
            type: "donut",
            toolbar: { show: false },
          },
          labels: categories, // Add this line to define labels for pie chart
          dataLabels: {
            enabled: true, // Hide data labels
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#fff"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "14px", // Adjust the size of the name labels from the series
                    color:
                      theme === "dark"
                        ? "#fff"
                        : theme === "high-contrast"
                        ? "#000"
                        : "#fff", // Adjusting label text color
                  },
                  value: {
                    fontSize: "14px", // Adjust the size of the value labels inside the donut chart
                    color:
                      theme === "dark"
                        ? "#fff"
                        : theme === "high-contrast"
                        ? "#000"
                        : "#fff", // Adjusting label text color
                  },
                },
              },
            },
          },
        }}
        series={series}
        type="donut"
        width="335"
      />
    </div>
  );

  const GraphView = ({ series, options }) => (
    <div id="graph" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "bar",
            toolbar: { show: false },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              distributed: true,
            },
          },
          
        }}
        series={[
          {
            name: "Task Count",
            data: series,
          },
        ]}
        type="bar"
        width="370"
      />
    </div>
  );

  const RadarChartView = ({ series, options }) => (
    <div id="radar-chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "radar",
            toolbar: { show: false },
          },
          stroke: { width: 2 },
          fill: { opacity: 0.2 },
          markers: { size: 4 },
        }}
        series={[
          {
            name: "Task Count",
            data: series,
          },
        ]}
        type="radar"
        width="350"
      />
    </div>
  );

  const AreaChartView = ({ series, options }) => (
    <div id="area-chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "area",
            toolbar: { show: false },
          },
          stroke: { curve: "smooth" },
          dataLabels: { enabled: true },
        }}
        series={[
          {
            name: "Task Count",
            data: series,
          },
        ]}
        type="area"
        width="370"
      />
    </div>
  );

  // Navigation handlers
  const handleNext = () => {
    if (currentIndex + itemsPerPage < data.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  return (
    <div className={`${theme === "dark" ? "text-gray-200" : "text-gray-900"}`}>
      <div className="flex justify-between py-1">
        <h2 className="semibold text-sm text-left inline-flex gap-2">
          <IoCheckmarkDoneCircle size={20} className=" text-green-500" />
          {translations[langMode].completedTaskCount}
        </h2>
        {selectedOrgId && (
          <div className="flex gap-2">
            {/* Button with tooltip for "View" */}
            <div className="relative group">
              <button
                type="button"
                className="text-xs justify-end font-medium text-center inline-flex items-center"
                onClick={() => setShowModal(true)}
              >
                <IoEye className="w-5 h-5 mr-2" />
              </button>
              {/* Tooltip for the first button */}
              <div className="absolute left-full text-nowrap transform -translate-x-1/2 mb-1 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg">
                View Chart
              </div>
            </div>

            {/* Button with tooltip for "Date Range" */}
            <div className="relative group">
              <button
                type="button"
                className="text-xs justify-end font-medium text-center inline-flex items-center"
                onClick={() => setDateFilter(!dateFilter)}
              >
                <IoCalendar className="w-5 h-5 mr-2" />
              </button>
              {/* Tooltip for the second button */}
              <div className="absolute left-full text-nowrap transform -translate-x-1/2 mb-1 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg">
                Date Range Filter
              </div>
            </div>
          </div>
        )}
      </div>

      {!selectedOrgId ? (
        <div className="flex items-center justify-center text-center text-red-600 h-[290px] w-[500px]">
          <span className="text-center mr-24 text-md">
            Please select an organization
          </span>
        </div>
      ) : (
        <>
          {chartView === "chart" ? (
            <ChartView series={series} options={options} />
          ) : chartView === "area" ? (
            <AreaChartView series={series} options={options} />
          ) : chartView === "radar" ? (
            <RadarChartView series={series} options={options} />
          ) : (
            <GraphView series={series} options={options} />
          )}
          <div className="flex justify-between items-center mt-2">
            <div className="relative group">
              <MdKeyboardDoubleArrowLeft
                onClick={handlePrev}
                size={20}
                className={
                  currentIndex === 0 ? "text-gray-400 cursor-not-allowed" : ""
                }
              />
              <div className="absolute bottom-full text-nowrap transform -translate-x-1/2 mb-1 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg">
                Back
              </div>
            </div>

            {/* Display current page out of total pages */}
            <span className="text-xs text-center text-gray-600">
              Page {currentPage} of {totalPages}
            </span>

            <div className="relative group">
              <MdKeyboardDoubleArrowRight
                onClick={handleNext}
                size={20}
                className={
                  currentIndex + itemsPerPage >= data.length
                    ? "text-gray-400 cursor-not-allowed"
                    : ""
                }
              />
              <div className="absolute bottom-full text-nowrap transform -translate-x-1/2 mb-1 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg">
                Next
              </div>
            </div>
          </div>
        </>
      )}

      <ChartModal
        showModal={showModal}
        setShowModal={setShowModal}
        setChartView={setChartView}
        setCurrentPage={setCurrentIndex}
      />
      <DateFilter
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        setFromDate={setFromDate}
        fromDate={fromDate}
        setToDate={setToDate}
        toDate={toDate}
      />
    </div>
  );
};

export default CompletedTask;
