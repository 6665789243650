import React, { useContext, useEffect } from "react";
import AppContext from "../../../context/AppContext";
import { Subactivity } from "../../../service/Task";
import translations from "../Translations";

export const SubActivity = () => {
  const { subactivity, setSubActivity, workflowId ,setSelectedSubactivity,selectedSubactivity , langMode} = useContext(AppContext);
  useEffect(() => {
    const loadSubactivities = async () => {
      try {
        const response = await Subactivity(workflowId);
        if (response.data.header.code === 600) {
          setSubActivity(response.data.body.value);
        } else {
          console.error("Unexpected response code:", response.data.header.code);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } };
    if (workflowId) {
      loadSubactivities();}
  }, [workflowId, setSubActivity]);
  
  const handleSubactivityChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSubactivity(selectedValue);
  };
  return (
    <div>
      {subactivity.length > 0 && (
        <select
          value={selectedSubactivity}
          onChange={handleSubactivityChange}
          className="rounded-md h-8 mt-1 border border-gray-300 text-xs">
          <option value=""> {translations[langMode].selectsubactivty}</option>
          {subactivity.map((activity, index) => (
            <option key={index} value={activity} className="m-2 hover:bg-indigo-700">
              {activity}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
