import React, { useContext } from "react";
import { Field } from "formik";
import AppContext from "../../context/AppContext";

const CommonRadio = ({ que, name, disabled }) => {
  const { theme } = useContext(AppContext);
  return (
    <div>
      <span
        className={`${
          theme === "dark"
            ? "dark-mode  "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        } text-sm font-semibold`}
      >
        {que}
      </span>
      <div className="flex mt-3">
        <div className="flex items-center mr-4">
          <Field
            id={`${name}-yes`}
            type="radio"
            value="true"
            name={name}
            disabled={disabled}
            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300
               focus:ring-blue-500 ${
                 theme === "dark"
                   ? "text-blue-600  "
                   : theme === "high-contrast"
                   ? "high-contrast  "
                   : ""
               }`}
          />
          <label
            htmlFor={`${name}-yes`}
            className={`ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            Yes
          </label>
        </div>
        <div className="flex items-center">
          <Field
            id={`${name}-no`}
            type="radio"
            value="false"
            name={name}
            disabled={disabled}
            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500
               
               ${
                 theme === "dark"
                   ? "text-blue-600 "
                   : theme === "high-contrast"
                   ? "high-contrast  "
                   : ""
               }`}
          />
          <label
            htmlFor={`${name}-no`}
            className={`ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default CommonRadio;
