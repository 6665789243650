import React, { useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "../user/static-charts/ChartModal";
import AppContext from "../../../context/AppContext";

const PieChart = ({
  tempData = { graphValueList: [] },
  chartName = "Default Chart Name",
}) => {
  const { theme } = useContext(AppContext);

  const seriesData = tempData.graphValueList.map((item) => item.frequency || 0);
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("chart");
  const labels = tempData.graphValueList.map(
    (item) => item.value || "Not answered"
  );
  const colors = ["#FF0000", "#FFA62F", "#0A6847", "#640D6B", "#673F69"];
  const options = {
    labels: labels,
    colors: colors,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "9px",
        colors:
          theme === "dark"
            ? ["#fff"]
            : theme === "high-contrast"
            ? ["#000"]
            : ["#333"], // Adjusting text color based on theme
      },
    },
    legend: {
      show: true, // Hide legend
      position: "bottom",
      labels: {
        colors:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333", // Adjusting legend label color
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "14px",
            },
            value: {
              fontSize: "14px",
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: theme === "dark" ? "dark" : "light",
      style: {
        fontSize: "12px",
        background:
          theme === "dark"
            ? "#333"
            : theme === "high-contrast"
            ? "#fff"
            : "#f4f4f4", // Adjusting tooltip background color
        color:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333", // Adjusting tooltip text color
      },
    },
  };

  const ChartView = () => (
    <div id="chart">
      <ReactApexChart
        options={{
          ...options,
          toolbar: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#fff"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
        }}
        series={seriesData}
        type="donut"
        width="350"
      />
    </div>
  );

  const GraphView = () => (
    <div id="graph">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#333"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
        }}
        series={[{ data: seriesData }]}
        type="bar"
        width="360"
      />
    </div>
  );

  const AreaChartView = () => (
    <div id="area-chart">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "area",
            toolbar: {
              show: false,
            },
          },

          stroke: {
            curve: "smooth",
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#333"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
        }}
        series={[{ data: seriesData }]}
        type="area"
        width="360"
      />
    </div>
  );

  const RadarChartView = () => {
    const radarOptions = {
      chart: {
        type: "radar",
      },
      toolbar: {
        show: false,
      },
      xaxis: {
        categories: labels || ["Category 1", "Category 2", "Category 3"],
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "9px",
          colors:
            theme === "dark"
              ? ["#fff"]
              : theme === "high-contrast"
              ? ["#000"]
              : ["#333"], // Adjusting text color based on theme
        },
      },
      legend: {
        show: true, // Hide legend
        position: "bottom",
        labels: {
          colors:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting legend label color
        },
      },
      tooltip: {
        enabled: true,
        theme: theme === "dark" ? "dark" : "light",
        style: {
          fontSize: "12px",
          background:
            theme === "dark"
              ? "#333"
              : theme === "high-contrast"
              ? "#fff"
              : "#f4f4f4", // Adjusting tooltip background color
          color:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting tooltip text color
        },
      },
      colors: colors,
    };

    const radarSeries = [
      {
        name: chartName,
        data: seriesData,
      },
    ];

    return (
      <div id="radar">
        <ReactApexChart
          options={radarOptions}
          series={radarSeries}
          type="radar"
          width="350"
        />
      </div>
    );
  };

  return (
    <div className="mixed-chart ">
      {seriesData.length === 0 ? (
        <div className="flex items-center justify-center text-center text-gray-500">
          No Records
        </div>
      ) : (
        <>
          <div className="flex justify-between my-5">
            <h3 className="text-sm font-semibold">{chartName}</h3>
            <button
              type="button"
              className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
              onClick={() => setShowModal(true)}
            >
              <IoEye className="w-5 h-5 mr-2" />
            </button>
          </div>
          {chartView === "chart" ? (
            <ChartView />
          ) : chartView === "area" ? (
            <AreaChartView />
          ) : chartView === "radar" ? (
            <RadarChartView />
          ) : (
            <GraphView />
          )}
        </>
      )}

      <div className="mixed-chart  mt-2">
        {" "}
        <ChartModal
          showModal={showModal}
          setShowModal={setShowModal}
          setChartView={setChartView}
        />
      </div>
    </div>
  );
};

export default PieChart;
