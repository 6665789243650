import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMeterInventory, updateInventory } from "../../service/Inventory";
import { toast } from "react-toastify";
import { Spinner } from "../../Spinner";
import { FaArrowLeft } from "react-icons/fa6";
import AppContext from "../../context/AppContext";
import translations from "../common/Translations";

export const UpdateStock = () => {
  const { barcode } = useParams();
  const [meterDetails, setMeterDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { theme, selectedOrgId ,langMode } = useContext(AppContext);

  useEffect(() => {
    const fetchMeterInventory = async () => {
      try {
        setLoading(true);
        const data = await getMeterInventory(barcode, selectedOrgId);
        const { transferredFrom, transferredTo, ...filteredFields } =
          data.body.value;
        setMeterDetails(filteredFields);
      } catch (err) {
        setError("Failed to fetch meter inventory details.");
        toast.error("Failed to fetch meter inventory details.");
      } finally {
        setLoading(false);
      }
    };

    if (barcode) {
      fetchMeterInventory();
    }
  }, [barcode]);

  const navigateToBack = () => {
    navigate("/stock-managment");
  };
  const handleInputChange = (key, value) => {
    setMeterDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const UpdateInventoryDetails = async () => {
    try {
      const success = await updateInventory(meterDetails);
      if (success) {
        toast.success("User status updated successfully");
        navigate("/stock-managment");
      } else {
        toast.error("Failed to update user status");
      }
    } catch (error) {
      toast.error("Failed to update user status");
    }
  };

  useEffect(() => {
    console.log(meterDetails);
  }, [meterDetails]);

  return (
    <>
      <div className="flex items-center mt-5">
        <div
          onClick={navigateToBack}
          className={`p-2 rounded-full border cursor-pointer w-8
            ${
              theme === "dark"
                ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
                : ""
            }
            ${theme === "high-contrast" ? "high-contrast border-gray-600" : ""}
            ${
              theme !== "dark" && theme !== "high-contrast"
                ? "border-gray-400 hover:bg-gray-200"
                : ""
            }`}
        >
          <FaArrowLeft
            className={`h-3 w-3 ${
              theme === "dark"
                ? "text-white"
                : theme === "high-contrast"
                ? "text-black"
                : "text-gray-700"
            }`}
          />
        </div>
        <h4
          className={`ml-4 text-sm font-semibold
          ${theme === "dark" ? "text-white" : "text-gray-800"}
          ${theme === "high-contrast" ? "text-black" : "text-gray-700"}`}
        >
          {translations[langMode].updatemeterDEtail}
        </h4>
      </div>
      <div className=" p-6 rounded-lg shadow-md w-full md:w-3/4 lg:w-1/2 my-5">
        {loading ? (
          <div className="text-center text-gray-500">
            <Spinner />
            <p>Loading meter details...</p>
          </div>
        ) : error ? (
          <p className="text-red-500 text-center">{error}</p>
        ) : meterDetails ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Object.entries(meterDetails).map(
              ([key, value]) =>
                key !== "organizationId" &&
                key !== "id" && (
                  <div key={key} className="flex flex-col">
                    <label
                      className={`text-gray-700 font-medium mb-2 capitalize text-sm ${
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      {key === "op" ? " Operator" : key.replace(/([A-Z])/g, " $1").toLowerCase()}
                    </label>
                    {key === "createdAt" || key === "activationDate" ? (
                      <>
                        <input
                          type="datetime-local"
                          value={
                            value && !isNaN(Date.parse(value)) // Ensure value is valid
                              ? new Date(
                                  new Date(value).getTime() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .slice(0, 16) // Extract date and time (YYYY-MM-DDTHH:MM)
                              : ""
                          }
                         
                          onChange={(e) => {
                            const localDate = new Date(e.target.value);
                            const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000); // Convert to UTC
                            handleInputChange(key, utcDate.toISOString()); // Store in UTC format
                          }}
                          className={`border text-sm rounded-md p-2 text-gray-700 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                            theme === "dark"
                              ? "dark-mode"
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : ""
                          }`}
                        />
                      </>
                    ) : (
                      <input
                        value={value !== null ? value : "N/A"}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                        readOnly={key === "meterBarCode"}
                        className={`border text-sm rounded-md p-2 text-gray-700 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      />
                    )}
                  </div>
                )
            )}
          </div>
        ) : (
          <p className="text-gray-500 text-center">
            No meter details found for barcode {barcode}
          </p>
        )}
      </div>
      <div className="mt-6 flex justify-end space-x-4 border-t pt-4">
        <button
          className="bg-blue-800 text-white rounded-md px-3 py-2 text-sm hover:bg-blue-500 transition duration-200"
          onClick={UpdateInventoryDetails}
        >
           {translations[langMode].save}
        </button>
        <button
          onClick={navigateToBack}
          className="bg-gray-300 text-gray-700 rounded-md px-2 py-2 text-sm hover:bg-gray-400 transition duration-200"
        >
         {translations[langMode].cancel}
        </button>
      </div>
    </>
  );
};
