import React, { useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "../user/static-charts/ChartModal";
import AppContext from "../../../context/AppContext";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

const AreaChart = ({
  tempData = { graphValueList: [] },
  chartName = "Default Chart Name",
}) => {
  const { theme } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("area");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3; // Number of bars to display per page
  const totalBars = tempData?.graphValueList?.length || 0;
  const maxPage = Math.ceil(totalBars / itemsPerPage);
  const paginatedCategories =
    tempData?.graphValueList
      ?.slice(
        currentPage * itemsPerPage,
        Math.min(totalBars, (currentPage + 1) * itemsPerPage)
      )
      .map((item) => item.value || "Unanswered") || [];

  const paginatedData =
    tempData?.graphValueList
      ?.slice(
        currentPage * itemsPerPage,
        Math.min(totalBars, (currentPage + 1) * itemsPerPage)
      )
      .map((item) => item.frequency) || [];

  const colors = ["#FF0000", "#FFA62F", "#0A6847", "#640D6B", "#673F69"];

  const baseOptions = {
    colors,
    tooltip: {
      enabled: true,
      theme: theme === "dark" ? "dark" : "light",
      style: {
        fontSize: "12px",
        background:
          theme === "dark"
            ? "#333"
            : theme === "high-contrast"
            ? "#fff"
            : "#f4f4f4",
        color:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "9px",
        colors:
          theme === "dark"
            ? ["#fff"]
            : theme === "high-contrast"
            ? ["#000"]
            : ["#333"],
      },
    },
    legend: {
      show: true,
      position: "bottom",
      labels: {
        colors:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333",
      },
    },
  };

  const ChartView = () => (
    <ReactApexChart
      options={{
        ...baseOptions,
        labels: paginatedCategories,
        toolbar: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: { show: true },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "9px",
            colors:
              theme === "dark"
                ? ["#fff"]
                : theme === "high-contrast"
                ? ["#000"]
                : ["#fff"], // Adjusting text color based on theme
          },
        },
        legend: {
          show: true, // Hide legend
          position: "bottom",
          labels: {
            colors:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333", // Adjusting legend label color
          },
        },
        tooltip: {
          enabled: true,
          theme: theme === "dark" ? "dark" : "light",
          style: {
            fontSize: "12px",
            background:
              theme === "dark"
                ? "#333"
                : theme === "high-contrast"
                ? "#fff"
                : "#f4f4f4", // Adjusting tooltip background color
            color:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333", // Adjusting tooltip text color
          },
        },
      }}
      series={paginatedData}
      type="donut"
      width="350"
    />
  );

  const GraphView = () => (
    <ReactApexChart
      options={{
        ...baseOptions,
        chart: { type: "bar",
          toolbar: {
            show: false,
          },
         },
        plotOptions: { bar: { distributed: true },
       },
       xaxis: {
        categories: paginatedCategories,
        labels: {
          style: {
            fontSize: "10px", // Adjust the font size if needed
            colors:
              theme === "dark"
                ? ["#fff"]
                : theme === "high-contrast"
                ? ["#000"]
                : ["#333"], // Adjust based on theme
          },
          offsetX: 0,
          formatter: function (value) {
            // Limit label length and add ellipsis
            return value.length > 10 ? value.substring(0, 10) + "..." : value;
          },
        },
      },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "9px",
            colors:
              theme === "dark"
                ? ["#fff"]
                : theme === "high-contrast"
                ? ["#000"]
                : ["#333"],
          },
        },
        legend: {
          show: true,
          position: "bottom",
          labels: {
            colors:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333",
          },
        },
        tooltip: {
          enabled: true,
          theme: theme === "dark" ? "dark" : "light",
          style: {
            fontSize: "12px",
            background:
              theme === "dark"
                ? "#333"
                : theme === "high-contrast"
                ? "#fff"
                : "#f4f4f4",
            color:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333",
          },
        },
      }}
      series={[{ data: paginatedData }]}
      type="bar"
      width="360"
    />
  );

  const AreaChartView = () => (
    <ReactApexChart
      options={{
        ...baseOptions,
        chart: {
           ...baseOptions.chart,
          type: "area",
          toolbar: {
            show: false,
          },
        },

        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: paginatedCategories,
          labels: {
            style: {
              fontSize: "10px", // Adjust the font size if needed
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#333"], // Adjust based on theme
            },
            offsetX: 0,
            formatter: function (value) {
              if (!value) return "N/A"; // Return a default value for undefined or null
              return value.length > 10 ? value.substring(0, 10) + "..." : value;
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "9px",
            colors:
              theme === "dark"
                ? ["#fff"]
                : theme === "high-contrast"
                ? ["#000"]
                : ["#333"], // Adjusting text color based on theme
          },
        },
        legend: {
          show: true, // Hide legend
          position: "bottom",
          labels: {
            colors:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333", // Adjusting legend label color
          },
        },
        tooltip: {
          enabled: true,
          theme: theme === "dark" ? "dark" : "light",
          style: {
            fontSize: "12px",
            background:
              theme === "dark"
                ? "#333"
                : theme === "high-contrast"
                ? "#fff"
                : "#f4f4f4", // Adjusting tooltip background color
            color:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333", // Adjusting tooltip text color
          },
        },
      }}
      series={[{ data: paginatedData }]}
      type="area"
      width="360"
    />
  );

  const RadarChartView = () => (
    <ReactApexChart
      options={{
        ...baseOptions,
        chart: { type: "radar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: paginatedCategories,
          labels: {
            style: {
              fontSize: "10px", // Adjust the font size if needed
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#333"], // Adjust based on theme
            },
            offsetX: 0,
            formatter: function (value) {
              if (!value) return "N/A"; // Return a default value for undefined or null
              return value.length > 10 ? value.substring(0, 10) + "..." : value;
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "9px",
            colors:
              theme === "dark"
                ? ["#fff"]
                : theme === "high-contrast"
                ? ["#000"]
                : ["#333"],
          },
        },
        legend: {
          show: true,
          position: "bottom",
          labels: {
            colors:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333",
          },
        },
        tooltip: {
          enabled: true,
          theme: theme === "dark" ? "dark" : "light",
          style: {
            fontSize: "12px",
            background:
              theme === "dark"
                ? "#333"
                : theme === "high-contrast"
                ? "#fff"
                : "#f4f4f4",
            color:
              theme === "dark"
                ? "#fff"
                : theme === "high-contrast"
                ? "#000"
                : "#333",
          },
        },
      }}
      series={[{ name: chartName, data: paginatedData }]}
      type="radar"
      width="370"
    />
  );

  const handleNext = () => {
    if (currentPage < maxPage - 1) setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="mixed-chart">
      {paginatedData.length === 0 ? (
        <div className="flex items-center justify-center text-center text-gray-500">
          No Records
        </div>
      ) : (
        <>
          <div className="flex justify-between my-5">
            <h3 className="text-sm font-semibold">{chartName}</h3>
            <button
              type="button"
              className="text-xs justify-end font-medium text-center inline-flex items-center"
              onClick={() => setShowModal(true)}
            >
              <IoEye className="w-5 h-5 mr-2" />
            </button>
          </div>
          {chartView === "chart" ? (
            <ChartView />
          ) : chartView === "area" ? (
            <AreaChartView />
          ) : chartView === "radar" ? (
            <RadarChartView />
          ) : (
            <GraphView />
          )}
        </>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          className="disabled:opacity-50"
          onClick={handlePrev}
          disabled={currentPage === 0}
        >
          <MdKeyboardDoubleArrowLeft />
        </button>
        <span className="text-xs">
          Page {currentPage + 1} of {maxPage}
        </span>
        <button
          className="disabled:opacity-50"
          onClick={handleNext}
          disabled={currentPage === maxPage - 1}
        >
          <MdKeyboardDoubleArrowRight />
        </button>
      </div>
      <ChartModal
        showModal={showModal}
        setShowModal={setShowModal}
        setChartView={setChartView}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default AreaChart;
