import React, { useContext, useState, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import UserDashboard from "../../components/dashboard/user/UserDashboard";
import { IoBagHandleSharp } from "react-icons/io5";
import { CgExport } from "react-icons/cg";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import FilterSelect from "../../components/common/FilterSelect";
import { fetchDynamicWorkflows } from "../../service/UserDashboard.js";
import Joyride from "react-joyride";
import LoginModal from "../auth/LoginModal.js";
import translations from "../../components/common/Translations.js";

const DASHBOARDS = {
  USER: "user",
  ACTIVITY: "activity",
};



const Dashboard = () => {
  const navigate = useNavigate();
  const GoExport = () => {
    Cookies.set("viewMode", "dashboard");
    navigate("/pdf-generator");
  };
  const { theme, langMode, selectedOrgId, runTour, dashboard,userType } =
    useContext(AppContext);
  const [activeDashboard, setActiveDashboard] = useState(DASHBOARDS.USER);
  const [loading, setLoading] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const justLoggedIn = localStorage.getItem('justLoggedIn');

    if (justLoggedIn === 'true') {
      setOpen(true); 
      localStorage.removeItem('justLoggedIn');  
    }
  }, []);
  useEffect(() => {
    if (workflows.length > 0) {
      const defaultWorkflow = workflows[0]; 
      setSelectedWorkflowName(defaultWorkflow.workflowName);
      setSelectedWorkflowId(defaultWorkflow.workflowId);
    } else {
      setSelectedWorkflowName("");
      setSelectedWorkflowId("");
    }
  }, [workflows, selectedOrgId]);

  const handleWorkflowChange = (selectedName) => {
    const selectedWorkflow = workflows.find(
      (workflow) => workflow.workflowName === selectedName
    );
    if (selectedWorkflow) {
      setSelectedWorkflowName(selectedWorkflow.workflowName);
      setSelectedWorkflowId(selectedWorkflow.workflowId);
    }
  };

  const switchDashboard = (dashboard) => {
    setLoading(true);
    setTimeout(() => {
      setActiveDashboard(dashboard);
      setLoading(false);
    }, 300);
  };

  useEffect(() => {
    const loadWorkflows = async () => {
      try {
        const workflows = await fetchDynamicWorkflows(selectedOrgId);
        setWorkflows(Array.isArray(workflows) ? workflows : []);
      } catch (error) {
        console.error("Error loading workflows:", error);
        setWorkflows([]);
      }
    };
    loadWorkflows();
  }, [selectedOrgId]);

  const goactivtydashbord = () => {
    navigate("/activty-dashboard");
  };
  return (
    <>
      <Joyride
        steps={dashboard}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff", 
            zIndex: 10000, 
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF", 
            textColor: "#333",  
          },
          tooltip: {
            borderRadius: "10px",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF", 
          },
          buttonBack: {
            color: "#4C3BCF", 
          }
        }}
      />
      <div className={`w-full py-2 ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : "bg-gray-50 "
        } `}>
        <div className="flex justify-between w-full py-3">
          <div className="flex items-center gap-5 " role="group">
           <div className="inline-flex  rounded-md shadow-sm">
           <button
              type="button"
              onClick={() => switchDashboard(DASHBOARDS.USER)}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-l-lg focus:ring-2 focus:ring-blue-700 transition-all
                ${
                  activeDashboard === DASHBOARDS.USER
                    ? "bg-blue-800 text-white hover:bg-blue-700"
                    : "bg-gray-200 text-gray-600 hover:bg-gray-300 border"
                } ${
                  theme === "dark"
                    ? "bg-blue-800 text-white hover:bg-blue-700 border"
                    : theme === "high-contrast"
                    ? "bg-blue-800 text-white hover:bg-blue-700 border "
                    : " "
                } `}
              aria-label="User Dashboard" >
              <FaUserCircle className="h-5 w-5" />
              {translations[langMode].userdashboard}
            </button>
            <button
              type="button"
              onClick={goactivtydashbord}
              className={`${
                theme === "dark"
                  ? "dark-mode border hover:bg-gray-600"
                  : theme === "high-contrast"
                  ? "high-contrast border border-gray-400"
                  : ""
              }  activitydashboard px-4 bg-gray-200 text-gray-600 hover:bg-gray-300 py-2 text-sm font-medium flex items-center gap-2 rounded-r-lg focus:ring-2 focus:ring-blue-700 transition-all `}
              aria-label="Activity Dashboard"    >
              <IoBagHandleSharp className="h-5 w-5" />
              {translations[langMode].activitydashboard}
            </button>
           </div>
          <div className="flex gap-3 chooseworkflow">
            <FilterSelect
              defaultText={translations[langMode].workflow}
              options={workflows.map((workflow) => ({
                id: workflow.workflowId,
                value: workflow.workflowName,
              }))}
              value={selectedWorkflowName}
              onChange={handleWorkflowChange}
            />
          </div> 
          </div>
          <button
            type="button"
            onClick={GoExport}
            className="importpdf px-4 gap-[24px] h-8 items-center inline-flex text-xs text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-500 font-medium rounded-lg transition-all"
          >
            <span className="bg-white rounded-full p-1">
              <CgExport size={12} className="text-yellow-500" />
            </span>
            {translations[langMode].pdf}
          </button>
        </div>
          <div className={`${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : "bg-gray-50 "
        }  flex w-full mt-3 `}>
          {activeDashboard === DASHBOARDS.USER && (
            <UserDashboard
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          )}
        </div>
        <LoginModal open={open} setOpen={setOpen} />
      </div>

    </>
  );
};

export default Dashboard;
