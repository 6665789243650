import React, { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import translations from "../../../common/Translations";
import AppContext from "../../../../context/AppContext";
import { RxCross2 } from "react-icons/rx";

export const DateFilter = ({
  dateFilter,
  setDateFilter,
  toDate,
  setToDate,
  fromDate,
  setFromDate,
}) => {
  const cancelButtonRef = useRef(null);
  const { theme, langMode } = useContext(AppContext);
  const [tempFromDate, setTempFromDate] = useState(fromDate);
  const [tempToDate, setTempToDate] = useState(toDate);

  const formatDateForInput = (dateStr) => {
    return dateStr ? dateStr : "";
  };

  // Handler for applying the selected dates
  const handleApply = () => {
    if (tempFromDate) {
      setFromDate(tempFromDate);
    } else if (tempToDate) {
      setToDate(tempToDate);
    }

    setDateFilter(false);
  };

  return (
    <Transition.Root show={dateFilter} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setDateFilter(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <div
                  className={`rounded-lg shadow p-5 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                     {translations[langMode].datefilterforchart}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setDateFilter(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="flex gap-2 py-2">
                    <div className="flex flex-col">
                      <label className="mb-1 text-sm">
                        {translations[langMode]?.fromdate || "From Date"}
                      </label>
                      <input
                        type="datetime-local"
                        value={formatDateForInput(tempFromDate) || fromDate}
                        onChange={(e) => setTempFromDate(e.target.value)}
                        className={`border p-2 rounded-md w-full ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-1 text-sm">
                        {translations[langMode]?.todate || "To Date"}
                      </label>
                      <input
                        type="datetime-local"
                        value={formatDateForInput(tempToDate) || toDate}
                        onChange={(e) => setTempToDate(e.target.value)}
                        className={`border p-2 rounded-md w-full ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <button
                      type="button"
                      onClick={handleApply}
                      className="bg-blue-800 text-white h-10 px-4 mt-6 rounded-md hover:bg-blue-600 focus:outline-none w-full"
                    >
                      {translations[langMode].apply}
                    </button>
                    <button
                      type="button"
                      onClick={() => setDateFilter(false)}
                      className="bg-gray-500 text-white h-10 px-4 mt-6 rounded-md hover:bg-gray-600 focus:outline-none w-full"
                    >
                      {translations[langMode].cancel}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
