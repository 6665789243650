import React, { useContext, useEffect, useState } from "react";
import { CiStar } from "react-icons/ci";
import WorkflowMainToolbar from "../../components/workflow/WorkflowMainToolbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modals/Modal";
import AppContext from "../../context/AppContext";
import {
  fetchData,
  deleteWorkflow,
  fetchWorkflowData,
} from "../../service/workflow";
import Cookies from "js-cookie";
import DeletePermission from "../../components/suspence/DeletePermission";
import { RiDeleteBin2Fill, RiEditBoxFill } from "react-icons/ri";
import { TbCopyPlusFilled } from "react-icons/tb";
import Joyride from "react-joyride";
import { Spinner } from "../../Spinner";

const translations = {
  en: {
    name: "Workflow",
  },
  ar: {
    name: "سير العمل",
  },
};
const WorkflowMain = () => {
  const [dynamicWorkflow, setdynamicWorkflow] = useState([]);
  const [getFormData, setgetFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [clone, setClone] = useState(false);
  const [refreshPage] = useState(false); // New state variable
  const navigate = useNavigate();
  const { theme, selectedOrgId, workflow, runTour, langMode,userType } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  const loadData = async () => {
    setLoading(true);
    try {
      const data = await fetchData(selectedOrgId, userType);
      setdynamicWorkflow(data);
      setLoading(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [refreshPage, selectedOrgId]);

  const handleDeleteClick = (workflowId) => {
    setWorkflowToDelete(workflowId);
    setShowDeleteModal(true);
  };

  const handleDeleteWorkflow = async (workflowId) => {
    try {
      const successMessage = await deleteWorkflow(workflowId);
      toast.success(successMessage);
      const updatedDynamicWorkflow = dynamicWorkflow.filter(
        (item) => item.workflowId !== workflowId
      );
      setdynamicWorkflow(updatedDynamicWorkflow);
      setShowDeleteModal(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditClick = async (workflowId) => {
    try {
      const {
        workflowFields,
        existWorkflowName,
        existPdfTitleName,
        workflowTimer,
        existDragItems,
        existRadioButtons,
      } = await fetchWorkflowData(workflowId);
      navigate("/workflow-form", {
        state: {
          workflowId,
          workflowFields,
          existWorkflowName,
          existPdfTitleName,
          workflowTimer,
          existDragItems,
          existRadioButtons,
          isEdit: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCloneClick = async (workflowId) => {
    try {
      const {
        workflowFields,
        existPdfTitleName,
        existWorkflowName,
        existDragItems,
        existRadioButtons,
      } = await fetchWorkflowData(workflowId);
      navigate("/workflow-form", {
        state: {
          workflowFields,
          existWorkflowName,
          existPdfTitleName,
          existDragItems,
          existRadioButtons,
          isClone: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Joyride
        steps={workflow}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
          },
          tooltip: {
            borderRadius: "10px",
            transition: "transform 0.3s ease, opacity 0.3s ease",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
            transition: "background-color 0.3s ease",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
          spotlight: {
            transition: "box-shadow 0.5s ease",
          },
        }}
      />

      <div className="w-full py-1">
        <h4
          className={`m-5 text-sm uppercase font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          {translations[langMode].name}
        </h4>
        <div
          className={`${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast "
              : ""
          }  px-5 py-5 bg-white`}
        >
          <WorkflowMainToolbar />
          <div className="px-14 p-2 h-[520px] overflow-y-auto">
            {Array.isArray(dynamicWorkflow) && dynamicWorkflow.length > 0 ? (
              <ul role="list" className=" ">
                {dynamicWorkflow.map((item, index) => (
                  <li
                    key={index}
                    className={`${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast "
                        : ""
                    } flex justify-between items-center gap-x-6 py-3 px-3 m-2 rounded-md border-2 border-indigo-500 bg-white shadow-xl hover:shadow-2xl  transition duration-300 ease-in-out transform hover:scale-105`}
                  >
                    <div className="flex min-w-0 items-center gap-x-4">
                      <CiStar className="text-md" />
                      <div className="min-w-0 flex-auto">
                        <p
                          className={`text-sm font-semibold leading-6 text-gray-900 ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast "
                              : ""
                          }`}
                        >
                          <span>{item.workflowName}</span>
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                          Updated on{" "}
                          {item.updatedAt
                            ? new Date(item.updatedAt).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )
                            : new Date(item.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <div className="items-center">
                        <p
                          className={`ext-sm font-semibold  text-gray-700 cursor-pointer cloneworkflow ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                          }`}
                          onClick={() => handleCloneClick(item.workflowId)}
                        >
                          <TbCopyPlusFilled
                            size={20}
                            className={`text-lg ml-2 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          />
                          Clone
                        </p>
                      </div>
                      <div className="">
                        <p
                          className={`text-sm font-semibold  text-gray-700 cursor-pointer editworkflow ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                          }`}
                          onClick={() =>
                            handleEditClick(
                              item.workflowId,
                              item.workflowName,
                              item.timer
                            )
                          }
                        >
                          <RiEditBoxFill
                            className={`text-lg  text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          />
                          Edit
                        </p>
                      </div>
                      <div
                        className=""
                        onClick={() => handleDeleteClick(item.workflowId)}
                      >
                        <p
                          className={`cursor-pointer text-sm font-semibold  text-gray-700 deleteworkflow ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                          }`}
                        >
                          <RiDeleteBin2Fill
                            size={20}
                            className={` ml-2 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          />
                          Delete
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-center">
                No workflows available.
              </p>
            )}
            {loading && <Spinner />}
          </div>
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          clone={clone}
          setClone={setClone}
          getFormData={getFormData}
          loadData={loadData}
        />
        {showDeleteModal && (
          <DeletePermission
            onClose={() => setShowDeleteModal(false)}
            deleteFunction={() => handleDeleteWorkflow(workflowToDelete)}
            onDeleteText="Are you sure you want to delete this workflow?"
          />
        )}
      </div>
    </>
  );
};

export default WorkflowMain;
