import {
    FaCamera,
    FaCheckSquare,
    FaHeading,
    FaPhoneAlt,
    FaUser,
  } from "react-icons/fa";
  import { MdDateRange, MdEmail } from "react-icons/md";
  import { IoLocationSharp, IoTime } from "react-icons/io5";
  import { PiSignature } from "react-icons/pi";
  import { IoIosRadioButtonOn, IoMdQrScanner } from "react-icons/io";
  import { RiDropdownList } from "react-icons/ri";
  import { TbSquareRoundedNumber7Filled } from "react-icons/tb";
  import { BiText } from "react-icons/bi";
  import { CiBarcode } from "react-icons/ci";

export const Elements= [
    {
      value: "",
      fieldName: "Heading",
      icon: <FaHeading />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Heading",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Name",
      icon: <FaUser />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Name",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Email",
      icon: <MdEmail />,
      fieldType: "email",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Email",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Phone",
      icon: <FaPhoneAlt />,
      fieldType: "number",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Phone",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Address",
      icon: <IoLocationSharp />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Address",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Date Picker",
      icon: <MdDateRange />,
      fieldType: "date",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Date Picker",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Take Photo",
      icon: <FaCamera />,
      fieldType: "file",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Take Photo",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Signature",
      icon: <PiSignature />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Signature",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Radio Button",
      icon: <IoIosRadioButtonOn />,
      fieldType: "radio",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Radio Button",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Checkbox",
      icon: <FaCheckSquare />,
      fieldType: "checkbox",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Checkbox",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Dropdown",
      icon: <RiDropdownList />,
      fieldType: "select",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Dropdown",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Number",
      icon: <TbSquareRoundedNumber7Filled />,
      fieldType: "number",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Number",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Time",
      icon: <IoTime />,
      fieldType: "time",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Time",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Long Text",
      icon: <BiText />,
      fieldType: "textarea",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Long Text",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Barcode",
      icon: <CiBarcode />,
      fieldType: "scan",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Barcode",
      validationValues: [],
    },
    {
      value: "",
      fieldName: "Scanning",
      icon: <IoMdQrScanner />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
      isPdf: false,
      cloneName: "Scanning",
      validationValues: [],
    },
  ];


export const Translations = {
    en: {
      workflow: "Workflow Maker",
      entername: " Enter name",
      title: "Title",
      selectTimer: "Select Timer",
      element: "Elements",
      parameter: "Parameters",
      mandatory: "Mandatory",
      excel: "Excel",
      pdf: "PDF",
      selectoption: " Select Option",
      editInputvalue: "Edit Input Values",
      selectedOption: " Selected Option",
      Que: "Que",
      option: "Option",
      addremove: "Add/Remove Options",
      clone: "Clone",
      update: "Update",
      Submit: "Submit",
      preview: "Preview",
    },
    ar: {
      workflow: "صانع سير العمل",
      entername: "أدخل الاسم",
      title: "عنوان",
      selectTimer: "حدد الموقت",
      element: "عناصر",
      parameter: "حدود",
      mandatory: "إلزامي",
      excel: "اكسل",
      pdf: "قوات الدفاع الشعبي",
      selectoption: "حدد الخيار",
      editInputvalue: "تحرير قيم الإدخال",
      selectedOption: " الخيار المحدد:",
      Que: "سؤال",
      option: "خيار",
      addremove: "إضافة/إزالة الخيارات",
      clone: "استنساخ",
      update: "تحديث",
      Submit: "يُقدِّم",
      preview: "معاينة",
    },
    ur: {
      workflow: "ورک فلو بنانے والا",
      entername: " نام درج کریں۔",
      title: "عنوان",
      selectTimer: "ٹائمر منتخب کریں۔",
      element: "عناصر",
      parameter: "پیرامیٹرز",
      mandatory: "لازمی",
      excel: "لازمی",
      pdf: "PDF",
      selectoption: " اختیار منتخب کریں۔",
      editInputvalue: "ان پٹ ویلیوز میں ترمیم کریں۔",
      selectedOption: " منتخب کردہ آپشن",
      Que: "سوالات",
      option: "آپشن",
      addremove: "اختیارات شامل کریں/ہٹائیں۔",
      clone: "کلون",
      update: "اپ ڈیٹ کریں۔",
      Submit: "جمع کروائیں۔",
      preview: "پیش نظارہ",
    },
  };


export const Parameters = [
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Account number",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Visit time",
      fieldType: "time",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "GIS X, GIS Y",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Meter Number",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Type of Meter",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Outstanding Due",
      fieldType: "number",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Priority",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Status",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Address",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Consumer Name",
      fieldType: "text",
    },
    {
      isReport: false,
      isMandatory: false,
      fieldName: "Consumer Mobile",
      fieldType: "number",
    },
  ];