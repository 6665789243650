import React, { useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import AppContext from "../../context/AppContext";
const translation = {
  en: {
    create:"Create Validation",
    code:"Validation Code",
    msg:"Error Message",
    cancle:"Cancel",
    add:"Add"
  },
  ar: {
    create:"إنشاء التحقق من الصحة",
    code:"رمز التحقق",
    msg:"رسالة خطأ",
    cancle:"يلغي",
    add:"يضيف"
  },
};
const ValidationModal = ({
  onCloseValidation,
  handleCreateValidation,
  currentValidation,
  setCurrentValidation,
  validationFormValues,
  previewKey,
  handleValidationDelete,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentValidation((prev) => ({ ...prev, [name]: value }));
  };
  const filteredValidations = validationFormValues.filter(
    (input) => input.uniqueKey === previewKey
  );

  const { theme , langMode} = useContext(AppContext);
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div
        className={`bg-white rounded-lg p-6 relative max-w-lg w-full overflow-y-auto h-[300px]  ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onCloseValidation}
        >
          <RxCross2 />
        </button>
        <form onSubmit={handleCreateValidation}>
          <h2 className="text-md font-semibold mb-4"> {translation[langMode].create}</h2>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="validationCode"
                className={` ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }block text-sm font-medium text-gray-700`}
              >
                 {translation[langMode].code}
              </label>
              <input
                id="validationCode"
                name="validationCode"
                value={currentValidation.validationCode}
                onChange={handleChange}
                className={`w-full border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 
                  focus:border-blue-500  ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
              />
            </div>
            <div>
              <label
                htmlFor="errorMessage"
                className={`block text-sm font-medium text-gray-700  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                 {translation[langMode].msg}
              </label>
              <input
                id="errorMessage"
                name="errorMessage"
                value={currentValidation.errorMessage}
                onChange={handleChange}
                className={`w-full border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5
                   focus:ring-blue-500 focus:border-blue-500  ${
                     theme === "dark"
                       ? "dark-mode "
                       : theme === "high-contrast"
                       ? "high-contrast"
                       : ""
                   }`}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onCloseValidation}
                className="px-4 py-2 bg-gray-200 text-gray-800 font-semibold rounded-md hover:bg-gray-300"
              >
                {translation[langMode].cancle}
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-800 text-white rounded-md font-semibold hover:bg-blue-700"
              >
                {translation[langMode].add}
              </button>
            </div>
          </div>
        </form>
        {filteredValidations.length > 0 && (
          <div className="mt-14">
            <h3 className="text-md font-bold mb-2">Added Validations</h3>
            {filteredValidations.map((input) => (
              <div
                key={input.validationId}
                className="flex items-center justify-between p-2 border-b border-gray-200"
              >
                <div>
                  <p className="text-sm font-semibold">
                    Validation Code: {input.validationCode}
                  </p>
                  <p className="text-sm">Error Message: {input.errorMessage}</p>
                </div>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() =>
                    handleValidationDelete(
                      input.validationId,
                      input.uniqueKey,
                      input.id
                    )
                  }
                >
                  <RxCross2 size={18} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ValidationModal;
