import axios from "axios";
import Cookies from "js-cookie"

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const fetchWorkLogs = async ({ page, size, workflowId, selectedOrgId, selectedPriority, dates,accountNo }) => {
    const token = Cookies.get("accessToken");
    const response = await axios.get(
      `${baseURL}api/v1/task/work-log?page=${page}&size=${size}&workflowId=${workflowId}&organizationId=${selectedOrgId}&priority=${selectedPriority}&fromDate=${dates.fromDate}&toDate=${dates.toDate}&accountNo=${accountNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.body.value;
  };

  export const createMailReport = async (payload) => {
    const token = Cookies.get("accessToken");
    try {
      const response = await axios.post(
        `${baseURL}api/v1/schedule-report/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  };

  export const getScheduleReport = async () => {
    const token = Cookies.get("accessToken");
  
    try {
      const response = await axios.get(
        `${baseURL}api/v1/schedule-report/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.body.value;
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  };

  export const updateMailReport = async (payload) => {
    const token = Cookies.get("accessToken");
    try {
      const response = await axios.post(
        `${baseURL}api/v1/schedule-report/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  };