import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { taskHistoryDetails } from "../../service/Task";
import Cookies from "js-cookie";
import ImportModal from "./ImportModal";
import { CgExport } from "react-icons/cg";
import { Spinner } from "../../Spinner";
import translations from "../../components/common/Translations";

const TaskHistory = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [accountDetails, setaccountDetails] = useState({});
  const [accountHistory, setAccountHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { selectedOrgId, langMode, theme } = context;
  const [open, setOpen] = useState(false);
  const accountNumberFetch = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleChange = (e) => {
    console.log(e.target.value);
  };

  const clearData = () => {
    setAccountNumber("");
    setaccountDetails({});
    setAccountHistory([]);
  };

  const fetchTaskHistory = async () => {
    setIsLoading(true);
    try {
      const userTypeCookie = Cookies.get("userType");
      const organizationId =
        userTypeCookie === "super_admin" ? selectedOrgId : "";
      const responseData = await taskHistoryDetails(
        accountNumber,
        organizationId
      );
      if (responseData?.header?.code === 600) {
        const accountDetails =
          responseData.body.value.accountDetails.accountDetailsJson;

        setaccountDetails(accountDetails); // Set the entire object

        setAccountHistory(responseData.body.value.accountHistory);
        toast.success("Data fetched successfully");
      } else {
        toast.error("Error: Unable to fetch task history");
      }
    } catch (error) {
      console.error("Error fetching task history:", error);
      toast.error("An error occurred while fetching the task history");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(accountHistory);
  }, [accountHistory]);

  const handlImportModal = () => {
    setOpen(true);
  };
  return (
    <div className="w-full py-1">
      <h4
        className={` m-5 text-sm uppercase font-semibold text-gray-800 ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        {translations[langMode].taskhistory}
      </h4>
      <div
        className={`bg-white px-5 rounded-md py-4 ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="px-4 sm:px-0 flex justify-between">
          <h3
            className={`text-sm font-semibold leading-7 text-gray-900 ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            {translations[langMode].acchistory}
          </h3>
          <button
            className="px-2 py-2 gap-3 mr-14 inline-flex text-sm rounded-md bg-blue-800 text-white font-medium hover:bg-blue-700 transition-colors  items-center"
            onClick={handlImportModal}
          >
            <CgExport size={20} />
            {translations[langMode].importdb}
          </button>
        </div>
        <div className="flex flex-col py-1 overflow-y-auto w-full">
          <div className="p-4">
            <label
              className={`${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              } text-gray-700  text-sm  font-medium block mb-2`}
            >
              {translations[langMode].enteraccno}
            </label>
            <input
              type="text"
              className={` ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              } w-full max-w-xs px-3 py-2 text-sm rounded-md border border-gray-300 
              focus:ring-2 focus:ring-blue-500 focus:outline-none transition-shado`}
              onChange={accountNumberFetch}
              value={accountNumber}
              placeholder={translations[langMode].enteraccno}
            />
            <div className="flex justify-start py-2 gap-4">
              <button
                className="px-2 py-2 text-sm rounded-md bg-yellow-400 text-white font-medium hover:bg-yellow-500 transition-colors"
                onClick={clearData}
              >
                {translations[langMode].reset}
              </button>
              <button
                onClick={fetchTaskHistory}
                className="px-2 py-2  text-sm rounded-md bg-blue-800 text-white font-medium hover:bg-blue-700 transition-colors flex items-center"
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                ) : (
                  translations[langMode].submit
                )}
              </button>
            </div>
          </div>
          <div
            className={`relative sm:rounded-lg py-2 bg-white shadow-lg  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            <h2
              className={`text-gray-800 dark:text-gray-100 text-sm font-semibold mb-4 ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              {translations[langMode].accDetail}
            </h2>
            <div className="w-full overflow-x-auto">
              <table className= "text-sm text-left text-gray-700 dark:text-gray-400">
                <thead
                  className={`   ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  } text-xs text-gray-700 uppercase border-b bg-gray-100 `}
                >
                  <tr>
                    {Object.keys(accountDetails).map((key) => (
                      <th key={key} scope="col" className="px-6 py-3 text-nowrap">
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={`bg-white  border-b hover:bg-gray-50 ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                  >
                    {Object.entries(accountDetails).map(([key, value]) => (
                      <td key={key} className="px-6 py-4">
                        {value ? (
                          value
                        ) : (
                          <input
                            type="text"
                            name={key}
                            value={value}
                            onChange={handleChange}
                            className={`w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ${
                              theme === "dark"
                                ? "dark-mode"
                                : theme === "high-contrast"
                                ? "high-contrast"
                                : ""
                            }`}
                            placeholder={`Enter ${key}`}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div>
              {accountHistory.map((workflow, index) => (
                <div key={index} className="relative sm:rounded-lg py-7">
                  <h2 className={`${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    } text-gray-800 text-md font-semibold`}>
                    {workflow.workflowName} History
                  </h2>
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm shadow-md text-left rtl:text-right text-gray-500 dark:text-gray-400 my-5">
                      <thead className={`${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    } text-xs text-gray-700 uppercase border-b bg-gray-100 `}>
                        <tr>
                          <th className="px-6 py-3">Datetime</th>
                          <th className="px-6 py-3">TaskId</th>
                          <th className="px-6 py-3">Finshed Date</th>
                          <th className="px-6 py-3">Finished Time</th>
                          <th className="px-6 py-3">Assignee Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {workflow.accountHistoryList.map((history, idx) => (
                          <tr
                            key={idx}
                            className={`bg-white  border-b dark:border-gray-700 ${
                              theme === "dark"
                                ? "dark-mode"
                                : theme === "high-contrast"
                                ? "high-contrast"
                                : ""
                            }`}
                          >
                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {history.visitDate} {history.vistTime}
                            </th>
                            <td className="px-6 py-4">{history.taskId}</td>
                            <td className="px-6 py-4">
                              {history.finishedDate}
                            </td>
                            <td className="px-6 py-4">
                              {history.finishedTime}
                            </td>
                            <td className="px-6 py-4">
                              {history.assigneeName}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <ImportModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        setLoading={setLoading}
      />
      {loading && <Spinner />}
    </div>
  );
};
export default TaskHistory;
