import React, {  useEffect, useRef, useCallback } from "react";

const WorkflowVersions = ({
  hasMore,
  handleVersionClick,
  loading,
  workflowHistories,
  pages, setPages
}) => {
  const listInnerRef = useRef();

  const handleScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        hasMore &&
        !loading
      ) {
        setPages((prevPage) => prevPage + 1);
      }
    }
  }, [hasMore, loading]);

  useEffect(() => {
    if (pages > 0) {
      handleVersionClick(pages);
    }
  }, [pages]);

  return (
    <div className="block max-w-sm p-2 bg-white border border-blue-200 rounded-md shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="flex justify-between content-center item-center">
        <div className="w-full rounded-lg">
          <span className="text-sm font-semibold py-2">Versions</span>
          <div
            className="max-h-[150px] h-auto overflow-y-auto"
            onScroll={handleScroll}
            ref={listInnerRef}
          >
            {workflowHistories.length > 0 ? (
              workflowHistories.map((history, index) => (
                <div
                  key={history.id || index} // Use index as a fallback key to avoid potential duplicate keys
                  className="p-2 mb-1 my-2 border border-blue-400 rounded-md flex justify-between items-center hover:bg-gray-200"
                >
                  <div>
                    <div className="font-semibold text-sm">
                      {history.updatedDateTime}
                    </div>
                    <div className="text-gray-600 text-sm">
                      Updated by: {history.updatedByName}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-600">No versions available</div>
            )}
            {loading && <div>Loading more versions...</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowVersions;
