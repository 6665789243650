import React, { useContext, useState, useRef, Fragment } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { Spinner } from "../../Spinner";
import { generateInventoryReport } from "../../service/Inventory";
import translations from "../common/Translations";

export const DownloadReport = ({ openmodal, setOpenModal }) => {
  const { theme, selectedOrgId , langMode } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const cancelButtonRef = useRef(null);

  const navigateToBack = () => {
    setOpenModal(false);
  };

  const reportDownloadInventory = async () => {
    try {
      setLoading(true); 
      const data = await generateInventoryReport(selectedOrgId, startDate, endDate);
      
      if (data.header.code === 600) {
        const s3Link = data.body.value; 
        toast.success("Report generated successfully!");
        const link = document.createElement('a');
        link.href = s3Link;
        link.setAttribute('download', 'inventory_report.xlsx'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        navigateToBack()
      } else if (data.header.code === 607) {
        toast.error(data.body.value);
      }
    } catch (error) {
      console.error("Error while generating report:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Transition.Root show={openmodal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpenModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity `}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                {loading && <Spinner />}
                <div className="flex justify-between items-center mt-5 mx-5">
                  <h4
                    className={`text-sm font-semibold
          ${theme === "dark" ? "text-white" : "text-gray-800"}
          ${theme === "high-contrast" ? "text-black" : "text-gray-700"}`}
                  >
                    {translations[langMode].downloadsheet}
                  </h4>
                  <RxCross2 onClick={navigateToBack} />
                </div>
                <div
                  className={` p-5 flex flex-col gap-5 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex gap-6">
                    <div className="flex flex-col">
                      <label className="text-sm ">From Date</label>
                      <input
                        type="date"
                        className={`${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        } rounded-md text-sm `}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm ">To Date</label>
                      <input
                        type="date"
                        className={`${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        } rounded-md text-sm `}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-5">
                    <button
                      className={`mt-auto bg-yellow-400 hover:bg-yellow-500 text-white px-2 py-2 text-sm rounded-md ${
                        theme === "dark"
                          ? ""
                          : theme === "high-contrast"
                          ? ""
                          : ""
                      }`}
                      onClick={reportDownloadInventory}
                    >
                     {translations[langMode].save}
                    </button>
                    <button
                      onClick={navigateToBack}
                      className={`mt-auto bg-gray-200 hover:bg-gray-300 text-gray-700 px-2 py-2 text-sm rounded-md ${
                        theme === "dark"
                          ? ""
                          : theme === "high-contrast"
                          ? ""
                          : ""
                      }`}
                    >
                      {translations[langMode].cancel}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
