import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCompletedTask } from "../../../service/Task";
import { FaArrowLeft } from "react-icons/fa6";
import AppContext from "../../../context/AppContext";
import { Spinner } from "../../../Spinner";
import AWS from "aws-sdk";
import { updateCompletedTask } from "../../../service/Task";
import { deleteS3Object, uploadFileToS3 } from "../../../service/S3Bucket";
import { toast } from "react-toastify";
import translations from "../../common/Translations";
export default function UpdateTask() {
  const { taskId } = useParams();
  const [taskDetail, setTaskDetail] = useState([]);
  const { theme ,langMode} = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatedFiles, setUpdatedFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    const getTaskData = async () => {
      try {
        const taskData = await fetchCompletedTask(taskId);
        setTaskDetail(taskData.body?.value?.fieldList || []);
      } catch (error) {
        console.error("Error fetching completed task:", error);
        setError("Failed to load task details.");
      } finally {
        setLoading(false);
      }
    };

    if (taskId) {
      getTaskData();
    }
  }, [taskId]);

  const handleFileChange = (fieldKey, event) => {
    const file = event.target.files[0];
    if (file) {
      setUpdatedFiles((prevFiles) => ({
        ...prevFiles,
        [fieldKey]: file,
      }));
    }
  };

  const handleChange = (fieldKey, event) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldKey]: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    const fieldsData = { taskId };
    setIsLoading(true);

    for (const field of taskDetail) {
      if (field.fieldType === "file" && updatedFiles[field.uniqueKey]) {
        if (field.value) {
          try {
            await deleteS3Object(field.value);
          } catch (deleteError) {
            console.error(`Failed to delete file ${field.value}:`, deleteError);
            toast.error("Error deleting existing file. Please try again.");
            return;
          }
        }
        try {
          const uploadedFilePath = await uploadFileToS3(
            updatedFiles[field.uniqueKey]
          );
          fieldsData[field.uniqueKey] = uploadedFilePath;
        } catch (uploadError) {
          console.error(`Failed to upload file:`, uploadError);
          toast.error("Error uploading file. Please try again.");
          return;
        }
      } else {
        fieldsData[field.uniqueKey] =
          fieldValues[field.uniqueKey] || field.value;
      }
    }
    try {
      const response = await updateCompletedTask(fieldsData);
      if (response.header.code === 600) {
        toast.success(response.body.value);
        navigate("/task-manage");
        setIsLoading(false);
      } else {
        toast.error(response.body.value);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to update task:", error);
      toast.error("Error updating task. Please try again.");
      setIsLoading(false);
    }
  };

  const renderField = (field, index) => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
      case "date":
      case "time":
      case "scan":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              {index + 1}. {field.fieldName} {/* Add the index number here */}
            </label>
            <input
              type={field.fieldType}
              defaultValue={fieldValues[field.uniqueKey] || field.value}
              key={field.uniqueKey}
              onChange={(e) => handleChange(field.uniqueKey, e)}
              className="border border-gray-300 p-2 mt-1 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
            />
          </div>
        );
      case "textarea":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              {index + 1}. {field.fieldName} {/* Add the index number here */}
            </label>
            <textarea
              defaultValue={fieldValues[field.uniqueKey] || field.value}
              key={field.uniqueKey}
              onChange={(e) => handleChange(field.uniqueKey, e)}
              className="border border-gray-300 p-2 mt-1 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
              rows="4"
            ></textarea>
          </div>
        );
      case "radio":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              {index + 1}. {field.fieldName} {/* Add the index number here */}
            </label>
            <div className="mt-2 space-y-2">
              {field.radioInputValues.map((option) => (
                <div
                  key={`${field.uniqueKey}-${option}`}
                  className="flex items-center"
                >
                  <input
                    type="radio"
                    id={`${field.uniqueKey}-${option}`}
                    name={field.uniqueKey}
                    value={option}
                    defaultChecked={field.value === option}
                    className="mr-2 focus:ring-indigo-500"
                    disabled
                  />
                  <label
                    htmlFor={`${field.uniqueKey}-${option}`}
                    className="text-sm text-gray-700"
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );
      case "checkbox":
        return (
          <div key={field.uniqueKey} className="mb-4 flex items-center">
            <input
              type="checkbox"
              defaultChecked={field.value === "true" || field.value === true}
              key={field.uniqueKey}
              className="mr-2 focus:ring-indigo-500"
              disabled
            />
            <label className="text-sm text-gray-700">
              {index + 1}. {field.fieldName} {/* Add the index number here */}
            </label>
          </div>
        );
      case "file":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              {index + 1}. {field.fieldName} {/* Add the index number here */}
            </label>
            {field.value ? (
              <div className="mt-2">
                {field.value.match(/\.(jpeg|jpg|gif|png|svg|webp)$/i) ? (
                  <div className="w-32 h-32 border border-gray-300 rounded-lg overflow-hidden">
                    <img
                      src={field.value}
                      alt="Uploaded file preview"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <a
                    href={field.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-500 underline hover:text-indigo-700 mb-2 block"
                  >
                    View Existing File
                  </a>
                )}
              </div>
            ) : (
              <p className="text-sm text-gray-500 mb-2">No file uploaded.</p>
            )}
            <input
              type="file"
              onChange={(e) => handleFileChange(field.uniqueKey, e)}
              className="border border-gray-300  mt-1 w-full rounded-lg focus:ring-2 focus:ring-indigo-500"
            />
          </div>
        );

      case "select":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              {index + 1}. {field.fieldName}
            </label>
            <select
              value={field.value || ""}
              disabled
              className="border border-gray-300 p-2 mt-1 w-full rounded-lg bg-gray-100 focus:outline-none"
            >
              {field.radioInputValues?.map((option, optionIndex) => (
                <option
                  key={`${field.uniqueKey}-option-${optionIndex}`}
                  value={option}
                >
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
    }
  };

  if (loading) {
    return <div className="text-center p-4">Loading task details...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }
  const handleGoBack = () => {
    navigate("/task-manage");
    return (
      <div>
        <Spinner />
      </div>
    );
  };

  return (
    <div className="">
      {isLoading && <Spinner />}
      <div className="inline-flex">
        <div
          onClick={handleGoBack}
          className={`p-2 rounded-full border cursor-pointer w-8 m-3 ml-5
        ${
          theme === "dark"
            ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
            : ""
        } 
        ${
          theme === "high-contrast"
            ? "high-contrast border border-gray-600"
            : ""
        } 
        ${
          theme !== "dark" && theme !== "high-contrast"
            ? "border-gray-400 hover:bg-gray-200"
            : ""
        } `}
        >
          <FaArrowLeft
            className={`h-3 w-3 ${
              theme === "dark"
                ? "text-white"
                : theme === "high-contrast"
                ? "text-black"
                : "text-gray-700"
            }`}
          />
        </div>
        <h2 className="text-md font-semibold mt-3 ">{translations[langMode].updatetaskdetail}</h2>
      </div>
      <div className="p-5">
        <div className="max-w-full  rounded-md">
          {taskDetail.length === 0 ? (
            <div className="text-center text-gray-500">No data available</div>
          ) : (
            <form className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {taskDetail.map((field, index) => renderField(field, index))}
            </form>
          )}
        </div>
        <div className="flex gap-5 justify-end">
          <button
            type="submit"
            onClick={handleSubmit}
            className=" bg-blue-800 text-white text-xs py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
          >
            {translations[langMode].save}
          </button>
          <button
            onClick={handleGoBack}
            className=" bg-black text-white text-xs py-2 px-4 rounded-md  transition duration-300"
          >
            {translations[langMode].cancel}
          </button>
        </div>
      </div>
    </div>
  );
}
