import React, { useContext, useState, useRef, Fragment } from "react";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { Spinner } from "../../Spinner";
import translations from "../common/Translations";

export const UploadExcelReport = ({ open, setOpen ,fetchInventoryData }) => {
  const { theme, selectedOrgId ,langMode,userType} = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const cancelButtonRef = useRef(null);

  const navigateToBack = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const UploadInventoryReport = async () => {
    const token = Cookies.get("accessToken");
    if (!file) {
      console.error("No file selected");
      return;
    }
    const formData = new FormData();
    formData.append("excel", file);
    if (userType === "super_admin" && selectedOrgId) {
      formData.append("organizationId", selectedOrgId);
    }
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/meter-inventory/excel-upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        toast.success("File uploaded successfully");
        setOpen(false);
        setLoading(false);
        fetchInventoryData()
      } else {
        toast.error("Failed to upload file");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity `}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                {loading && <Spinner />}
                <div className="flex justify-between items-center mt-5 mx-5">
                  <h4
                    className={`text-sm font-semibold
          ${theme === "dark" ? "text-white" : "text-gray-800"}
          ${theme === "high-contrast" ? "text-black" : "text-gray-700"}`}
                  >
                    {translations[langMode].uploadinventory}
                  </h4>
                  <RxCross2 onClick={navigateToBack} />
                </div>
                <div
                  className={`bg-white p-5 flex flex-col ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    className="mb-3 border border-gray-600 rounded-md w-64 "
                  />
                  <div className="flex justify-end gap-5">
                    <button
                      onClick={UploadInventoryReport}
                      className={`mt-auto  bg-yellow-400 hover:bg-yellow-500 inline-flex gap-3 text-white px-2 py-2 text-sm rounded-md  ${
                        theme === "dark"
                          ? ""
                          : theme === "high-contrast"
                          ? ""
                          : ""
                      }`}
                    >
                      Upload
                    </button>
                    <button
                      className={`mt-auto  bg-gray-300 hover:bg-gray-400 inline-flex gap-3 text-gray-700 px-2 py-2 text-sm rounded-md ${
                        theme === "dark"
                          ? ""
                          : theme === "high-contrast"
                          ? ""
                          : ""
                      }`}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
