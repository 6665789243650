// Translations.js
const translations = {
  en: {
    dashboard: "Dashboard",
    dashboardtolltip: "Dashboard,View and manage your dashboard overview",
    usertooltip: "Users, Manage user accounts and roles",
    setting: "Settings",
    asset: "Asset Management",
    assettooltip: "Asset, View and manage your assets",
    config: "Configuration",
    configtooltip:
      "Configuration, Manage Assignment rules, custom rules and completion rules",
    task: "Task Manage",
    tasktooltip: "Task Manage, Manage your tasks and assignments, deletion",
    acchistory: "Account History",
    historytooltip: "Account History, View the history of completed tasks",
    workflowe: "Workflow",
    workflowtooltip: "Workflow, Manage your workflows, Guidelines",
    worklog: "Worklog",
    worklogtooltip:
      "Worklog, View and manage your work logs, Export Excel Report, Export PDF",
    security: "Security",
    securitytooltip: "Security, Manage security settings",
    report: "Reports",
    reportooltip: "Reports, Request for report, Check status of the Reports",
    org: "Organization",
    orgtooltip: "Organization, Manage Organization",
    stock: "Stock Management",
    stocktoooltip:
      "Stock Management, Update inventory Details, Download Report",
    //USER
    addUser: "Add User",
    searchusername: "Search by username...",
    searchareacode: "Search by areacode...",
    userType: "User Type",
    user: "User",
    edit: "Edit",
    userlist: "User Management",
    userdetail: "User Details",
    previllage: "Previleges",
    cancle: "Cancel",
    updateuser: "Update User",
    add: "Add User",
    username: "User Name",
    Email: "Email",
    selectOrg: "Select Organization",
    areCode: "Area Code",
    addeditActivity: "Add/edit Activity",
    addnewlabel: "Add/Edit Configuration",
    addEdituser: "Add/edit User",
    canasset: "Can access asset Management",
    uservisibility: " User Visibility",
    modifyguidline: "Modifying Guidelines",
    createDashboar: "Create Dashboard",
    addeditleader: "Add/edit Leader-board",
    canTaskManage:"Can access Task Manage",
    canAddEditProtectedAccount:"Can Add /Edit Protected account",
    canAddEditInventory:"Can add/Edit Inventory Management",
    //SETTINGS
    general: "General",
    save: "Save Changes",
    langSetting: "Language Settings",
    colormode: "Color Mode",
    light: "Light",
    dark: "Dark",
    highcontrast: "High Contrast",
    lang: "Language",
    toursetting: "Tour Setting",
    stoptour: "Stop Tour",
    starttour: "Start Tour",
    custommarker: "Custom Marker",
    all: "All",
    assigned: "Assigned",
    unassign: "Unassigned",
    progress: "In Progress",
    completed: "Completed",
    onhold: "On Hold",
    //ASSET
    vehical: "Vehical",
    Inventory: "Inventory",
    exportreport: "Export Report",
    addVehical: "Add New Vehical",
    addAsset: " Add New Asset",
    from: "From",
    to: "To",
    search: "Search",
    vehicalType: "Vehicle type",
    vehicalAssign: "Vehicle Assignee",
    searchbyId: "Search By Id...",
    assetType: "Asset type",
    assetAssign: "Asset Assignee",
    addnewasset: "Add New Assert",

    updateAsset: "Update Asset",
    Update: "Update",
    updateVehical: "Update Vehical",
    //CONFIGURATION
    complitionrule: "Completion Rules",
    assignment: "Assignment Rules",
    customerule: "Custom Variables",
    configuration: "Configuration",
    rulename: "Rule Name",
    assignTo: "Assign To",
    activedeactive: "Active / Deactive",
    criteria: "Criteria",
    submit: "Submit",
    update: "Update",
    norule: "No more rules to load",
    icon: "Icon",
    createrule: "Create new rule",
    //TASKMANAGE
    multiSelect: "Multi Select",
    smartAssign: "Smart Assign",
    fieldAgent: "Field Agent",
    createNewTask: " Create new task",
    importCSV: "Upload Task",
    mapview: "Mapview",
    createTask: "Create new task",

    download: "Download",
    deleteAllRecord: " Delete All Record",
    assign: "Assign",
    reassign: "Re-Assign",

    alert: "Sent Alert",
    delete: " Delete",
    priority: "Priority",
    status: "Status",
    searchbycode: "Search by Area Code..",
    selectAssign: "Select Assignee",
    downloadtask: " Download Task Report",
    seectallactivty: " Select All Activity",
    selectpartcular: " Selected Particular Activity",
    selectMsg: "Please select an activity to proceed.",
    createnewtask: "Create new task",
    cancel: "Cancel",
    create: "Create",
    DragDetail: "Drag and drop CSV containing all the task details",
    sizeMsg: "Max file size: 15MB",
    import: " Import CSV ",
    uploadfile: "Upload Task Sheet",
    uploadby: "Uploaded By",
    uploaddate: "Upload Date",
    uploadtime: "Upload Time (24hrs)",
    taskdetail: "Task Detail",
    taskhistory: " Task History",
    nohistory: "No task history available.",
    more: "Loading more task history...",
    enteraccno: " Enter Account Number",
    reset: " Reset",
    submitting: "Submitting..",
    accDetail: " Account Details",
    accnumber: "Account Number",
    area: "Area",
    areacode: "Area Code",
    latitude: "Latitude",
    longitude: "Longitude",
    importdb: "Import Master DB",

    exportData: " Export Report",
    downloading: "Downloading...",
    viewGuideline: "View Guideline",
    createWorkflow: "Create new Workflow",
    selectOrgWarning: "Select organization first",
    guidline: "Guidlines",
    noworkflow: "No workflows available",
    pleaseselect: " Please select a workflow first.",
    email: "  Schedule email report",
    export: "Export Report",
    pdf: "Export PDF",
    todo: "To do",
    loading: "Loading more worklog...",
    nomore: "No more worklog to load",
    name: "Protected Account",
    createacc: "Create new account",
    fromdate: "From date",
    todate: "To date",

    editacc: "Edit Protected Account",
    createproacc: "Create Protected Account",
    permanent: "Permanent Protection",
    accProtection: "Account Protection for All Activity",
    accno: "Account No",
    remark: "Remarks",
    enterremark: "Enter your remarks here",
    updatemeterDEtail: "Update Meter Details",
    downloadsheet: "Download Sheet",
    uploadinventory: "Upload Inventory Sheet",
    reportType: "Report Type",

    form: "Request Report",
    uploadSheet :"Upload Sheet",
    startDate: "Start Date",
    endDate: " End Date",
    request: "Request",

    orglist: " Orgnizations List",
    createOrg: " Create Orgnization",
    noOrg: " No organization data available.",
    rows: "Rows per page",
    orgname: "Organization Name",
    img: "  Upload Logo",

    creating: "Creating...",
    updateOrg: "Update Organization",

    Updating: "Updating...",
    nolog: "No Logo",

    //dashbord
    userdashboard: "User Dashboard",
    activitydashboard: "Activity Dashboard",
    admin: "Admin",
    office_user: "Office Users",
    field_agent: "Field Agents",
    dashboardName: "Dashboard Name",
    addnewDashboard: "Add New Dashboard",
    chartMessage: " Select the Dashboard to view charts",
    deletDashbord: "Delete Dashboard",
    //notifucation
    notification: "Notification",
    allnotification: "All Notification",
    profile: "Profile",
    select: " Select Activity",
    selecteOrgName: "Select Organization",
    selectsubactivty: "Select Subactivity",

    emailreport: "Schedule Email Report",
    selectactivity: "Select the activity:",
    recipients: "Recipients",
    bcc: "Bcc",
    cc: "Cc",
    sendreport: "Send scheduled report",
    senddashboard: "Send Dashboard:",
    shedulesend: "Schedule send:",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
   
    send: "Send",
    reportbtn: "Request Report",
    report: "Reports",
  },
  ar: {
    profile: "حساب تعريفي",
    uploadSheet :" تحميل الورقة",
    //notification
    notification: "إشعار",
    allnotification: "كل الإخطار",
    //dashbord
    deletDashbord: "حذف لوحة المعلومات",
    workflow: "سير العمل",
    dashboard: "لوحة القيادة",
    userdashboard: "لوحة تحكم المستخدم",
    activitydashboard: "لوحة معلومات النشاط",
    admin: "مسؤل",
    office_user: "مستخدمي المكتب",
    field_agent: "الوكلاء الميدانيو",
    dashboardName: "اسم لوحة المعلومات",
    addnewDashboard: "إضافة لوحة تحكم جديدة",
    chartMessage: "حدد لوحة المعلومات لعرض المخططات",

    dashboardtolltip:
      "لوحة المعلومات، عرض وإدارة نظرة عامة على لوحة المعلومات الخاصة بك",
    usertooltip: "المستخدمون، إدارة حسابات المستخدمين والأدوار",
    asset: "إدارة الأصول",
    assettooltip: "الأصول وعرض وإدارة الأصول الخاصة بك",
    config: "إعدادات",
    configtooltip:
      "التكوين وإدارة قواعد التعيين والقواعد المخصصة وقواعد الإكمال",
    task: "إدارة المهام",
    tasktooltip: "إدارة المهام، إدارة المهام والواجبات الخاصة بك، والحذف",
    acchistory: "تاريخ الحساب ",
    historytooltip: "سجل الحساب، عرض تاريخ المهام المكتملة",
    workflowe: "سير العمل",
    workflowtooltip: "سير العمل، إدارة سير العمل الخاص بك، المبادئ التوجيهية",
    worklog: "سجل العمل",
    worklogtooltip:
      "سجل العمل، عرض وإدارة سجلات العمل الخاصة بك، تصدير تقرير Excel، تصدير PDF",
    security: "حماية",
    securitytooltip: "الأمان، إدارة إعدادات الأمان",
    report: "التقارير",
    reportooltip: "التقارير، طلب التقرير، التحقق من حالة التقارير",
    org: "منظمة",
    orgtooltip: "التنظيم، إدارة المنظمة",
    stock: "إدارة المخزون",
    stocktoooltip: "إدارة المخزون، تحديث تفاصيل المخزون، تنزيل التقرير",
    //USER
    addUser: "إضافة مستخدم",
    searchusername: "البحث حسب اسم المستخدم...",
    searchareacode: "البحث عن طريق رمز المنطقة...",
    userType: "نوع المستخدم",
    user: "مستخدم",
    edit: "يحرر",
    userlist: "إدارة المستخدمين ",
    userdetail: "تفاصيل المستخدم",
    previllage: "الامتيازات",
    cancle: "يلغي",
    updateuser: "تحديث المستخدم",
    add: "إضافة مستخدم",
    username: "اسم المستخدم",
    Email: "بريد إلكتروني",
    selectOrg: "حدد المنظمة",
    areCode: "كود المنطقة",
    addeditActivity: "إضافة/تعديل النشاط ",
    addnewlabel: "إضافة/تحرير التكوين     ",
    addEdituser: "إضافة/تحرير مستخدم",
    canasset: "يمكن الوصول إلى إدارة الأصول",
    uservisibility: "رؤية المستخدم",
    modifyguidline: "تعديل المبادئ التوجيهية",
    createDashboar: "إنشاء لوحة المعلومات",
    addeditleader: "إضافة/تحرير لوحة المتصدرين",
    canTaskManage:"يمكن الوصول إلى إدارة المهام",
    canAddEditProtectedAccount:"يمكن إضافة/تحرير الحساب المحمي",
    canAddEditInventory:"يمكن إضافة/تحرير إدارة المخزون",

    //SETTING
    general: "عام",
    save: "حفظ التغييرات",
    setting: "جلسة",
    langSetting: "إعدادات اللغة",
    colormode: "وضع اللون",
    light: "ضوء",
    dark: "مظلم",
    highcontrast: "التباين العالي",
    lang: "لغة",
    toursetting: "إعداد الجولة",
    stoptour: "توقف الجولة",
    starttour: "ابدأ الجولة",
    custommarker: "علامة مخصصة",
    all: "الجميع",
    assigned: "مُكَلَّف",
    unassign: "غير معين",
    progress: "في تَقَدم",
    completed: "مكتمل",
    onhold: "في الانتظار",
    //ASSET
    vehical: "عربة",
    Inventory: "جرد",
    exportreport: "تقرير التصدير",
    addVehical: "أضف مركبة",
    addAsset: "إضافة أصول جديدة",
    from: "من",
    to: "ل",
    search: "يبحث",
    vehicalType: "نوع المركبة",
    vehicalAssign: "المكلف بالمركبة",
    searchbyId: "البحث بالمعرف...",
    assetType: "نوع الأصول",
    assetAssign: "المكلف بالأصول",
    addnewasset: "إضافة تأكيد جديد",
    updateAsset: "تحديث الأصول",
    Update: "تحديث",
    updateVehical: "تحديث المركبة",
    //CONFIGURATION
    complitionrule: "قواعد الإكمال",
    assignment: "قواعد التعيين",
    customerule: "المتغيرات المخصصة",
    configuration: "إعدادات",
    rulename: "اسم القاعدة",
    assignTo: "تعيين ل",
    activedeactive: "نشط / غير نشط",
    criteria: "معايير",
    submit: "يُقدِّم",
    update: "تحديث",
    norule: "لا مزيد من القواعد للتحميل",
    icon: "رمز",
    createrule: "إنشاء قاعدة جديدة",
    multiSelect: "تحديد متعدد",
    smartAssign: "التعيين الذكي",
    fieldAgent: "الوكيل الميداني",
    createNewTask: "إنشاء مهمة جديدة",
    importCSV: "  تحميل المهمة",
    mapview: "عرض الخريطة",
    createTask: "إنشاء مهمة جديدة",
    download: "تحميل",
    deleteAllRecord: "حذف كافة السجلات",
    assign: "المكلف",

    reassign: "إعادة التعيين",
    alert: "تنبيه مرسل",
    delete: " يمس",
    priority: "أولوية",
    status: "حالة",
    searchbycode: "البحث عن طريق رمز المنطقة..",
    selectAssign: "حدد المكلف",
    downloadtask: " تنزيل تقرير المهام",
    seectallactivty: "حدد كل الأنشطة",
    selectpartcular: " نشاط معين محدد",
    selectMsg: "الرجاء تحديد نشاط للمتابعة.",
    createnewtask: "إنشاء مهمة جديدة",
    cancel: "كانسل",
    create: "يخلق",
    //import csv
    DragDetail: "قم بسحب وإسقاط ملف CSV الذي يحتوي على جميع تفاصيل المهمة",
    sizeMsg: "الحد الأقصى لحجم الملف: 15 ميجابايت",
    import: "استيراد ملف CSV",
    uploadfile: "تحميل ورقة المهام",
    uploadby: "تم الرفع بواسطة",
    uploaddate: "تاريخ التحميل",
    uploadtime: "وقت التحميل (24 ساعة)",
    //taskd etail
    taskdetail: "تفاصيل المهمة",
    taskhistory: " تاريخ المهمة",
    nohistory: "لا يوجد سجل مهام متاح.",
    more: "جارٍ تحميل المزيد من سجل المهام...",
    //task hisotry
    enteraccno: "أدخل رقم الحساب",
    reset: "إعادة ضبط",
    submitting: "جارٍ التقديم..",
    accDetail: "تفاصيل الحساب :-",
    accnumber: "رقم الحساب",
    area: "المنطقة",
    areacode: "كود المنطقة",
    latitude: "خط العرض",
    longitude: "خط الطول",
    importdb: "استيراد قاعدة البيانات الرئيسية",
    exportData: "تقرير التصدير",
    downloading: "جاري التحميل...",
    viewGuideline: "عرض الإرشادات",
    createWorkflow: "إنشاء سير عمل جديد",
    selectOrgWarning: "حدد المنظمة أولاً",

    guidline: "المبادئ التوجيهية",
    noworkflow: "لا توجد مسارات عمل متاحة",
    pleaseselect: " الرجاء تحديد سير العمل أولاً.",
    //worklog
    email: "جدولة تقرير البريد الإلكتروني",
    export: "تقرير التصدير",
    pdf: "تصدير قوات الدفاع الشعبي",
    todo: "للقيام به",
    loading: "جاري تحميل المزيد من سجل العمل...",
    nomore: "لا مزيد من سجل العمل للتحميل",
    name: "الحساب المحمي",
    createacc: "إنشاء حساب جديد",
    fromdate: "من تاريخ",
    todate: "حتى الآن",
    editacc: "تحرير الحساب المحمي",
    createproacc: "إنشاء حساب محمي",
    permanent: "الحماية الدائمة",
    accProtection: "حماية الحساب لجميع الأنشطة",
    accno: "رقم الحساب",
    remark: "ملاحظات",
    enterremark: "أدخل ملاحظاتك هنا",
    updatemeterDEtail: "تحديث تفاصيل العداد",
    downloadsheet: "تحميل الورقة",
    uploadinventory: "تحميل ورقة الجرد",
    reportType: "نوع التقرير",
    form: "طلب تقرير",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    orglist: "قائمة المنظمات",
    createOrg: " إنشاء منظمة",
    noOrg: "لا توجد بيانات المنظمة المتاحة.",
    rows: "صفوف لكل صفحة",
    orgname: "اسم المنظمة",
    img: "تحميل الشعار",
    creating: "جارٍ الإنشاء...",
    updateOrg: "تحديث المنظمة",
    Updating: "جارٍ التحديث...",
    nolog: "لا يوجد شعار",
    select: " حدد النشاط",
    selecteOrgName: " اختر المنظمة",
    selectsubactivty: " حدد النشاط الفرعي ",

    emailreport: "جدولة تقرير البريد الإلكتروني",
    selectactivity: "اختر النشاط:",
    recipients: "المستلمون",
    bcc: "نسخة مخفية الوجهة",
    cc: "نسخة",
    sendreport: "إرسال التقرير المقرر",
    senddashboard: "إرسال لوحة المعلومات:",
    shedulesend: "جدول الإرسال:",
    daily: "يوميًا",
    weekly: "أسبوعي",
    monthly: "شهريا",
    send: "يرسل",
    reportbtn: "طلب تقرير",
    report: "التقارير",
  },
};

export default translations;
