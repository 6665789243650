import React from "react";

const highlightText = (text) => {
    if (typeof text !== "string") {
      text = "";
    }
    const stringRegex = /(".*?"|'.*?')/g;
    const keywordRegex =
      /\b(const|let|var|function|return|if|else|for|while|true|false|null|in|and)\b/g;
    const numberRegex = /\b(\d+)\b/g;
    const operatorRegex = /(\+|\-|\*|\/|\=|\!|\&|\||\?|\:|\%)/g;
    const tokens = text.split(/(\s+)/);
    const wrapSpan = (token, regex, className) => {
      return token.replace(regex, `<span class="${className}">$1</span>`);
    };
    const highlightedTokens = tokens.map((token) => {
      if (stringRegex.test(token)) {
        return wrapSpan(token, stringRegex, "text-green-500");
      } else if (keywordRegex.test(token)) {
        return wrapSpan(token, keywordRegex, "text-blue-500");
      } else if (numberRegex.test(token)) {
        return wrapSpan(token, numberRegex, "text-purple-500");
      } else if (operatorRegex.test(token)) {
        return wrapSpan(token, operatorRegex, "text-red-500");
      } else {
        return token;
      }
    });
    return highlightedTokens.join("");
 };
  
  const HighlightedTextarea = ({ value, onChange ,criteria ,setCriteria }) => {
    return (
      <div className="relative">
        <div
          className="absolute inset-0 m-0 overflow-auto p-3 pointer-events-none whitespace-pre-wrap break-words"
          aria-hidden="true"
          dangerouslySetInnerHTML={{ __html: highlightText(value) }}
        />
        <textarea
          value={value}
          onChange={onChange}
          rows={5}
          className="relative w-full h-full shadow-inner bg-transparent border-1 p-3 outline-none text-transparent rounded-md   caret-black"
        />
      </div>
    );
  };

  export default HighlightedTextarea;