import React, { useContext } from "react";
import { toast } from "react-toastify";
import { createDashboard } from "../../../service/UserDashboard";
import AppContext from "../../../context/AppContext";
import { RxCross2 } from "react-icons/rx";
const AddDashboardModal = ({
  onClose,
  selectedOrganizationId,
  selectedWorkflowId,
  setSelectedWorkflowId,
  dashboardName,
  setDashboardName,
  selectedrole,
  setSelectedRole,
  selectedOrgId,
  data,
}) => {
  const { theme,userType } = useContext(AppContext);
  const id =
    userType === "super_admin" ? selectedOrgId : selectedOrganizationId;
  const handleWorkflowChange = (event) => {
    setSelectedWorkflowId(event.target.value);
  };
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      dashboardName: dashboardName,
      workflowId: +selectedWorkflowId,
      role: selectedrole,
    };
    createDashboard(formData, id)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.header.code === 600) {
            toast.success(responseData.body.value);
            setDashboardName('');
            setSelectedWorkflowId('');
            setSelectedRole('');
            onClose(); // Close modal
          } else if (responseData.header.code === 601) {
            toast.warning(responseData.body.value);
          } else {
            console.error("Unexpected header code:", responseData.header.code);
          }
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error creating dashboard:", error);
      });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div
        className={`bg-white rounded-lg p-8 relative overflow-y-auto max-h-full  ${
          theme === "dark"
            ? "dark-mode border "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        <span
          className="absolute top-0 right-0 m-4 cursor-pointer text-gray-500"
          onClick={onClose}
        >
          <RxCross2 />
        </span>
        <div className="flex justify-center items-center">
          <form onSubmit={handleSubmit} className="">
            <h5 className="font-semibold">Create Activity Dashboard</h5>
            <div className="py-5">
              <div className="mb-5 flex items-center gap-5">
                <div>
                  <label
                    htmlFor="dashboardName"
                    className={`block text-sm mb-2 font-medium text-gray-900 dark:text-white ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    Dashboard Name
                  </label>
                  <input
                    id="dashboardName"
                    name="dashboardName"
                    value={dashboardName}
                    onChange={(e) => setDashboardName(e.target.value)}
                    className={`w-60 border border-gray-300 text-gray-900 text-sm rounded-lg 
                      focus:ring-blue-500 focus:border-blue-500 block p-2.5 
                       ${
                         theme === "dark"
                           ? "dark-mode "
                           : theme === "high-contrast"
                           ? "high-contrast  "
                           : ""
                       }`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="role"
                    className={`block text-sm mb-2 font-medium text-gray-900 dark:text-white ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    value={selectedrole}
                    onChange={handleRoleChange}
                    className={`w-60 border border-gray-300 text-gray-900 text-sm 
                      rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5
                        ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                  >
                    <option value="">Select</option>
                    <option value="admin">Admin</option>
                    <option value="office_user">Office User</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div>
                  <label
                    htmlFor="workflowId"
                    className={`block text-sm mb-2 font-medium text-gray-900 dark:text-white ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    Activity
                  </label>
                  <select
                    id="workflowId"
                    name="workflowId"
                    value={selectedWorkflowId}
                    onChange={handleWorkflowChange}
                    className={`w-60 border border-gray-300 text-gray-900 text-sm 
                      rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5
                        ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                  >
                    <option value="">Select</option>
                    {data.map((workflow) => (
                      <option
                        key={workflow.workflowId}
                        value={workflow.workflowId}
                      >
                        {workflow.workflowName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex justify-end mt-5">
                <button
                  type="button"
                  onClick={onClose}
                  className="mt-6 px-2 py-2 text-sm bg-white border border-gray-500 text-black font-semibold rounded-md hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="mt-6 ml-4 px-2 text-sm py-2 bg-blue-800 text-white rounded-md font-semibold hover:bg-blue-900"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDashboardModal;
