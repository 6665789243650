import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuMoreVertical } from "react-icons/lu";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import createImg from "../../assets/Vector (6).png";
import AppContext from "../../context/AppContext";
import DeletePermission from "../suspence/DeletePermission";
import {
  deleteProtectedAccounts,
  fetchExportProtected,
} from "../../service/Security";
import translations from "../common/Translations";

export const SecurityToolbar = ({
  accountNo,
  setAccountNo,
  checkedbox,
  selectedRecords,
  setCheckedBox,
  setList,
}) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const {
    setParticularAccount,
    setIsEditRequest,
    workflowId,
    selectedOrgId,
    theme,
    langMode,
    userType
  } = useContext(AppContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");

  const deleteFunction = async (selectedRecords) => {
    try {
      const data = await deleteProtectedAccounts(selectedRecords);
      if (data.header && data.header.code === 600) {
        setCheckedBox(false);
        setList((prevList) =>
          prevList.filter((item) => !selectedRecords.includes(item.id))
        );
        setIsPopupVisible(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleCloseDeleteModal();
  };

  const navigate = useNavigate();
  const handleCreate = () => {
    if (userType === "super_admin" && !selectedOrgId) {
      toast.warn("Please select an organization before creating an account.");
      return;
    }
    setParticularAccount([]);
    setIsEditRequest(false);
    navigate("/createAccount");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  const showDeleteModal = (type, id) => {
    setDeleteItemType(type);
    setDeleteItemId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const exportReport = async (workflowId, fromDate, toDate, selectedOrgId) => {
    if (!fromDate || !toDate) {
      toast.error("Please select both From Date and To Date.");
      return;
    }
    if(!workflowId){
      toast.error("Please select workflow first");
      return;
    }
    if(!selectedOrgId){
      toast.error("Please select organization first");
      return;
    }
    try {
      const response = await fetchExportProtected(
        workflowId,
        selectedOrgId,
        fromDate,
        toDate
      );
      if (response) {
        if (response.header) {
          const code = response.header.code;
          if (code === 607) {
            toast.error("No Records are Available");
          } else if (code === 600) {
            const s3Link = response.body.value.reportUrl;
            const link = document.createElement("a");
            link.href = s3Link;
            link.setAttribute("download", "report.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setFromDate("");
            setToDate("")
          } else {
            toast.error("Unexpected response code");
          }
        } else {
          toast.error("Header is missing in the response");
        }
      } else {
        toast.error("Response data is missing");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleSpecialForm = () => {
    navigate("/special-form");
  };

  return (
    <>
      <div
        className={`bg-white p-2 ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        <div
          className={`bg-white flex justify-between ${
            theme === "dark"
              ? "dark-mode  rounded-md "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          <div
            className={`text-gray-800 text-sm font-semibold ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            {translations[langMode].name}
          </div>
          <div>
            <button
              type="button"
              onClick={handleCreate}
              className="px-3 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              <img src={createImg} className="px-2" alt="create img" />
              {translations[langMode].createproacc}
            </button>
            {/* <button
              type="button"
              onClick={handleSpecialForm}
              className="px-3 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-yellow-600 rounded-lg hover:bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              <img src={createImg} className="px-2" alt="create img" />
              Special Protection form
            </button> */}
          </div>
        </div>
        <div className="flex my-2 items-center">
          <div className="flex-grow flex items-center">
            <div className="flex space-x-2 ">
              <div className="flex flex-col">
                <label
                  htmlFor="fromDate"
                  className="text-gray-700 text-sm dark:text-gray-300 high-contrast:text-white font-medium"
                >
                  {translations[langMode].fromdate}
                </label>
                <input
                  id="fromDate"
                  type="date"
                  className={`form-input text-sm border pt-1 px-3 border-gray-400 rounded-md w-40 h-8 transition-all duration-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast bg-black text-white"
                      : ""
                  }`}
                  value={fromDate ? formatDate(fromDate) : ""}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      e.target.type = "text";
                    }
                  }}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder={translations[langMode].fromdate}
                  aria-label="From Date"
                />
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="toDate"
                  className="text-gray-700 text-sm dark:text-gray-300 high-contrast:text-white font-medium"
                >
                  {translations[langMode].todate}
                </label>
                <input
                  id="toDate"
                  type="date"
                  className={`form-input border text-sm pt-1 px-3 border-gray-400 rounded-md w-40 h-8 transition-all duration-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                    theme === "dark"
                      ? 'dark-mode'
                      : theme === "high-contrast"
                      ? "high-contrast bg-black text-white"
                      : ""
                  }`}
                  value={toDate ? formatDate(toDate) : ""}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      e.target.type = "text";
                    }
                  }}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder={translations[langMode].todate}
                  aria-label="To Date"
                />
              </div>
            </div>
            <button
              type="button"
              className="text-white text-sm mt-5 py-1.5 bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg  px-3  ml-3 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              onClick={() => {
                exportReport(workflowId, fromDate, toDate ,selectedOrgId);
              }}
            >
              {translations[langMode].export}
            </button>
          </div>
          <div className="ml-auto flex items-center">
            <LuMoreVertical
              className={`text-md ${
                checkedbox
                  ? "cursor-pointer text-black"
                  : "cursor-not-allowed text-gray-400"
              }`}
              onClick={checkedbox ? togglePopup : null}
            />
            {isPopupVisible && (
              <div className="absolute right-14 mt-5 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                <button
                  type="button"
                  className="w-full px-4 py-2 text-left text-red-600 hover:bg-red-100 rounded-t-lg"
                  onClick={() => showDeleteModal("single", null)}
                >
                  {translations[langMode].delete}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {deleteModalOpen && deleteItemType === "single" && (
        <DeletePermission
          deleteFunction={() => deleteFunction(selectedRecords)}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete record?"
        />
      )}
    </>
  );
};
