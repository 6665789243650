import React from 'react'
import { RiUploadCloud2Line } from "react-icons/ri";

export const DragDropFile = ({ filelabel, sizelabel }) => {
    return (
        <>
            <div className="flex items-center justify-center py-5 w-full">
                <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center  w-full h-54 border-2 bg-gray-100 border-gray-100  rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                    <div className="flex flex-row items-center justify-center gap-4 p-2 pt-5 pb-6">
                    <RiUploadCloud2Line className=" w-10 h-10" />
                        <div className="flex-flex-col items-center">
                            <p className="mb-2 text-sm text-gray-600 dark:text-gray-400">
                                <span className="font-semibold">
                                    {filelabel}
                                </span>
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                {sizelabel}
                            </p>
                            <p className="text-sm text-blue-700 font-semibold underline dark:text-gray-400">
                                Browse
                            </p>
                        </div>
                    </div>
                    <input id="dropzone-file" type="file" className="hidden" />
                </label>
            </div>
        </>
    )
}
