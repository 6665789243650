import React, { useContext, useState, useRef, useEffect } from "react";
import { FaUsers } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import {
  RiDashboardFill,
  RiMenuFill,
  RiGitBranchLine,
  RiTimeLine,
  RiFileTextLine,
  RiGroupFill,
  RiSettingsLine,
} from "react-icons/ri";
import { MdOutlineSecurity } from "react-icons/md";
import { TbAsset, TbListCheck } from "react-icons/tb";

// Imported useLocation here #Changes Commit-2
import { Link, useLocation } from "react-router-dom";

import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import { FaHistory } from "react-icons/fa";
import { AiOutlineStock } from "react-icons/ai";
import translations from '../common/Translations'
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardMenus = () => {
  const { theme, langMode, userType } = useContext(AppContext);
  const [showNames, setShowNames] = useState(false);
  const sidebarRef = useRef(null);

  // We will be using this uselocation to also track the link to match it with the menu.link to set the active class #Changes Commit-2
  const location = useLocation();

  const toggleShowNames = () => {
    setShowNames((prev) => !prev);
  };
  const menus = [
    {
      icon: (
        <RiDashboardFill
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/dashboard",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/dashboard", "/activty-dashboard", "/powerbi-dashboard"],
      name: translations[langMode].dashboard,
      tooltip: translations[langMode].dashboardtolltip,
    },
    {
      icon: (
        <FaUsers
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/all-users",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/all-users"],
      name: translations[langMode].userlist,
      tooltip: translations[langMode].usertooltip,
    },
    {
      icon: (
        <IoSettings
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/settings",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/settings"],
      name: translations[langMode].setting,
      tooltip: translations[langMode].setting,
    },
    {
      icon: (
        <TbAsset
          className={`${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/asset",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/asset"],
      name: translations[langMode].asset,
      tooltip: translations[langMode].assettooltip,
    },
    {
      icon: (
        <RiSettingsLine
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/configuration",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/configuration"],
      name: translations[langMode].config,
      tooltip: translations[langMode].configtooltip,
    },
    {
      icon: (
        <TbListCheck
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/maptask",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/maptask", "/task-manage"],
      name: translations[langMode].task,
      tooltip: translations[langMode].tasktooltip,
    },
    {
      icon: (
        <FaHistory
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/taskhistory",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/taskhistory"],
      name: translations[langMode].acchistory,
      tooltip: translations[langMode].historytooltip,
    },
    {
      icon: (
        <RiGitBranchLine
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/workflow",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/workflow"],
      name: translations[langMode].workflowe,
      tooltip: translations[langMode].workflowtooltip,
    },
    {
      icon: (
        <RiTimeLine
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/worklog",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/worklog"],
      name: translations[langMode].worklog,
      tooltip: translations[langMode].worklogtooltip,
    },
    {
      icon: (
        <MdOutlineSecurity
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/security",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/security"],
      name: translations[langMode].security,
      tooltip: translations[langMode].securitytooltip,
    },
    {
      icon: (
        <AiOutlineStock
          className={`  ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            }`}
          size={20}
        />
      ),
      link: "/stock-managment",
      // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
      relatedPaths: ["/stock-managment"],
      name: translations[langMode].stock,
      tooltip: translations[langMode].stocktoooltip,
    }
  ];
  if (userType === "super_admin") {
    menus.push(
      {
        icon: (
          <RiFileTextLine
            className={`  ${theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
                ? "high-contrast"
                : ""
              }`}
            size={20}
          />
        ),
        link: "/reports",
        // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
        relatedPaths: ["/reports"],
        name: translations[langMode].report,
        tooltip: translations[langMode].reportooltip,
      },
      {
        icon: (
          <RiGroupFill
            className={`  ${theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
                ? "high-contrast"
                : ""
              }`}
            size={20}
          />
        ),
        link: "/organization",
        // Added relatedpaths below for the active class to work properly on each page that doesn't lie on the main dashboard menu #Changes Commit-2
        relatedPaths: ["/organization"],
        name: translations[langMode].org,
        tooltip: translations[langMode].orgtooltip,
      },

    );
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowNames(false);
      }
    };
    if (showNames) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNames]);
  return (
    <>
      {showNames && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-5  transition-all duration-300" />
      )}
      <div
        ref={sidebarRef}
        className={`relative bg-white ${showNames
          ? "w-[210px] opacity-100 bg-white h-[600px]"
          : "w-[45px] opacity-100 h-[530px]"
          } ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }
         ${theme === "dark" && showNames
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
      >
        <div
          className={`relative flex items-center  transition-all origin-top-left duration-300 ease-in-out ${showNames
            ? "w-[210px] opacity-100 bg-white rounded-t-md "
            : "w-[45px] opacity-100"
            }${theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          style={{ zIndex: 8888 }}
        >
          <button
            className={`relative flex items-center px-3 py-3 ${theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
                ? "high-contrast"
                : ""
              }`}
            onClick={toggleShowNames}
          >
            {showNames ? (
              <RxCross2
                size={20}
                className={`   ${theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                  }text-gray-700  ml-3`}
              />
            ) : (
              <RiMenuFill
                size={20}
                className={`  ${theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                  }text-gray-700 `}
              />
            )}
          </button>
        </div>
        <div
          className={`absolute top-10 left-0 z-50 h-[600px] origin-top-left transition-all duration-300 ease-in-out ${theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
              ? "high-contrast"
              : ""
            } ${showNames
              ? "w-[210px] opacity-100 bg-white "
              : "w-[45px] opacity-100"
            }`}
          style={{ zIndex: 8888 }}
        >
          {menus.map((menu, index) => (
            <div
              key={index}
              className={` ${theme === "dark" ? "dark-mode " : "light-mode"
                }  relative group`}
            >
              <Link
                to={menu.link}
                onClick={menu.onClick}
                className={classNames(
                  "flex items-center py-2 px-3 text-sm justify-left transition-all duration-300  transform hover:scale-110 hover:text-blue-600",
                  // Here we have added this location pathname to match it with menu.link for setting active class #Changes Commit-2
                  menu.relatedPaths.includes(location.pathname) ? "bg-blue-600 text-white hover:text-white" : "",
                  theme === "dark"
                    ? "dark:text-white "
                    : theme === "high-contrast"
                      ? ""
                      : "",

                  showNames
                    ? " mx-3 py-2.5 hover:text-blue-600 text-gray-600 hover:scale-105"
                    : ""
                )}
              >
                {menu.icon}
                {showNames && (
                  <span
                    className={`ml-3 text-sm font-semibold text-nowrap  transition-opacity duration-300 ease-in   ${theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                      }`}
                  >
                    {menu.name}
                  </span>
                )}
              </Link>
              <div
                id={`tooltip-${index}`}
                role="tooltip"
                className={`absolute left-14 top-10 text-nowrap inline-block px-3 py-2 text-sm font-medium text-white
          transition-all duration-300 bg-gray-900  opacity-0 transform scale-95 group-hover:opacity-100
          group-hover:scale-100 pointer-events-none ${showNames ? "left-52 top-0" : ""
                  }`}
              >
                {menu.tooltip}
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default DashboardMenus;
