import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { getUserDetails, requestReport } from "../../service/Report";
import { RxCross2 } from "react-icons/rx";
import translations from "../common/Translations";

export default function RequestReport({
  open,
  setOpen,
  handleReportRequestSuccess,
}) {
  const [ReportType, setReportType] = useState("");
  const [UserType, setUserType] = useState("");
  const [userlist, setUserList] = useState("");
  const [selecteduser, setSelectedUser] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const cancelButtonRef = useRef(null);
  const { theme, selectedOrgId, langMode } = useContext(AppContext);

  const reportType = [
    { id: "VEHICLES", value: "VEHICLES" },
    { id: "INVENTORY", value: "INVENTORY" },
  ];
  const userType = [
    { id: "admin", value: "admin" },
    { id: "office_user", value: "office_user" },
  ];

  const status = [
    { id: "0", value: "pending" },
    { id: "1", value: "ready to download" },
  ];

  const handleReportType = (event) => {
    setReportType(event.target.value);
  };
  const handleUserType = (event) => {
    setUserType(event.target.value);
  };

  const handleSelectUser = (event) => {
    setSelectedUser(event.target.value);
  };

  useEffect(() => {
    if (selectedOrgId && UserType) {
      const loadUserDetails = async () => {
        try {
          const users = await getUserDetails(selectedOrgId, UserType);
          setUserList(users);
        } catch (error) {
          console.error("Error loading user details:", error);
        }
      };
      loadUserDetails();
    }
  }, [selectedOrgId, UserType]);

  const handleSubmit = async () => {
    if (!ReportType || !UserType || !startDate || !endDate) {
      toast.error("All fields are required");
      return;
    }
    try {
      await requestReport({
        selecteduser,
        UserType,
        ReportType,
        startDate,
        endDate,
      });
      handleReportRequestSuccess();
      toast.success("Report Request Successfully");
      setOpen(false);
      setUserType("");
      setReportType("");
      setStartDate("");
      setEndDate("");
      setSelectedUser("");
    } catch (error) {
      toast.error("Error requesting report");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity `}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <div
                  className={`bg-white rounded-lg shadow  ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      {translations[langMode].form}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setOpen(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4">
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <select
                          value={ReportType}
                          onChange={handleReportType}
                          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          } appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                        >
                          <option value="" selected disabled>
                            {translations[langMode].reportType}
                          </option>
                          {reportType.map((report) => (
                            <option value={report.value}>{report.id}</option>
                          ))}
                        </select>
                        <label
                          className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          }`}
                        >
                          {translations[langMode].reportType}
                        </label>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <select
                          value={UserType}
                          onChange={handleUserType}
                          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          } appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                        >
                          <option value="" selected disabled>
                            {translations[langMode].userType}
                          </option>
                          {userType.map((user) => (
                            <option value={user.value}>{user.id}</option>
                          ))}
                        </select>
                        <label
                          className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          }`}
                        >
                          {translations[langMode].userType}
                        </label>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <select
                          value={selecteduser}
                          onChange={handleSelectUser}
                          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          } appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                        >
                          <option value="" selected disabled>
                            {translations[langMode].user}
                          </option>
                          {userlist &&
                            userlist.map((user) => (
                              <option key={user.userId} value={user.userId}>
                                {user.userName}
                              </option>
                            ))}
                        </select>
                        <label
                          className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          }`}
                        >
                          {translations[langMode].user}
                        </label>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          } appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                        />
                        <label
                          className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          }`}
                        >
                          {translations[langMode].startDate}
                        </label>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <input
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          } appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                        />
                        <label
                          className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            theme === "dark" ? "dark-mode" : "light-mode"
                          }`}
                        >
                          {translations[langMode].endDate}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center float-right p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={() => setOpen(false)}
                      data-modal-hide="small-modal"
                      type="button"
                      className="py-1.5 px-3 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      {translations[langMode].cancel}
                    </button>
                    <button
                      data-modal-hide="small-modal"
                      type="button"
                      onClick={handleSubmit}
                      className="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {translations[langMode].request}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
