import React, { Fragment, useContext, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
export default function Logout({ showModal, setShowModal }) {
  const cancelButtonRef = useRef(null);
  const { theme, setWorkflowId, setSelectedOption } = useContext(AppContext);
  const navigate = useNavigate();

  const handleConfirmLogout = () => {
    window.localStorage.removeItem("isLoggedIn");
    setShowModal(false);
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    sessionStorage.clear();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("activeTab");
    localStorage.removeItem("selectedOrgName");
    localStorage.removeItem("selectedOrgId");
    localStorage.removeItem("selectedOrgId");
    localStorage.removeItem("selectedFields");
    localStorage.removeItem("FCMToken");

    setSelectedOption(""); // Reset selectedOption in context
    setWorkflowId(""); // Reset workflowId in context
    localStorage.removeItem("selectedActivity");
    navigate("/");
    toast.success("Logged out successfully");
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => setShowModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity z-50" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={`absolute z-50 transform overflow-hidden rounded-lg shadow-xl transition-all top-20 right-10  w-[300px] max-w-md p-3 ${
                theme === "dark" ? "bg-gray-800 text-white" : "bg-white"
              }`}
            >
              <div className="flex justify-end">
                <button
                  type="button"
                  className="text-gray-600 transition-transform transform hover:scale-110"
                  onClick={handleCancelLogout}
                  ref={cancelButtonRef}
                >
                  <RxCross2 className="h-6 w-6" />
                </button>
              </div>
              <div className="flex flex-col items-center text-center mt-4">
                <FaSignOutAlt className="h-10 w-10" />
                <h3 className="mt-4 text-sm font-normal leading-4">
                  Are you sure you want to logout?
                </h3>
              </div>
              <div className="mt-6 flex justify-center space-x-3">
                <button
                  className="px-5 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                  onClick={handleCancelLogout}
                >
                  Cancel
                </button>
                <button
                  className="px-5 py-2 rounded-md bg-blue-800 text-white hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleConfirmLogout}
                >
                  Logout
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
