import React, { useContext, useState } from "react";
import {
  FaCheck,
  FaClock,
  FaEye,
  FaLocationDot,
  FaPause,
  FaSpinner,
  FaUser,
} from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { updateTaskDetails } from "../../../service/Task";
import { toast } from "react-toastify";
import { FaUserEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import AppContext from "../../../context/AppContext";
const MarkerInfoModal = ({
  taskDetail,
  onClose,
  onDelete,
  setAssignTask,
  id,
  assigneName,
  setAssignName,
}) => {
  const { theme } = useContext(AppContext);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const parsedTaskDetails = taskDetail.taskDetails
    ? JSON.parse(taskDetail.taskDetails)
    : taskDetail.taskDetailsParsed || {};

  const [formData, setFormData] = useState({
    priority: taskDetail.priority || "N/D",
    area: taskDetail.area || "N/A",
    ...parsedTaskDetails,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', etc.
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };
  const formattedCreatedDate = taskDetail.createdDate
    ? formatDate(taskDetail.createdDate)
    : "N/A";
  const formattedFinishedDate = taskDetail.finishedDate
    ? formatDate(taskDetail.finishedDate)
    : "N/A";

  const getStatusIcon = (status) => {
    switch (status) {
      case "Active":
        return <FaCheck className="text-purple-200" />;
      case "Assigned":
        return <FaClock className="text-blue-500" />;
      case "On_Hold":
        return <FaPause className="text-yellow-500" />;
      case "In_Progress":
        return <FaSpinner className="text-orange-500 animate-spin" />;
      case "Completed":
        return <FaCheck className="text-green-500" />;
      default:
        return <FaEye className="text-gray-500" />;
    }
  };

  const handleEditTask = () => {
    setShowEditPopup((prevedit) => !prevedit);
  };

  const handleUpdateTask = async () => {
    const updatedTaskDetails = {
      ...taskDetail.taskDetailsParsed, // Preserve existing data
      ...formData, // Include dynamically updated fields
    };

    const updatedTask = {
      taskId: taskDetail.taskId,
      workflowId: taskDetail.workflowId,
      assigneeId: taskDetail.assigneeId,
      priority: formData.priority || "Low_Priority",
      taskDetails: JSON.stringify(updatedTaskDetails),
      taskStatus: taskDetail.taskStatus,
      assignedBy: taskDetail.assignedBy,
      createdDate: taskDetail.createdDate,
      latitude: parseFloat(formData.latitude) || null,
      longitude: parseFloat(formData.longitude) || null,
      areaCode: formData.areaCode || null, // Ensure areaCode is directly passed here
      tariff: taskDetail.tariff,
      accountNo: formData.accountNo || null,
      createdBy: taskDetail.createdBy,
      finishedDate: taskDetail.finishedDate,
      office: formData.office || null,
      area: formData.area || null,
      source: taskDetail.source,
      icon: taskDetail.icon,
      completed: taskDetail.completed,
      assigned: taskDetail.assigned,
      deleted: taskDetail.deleted,
    };

    try {
      await updateTaskDetails(updatedTask);
      onClose();
      toast.success("Task Updated Successfully");
    } catch (error) {
      console.error("Error updating task details:", error);
    }
  };
  const taskDetailClose = () => {
    onClose();
    setAssignTask(false);
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 ">
      <div
        className="absolute bg-black bg-opacity-80 p-3 rounded-lg shadow-lg overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300"
        style={{
          top: "35%",
          left: "0",
          zIndex: 9999,
          width: "220px",
          height: "260px",
        }}
      >
        <div className="flex justify-between items-center mb-3">
          <h6 className="font-bold text-xs text-white">
            {taskDetail.accountNo}
          </h6>
          <div className="flex gap-2">
            <button
              className="text-white hover:text-gray-300"
              onClick={handleEditTask}
            >
              <FaUserEdit size={16} />
            </button>
            <button
              className="text-white hover:text-gray-300"
              onClick={onDelete}
            >
              <RiDeleteBin5Fill size={16} />
            </button>
            <button
              className="text-white hover:text-gray-300"
              onClick={taskDetailClose}
            >
              <MdClose size={16} />
            </button>
          </div>
        </div>
        <div className="text-white text-xs">
          <div className="flex justify-between items-center mb-2 group relative">
            {/* Assignee Name Display */}
            <h3 className="text-gray-300 font-semibold flex gap-2 text-xs text-wrap">
              <div className="inline-flex gap-2">
                <FaUser />
                {assigneName?.slice(0, 1).map((assignee, index) => (
                  <span key={index}>{assignee.assigneeName}, </span>
                ))}
              </div>
              <div onClick={() => setIsModalOpen(true)} className="cursor-pointer">
                {assigneName?.length > 1 && (
                  <span>+ {assigneName.length - 1} more</span>
                )}
              </div>
            </h3>
            {isModalOpen && (
              <div
                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                onClick={() => setIsModalOpen(false)} // Close modal when clicked outside
              >
                <div
                  className="bg-white p-4 rounded-lg w-1/4 "
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
                >
                  <h3 className="font-bold text-xl mb-4">All Assignees</h3>
                  <div className=" h-auto  max-h-[300px] overflow-y-auto">
                    {assigneName?.map((assignee, index) => (
                      <div key={assignee.assigneeId} className="mb-2 ">
                        <div className="text-yellow-500 py-1">
                          <strong>Field Agent ID:</strong> {assignee.assigneeId}
                        </div>
                        <div className="text-blue-500">
                          <strong>Field Agent Name:</strong>{" "}
                          {assignee.assigneeName}
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    className="mt-4 bg-blue-800 text-white px-4 py-2 rounded"
                    onClick={() => setIsModalOpen(false)} // Close modal
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center mb-2">
            <p className="flex items-center gap-2 font-semibold text-xs text-wrap">
              <FaLocationDot className="text-yellow-400" />
              {taskDetail.taskDetailsParsed?.["Activity"] || "N/A"}
            </p>
            <p className="flex items-center gap-2 font-semibold text-xs">
              {getStatusIcon(taskDetail.taskStatus || "N/A")}
              {taskDetail.taskStatus || "N/A"}
            </p>
          </div>
          <hr className="border-gray-600 my-2" />
          <div className="p-2">
            <h6 className="text-xs font-semibold mb-2">Task Params</h6>
            <div className="bg-gray-900 bg-opacity-50 rounded-lg flex flex-col px-4 py-3 text-xs gap-1">
              {taskDetail.taskDetailsParsed &&
                Object.entries(taskDetail.taskDetailsParsed).map(
                  ([key, value]) => (
                    <span key={key}>
                      {key}:{" "}
                      {typeof value === "object"
                        ? JSON.stringify(value)
                        : value || "N/A"}
                    </span>
                  )
                )}
            </div>
          </div>
        </div>
      </div>

      {showEditPopup && (
        <div
          className={`absolute bg-white p-2 shadow-lg rounded-md overflow-y-auto  ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
          style={{
            top: "35%",
            right: "0",
            zIndex: 1000,
            width: "250px",
            maxHeight: "260px",
            bottom: 0,
          }}
        >
          {" "}
          <div className="edit-popup">
            <form>
              <div className="flex justify-between">
                <h3 className="text-sm font-bold ">Task Details</h3>

                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    className="px-2 text-xs py-1 bg-white  text-gray-700 rounded-md border-2"
                    onClick={() => setShowEditPopup(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="px-2 py-1  bg-blue-800 text-white rounded-md text-xs"
                    onClick={handleUpdateTask}
                  >
                    Update
                  </button>
                </div>
              </div>
              <div className="task-details-header">
                <h6 className="font-bold text-gray-700 py-1 text-xs ">
                  {taskDetail.accountNo}
                </h6>
                <h3 className="flex items-center gap-2 font-semibold text-xs">
                  <FaLocationDot className="text-rose-400" />
                  {taskDetail.taskDetailsParsed &&
                    taskDetail.taskDetailsParsed["Activity"]}
                </h3>
                <p className="flex items-center gap-2 font-semibold text-gray-700 text-xs">
                  {getStatusIcon(taskDetail.taskStatus)} {taskDetail.taskStatus}
                </p>
              </div>

              {Object.keys(formData).map((key) => (
                <div className="mb-1 py-1" key={key}>
                  <label className="block text-xs font-semibold mb-1">
                    {key}:
                  </label>
                  <input
                    type="text"
                    name={key}
                    className={`w-full px-3 py-1  text-xs border rounded-md bg-white ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                    value={formData[key]}
                    onChange={handleInputChange}
                  />
                </div>
              ))}

              {/* Special handling for fields like "Created Date" and "Finished Date" */}
              <div className="mb-1 py-1">
                <label className="block text-xs font-semibold mb-1">
                  Created Date:
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-1 text-xs border rounded-md bg-white"
                  value={formattedCreatedDate || "N/A"}
                  readOnly
                />
              </div>
              <div className="mb-1 py-1">
                <label className="block text-xs font-semibold mb-1">
                  Finished Date:
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-1 border text-xs rounded-md bg-white"
                  value={formattedFinishedDate || "N/A"}
                  readOnly
                />
              </div>
            </form>
          </div>
          {/* <div
              className={`bg-white py-1 px-2 rounded-md border shadow-inner text-xs  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <h6 className="font-bold text-gray-700 py-1">{id}</h6>
              <h3 className="flex items-center gap-2 font-semibold">
                <FaLocationDot className="text-rose-400" />
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["Activity"]}
              </h3>
              <p className="flex items-center gap-2 font-semibold text-gray-700 text-xs">
                {getStatusIcon(taskDetail.taskStatus)}
                {taskDetail.taskStatus}
              </p>
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Mobile Number:
              </label>
              <input
                type="text"
                name="mobile"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.mobile} // Use formData for value, not taskDetail
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                GEO CODE:
              </label>
              <input
                type="text"
                name="geoCode"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.geoCode} // Use formData for value, not taskDetail
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                METERNO:
              </label>
              <input
                type="text"
                name="meterNo"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.meterNo} // Use formData for value, not taskDetail
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Priority:
              </label>
              <input
                type="text"
                name="priority"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.priority} // Use formData for value, not taskDetail
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Created Date:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formattedCreatedDate || "N/A"}
                readOnly
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Finished Date:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formattedFinishedDate || "N/A"}
                readOnly
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Area code:
              </label>
              <input
                type="text"
                name="areaCode"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.areaCode} // Use formData for value, not taskDetail
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Office:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.office} // Use formData for value, not taskDetail
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">Area:</label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.area}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Latitude:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.longitude}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Longitude:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.latitude}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Category:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.category}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Outstanding Init:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.outstanding}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                TypeCode:
              </label>
              <input
                type="text"
                className={`w-full px-3 py-1 border rounded-md bg-white  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                value={formData.typeCode}
                onChange={handleInputChange}
              />
            </div> */}
        </div>
      )}
    </div>
  );
};

export default MarkerInfoModal;
