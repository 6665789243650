import React, { useContext, useEffect, useState } from "react";
import { SecurityToolbar } from "./SecurityToolbar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import { LuMoreVertical } from "react-icons/lu";
import {
  fetchProtectedAccounts,
  fetchParticularAccount,
} from "../../service/Security";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { Spinner } from "../../Spinner";
import translations from "../common/Translations";

export const Security = () => {
  const {
    theme,
    setParticularAccount,
    setIsEditRequest,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    selectedOrgId,
    langMode,
  } = useContext(AppContext);
  const [totalValue, setTotalValue] = useState(0);
  const [accountNo, setAccountNo] = useState("");
  const [list, setList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const [loading, setLoading] = useState(false);
  const totalItems = totalValue;
  const tableHeaders = [
    "Account No",
    "From Date",
    "To Date",
    "Account Protected",
    "Permanent Protection",
    "Remarks", // Add the Remarks header
  ];

  const navigate = useNavigate();
  const [checkedbox, setCheckedBox] = useState(false);
  const loadProtectedAccounts = async () => {
    const accessToken = Cookies.get("accessToken");
    try {
      setLoading(true);
      const protectedAccounts = await fetchProtectedAccounts(
        accessToken,
        currentPage,
        pageSize,
        selectedOrgId
      );
      setList(protectedAccounts.accountProtectionList);
      setTotalValue(protectedAccounts.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Error loading protected accounts:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    loadProtectedAccounts();
  }, [currentPage, pageSize, selectedOrgId]);

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };
  useEffect(() => {
    if (selectedOrgId) {
      setCurrentPage(0);
      setPageSize(5);
      loadProtectedAccounts(selectedOrgId, currentPage, pageSize);
    }
  }, [selectedOrgId]);

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleCheckboxChange = (uniqueId) => {
    setSelectedRecords((prevSelected) => {
      let newSelected = [...prevSelected];
      if (newSelected.includes(uniqueId)) {
        newSelected = newSelected.filter((id) => id !== uniqueId);
      } else {
        newSelected.push(uniqueId);
      }
      return newSelected;
    });
    setCheckedBox(true);
  };

  const handleMasterCheckboxChange = () => {
    if (isAllSelected) {
      setSelectedRecords([]);
    } else {
      const allprotectedAcc = list.map((item) => item.id);
      setSelectedRecords(allprotectedAcc);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleEditPopup = (userId, position) => {
    setEditUserId(userId);
    setPopupPosition(position);
    setShowEdit(true);
  };

  const closeEditPopup = () => {
    setShowEdit(false);
  };

  const Popup = ({ onClose, onEdit, position, userId }) => {
    if (!position) {
      return null;
    }
    const adjustedPosition = {
      x: position.x - 80,
      y: position.y,
    };

    return (
      <div
        className="fixed bg-opacity-50 flex items-center justify-center top-2"
        style={{
          top: adjustedPosition.y,
          left: adjustedPosition.x < 0 ? 0 : adjustedPosition.x,
          width: "100px",
        }}
      >
        <div
          className={`bg-white rounded p-3 shadow-lg relative ${
            theme === "dark"
              ? "dark-mode border "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
          style={{ width: "80%" }}
        >
          <button
            className={`absolute top-0 right-0 p-1 text-sm ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
            onClick={onClose}
          >
            <RxCross2 />
          </button>
          <button
            className={`text-black px-2 text-sm py-1 rounded ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
            onClick={() => onEdit(userId)}
          >
            {translations[langMode].edit}
          </button>
        </div>
      </div>
    );
  };

  const editProtectedAccount = async (editUserId) => {
    const accessToken = Cookies.get("accessToken");
    try {
      const fetchedParticularAccount = await fetchParticularAccount(
        accessToken,
        editUserId
      );
      setParticularAccount(fetchedParticularAccount);
      setIsEditRequest(true);
      const updateProtectedAccount = `/createAccount/${editUserId}`;
      navigate(updateProtectedAccount);
    } catch (error) {}
  };

  return (
    <div className="w-full py-2">
      <h4
        className={`m-2 text-sm uppercase font-semibold text-gray-800 ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        {translations[langMode].security}
      </h4>
      <div
        className={`bg-white ${
          theme === "dark"
            ? "dark-mode  "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        <SecurityToolbar
          setAccountNo={setAccountNo}
          accountNo={accountNo}
          checkedbox={checkedbox}
          selectedRecords={selectedRecords}
          setCheckedBox={setCheckedBox}
          setList={setList}
        />
          <div className="px-5 py-1 flex justify-between items-center">
          <div className="text-sm text-gray-400 flex items-center gap-4">
            <label htmlFor="pageSize">Rows per page:</label>
            <select
              id="pageSize"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div>
            <p className="text-sm text-gray-400 flex items-center gap-4">
              {`${startIndex + 1}-${Math.min(
                endIndex,
                totalItems
              )} of ${totalItems} items`}
              <IoIosArrowBack
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
              />
              <IoIosArrowForward
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              />
            </p>
          </div>
        </div>
        <div className="overflow-x-auto mx-5">
          <table
            className={`min-w-full text-sm text-left rtl:text-right table-auto ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            <thead
              className={`text-sm bg-gray-100 text-gray-800 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <tr>
                <th scope="col" className="px-6 py-2">
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleMasterCheckboxChange}
                    className="form-checkbox"
                  />
                </th>
                {tableHeaders.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list?.slice(startIndex, endIndex).map((item, index) => (
                <tr
                  key={index}
                  className={`bg-white text-sm border-b hover:bg-gray-100 dark:hover:bg-gray-900 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <td className="w-2 p-2">
                    <input
                      type="checkbox"
                      checked={selectedRecords.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                      className="form-checkbox ml-4"
                    />
                  </td>
                  <td className="px-4 py-2">{item.accountNo}</td>
                  <td className="px-4 py-2">{item.fromDate}</td>
                  <td className="px-4 py-2">{item.toDate}</td>
                  <td className="px-4 py-2">
                    {item.forAllWorkflow ? "Yes" : "No"}
                  </td>
                  <td className="px-4 py-2">{item.permanent ? "Yes" : "No"}</td>
                  <td className="px-4 py-2">{item.remarks}</td>
                  <td className="px-4 py-2">
                    <div>
                      <LuMoreVertical
                        size={20}
                        className="text-blue-600 hover:text-blue-800 cursor-pointer"
                        onClick={(event) =>
                          handleEditPopup(item.id, {
                            x: event.clientX,
                            y: event.clientY,
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
              {list.length === 0 && <p className="text-center">No Data</p>}
              {loading && <Spinner />}
            </tbody>
          </table>
        </div>

      
      </div>
      {showEdit && (
        <Popup
          onClose={closeEditPopup}
          onEdit={() => editProtectedAccount(editUserId)}
          position={popupPosition}
          userId={editUserId}
        />
      )}
    </div>
  );
};
