import React, { useState, useEffect, useContext } from "react";
import { getFieldNames } from "../../../service/UserDashboard";
import { RxCross2 } from "react-icons/rx";
import AppContext from "../../../context/AppContext";
const ActivityQuestions = ({
  onClose,
  handleOpenListModal,
  dashboardNameInput,
  selectedFieldQuestionId,
  handleQuestionChange,
}) => {
  const [fieldQuestion, setFieldQuestion] = useState([]);
  const { theme } = useContext(AppContext);
  useEffect(() => {
    const fetchFieldNames = async () => {
      const fieldNames = await getFieldNames(dashboardNameInput);
      setFieldQuestion(fieldNames);
    };
    fetchFieldNames();
  }, [dashboardNameInput]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50 backdrop-blur-sm">
      <div
        className={`bg-white rounded-lg p-4 relative overflow-y-auto w-5/12 max-h-full  ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`} >
        <span
          className="absolute top-0 right-0 m-4 cursor-pointer text-gray-500"
          onClick={onClose}
        >
          <RxCross2 />
        </span>
        <h5 className="font-semibold ml-4 text-center">
          Select Activity Questions
        </h5>
        <div className="mt-10">
          <label
            htmlFor="organizationId"
            className={`block text-sm mb-2 font-medium text-gray-900 dark:text-white ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            Select Field Question
          </label>
          <select
            id="dashboardName"
            name="dashboardName"
            value={selectedFieldQuestionId}
            onChange={handleQuestionChange}
            className={` ${
              theme === "dark"
                ? "dark-mode border"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }w-60 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          >
            <option value="">Select</option>
            {fieldQuestion.map((que) => (
              <option
                key={que.workflowFormFieldId}
                value={que.workflowFormFieldId}
              >
                {que.fieldName}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mt-5">
          <button
            type="button"
            onClick={onClose}
            className="mt-6 px-4 py-2 bg-white border border-gray-500 text-black font-semibold rounded-md hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="mt-6 ml-4 px-4 py-2 bg-blue-800 text-white rounded-md font-semibold hover:bg-blue-900"
            onClick={handleOpenListModal}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivityQuestions;
