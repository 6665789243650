import React, { useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "./ChartModal";
import AppContext from "../../../../context/AppContext";
import { fetchUserCount } from "../../../../service/UserDashboard";
import { FaUser } from "react-icons/fa";
const translations = {
  en: {
    usercount: "User Counts",
  },
  ar: {
    usercount: "أعداد المستخدمين",
  },
};
const UserCountChart = ({ selectedOrgId, loading, setLoading }) => {
  const [userCount, setUserCount] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [chartView, setChartView] = useState("chart"); // Default to donut chart view
  const colors = ["#399918", "#EF5A6F", "#674188", "#FFD433", "#FF0000"];
  const Piecolors = ["#FF9800", "#41B06E", "#E72929"];
  const { setAdminCount, setFieldAgentCount, setOfficeUser, langMode, theme } =
    useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserCount(selectedOrgId);
        if (data.header?.code === 601) {
          setError("Please select an organization.");
          setLoading(false);
          return;
        }
        if (data.body && data.body.value) {
          setUserCount(data.body.value);
          setOfficeUser(data.body.value.officeUserCount || 0);
          setFieldAgentCount(data.body.value.fieldAgentCount || 0);
          setAdminCount(data.body.value.adminCount || 0);
          setError("");
          setLoading(false);
        } else {
          setError("Unexpected response format. Please try again.");
          setLoading(false);
        }
      } catch (err) {
        setError("Failed to fetch user count. Please try again.");
        setLoading(false);
        console.error(err);
      }
    };
    fetchData();
  }, [selectedOrgId]);

  const ChartView = ({ series, options }) => (
    <div id="chart" className="">
      <ReactApexChart
        options={{
          ...options,
          dataLabels: {
            enabled: true, // Hide data labels
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#fff"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "14px", // Adjust the size of the name labels from the series
                    color:
                      theme === "dark"
                        ? "#fff"
                        : theme === "high-contrast"
                        ? "#000"
                        : "#fff", // Adjusting label text color
                  },
                  value: {
                    fontSize: "14px", // Adjust the size of the value labels inside the donut chart
                    color:
                      theme === "dark"
                        ? "#fff"
                        : theme === "high-contrast"
                        ? "#000"
                        : "#fff", // Adjusting label text color
                  },
                },
              },
            },
          },
        }}
        series={series}
        type="donut"
        width="350"
      />
    </div>
  );

  const GraphView = ({ series, options }) => {
    const graphSeries = [
      {
        name: "User Count",
        data: series,
      },
    ];

    const graphOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: Object.keys(userCount).map((key) => formatLabel(key)),
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: true, // Show data labels
        style: {
          colors:
            theme === "dark"
              ? ["#fff"]
              : theme === "high-contrast"
              ? ["#000"]
              : ["#333"], // Adjusting text color based on theme
        },
      },
      legend: {
        show: true, // Hide legend
        position: "bottom",
        labels: {
          colors:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting legend label color
        },
      },
      tooltip: {
        theme: theme === "dark" ? "dark" : "light",
        enabled: true, // Show tooltips
        style: {
          background:
            theme === "dark"
              ? "#333"
              : theme === "high-contrast"
              ? "#fff"
              : "#f4f4f4", // Adjusting tooltip background color
          color:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting tooltip text color
        },
      },
    };

    return (
      <div id="graph" className="w-full">
        <ReactApexChart
          options={graphOptions}
          series={graphSeries}
          type="bar"
          width="390"
        />
      </div>
    );
  };

  const RadarChartView = ({ series, options }) => {
    const radarOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "radar", // Setting the chart type to radar
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.2,
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true, // Show data labels
        style: {
          colors:
            theme === "dark"
              ? ["#fff"]
              : theme === "high-contrast"
              ? ["#000"]
              : ["#333"], // Adjusting text color based on theme
        },
      },
      legend: {
        show: true, // Hide legend
        position: "bottom",
        labels: {
          colors:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting legend label color
        },
      },
       tooltip: {
        theme: theme === "dark" ? "dark" : "light",
        enabled: true, // Show tooltips
        style: {
          background:
            theme === "dark"
              ? "#333"
              : theme === "high-contrast"
              ? "#fff"
              : "#f4f4f4", // Adjusting tooltip background color
          color:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting tooltip text color
        },
      },
    };
    return (
      <div id="radar-chart " className="w-full">
        <ReactApexChart
          options={radarOptions}
          series={[{ name: "User Count", data: series }]}
          type="radar"
          width="390"
        />
      </div>
    );
  };

  const AreaChartView = ({ series, options }) => {
    const areaOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "area", // Changing the chart type to area for area chart view
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      xaxis: {
        categories: Object.keys(userCount).map(formatLabel), // Ensure userCount is correctly populated
      },
      colors: colors, // Customize your area chart colors here
      dataLabels: {
        enabled: true, // Show data labels
        style: {
          colors:
            theme === "dark"
              ? ["#fff"]
              : theme === "high-contrast"
              ? ["#000"]
              : ["#333"], // Adjusting text color based on theme
        },
      },
      stroke: {
        curve: "smooth",
      },
      tooltip: {
        theme: theme === "dark" ? "dark" : "light",
        enabled: true, // Show tooltips
        style: {
          background:
            theme === "dark"
              ? "#333"
              : theme === "high-contrast"
              ? "#fff"
              : "#f4f4f4", // Adjusting tooltip background color
          color:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting tooltip text color
        },
      },
   legend: {
        show: true, // Hide legend
        position: "bottom",
        labels: {
          colors:
            theme === "dark"
              ? "#fff"
              : theme === "high-contrast"
              ? "#000"
              : "#333", // Adjusting legend label color
        },
      },
    };
    return (
      <div
        id="area-chart"
        className={` ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }w-full px-2`}
      >
        <ReactApexChart
          options={areaOptions}
          series={[{ name: "Task Priority Count", data: series }]}
          type="area"
          width="375"
        />
      </div>
    );
  };

  const formatLabel = (label) => {
    switch (label) {
      case "fieldAgentCount":
        return "Field Agents";
      case "officeUserCount":
        return "Office Users";
      case "adminCount":
        return "Admin";
      default:
        return label;
    }
  };

  const categories = Object.keys(userCount).map((key) => formatLabel(key));
  const data = Object.values(userCount);
  const options = {
    labels: categories,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "User Count",
              showAlways: true,
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    colors: Piecolors,
  };
  const series = data;

  return (
    <div
      className={` ${theme === "dark" ? " text-gray-200 " : " text-gray-900"}`}
    >
      <div className="flex  justify-between py-1">
        <h2 className="semibold text-sm text-left py-1 flex">
          {" "}
          <FaUser className="mt-1 text-orange-600 mr-1" />
          {translations[langMode].usercount}
        </h2>
        {!error && (
          <button
            type="button"
            className="px-3 text-xs justify-end font-medium text-center inline-flex items-center text-gray-700"
            onClick={() => setShowModal(true)}
          >
            <IoEye className="w-5 h-5 mr-1" />
          </button>
        )}
      </div>
      {error ? (
        <div className="flex items-center justify-center text-center text-red-600 font-bold h-96 w-[500px]">
          {error}
        </div>
      ) : (
        <>
          {chartView === "chart" ? (
            <ChartView series={series} options={options} />
          ) : chartView === "area" ? (
            <AreaChartView series={series} options={options} />
          ) : chartView === "radar" ? (
            <RadarChartView series={series} options={options} />
          ) : (
            <GraphView series={series} options={options} />
          )}
        </>
      )}
      <div className="mixed-chart ">
        <ChartModal
          showModal={showModal}
          setShowModal={setShowModal}
          setChartView={setChartView}
        />
      </div>
    </div>
  );
};

export default UserCountChart;
