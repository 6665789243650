import React, { useContext, useState, useEffect, useRef } from "react";
import { FaCaretDown, FaLocationDot } from "react-icons/fa6";
import AppContext from "../../../context/AppContext";
import { toast } from "react-toastify";
import { fetchMarkerColors, SaveColors } from "../../../service/setting";
import translations from "../../common/Translations";

export const GeneralSetting = () => {
  const {
    toggleTheme,
    theme,
    langMode,
    toggleLangMode,
    setRunTour,
    runTour,
    workflowId,
    userType
  } = useContext(AppContext);
  const toggleTour = () => {
    setRunTour((prevRun) => !prevRun);
  };
  const colorArray = [
    "#4379F2", //blue
    "#F3C623", //yellow
    "#FF3333", //dark red
    "#CC2B52", //pink
    "#6EC207", //green

    "#CD5C08", //brown

    "#7A1CAC", //purple
    "#E85C0D", //orange
    "#939185", //gray
    "#124076", //neavy blue
  ];
  const statusList = [
    { name: translations[langMode].unassign, taskStatus: "Active" },
    { name: translations[langMode].assigned, taskStatus: "Assigned" },
    { name: translations[langMode].onhold, taskStatus: "On_Hold" },
    { name: translations[langMode].progress, taskStatus: "In_Progress" },
    { name: translations[langMode].completed, taskStatus: "Completed" },
  ];

  const toastShownRef = useRef({ workflowToast: false, colorToast: false }); // Track if toast has been shown
  const defaultColorMap = statusList.reduce((map, status, index) => {
    map[status.taskStatus] = colorArray[index % colorArray.length];
    return map;
  }, {});
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(statusList.length).fill(false) // Track dropdown state for each status
  );
  const [selectedColor, setSelectedColor] = useState([]);
  const [hasColors, setHasColors] = useState(false);
  const [colorData, setColorData] = useState([]);

  useEffect(() => {
    if (!workflowId) {
      if (!toastShownRef.current.workflowToast) {
        toast.warn("Select workflow first");
        toastShownRef.current.workflowToast = true;
      }
      return;
    }

    const fetchColors = async () => {
      try {
        const fetchedColorData = await fetchMarkerColors(workflowId);
        setColorData(fetchedColorData); // Store colorData for use in handleSave
        setHasColors(fetchedColorData.length > 0);

        if (fetchedColorData.length === 0) {
          if (!toastShownRef.current.colorToast) {
            toast.warn(
              "The custom marker color not added, select from below !!"
            );
            toastShownRef.current.colorToast = true;
          }
        }

        const mappedColors = statusList.map((status) => {
          const colorEntry = fetchedColorData.find(
            (color) => color.taskStatus === status.taskStatus
          );
          return colorEntry
            ? colorEntry.color
            : defaultColorMap[status.taskStatus];
        });

        setSelectedColor(mappedColors);
      } catch (error) {
        console.error("Error fetching colors:", error);
        setSelectedColor(
          statusList.map((status) => defaultColorMap[status.taskStatus])
        );
      }
    };

    fetchColors();
  }, [workflowId]);
  const handleDropdownToggle = (statusIndex) => {
    setDropdownOpen((prev) =>
      prev.map((isOpen, index) => (index === statusIndex ? !isOpen : false))
    );
  };

  const handleColorChange = (statusIndex, color) => {
    const updatedColors = [...selectedColor];
    updatedColors[statusIndex] = color;
    setSelectedColor(updatedColors);
  };

  const handleSave = async () => {
    if (!workflowId) {
      toast.warn("Please select a workflow first.");
      return;
    }

    // Prepare the payload by checking if the color already exists
    const markerColorList = statusList.map((status, index) => {
      const colorEntry = colorData.find(
        (color) => color.taskStatus === status.taskStatus
      );

      return {
        id: colorEntry ? colorEntry.id : undefined, // Only include id if updating
        taskStatus: status.taskStatus,
        color: selectedColor[index] || defaultColorMap[status.taskStatus],
      };
    });

    const payload = {
      workflowId,
      markerColorList,
    };

    try {
      const response = await SaveColors(payload);
      toast.success("Marker colors saved successfully!");
    } catch (error) {
      console.error("Error saving marker colors:", error);
      toast.error("Failed to save marker colors.");
    }
  };

  return (
    <div className="w-full">
      <div className="flex gap-5 flex-col">
        <h2 className="text-sm text-gray-500 font-bold">
          {translations[langMode].toursetting}
        </h2>
        <div className="flex gap-5">
          <button
            className="rounded-md px-3 py-2 text-white bg-yellow-400 text-sm"
            onClick={toggleTour}
          >
            {runTour
              ? translations[langMode].stoptour
              : translations[langMode].starttour}
          </button>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="text-sm text-gray-500 font-bold">
          {translations[langMode].colormode}
        </h3>
        <div
          className="mt-5 inline-flex rounded-md gap-4 shadow-sm"
          role="group"
        >
          <button
            type="button"
            onClick={() => toggleTheme("light")}
            className={`px-4 py-2 text-sm font-medium text-gray-900 bg-white border
               border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 
               focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600
                dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 
                dark:focus:text-white `}
          >
            {translations[langMode].light}
          </button>
          <button
            type="button"
            onClick={() => toggleTheme("dark")}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            {translations[langMode].dark}
          </button>
          <button
            type="button"
            onClick={() => toggleTheme("high-contrast")}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            {translations[langMode].highcontrast}
          </button>
        </div>
      </div>
      <div className="mt-10 text-sm text-gray-500 font-bold">
        <h3>{translations[langMode].lang}</h3>
        <div
          className="mt-5 inline-flex rounded-md gap-4 shadow-sm"
          role="group"
        >
          <button
            type="button"
            onClick={() => toggleLangMode("en")}
            className={`px-4 py-2 text-sm font-medium  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            } border border-gray-400 rounded-s-lg  focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600`}
          >
            English
          </button>
          <button
            type="button"
            onClick={() => toggleLangMode("ur")}
            className={`px-4 py-2 text-sm font-medium  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }  border border-gray-400   focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600`}
          >
            Urdu
          </button>
          <button
            type="button"
            onClick={() => toggleLangMode("ar")}
            className={`px-4 py-2 text-sm font-medium  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }  border border-gray-400 rounded-e-lg  focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600`}
          >
            Arabic
          </button>
        </div>
      </div>

      <div className="mt-5 flex flex-col gap-4">
        <h2 className="text-sm text-gray-500 font-bold">
          {translations[langMode].custommarker}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3">
          {statusList.map((status, statusIndex) => (
            <div key={statusIndex} className="mt-5 flex flex-col gap-3">
              <span className={`text-sm text-gray-700 font-semibold  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }  `}>
                {status.name}
              </span>
              <div className="flex items-center gap-5 relative">
                <div>
                  <FaLocationDot
                    size={40}
                    style={{
                      color: selectedColor[statusIndex],
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div
                  onClick={() => handleDropdownToggle(statusIndex)}
                  className="cursor-pointer relative"
                >
                  <FaCaretDown size={20} />
                  {dropdownOpen[statusIndex] && (
                    <div className="absolute top-full left-0 bg-white px-2 py-2 w-44 rounded-md shadow-lg border border-gray-200 z-10 grid grid-cols-5 gap-3">
                      {colorArray.map((color, colorIndex) => (
                        <div
                          key={colorIndex}
                          onClick={() => handleColorChange(statusIndex, color)}
                          className="w-5 h-5 rounded-md cursor-pointer"
                          style={{
                            backgroundColor: color,
                            border:
                              selectedColor[statusIndex] === color
                                ? "2px solid black"
                                : "none",
                          }}
                        ></div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-20 w-full">
        <div className="sm:col-span-4">
          <hr className="w-full border-gray-300" />
        </div>
        <div className="sm:col-span-4 pt-5 flex justify-end items-center w-full">
          <button
            type="button"
            className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            {translations[langMode].cancle}
          </button>
          {hasColors ? (
            <button
              type="button"
              onClick={handleSave}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {translations[langMode].update}
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSave}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {translations[langMode].save}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
