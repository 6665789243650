import React, { useContext, useState } from "react";
import TaskStatusCountChart from "./static-charts/TaskStatusCountChart";
import UserCountChart from "./static-charts/UserCountChart";
import PriorityChart from "./static-charts/PriorityChart";
import AppContext from "../../../context/AppContext";
import { FaUsers } from "react-icons/fa";
import { RiAdminLine, RiMapPinUserFill } from "react-icons/ri";
import { Spinner } from "../../../Spinner";
import translations from "../../common/Translations";

const UserDashboard = ({ selectedWorkflowId }) => {
  const {
    admincount = 0,
    fieldagentcount = 0,
    officeuser = 0,
    theme,
    selectedOrgId,
    langMode,
    runTour,
    tourSteps,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const cardClass = `rounded-lg shadow-lg p-2 transform transition-transform duration-300 ease-in-out 
    ${
      theme === "dark" ? "bg-gray-800 text-gray-300" : "bg-white text-gray-700"
    } 
    hover:scale-105 hover:shadow-xl`;

  const titleClass = ` text-nowrap text-sm font-semibold mb-1 ${
    theme === "dark" ? "text-blue-400" : "text-blue-600"
  }`;
  const countClass = `text-sm font-bold ${
    theme === "dark" ? "text-blue-200" : "text-blue-500"
  }`;

  return (
    <div className="absolute ">
      {loading && (
        <Spinner/>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-3 mb-4">
        <div className={cardClass}>
          <div className="flex items-center justify-between">
            <h3 className={titleClass}>{translations[langMode].admin}</h3>
            <RiAdminLine className="text-blue-500" size={28} />
          </div>
          <p className={countClass}>{admincount}</p>
        </div>
        <div className={cardClass}>
          <div className="flex items-center justify-between">
            <h3 className={titleClass}>{translations[langMode].office_user}</h3>
            <FaUsers className="text-purple-500" size={28} />
          </div>
          <p className={countClass}>{officeuser}</p>
        </div>
        <div className={cardClass}>
          <div className="flex items-center justify-between">
            <h3 className={titleClass}>{translations[langMode].field_agent}</h3>
            <RiMapPinUserFill className="text-green-500" size={28} />
          </div>
          <p className={countClass}>{fieldagentcount}</p>
        </div>
      </div>
      <div className="h-[500px]  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 ">
        <div className="w-full">
          <div
            className={`bg-white rounded-lg shadow-md p-2 w-[370px] h-[300px]
        ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
          >
            <UserCountChart
              setLoading={setLoading}
              loading={loading}
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          </div>
        </div>
        <div className="w-full">
          <div
            className={`bg-white rounded-lg shadow-md p-2  w-[370px] h-[300px]
        ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
          >
            <TaskStatusCountChart
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          </div>
        </div>
        <div className="w-full">
          <div
            className={`bg-white rounded-lg shadow-md p-2  w-[370px] h-[300px]
        ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
          >
            <PriorityChart
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
