import React, { useContext, useState, Fragment, useRef } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { masterRecordApi } from "../../service/Task";
import translations from "../../components/common/Translations";

const ImportModal = ({ open, setOpen, loading, setLoading }) => {
  const cancelButtonRef = useRef(null);
  const { theme, langMode, selectedOrgId } = useContext(AppContext);
  const [fileData, setFileData] = useState(null);
  const fileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileData(file);
    }
  };
  const masterRecordUpload = async () => {
    if (!fileData) {
      toast.error("Please select a file before uploading.");
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
    try {
      const response = await masterRecordApi(selectedOrgId, fileData);
      if (response && response.header.code === 600) {
        toast.success(response.body.value);
      } else if (response && response.header.code === 601) {
        toast.error(response.body.value);
      } else {
        toast.error(response.message || "Error uploading file.");
      }
      console.log(response);
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <div className="h-[200px]">
                  <div className="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                    <h3 className="px-5 text-sm font-semibold text-gray-900 dark:text-white">
                      {translations[langMode].exportData}
                    </h3>

                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setOpen(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-14">
                    <input
                      type="file"
                      onChange={fileUpload}
                      className="rounded-l-md border-2"
                    />
                  </div>
                  <div className="flex gap-5 justify-end px-14 py-10">
                    <button
                      onClick={masterRecordUpload}
                      className="px-2 py-2  text-sm rounded-md bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors flex items-center"
                    >
                      {loading ? "Uploading.." : "Upload"}
                    </button>
                    <button
                      onClick={() => setOpen(false)}
                      className="px-2 py-2  text-sm rounded-md bg-gray-300 text-black font-medium hover:bg-gray-200 transition-colors flex items-center"
                    >
                      Cancel
                    </button>
                  </div>
                  {loading && (
                    <>
                      <p className="text-center text-sm text-gray-500 mt-4 absolute bg-black bg-opacity-20">
                        Please wait! The file is being uploaded. It may take
                        some time, and once successfully uploaded, you will
                        receive a success message.
                      </p>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImportModal;
