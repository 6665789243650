import React, { useContext, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import AppContext from "../../context/AppContext";
import { getFieldNames } from "../../service/workflow";
import { GetFieldFolder } from "./GetFieldFolder/GetFieldFolder";
import { toast } from "react-toastify";
const CloneOption = ({
  isClone,
  isEdit,
  langMode,
  theme,
  workflowName,
  workflowId,
  title,
  handleChangee,
  titleHandle,
  handleTimer,
  selectedTimer,
  timerValue,
  Translations,
  taskExcelColumns, setTaskExcelColumns
}) => {
  const [fields, setFields] = useState([]);
  const [open, setOpen] = useState(false);

  const handleGetfields = async () => {
    try {
      const data = await getFieldNames(workflowId);
      if (data.header.code === 600) {
        if (data && data.body && Array.isArray(data.body.value)) {
          if (data.body.value.length === 0) {
            // Show a toast notification if the array is empty
            toast.warn("No fields available, Upload task sheet first to get additional fields");

            return;
          } else {
            setFields(data.body.value); // Set the list of field names if not empty
            
          }
        }
        setOpen(true); // Set `open` to true if the code is 600
      }
      console.log(data); // Handle data as needed
    } catch (error) {
      console.error("Error fetching field names:", error);
    }
  };
  return (
    <div className="flex justify-between">
      <div className="flex ">
        <div className="flex">
          <div>
            {isClone ? (
              <div className="flex flex-col space-y-2 py-3">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].entername}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                </div>
                <input
                  id="cloneName"
                  type="text"
                  value={workflowName}
                  className={`${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  } bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  onChange={handleChangee}
                  placeholder="Enter workflow name"
                />
              </div>
            ) : isEdit ? (
              <div className="flex flex-col space-y-2 py-3">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].entername}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                </div>
                <input
                  id="cloneName"
                  type="text"
                  value={workflowName}
                  className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  onChange={handleChangee}
                  placeholder="Enter workflow name"
                />
              </div>
            ) : (
              <div className="flex flex-col space-y-2 py-2">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].entername}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                </div>
                <input
                  id="cloneName"
                  type="text"
                  value={workflowName}
                  className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  onChange={handleChangee}
                  placeholder="Enter workflow name"
                />
              </div>
            )}
          </div>
          <div className="ml-5">
            {isEdit ? (
              <div className="flex flex-col space-y-2 py-3">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].title}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                </div>
                <input
                  id="cloneName"
                  type="text"
                  value={title}
                  className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  onChange={titleHandle}
                  placeholder="Enter PDF Title"
                />
              </div>
            ) : (
              <div className="flex flex-col space-y-2 py-2">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].title}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                </div>
                <input
                  id="cloneName"
                  type="text"
                  value={title}
                  className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  onChange={titleHandle}
                  placeholder="Enter PDF title"
                />
              </div>
            )}
          </div>
        </div>
        <div>
          {isEdit ? (
            <div className="flex flex-col space-y-2 py-3">
              <div className="flex items-center">
                <label
                  htmlFor="cloneName"
                  className="text-sm ml-5 font-medium text-gray-900 dark:text-white"
                >
                  {Translations[langMode].selectTimer}
                </label>
              </div>
              <select
                id="numberDropdown"
                className={`w-full ml-3 bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
                onChange={handleTimer}
                value={selectedTimer}
              >
                {timerValue.map((option) => (
                  <option
                    key={option}
                    value={option}
                    className={`${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="flex flex-col space-y-2 py-3">
              <div className="flex items-center">
                <label
                  htmlFor="cloneName"
                  className="text-sm ml-5 font-medium text-gray-900 dark:text-white"
                >
                  {Translations[langMode].selectTimer}
                </label>
              </div>
              <select
                id="numberDropdown"
                className={`w-full ml-3 bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
                onChange={handleTimer}
                value={selectedTimer}
              >
                {timerValue.map((option) => (
                  <option
                    key={option}
                    value={option}
                    className={`${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="">
        {isEdit && (
          <div className="flex">
            <button
              className="inline-flex px-2 py-2 bg-blue-800 text-white rounded-md text-xs gap-2"
              onClick={handleGetfields}
            >
              <FaPlusCircle size={14} /> Add Additional Field{" "}
            </button>
          </div>
        )}
      </div>
      <GetFieldFolder open={open} setOpen={setOpen} fields={fields} setFields={setFields} taskExcelColumns={taskExcelColumns} setTaskExcelColumns={setTaskExcelColumns} />
    </div>
  );
};

export default CloneOption;
