import React, { useEffect, useState, useContext } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-grid-layout/css/styles.css";
import { FaArrowLeft } from "react-icons/fa";
import "react-resizable/css/styles.css";
import { DraggableElement } from "../../components/workflow/DraggableElement";
import DroppableArea from "../../components/workflow/DroppableArea";
import { toast } from "react-toastify";
import Modal from "./PreviewModal/Modal";
import DynamicPage from "./DynamicPage";
import { useLocation, useNavigate } from "react-router-dom";
import ValidationModal from "./ValidationModal";
import WorkflowVersions from "./WorkflowVersions";
import { cloneWorkflow } from "../../service/workflow";
import AppContext from "../../context/AppContext";
import ExitModal from "./ExitModal/ExitModal";
import { Translations } from "./constants";
import { Parameters } from "./constants";
import { Elements } from "./constants";
import Joyride from "react-joyride";
import { createWorkflow } from "../../service/workflow";
import { fetchWorkflowHistory } from "../../service/workflow";
import { updateWorkflow } from "../../service/workflow";
import FieldDetails from "./FieldDetails";
import CloneOption from "./CloneOption";
import { Spinner } from "../../Spinner";

const DynamicWorkFlow = () => {
  const {
    selectedOrgId,
    theme,
    langMode,
    workflowmaker,
    runTour,
    handleJoyrideCallback,
  } = useContext(AppContext);
  const [isNewFieldDragged, setIsNewFieldDragged] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [elements, setElements] = useState(Elements);
  const [parameters, setParameters] = useState(Parameters);
  const [droppedItems, setDroppedItems] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [mainTab, setMainTab] = useState(1);
  const [showInput, setShowInput] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [nextId, setNextId] = useState(1);
  const [savedItems, setSavedItems] = useState([]);
  const [preview, setPreview] = useState({});
  const [dragItems, setDragItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [tempInputValue, setTempInputValue] = useState({});
  const [tempEditValues, setTempEditValues] = useState({});
  const [isEditShow, setIsEditShow] = useState("");
  const [radioButtons, setRadioButtons] = useState([]);
  const [workflowName, setworkflowName] = useState("");
  const [reportField, setreportField] = useState(false);
  const [reportInput, setreportInput] = useState("");
  const [pdfInput, setPdfInput] = useState("");
  const [showRadioOptions, setShowRadioOptions] = useState(true);
  const [selectedRadioOption, setSelectedRadioOption] = useState();
  const [page, setPage] = useState(1);
  const [fieldIdsToBeDeleted, setfieldIdsToBeDeleted] = useState([]);
  const [radioValueIdsToBeDeleted, setradioValueIdsToBeDeleted] = useState([]);
  const [validationValueToBeDeleted, setvalidationValueToBeDeleted] = useState(
    []
  );
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [collapse, setCollapse] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedRadioId, setSelectedRadioId] = useState(null);
  const [taskExcelColumns, setTaskExcelColumns] = useState([]);
  const location = useLocation();
  const { isClone } = location.state || {};
  const { isEdit } = location.state || {};
  const [timerValue, setTimerValue] = useState(
    Array.from({ length: 11 }, (_, i) => (i + 1) * 5)
  );
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedTimer, setSelectedTimer] = useState(30);
  const [workflowVersionHistories, setWorkflowVersionHistories] = useState([]);
  const [showVersions, setShowVersions] = useState(false);
  const [validationId, setValidationId] = useState(1);
  const [validationModal, setValidationModal] = useState(false);
  const [pdficon, setPdfIcon] = useState(false);
  const [reporticon, setReportIcon] = useState(false);
  const [size] = useState(5);
  const [pages, setPages] = useState(0);
  const [exitModal, setExitModal] = useState(false);
  const {
    workflowId,
    workflowFields,
    existWorkflowName,
    existPdfTitleName,
    workflowTimer,
    existDragItems,
    existRadioButtons,
  } = location.state || {};

  useEffect(() => {
    if (isEdit) {
      setworkflowName(existWorkflowName);
      setTitle(existPdfTitleName);
      setSelectedTimer(workflowTimer);
      setPdfInput(preview.excelReportName);
      setreportInput(preview.reportName);
    }
  }, [
    isEdit,
    existWorkflowName,
    existPdfTitleName,
    preview.excelReportName,
    preview.reportName,
  ]);

  useEffect(() => {
    if (isClone) {
      setPdfInput(preview.excelReportName);
      setreportInput(preview.reportName);
    }
  }, [isClone, preview.excelReportName, preview.reportName]);

  useEffect(() => {
    const initialCollapseState = Object.keys(groupedRadioButtons).reduce(
      (acc, uniqueKey) => {
        acc[uniqueKey] = true;
        return acc;
      },
      {}
    );
    setCollapsedGroups(initialCollapseState);
    setCollapse(false);
  }, []);

  const toggleCollapse = (uniqueKey) => {
    setCollapse((prevState) => ({
      ...prevState,
      [uniqueKey]: true,
    }));
    setCollapsedGroups((prevState) => {
      const newState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = true;
      });
      newState[uniqueKey] = !prevState[uniqueKey];
      return newState;
    });
  };

  const workflowUpdate = async (isEdit = true) => {
    const dataToSend = {
      workflowId: workflowId,
      timer: isEdit ? selectedTimer : workflowTimer,
      pdfTitle: isEdit ? title : existPdfTitleName,
      workflowName: isEdit ? workflowName : existWorkflowName,
      fieldsMetadata: droppedItems,
      fieldIdsToBeDeleted: fieldIdsToBeDeleted,
      radioValueIdsToBeDeleted: radioValueIdsToBeDeleted,
      validationValueToBeDeleted: validationsDelteId,
      taskExcelColumns: taskExcelColumns,
    };

    try {
      setIsLoading(true);
      const jsonData = await updateWorkflow(dataToSend);

      if (jsonData && jsonData.header.code === 600) {
        toast.success(jsonData.body.value);
        navigate("/workflow");
        localStorage.removeItem("selectedFields");
        setLoading(false);
      } else if (jsonData.header.code === 601) {
        toast.success(jsonData.body.value);
        setLoading(false);
      } else if (jsonData) {
        toast.error(jsonData.body.value);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error updating workflow:", err);
      setLoading(false);
    }
  };
  // useEffect(() =>{
  //   if(!showVersions){
  //     setPages(0)
  //     setWorkflowVersionHistories([])
  //   }
  // },[showVersions])

  const handleVersionClick = async () => {
    try {
      if (workflowId) {
        setLoading(true); // Set loading to true when data fetching starts
        const jsonData = await fetchWorkflowHistory(workflowId, pages, size); // Pass page to the API
        if (jsonData && jsonData.header.code === 600) {
          const newHistories = jsonData.body.value.workflowHistories;
          if (newHistories.length > 0) {
            const existingIds = new Set(
              workflowVersionHistories.map((history) => history.id)
            );
            const uniqueNewHistories = newHistories.filter(
              (newHistory) => !existingIds.has(newHistory.id)
            );
            if (uniqueNewHistories.length > 0) {
              setWorkflowVersionHistories((prevHistories) => [
                ...prevHistories,
                ...uniqueNewHistories,
              ]);
            } else {
              toast.info("No more unique versions available");
            }
          } else {
            toast.info("No more versions available");
            setHasMore(false); // No more data to fetch
          }
        } else {
          toast.info("Create Workflow");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const triggerVersion = () => {
    if (!showVersions) {
      // If opening versions, fetch the workflow history
      handleVersionClick(); // Fetch versions
    } else {
      // If closing versions, reset the version histories to an empty state
      setWorkflowVersionHistories([]); // Clear version histories
      setPages(0);
    }

    // Toggle visibility
    setShowVersions((val) => !val);
  };
  useEffect(() => {
    if (existDragItems && existDragItems.length > 0) {
      setDroppedItems(existDragItems);
      if (Array.isArray(workflowFields) && workflowFields.length > 0) {
        workflowFields.forEach((field) => {
          if (Array.isArray(field.validationValues)) {
            setValidationFormValues(field.validationValues);
          } else {
            console.error(
              `validationValues is not an array for field: ${field.fieldName}`
            );
          }
        });
      } else {
        console.error(
          "existDragItems is either not defined or not an array or is empty."
        );
      }
    }
    if (existDragItems && existDragItems.length > 0) {
      setDragItems(existDragItems);
    }
    if (existRadioButtons && existRadioButtons.length > 0) {
      setRadioButtons(existRadioButtons);
    }
  }, [workflowFields]);

  const handleTimer = (event) => {
    setSelectedTimer(parseInt(event.target.value));
  };

  const handleChangee = (event) => {
    setworkflowName(event.target.value);
  };

  const titleHandle = (event) => {
    setTitle(event.target.value);
  };

  const handleReportInput = (event) => {
    setreportInput(event.target.value);
  };

  const handlePdfInput = (event) => {
    setPdfInput(event.target.value);
  };

  const workflowSubmit = async () => {
    const dataToSend = {
      timer: selectedTimer,
      workflowName: workflowName,
      pdfTitle: title,
      fieldsMetadata: droppedItems,
      organizationId: selectedOrgId,
    };

    try {
      setIsLoading(true);
      const jsonData = await createWorkflow(dataToSend);
      if (jsonData && jsonData.header.code === 600) {
        toast.success(jsonData.body.value);
        navigate("/workflow");
        setIsLoading(false);
      } else if (jsonData) {
        toast.error(jsonData.body.value);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error submitting workflow:", err);
      setIsLoading(false);
    }
  };

  function updateNestedAllInputValues(items, targetUniqueKey, newInputData) {
    return items.map((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return {
          ...item,
          allInputValues: [...newInputData],
        };
      }
      if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = updateNestedAllInputValues(
          item.radioInputValues,
          targetUniqueKey,
          newInputData
        );
        return { ...item, radioInputValues: updatedRadioInputValues };
      }
      return item;
    });
  }

  const updateAllInputValues = (updatedValues, previewkey) => {
    let newAllInputValues = [];
    updatedValues.forEach((input) => {
      if (input.uniqueKey === previewkey && input.value !== "") {
        const key = input.radioKey !== undefined ? input.radioKey : input.id;
        const newEntry = {
          inputKey: `input-${key}`,
          inputValue: input.value,
        };
        if (input.workflowFormFieldMetadataId !== undefined) {
          newEntry.id = input.id;
        }
        newAllInputValues.push(newEntry);
      }
    });

    const updatedDroppedItems = updateNestedAllInputValues(
      droppedItems,
      previewkey,
      newAllInputValues
    );
    setDroppedItems(updatedDroppedItems);
    setDragItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.uniqueKey === previewkey) {
          return {
            ...item,
            allInputValues: newAllInputValues,
          };
        }
        return item;
      });
    });
  };

  const handleAddInput = (uniqueKey, page) => {
    const nextKey = `${new Date().getTime()}`;
    setInputValues([
      ...inputValues,
      { id: nextKey, value: "", uniqueKey: uniqueKey },
    ]);

    setRadioButtons([
      ...radioButtons,
      {
        id: nextKey,
        value: "",
        uniqueKey: uniqueKey,
        isShow: true,
        radioPage: page,
      },
    ]);
  };

  const addReportfield = (previewKey, reportName) => {
    if (!reportInput && !reportName) {
      toast.warn("Update pdf name first");
      return;
    }
    const updatedData = droppedItems.map((item) => {
      if (item.uniqueKey === previewKey) {
        return {
          ...item,
          reportName: reportInput,
          reportField: false,
        };
      }
      return item;
    });
    setDroppedItems(updatedData);
    setDragItems(updatedData);
    setReportIcon(true);
    toast.success("PDF name update succssfully");
  };

  const addPdffield = (previewKey, excelReportName) => {
    if (!pdfInput && !excelReportName) {
      toast.warn("Update report name first");
      return;
    }
    const updatedData = droppedItems.map((item) => {
      if (item.uniqueKey === previewKey) {
        return {
          ...item,
          excelReportName: pdfInput,
          showInput: false,
        };
      }
      return item;
    });
    setDroppedItems(updatedData);
    setDragItems(updatedData);
    setPdfIcon(true);
    toast.success("Excel name update succssfully");
  };

  const onCloseValidation = () => setValidationModal(false);
  const [validationFormValues, setValidationFormValues] = useState([]);
  const [currentValidation, setCurrentValidation] = useState({
    validationId: validationId,
    validationCode: "",
    errorMessage: "",
    uniqueKey: "",
  });

  const handleCreateValidation = (event) => {
    event.preventDefault();
    if (currentValidation.validationCode !== "") {
      setValidationFormValues((prev) => [...prev, currentValidation]);
      updateValidationField(
        validationFormValues.concat(currentValidation),
        currentValidation.uniqueKey
      );
    }
    setCurrentValidation({
      validationId: validationId,
      validationCode: "",
      errorMessage: "",
      uniqueKey: "",
    });
    setValidationModal(false);
  };
  const updateValidationField = (updatedValues, previewkey) => {
    let newValidationVals = updatedValues
      .filter(
        (input) => input.uniqueKey === previewkey && input.validationCode !== ""
      )
      .map((input) => ({
        validationId: `validation-${input.validationId}`,
        errorMessage: input.errorMessage,
        validationCode: input.validationCode,
      }));
    const updatedDroppedItems = updateNestedFiledValidations(
      droppedItems,
      previewkey,
      newValidationVals
    );
    setDroppedItems(updatedDroppedItems);
  };
  function updateNestedFiledValidations(items, targetUniqueKey, newInputData) {
    return items.map((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return {
          ...item,
          validationValues: [...newInputData],
        };
      } else if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = updateNestedFiledValidations(
          item.radioInputValues,
          targetUniqueKey,
          newInputData
        );
        return {
          ...item,
          radioInputValues: updatedRadioInputValues,
        };
      }
      return item;
    });
  }

  const [validationsDelteId, setValidationsDeleteId] = useState([]);
  const handleValidationDelete = (id, previewKey, editId) => {
    setvalidationValueToBeDeleted((prevFieldIds) => [...prevFieldIds, editId]);
    setValidationFormValues((prevItems) => {
      const updatedValues = prevItems.filter(
        (item) => !(item.validationId === id && item.uniqueKey === previewKey)
      );
      return updatedValues;
    });
    setDroppedItems((prevItems) =>
      prevItems.map((item) => deleteValidationFromItem(item, id, previewKey))
    );
    setValidationsDeleteId((prev) => [...prev, editId]);
  };

  function deleteValidationFromItem(item, id, previewKey) {
    if (item.uniqueKey === previewKey) {
      if (item.validationValues) {
        item.validationValues = item.validationValues.filter(
          (validation) => validation.validationId !== `validation-${id}`
        );
      }
      if (item.radioInputValues && item.radioInputValues.length > 0) {
        item.radioInputValues = item.radioInputValues.map((nestedItem) =>
          deleteValidationFromItem(nestedItem, id, previewKey)
        );
      }
    } else if (item.radioInputValues && item.radioInputValues.length > 0) {
      item.radioInputValues = item.radioInputValues.map((nestedItem) =>
        deleteValidationFromItem(nestedItem, id, previewKey)
      );
    }
    return item;
  }

  const handleRadioOptions = (id, value, previewkey) => {
    let updatedRadioValues = [...radioButtons];
    const index = updatedRadioValues.findIndex(
      (input) => input.id === id && input.uniqueKey === previewkey
    );
    if (index !== -1) {
      updatedRadioValues[index] = { ...updatedRadioValues[index], value };
    } else {
      updatedRadioValues.push({ id, value, uniqueKey: previewkey });
    }
    setRadioButtons(updatedRadioValues);
    updateAllInputValues(updatedRadioValues, previewkey);
    setInputValues([{ id, value, uniqueKey: previewkey }]);
    removeSpecificInput(id, previewkey);
  };

  const removeSpecificInput = (idToRemove, uniqueKeyToRemove) => {
    const updatedRadioButtons = inputValues.filter(
      (input) =>
        !(input.id === idToRemove && input.uniqueKey === uniqueKeyToRemove)
    );
    setInputValues(updatedRadioButtons);
  };

  const handleRadioDeleteOptions = (id, radioKey, value, previewKey) => {
    setradioValueIdsToBeDeleted((prevFieldIds) => [...prevFieldIds, id]);
    setDroppedItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uniqueKey === previewKey) {
          let canDelete =
            !item.radioInputValues ||
            (Array.isArray(item.radioInputValues) &&
              item.radioInputValues.length === 0);
          if (canDelete) {
            setRadioButtons((prevRadioButtons) =>
              prevRadioButtons.filter(
                (btn) =>
                  !(
                    btn.id === id &&
                    btn.radioKey === radioKey &&
                    btn.uniqueKey === previewKey
                  )
              )
            );
            setDragItems((prevDragItems) =>
              prevDragItems.map((dragItem) => {
                if (dragItem.uniqueKey === previewKey) {
                  let updatedAllInputValues = dragItem.allInputValues.filter(
                    (input) => input.inputKey !== `input-${radioKey}-${id}`
                  );
                  return { ...dragItem, allInputValues: updatedAllInputValues };
                }
                return dragItem;
              })
            );
            setTempInputValue((prevValues) => {
              const keyToDelete = `${previewKey}-${radioKey}-${id}`;
              const { [keyToDelete]: _, ...remainingValues } = prevValues;
              return remainingValues;
            });
            return {
              ...item,
              allInputValues: item.allInputValues.filter(
                (input) => input.inputKey !== `input-${radioKey}-${id}`
              ),
            };
          } else {
            toast.warning(
              "Warning: This option is already included in the workflow. Removal is restricted."
            );
          }
        }
        return item;
      })
    );
    setRadioButtons((prevRadioButtons) => {
      const updatedRadioButtons = prevRadioButtons.filter(
        (btn) =>
          !(
            btn.id === id &&
            btn.radioKey === radioKey &&
            btn.uniqueKey === previewKey
          )
      );
      updateAllInputValues(updatedRadioButtons, previewKey);
      return updatedRadioButtons;
    });
    removeSpecificInput(id, previewKey);
  };

  const updateItems = (previewKey, updatedProps) => {
    const updateItemList = (items) =>
      items.map((item) => {
        if (item.uniqueKey === previewKey) {
          return { ...item, ...updatedProps };
        }
        if (item.radioInputValues) {
          return {
            ...item,
            radioInputValues: updateItemList(item.radioInputValues),
          };
        }
        return item;
      });

    setDroppedItems(updateItemList(droppedItems));
    setDragItems(updateItemList(dragItems));
  };

  const handleInputChange = (event, previewKey) => {
    const updatedProps = { fieldName: event.target.value };
    updateItems(previewKey, updatedProps);
  };
  const reportMandatory = (event, previewKey) => {
    const updatedProps = { isReport: event.target.checked };
    updateItems(previewKey, updatedProps);
    setreportField(event.target.checked);
  };

  const pdfMandatory = (event, previewKey) => {
    const updatedProps = { isPdf: event.target.checked };
    updateItems(previewKey, updatedProps);
    setShowInput(event.target.checked);
  };

  const handleToggleChange = (event, preview) => {
    setIsChecked(false);
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      let droppedItem = newDroppedItems.find(
        (item) => item.uniqueKey === preview
      );
      if (!droppedItem) {
        newDroppedItems.forEach((item) => {
          if (item.radioInputValues) {
            item.radioInputValues.forEach((radioInput) => {
              if (radioInput.uniqueKey === preview) {
                droppedItem = radioInput;
              }
            });
          }
        });
      }
      if (droppedItem) {
        droppedItem.isMandatory = event.target.checked;
        if (droppedItem.radioInputValues) {
          droppedItem.radioInputValues.forEach((radioInput) => {
            radioInput.isMandatory = event.target.checked;
          });
        }
      } else {
        console.error("Item not found in droppedItems");
      }
    } else {
      console.error("Item not found in draggedItems");
    }
    const newDroppedItem = dragItems.find((item) => item.uniqueKey === preview);
    newDroppedItem.isMandatory = event.target.checked;
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
  };

  function insertRadioVal(selectedItem, radioId, radioKey) {
    return droppedItems.map((val) =>
      val.uniqueKey === selectedItem.uniqueKey
        ? {
            ...val,
            radioId: radioKey ? `input-${radioKey}` : `input-${radioId}`,
          }
        : val
    );
  }

  function getFieldNameByInputKey(keyNumber, previewKey) {
    const inputKey = `input-${keyNumber}`;
    let foundFieldName = null;
    for (const item of droppedItems) {
      if (item.allInputValues) {
        for (const input of item.allInputValues) {
          if (input.inputKey === inputKey) {
            foundFieldName = item.fieldName;
            let updatedRadioValues = [...radioButtons];
            const index = updatedRadioValues.findIndex(
              (radioInput) =>
                radioInput.id === keyNumber &&
                radioInput.uniqueKey === previewKey
            );
            if (index !== -1) {
              updatedRadioValues[index] = {
                ...updatedRadioValues[index],
                fieldName: foundFieldName,
              };
            } else {
              updatedRadioValues.push({
                id: keyNumber,
                fieldName: foundFieldName,
                uniqueKey: previewKey,
              });
            }
            setRadioButtons(updatedRadioValues);
            break;
          }
        }
      }
      if (foundFieldName) break;
    }
    return foundFieldName;
  }

  const handleRadioBtnBelongs = (
    radioUniqueKey,
    radioId,
    value,
    preview,
    radioKey
  ) => {
    const fieldName = getFieldNameByInputKey(radioId, radioUniqueKey);
    const selectedItem = droppedItems.find(
      (dropItem) => dropItem.uniqueKey === preview.uniqueKey
    );
    const filteredDroppedItems = droppedItems.filter(
      (dropItem) => dropItem.uniqueKey !== preview.uniqueKey
    );
    if (filteredDroppedItems.length === 0) {
    } else {
      const updateRadioVal = insertRadioVal(selectedItem, radioId, radioKey);
      setDroppedItems(updateRadioVal);
    }
  };
  const handleRemoveInput = () => {
    setInputValues(inputValues.slice(0, -1));
  };
  const shouldRenderSelectOption = radioButtons.some(
    (input) => input.uniqueKey !== preview.uniqueKey && input.value !== ""
  );
  const shouldRenderSelectOptionEdit = radioButtons.some(
    (input) => input.uniqueKey === preview.uniqueKey && input.value !== ""
  );
  const handleRemoveElement = (uniqueKey, backendkey) => {
    const collectNestedKeys = (item) => {
      let keys = [];
      if (item.radioInputValues) {
        for (const nestedItem of item.radioInputValues) {
          keys.push(nestedItem.uniqueKey);
          keys = keys.concat(collectNestedKeys(nestedItem));
        }
      }
      return keys;
    };

    const removeItemRecursive = (items, uniqueKey, collectedKeys = []) => {
      return items.reduce((acc, item) => {
        if (item.uniqueKey === uniqueKey) {
          collectedKeys.push(...collectNestedKeys(item));
          return acc;
        }
        if (item.radioInputValues) {
          item.radioInputValues = removeItemRecursive(
            item.radioInputValues,
            uniqueKey,
            collectedKeys
          );
        }
        acc.push(item);
        return acc;
      }, []);
    };

    // Remove from droppedItems and update its state
    let collectedKeys = [];
    let updatedDroppedItems = removeItemRecursive(
      droppedItems,
      uniqueKey,
      collectedKeys
    );

    updatedDroppedItems = updatedDroppedItems.map((item, index) => ({
      ...item,
      queNo: index + 1,
    }));

    setDroppedItems(updatedDroppedItems);

    // Apply the same logic to dragItems
    let updatedDragItems = removeItemRecursive(
      dragItems,
      uniqueKey,
      collectedKeys
    );

    updatedDragItems = updatedDragItems.map((item, index) => ({
      ...item,
      queNo: index + 1,
    }));

    setDragItems(updatedDragItems);

    // Filter radioButtons that are not part of the collected keys
    const updatedRadioButtons = radioButtons.filter(
      (item) =>
        !collectedKeys.includes(item.uniqueKey) &&
        !uniqueKey.includes(item.uniqueKey)
    );

    setRadioButtons(updatedRadioButtons);

    // Add the backendkey to fieldIdsToBeDeleted for later use
    setfieldIdsToBeDeleted((prevFieldIds) => [...prevFieldIds, backendkey]);

    // Update preview if the removed item was currently being previewed
    const updatedPreview = updatedDroppedItems.find(
      (item) => item.uniqueKey === uniqueKey
    );
    setPreview(updatedPreview || {});

    toast.warn("Field Removed...!");
  };

  const selectedId = selectedRadioOption
    ? selectedRadioOption.split("-")[1]
    : "";

  const selectedRadioButton = radioButtons.find((button) => {
    const keyToCompare = button.radioKey ? button.radioKey : button.id;
    return String(keyToCompare) === String(selectedId);
  });

  const displaySelectedValue = selectedRadioButton
    ? selectedRadioButton.value
    : "";

  const question = selectedRadioButton ? selectedRadioButton.fieldName : "N/A";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowPreviewData, setWorkflowPreviewData] = useState([]);
  const handlePreviewClick = () => {
    setIsModalOpen(true);
    setWorkflowPreviewData(droppedItems);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const navigateToWorkflow = () => {
    navigate("/workflow");
  };

  const handleCloneElement = (cloneName, fieldName) => {
    const elementToClone = elements.find(
      (element) => element.cloneName === cloneName
    );
    const elementWithInputValues = dragItems.find(
      (element) =>
        element.uniqueKey === preview.uniqueKey && element.allInputValues
    );
    if (elementToClone) {
      const newUniqueKey = `${new Date().getTime()}`;
      const { icon, ...elementWithoutIcon } = elementToClone;
      const newElement = {
        ...elementWithoutIcon,
        uniqueKey: newUniqueKey,
        page,
        fieldName: fieldName,
      };
      if (elementWithInputValues) {
        let localNextId = nextId;
        const updatedInputValues = elementWithInputValues.allInputValues.map(
          (inputValue) => {
            const newInputKey = `input-${localNextId}`;
            const newRadioButton = {
              id: localNextId,
              value: inputValue.inputValue,
              uniqueKey: newUniqueKey,
              isShow: true,
              radioPage: page,
            };
            setRadioButtons((prevButtons) => [...prevButtons, newRadioButton]);
            const updatedInputValue = {
              ...inputValue,
              inputKey: newInputKey,
            };
            localNextId += 1;
            return updatedInputValue;
          }
        );
        newElement.allInputValues = updatedInputValues;
        setNextId(localNextId);
      }
      setDroppedItems((prevItems) => [...prevItems, newElement]);
      setDragItems((prevItems) => [...prevItems, newElement]);
      toast.warn("Field Cloned ..!");
    }
  };
  const groupedRadioButtons = radioButtons.reduce((acc, input) => {
    if (input.isShow === true && input.value !== "") {
      if (!acc[input.uniqueKey]) {
        acc[input.uniqueKey] = [];
      }
      acc[input.uniqueKey].push(input);
    }
    return acc;
  }, {});
  const getHeadline = (uniqueKey) => {
    const item = dragItems.find((item) => item.uniqueKey === uniqueKey);
    return item ? item.fieldName : "";
  };

  const removeIds = (items) => {
    return items.map((item) => {
      const { id, workflowId, ...rest } = item;
      const allInputValues = rest.allInputValues?.map((input) => {
        const { id, workflowFormFieldMetadataId, ...inputRest } = input;
        return inputRest;
      });

      return { ...rest, allInputValues };
    });
  };

  const workflowClone = async (isClone = true) => {
    setLoading(true);
    try {
      const removalIds = removeIds(droppedItems);
      const message = await cloneWorkflow(
        workflowName,
        selectedTimer,
        removalIds,
        selectedOrgId,
        title
      );
      if (message.header.code === 600) {
        toast.success("Cloned Successfully");
        navigate("/workflow");
        setLoading(false);
      } else {
        toast.error(message.body.value);
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleCloneClick = () => {
    if (!workflowName.trim()) {
      toast.error("Please enter a name for the cloned workflow.");
      return;
    }
    workflowClone(true);
  };

  const handleExitModal = () => {
    if (
      (!isEdit && !isClone && dragItems.length !== 0) ||
      droppedItems.length !== 0
    ) {
      setExitModal(true);
    } else if (isEdit || isClone) {
      setExitModal(true);
    } else {
      navigateToWorkflow();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (dragItems.length > 0 || droppedItems.length > 0) {
        e.preventDefault();
        setExitModal(true);
        e.returnValue = "";
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dragItems.length, droppedItems.length]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (e) => {
      if (dragItems.length > 0 || droppedItems.length > 0) {
        e.preventDefault();
        setExitModal(true);
        e.returnValue = "";
        e.returnValue = "";
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [dragItems.length, droppedItems.length]);

  useEffect(() => {
    if (preview) {
      setIsChecked(false);
      setSelectedRadioId(null);
    }
  }, [preview]);

  useEffect(() => {
    console.log(droppedItems, "aaaa", dragItems);
  }, [droppedItems, dragItems]);

  return (
    <>
      <Joyride
        steps={workflowmaker}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
          },
          tooltip: {
            borderRadius: "10px",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
        }}
      />

      <div className="w-full">
        <div className="flex gap-5 mt-5">
          <div
            onClick={handleExitModal}
            className={`flex w-8 p-2 rounded-full border border-gray-400 hover:bg-gray-200 cursor-pointer ${
              theme === "dark"
                ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
                : ""
            } 
                  ${
                    theme === "high-contrast"
                      ? "high-contrast border border-gray-600"
                      : ""
                  } 
                  ${
                    theme !== "dark" && theme !== "high-contrast"
                      ? "border-gray-400 hover:bg-gray-200"
                      : ""
                  }`}
          >
            <FaArrowLeft
              className={`h-3 w-3 ${
                theme === "dark"
                  ? "text-white"
                  : theme === "high-contrast"
                  ? "text-black"
                  : "text-gray-700"
              }`}
            />
          </div>
          <h4
            className={`text-sm font-semibold mt-1 text-gray-800 ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            {Translations[langMode].workflow}
          </h4>
        </div>
        <div
          className={`min-h-screen bg-white flex flex-col p-3 rounded-md ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          <CloneOption
            isClone={isClone}
            workflowId={workflowId}
            isEdit={isEdit}
            langMode={langMode}
            theme={theme}
            workflowName={workflowName}
            title={title}
            handleChangee={handleChangee}
            titleHandle={titleHandle}
            handleTimer={handleTimer}
            selectedTimer={selectedTimer}
            timerValue={timerValue}
            Translations={Translations}
            setTaskExcelColumns={setTaskExcelColumns}
            taskExcelColumns={taskExcelColumns}
          />
          <DndProvider backend={HTML5Backend}>
            <div className="flex-grow flex justify-between gap-4">
              <div
                className={` sm:w-1/4  md:w-1/4 lg:w-1/6  bg-white py-1 px-4 rounded-md border border-gray-300 shadow-2xl  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                {mainTab === 1 && (
                  <React.Fragment>
                    <div className="py-2 flex flex-wrap justify-around rounded-sm fields">
                      <button
                        onClick={() => setOpenTab(1)}
                        className={`w-full md:w-auto text-xs py-1 px-2 mb-2 md:mb-0 rounded-sm text-gray-400 font-semibold transition-all duration-300 ${
                          openTab === 1
                            ? "border-b-4 border-blue-900 text-gray-900"
                            : ""
                        } ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      >
                        {Translations[langMode].element}
                      </button>
                      <button
                        onClick={() => setOpenTab(2)}
                        className={`w-full text-xs md:w-auto py-1 px-2 rounded-sm text-gray-400 font-semibold transition-all duration-300 ${
                          openTab === 2
                            ? "border-b-4 border-blue-900 text-gray-900"
                            : ""
                        } ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      >
                        {Translations[langMode].parameter}
                      </button>
                    </div>
                    <div className={`${openTab === 1 ? "block" : "hidden"}`}>
                      {elements.map((element) => (
                        <DraggableElement
                          key={element.id}
                          id={element.id}
                          value={element.value}
                          fieldName={element.fieldName}
                          isNewFieldDragged={isNewFieldDragged}
                          setIsNewFieldDragged={setIsNewFieldDragged}
                          icon={element.icon}
                          fieldType={element.fieldType}
                          isMandatory={element.isMandatory}
                          isReport={element.isReport}
                          cloneName={element.cloneName}
                          validationValues={validationFormValues}
                          setSelectedRadioId={setSelectedRadioId}
                          setCollapse={setCollapse}
                        />
                      ))}
                    </div>
                    <div className={`${openTab === 2 ? "block" : "hidden"}`}>
                      {parameters.map((parameter) => (
                        <DraggableElement
                          key={parameter.id}
                          id={parameter.id}
                          value={parameter.value}
                          fieldName={parameter.fieldName}
                          fieldType={parameter.fieldType}
                          isNewFieldDragged={isNewFieldDragged}
                          setIsNewFieldDragged={setIsNewFieldDragged}
                        />
                      ))}
                    </div>
                  </React.Fragment>
                )}
              </div>
              <DroppableArea
                setPdfInput={setPdfInput}
                setDroppedItems={setDroppedItems}
                droppedItems={droppedItems}
                mainTab={mainTab}
                setMainTab={setMainTab}
                savedItems={savedItems}
                setPreview={setPreview}
                preview={preview}
                setDragItems={setDragItems}
                dragItems={dragItems}
                handleRemoveInput={handleRemoveInput}
                setreportInput={setreportInput}
                setreportField={setreportField}
                setShowInput={setShowInput}
                setShowRadioOptions={setShowRadioOptions}
                setSelectedRadioOption={setSelectedRadioOption}
                setRadioButtons={setRadioButtons}
                radioButtons={radioButtons}
                page={page}
                triggerVersion={triggerVersion}
                handleVersionClick={handleVersionClick}
                isNewFieldDragged={isNewFieldDragged}
                setIsNewFieldDragged={setIsNewFieldDragged}
                setSelectedRadioId={setSelectedRadioId}
                setCollapse={setCollapse}
                setCollapsedGroups={setCollapsedGroups}
              />
              <div
                className={`w-1/3 bg-white p-6 rounded-md border border-gray-300 shadow-2xl h-[1300px] overflow-y-auto ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <h6 className="text-sm font-semibold">
                  {Translations[langMode].preview}
                </h6>
                <div className="">
                  <DynamicPage
                    page={page}
                    setPage={setPage}
                    dragItems={dragItems}
                  />
                </div>

                {showVersions && (
                  <WorkflowVersions
                    workflowId={workflowId}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                    loading={loading}
                    setLoading={setLoading}
                    pages={pages}
                    setPages={setPages}
                    handleVersionClick={handleVersionClick}
                    workflowHistories={workflowVersionHistories}
                  />
                )}
                {preview.page === page && (
                  <FieldDetails
                    preview={preview}
                    theme={theme}
                    isEdit={isEdit}
                    isClone={isClone}
                    langMode={langMode}
                    handleCloneElement={handleCloneElement}
                    handleRemoveElement={handleRemoveElement}
                    handleInputChange={handleInputChange}
                    handleToggleChange={handleToggleChange}
                    pdfMandatory={pdfMandatory}
                    handlePdfInput={handlePdfInput}
                    addPdffield={addPdffield}
                    pdfInput={pdfInput}
                    showInput={showInput}
                    reportMandatory={reportMandatory}
                    reportField={reportField}
                    reportInput={reportInput}
                    handleReportInput={handleReportInput}
                    addReportfield={addReportfield}
                    Translations={Translations}
                    shouldRenderSelectOption={shouldRenderSelectOption}
                    groupedRadioButtons={groupedRadioButtons}
                    collapse={collapse}
                    collapsedGroups={collapsedGroups}
                    toggleCollapse={toggleCollapse}
                    selectedRadioId={selectedRadioId}
                    handleRadioBtnBelongs={handleRadioBtnBelongs}
                    setSelectedRadioId={setSelectedRadioId}
                    shouldRenderSelectOptionEdit={shouldRenderSelectOptionEdit}
                    isEditShow={isEditShow}
                    setIsEditShow={setIsEditShow}
                    radioButtons={radioButtons}
                    tempEditValues={tempEditValues}
                    setTempEditValues={setTempEditValues}
                    handleRadioOptions={handleRadioOptions}
                    handleRadioDeleteOptions={handleRadioDeleteOptions}
                    isNewFieldDragged={isNewFieldDragged}
                    displaySelectedValue={displaySelectedValue}
                    question={question}
                    inputValues={inputValues}
                    tempInputValue={tempInputValue}
                    setTempInputValue={setTempInputValue}
                    handleAddInput={handleAddInput}
                    handleRemoveInput={handleRemoveInput}
                    toast={toast}
                    setCurrentValidation={setCurrentValidation}
                    setValidationModal={setValidationModal}
                    setValidationId={setValidationId}
                    validationId={validationId}
                    validationFormValues={validationFormValues}
                    setIsChecked={setIsChecked}
                    getHeadline={getHeadline}
                    setPdfIcon={setPdfIcon}
                    pdficon={pdficon}
                    setReportIcon={setReportIcon}
                    reporticon={reporticon}
                  />
                )}
              </div>
            </div>
          </DndProvider>
          {validationModal && (
            <ValidationModal
              onCloseValidation={onCloseValidation}
              handleCreateValidation={handleCreateValidation}
              setCurrentValidation={setCurrentValidation}
              currentValidation={currentValidation}
              validationFormValues={validationFormValues}
              previewKey={preview.uniqueKey}
              handleValidationDelete={handleValidationDelete}
            />
          )}
          <div className="w-100 px-5 py-2 flex gap-4">
            {isClone ? (
              <button
                type="button"
                onClick={handleCloneClick}
                className="px-2 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
              >
                {Translations[langMode].clone}
              </button>
            ) : workflowFields && workflowFields.length > 0 ? (
              <button
                type="button"
                className="px-2 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                onClick={workflowUpdate}
              >
                {Translations[langMode].update}
              </button>
            ) : (
              <button
                type="button"
                className="px-2 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                onClick={workflowSubmit}
              >
                {Translations[langMode].Submit}
              </button>
            )}
            <button
              type="button"
              className="px-2 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
              onClick={handlePreviewClick}
            >
              {Translations[langMode].preview}
            </button>
            {isModalOpen && workflowPreviewData && (
              <Modal
                onClose={handleCloseModal}
                previewData={workflowPreviewData}
                page={page}
              />
            )}
          </div>
        </div>

        {exitModal && (
          <ExitModal
            setExitModal={setExitModal}
            exitModal={exitModal}
            navigateToWorkflow={navigateToWorkflow}
          />
        )}
      </div>
      {isloading && <Spinner />}
    </>
  );
};

export default DynamicWorkFlow;
