import React, { useContext, useState } from "react";
import ConfigurationSetting from "../../components/profile-comp/notification/ConfigurationSetting";
import { GeneralSetting } from "../../components/profile-comp/notification/GeneralSetting";
import AppContext from "../../context/AppContext";
import translations from "../../components/common/Translations";

const Settings = () => {
  const [openTab, setOpenTab] = useState(1);
  const { theme, langMode } = useContext(AppContext);
  return (
    <div className="w-full ">
      <h4
        className={`my-3 ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }  uppercase text-sm font-semibold text-gray-800`}
      >
        {translations[langMode].setting}
      </h4>
      <div
        className={`px-5 p-4 mt-5 bg-white overflow-y-auto h-[550px] ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="max-w-3xl">
          <div className="mb-4 flex rounded-sm">
            <button
              onClick={() => setOpenTab(1)}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-l-lg focus:ring-2 focus:ring-blue-700 transition-all ${
                openTab === 1
                  ? "bg-blue-800 text-white hover:bg-blue-600 backdrop-blur-sm"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}
            >
              {translations[langMode].general}
            </button>
            <button
              onClick={() => setOpenTab(2)}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-r-lg focus:ring-2 focus:ring-blue-700 transition-all ${
                openTab === 2
                  ? "bg-blue-500 text-white hover:bg-blue-800 backdrop-blur-sm"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}
            >
              {translations[langMode].config}
            </button>
          </div>
          <div style={{ display: openTab === 1 ? "block" : "none" }}>
            <GeneralSetting />
          </div>
          <div style={{ display: openTab === 2 ? "block" : "none" }}>
            <ConfigurationSetting />
          </div>
        </div>
      
      </div>
    </div>
  );
};
export default Settings;
