import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import FieldDisplay from './FieldDisplay';
const DropAreaUpdate = () => {
  const { workflowId } = useParams();
  const [workflowFields, setWorkflowFields] = useState([]);

  useEffect(() => {
    async function fetchWorkflowData() {
      const token = Cookies.get("accessToken");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/get-workflow-metadata?workflowId=${workflowId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.header.code === 600) {
          setWorkflowFields(data.body.value.fieldsMetadata);
        } else {
          console.error("Failed to fetch data:", data.body.error);
        }
      } catch (error) {
        console.error("Error fetching workflow data:", error);
      }
    }
    fetchWorkflowData();
  }, [workflowId]);

  return (
    <div className="flex flex-col p-4 bg-white w-full">
    {Array.isArray(workflowFields) && workflowFields.map((field, index) => (
      <FieldDisplay key={index} field={field} />
    ))}
  </div>
  );
};

export default DropAreaUpdate;
