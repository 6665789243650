import axios from "axios";
import Cookies from "js-cookie";
//get report list
const BASE_URL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const fetchReportData = async (currentPage, pageSize, filters,selectedOrgName) => {
    const token = Cookies.get('accessToken');
    const {  ReportType, UserType, Status, fromDate, toDate } = filters;
  
    try {
      const response = await axios.get(
        `${BASE_URL}api/v1/report/report-list`,
        {
          params: {
            page: currentPage,
            size: pageSize,
            organizationName: selectedOrgName,
            userType: UserType ? UserType.toLowerCase():"",
            reportType: ReportType ? ReportType.toUpperCase() : "",
            status: Status,
            fromDate: fromDate,
            toDate: toDate
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data?.body?.value?.reportList) {
        return {
          reportList: response.data.body.value.reportList,
          totalCount: response.data.body.value.totalCount,
        };
      } else {
        return { reportList: [], totalCount: 0 };
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return { reportList: [], totalCount: 0 };
    }
  };

  //fetch orgnization
  export const fetchOrganizations = async () => {
    const token = Cookies.get('accessToken');
    try {
      const response = await axios.get(`${BASE_URL}api/v1/organization/get-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response || !response.data || !response.data.header || response.data.header.code !== 600) {
        throw new Error('Failed to fetch organizations');
      }
      return response.data.body.value;
    } catch (error) {
      console.error('Error fetching organizations:', error);
      throw error;
    }
  };
  // get user name by userType and ornizationid
  export const getUserDetails = async (selectedOrgId, UserType) => {
    const token = Cookies.get('accessToken');
    try {
      const response = await axios.get(`${BASE_URL}api/v1/user/get-office-users`, {
        params: {
          organizationId: selectedOrgId,
          userType: UserType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response || !response.data || !response.data.header || response.data.header.code !== 600) {
        throw new Error('Failed to fetch user details');
      }
  
      return response.data.body.value;
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw error;
    }
  };
  
  //make request for report
  export const requestReport = async ({ selecteduser, UserType, ReportType, startDate, endDate }) => {
    const token = Cookies.get('accessToken');
    try {
      const response = await axios.post(
        `${BASE_URL}api/v1/report/request`,
        {
          userId: selecteduser,
          userType: UserType,
          reportType: ReportType,
          fromDate: startDate,
          toDate: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const responseData = response.data;
      if (responseData.header.code === 600) {
        return responseData.body.value;
      } else {
        throw new Error(responseData.header.message || 'Error requesting report');
      }
    } catch (error) {
      console.error('Error requesting report:', error);
      throw error;
    }
  };