// StatusFilter.js
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import AppContext from "../../context/AppContext";
import FilterIcon from "../../assets/filter-2-fill 1.png";
import { toast } from "react-toastify";

const StatusFilter = ({
  setShowFilter,
  showFilter,
  setMarkers,
  setAssignTask,
  setShowTaskDetails,
}) => {
  const { theme, workflowId } = useContext(AppContext);
  const [taskStatus, setTaskStatus] = useState("Active");
  const [filterCount, setFilterCountState] = useState(null);
  const handleMapFilter = () => {
    if (!workflowId) {
      toast.error("Select workflow first");
      return;
    }
    setShowFilter((prevShowFilter) => !prevShowFilter);
    setAssignTask(false);
    setShowTaskDetails(false);
  };

  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    const token = Cookies.get("accessToken");
    axios
      .get(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/task/map/get-tasks?workflowId=${workflowId}&taskStatus=${taskStatus}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const tasks = response.data.body.value.taskList;
        console.log(tasks, "tasks");
        const extractedDetails = tasks.map((task) => ({
          latitude: parseFloat(task.latitude),
          longitude: parseFloat(task.longitude),
          taskId: task.taskId,
          accountNo: task.accountNo,
          taskStatus: task.taskStatus,
          icon: task.icon,
          color: task.color,
        }));
        setFilterCountState(response.data.body.value);
        setMarkers(extractedDetails);
        setShowFilter(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if (workflowId) {
      if (workflowId) {
        handleFilterSubmit(new Event("submit"));
      }
    }
  }, [workflowId]);
  const getCount = (key) => {
    if (!filterCount) return 0;
    const {
      activeCount,
      assignedCount,
      inProgressCount,
      onHoldCount,
      completedCount,
    } = filterCount;
    switch (key) {
      case "Active":
        return activeCount;
      case "assigned":
        return assignedCount;
      case "inProgress":
        return inProgressCount;
      case "onHold":
        return onHoldCount;
      case "completed":
        return completedCount;
      case "totalCount":
        let total =
          activeCount + assignedCount + inProgressCount + completedCount;
        return total;
      default:
        return 0;
    }
  };

  const iconStyle = {
    position: "absolute",
    top: "18px",
    left: "65px",
    padding: "6px",
    borderRadius: "50%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 9999,
  };

  const boxStyle = {
    position: "absolute",
    top: "18px",
    left: "120px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  };

  const FilterScreen = {
    position: "absolute",
    top: "76px",
    left: "120px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 9999,
  };

  return (
    <div>
      <div
        style={iconStyle}
        className={`z-10 bg-white cursor-pointer ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
        onClick={handleMapFilter}
      >
        <img src={FilterIcon} alt="filter icon" className="h-6 w-6" />
      </div>
      {showFilter && (
        <div
          style={boxStyle}
          className={`${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          } flex flex-col bg-white p-2 text-sm rounded-md`}
        >
          <span
            className={`text-normal font-semibold text-gray-800 ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
  {new Date().toLocaleDateString("en-GB")}
  </span>
          <span className="flex">
            <p className="text-red-500 mr-1  text-xs">
              {getCount("totalCount")}
            </p>
            /
            <p className="text-blue-600 ml-1 text-xs">
              {taskStatus === "Active"
                ? getCount("Active")
                : taskStatus === "Assigned"
                ? getCount("assigned")
                : taskStatus === "On_Hold"
                ? getCount("onHold")
                : taskStatus === "In_Progress"
                ? getCount("inProgress")
                : taskStatus === "Completed"
                ? getCount("completed")
                : taskStatus === ""
                ? getCount("totalCount")
                : null}
            </p>
          </span>
        </div>
      )}
      {showFilter && (
        <div
          className={`bg-white absolute cursor-pointer rounded-md shadow-2xl ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
          style={FilterScreen}
        >
          <div className="flex flex-col px-5">
            <div className="relative flex gap-2 text-sm py-2">
              <span className="flex flex-col">
                <p className="font-semibold text-xs ">Task</p>
                {/* <p className="font-normal text-xs">Markers 0 / undefined</p> */}
              </span>
            </div>
            <form onSubmit={handleFilterSubmit} className="space-y-2">
              <div className="relative flex gap-2 text-sm">
                <input
                  type="radio"
                  name="taskStatus"
                  value=""
                  checked={taskStatus === ""}
                  onChange={() => setTaskStatus("")}
                  className=" rounded-md h-3 w-3"
                />
                <span className="font-normal text-xs">All</span>
              </div>
              <div className="relative flex gap-2 text-sm">
                <input
                  type="radio"
                  name="taskStatus"
                  value="Active"
                  checked={taskStatus === "Active"}
                  onChange={() => setTaskStatus("Active")}
                  className="rounded-md h-3 w-3"
                />
                <span className="font-normal text-xs">Active</span>
              </div>
              <div className="relative flex gap-2 text-sm ">
                <input
                  type="radio"
                  name="taskStatus"
                  value="Assigned"
                  checked={taskStatus === "Assigned"}
                  onChange={() => setTaskStatus("Assigned")}
                  className="mt-1 rounded-md h-3 w-3"
                />
                <span className="font-normal text-xs">Assigned</span>
              </div>
              <div className="relative flex gap-2 text-sm ">
                <input
                  type="radio"
                  name="taskStatus"
                  value="On_Hold"
                  checked={taskStatus === "On_Hold"}
                  onChange={() => setTaskStatus("On_Hold")}
                  className="rounded-md h-3 w-3"
                />
                <span className="font-normal text-xs">On Hold</span>
              </div>
              <div className="relative flex gap-2 text-sm">
                <input
                  type="radio"
                  name="taskStatus"
                  value="In_Progress"
                  checked={taskStatus === "In_Progress"}
                  onChange={() => setTaskStatus("In_Progress")}
                  className="rounded-md h-3 w-3"
                />
                <span className="font-normal text-xs">In Progress</span>
              </div>
              <div className="relative flex gap-2 text-sm">
                <input
                  type="radio"
                  name="taskStatus"
                  value="Completed"
                  checked={taskStatus === "Completed"}
                  onChange={() => setTaskStatus("Completed")}
                  className="rounded-md h-3 w-3"
                />
                <span className="font-normal text-xs">Completed</span>
              </div>
              <div className="flex  py-1">
                <button
                  type="submit"
                  className="px-2 py-1 text-xs bg-blue-800 hover:bg-blue-700 rounded-md text-white"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default StatusFilter;
