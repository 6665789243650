import React, { useContext, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { MdOutlineCopyAll } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import "react-resizable/css/styles.css";
import { TbVersionsFilled } from "react-icons/tb";
import AppContext from "../../context/AppContext";
import Joyride from "react-joyride";

const ItemTypes = {
  ELEMENT: "element",
};

const DroppableArea = ({
  setDroppedItems,
  droppedItems,
  mainTab,
  setMainTab,
  allInputValues,
  setPreview,
  preview,
  setDragItems,
  dragItems,
  handleRemoveInput,
  setreportInput,
  setreportField,
  setShowRadioOptions,
  setSelectedRadioOption,
  setRadioButtons,
  radioButtons,
  page,
  handleVersionClick,
  setCollapse,
  setShowInput,
  isNewFieldDragged,
  setIsNewFieldDragged,
  setSelectedRadioId,
  setpdfInput,
  setCollapsedGroups,
  initialCollapseState,
  setPdfInput,
  triggerVersion
}) => {
  const [itemSelected, setItemsSelected] = useState();
  const { theme, runTour, workflowmaker } = useContext(AppContext);
  const [queNo, setQueNo] = useState(1);
  const pageRef = useRef(page);
  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.ELEMENT,
      drop: (item, monitor) => {
        const newUniqueKey = `${new Date().getTime()}`;
  
        let newQueNo = 1; 
        let newItem = {}; 
  
        setDroppedItems((prevFields) => {
          if (prevFields.length > 0) {
            const highestQueNo = Math.max(
              ...prevFields.map((field) => field.queNo)
            );
            newQueNo = highestQueNo + 1;
          }
  
          newItem = {
            ...item,
            uniqueKey: newUniqueKey,
            page: page,
            queNo: newQueNo,
          };
  
          const updatedFields = [...prevFields, newItem].sort((a, b) => {
            if (a.page === b.page) {
              return a.queNo - b.queNo;
            }
            return a.page - b.page;
          });
  
          return updatedFields.map((field, index) => ({
            ...field,
            queNo: index + 1,
          }));
        });
  
        setDragItems((prevFields) => {
          const highestQueNo = prevFields.length > 0 
            ? Math.max(...prevFields.map((field) => field.queNo)) + 1
            : 1;
  
          newItem = {
            ...item,
            uniqueKey: newUniqueKey,
            page: page,
            queNo: highestQueNo,
          };
  
          const updatedDragFields = [...prevFields, newItem].sort((a, b) => {
            if (a.page === b.page) {
              return a.queNo - b.queNo;
            }
            return a.page - b.page;
          });
  
          return updatedDragFields.map((field, index) => ({
            ...field,
            queNo: index + 1,
          }));
        });
  
        setPreview(newItem);
        setItemsSelected(newUniqueKey);
  
        if (!newItem.excelReportName) {
          console.error("Report name is missing for the newly dropped field");
          setPdfInput("");
        }
        if (!newItem.reportName) {
          console.error("PDF name is missing for the newly dropped field");
          setreportInput("");
        }
  
        handleRemoveInput();
        setIsNewFieldDragged(false);
        setSelectedRadioId(null);
        setCollapse(false);
        setCollapsedGroups([]);
        setreportField(false);
        setShowInput(false);
      },
    }),
    [page, queNo]
  );
  
 
  const calculateHeight = (item) => {
    if (
      (item.fieldType === "radio" || item.fieldType === "checkbox") &&
      item.allInputValues
    ) {
      return item.allInputValues.length + 1;
    }
    return 3;
  };

  const containerWidth = window.innerWidth * 0.45;
  const cols = Math.floor(containerWidth / 50);
  const layout = dragItems.map((item, index) => ({
    i: item.uniqueKey,
    x: 0,
    y: 0,
    w: cols,
    h: calculateHeight(item),
    autoSize: true,
    isDraggable: true,
    isResizable: false,
    static: false,
  }));

  function radioIdSelected(items, targetUniqueKey) {
    let selectedRadioId = null;
    items.forEach((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        selectedRadioId = item.radioId;
      } else if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = radioIdSelected(
          item.radioInputValues,
          targetUniqueKey
        );
        if (updatedRadioInputValues) {
          selectedRadioId = updatedRadioInputValues;
        }
      }
    });
    return selectedRadioId;
  }
  const checkIsChildContains = (radioInputValues, uniqueKey) => {
    if (radioInputValues && Array.isArray(radioInputValues)) {
      for (const item of radioInputValues) {
        if (item.uniqueKey === uniqueKey) {
          return true;
        }
        if (item.radioInputValues && Array.isArray(item.radioInputValues)) {
          if (checkIsChildContains(item.radioInputValues, uniqueKey)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  function isOrphan(itemUniqueKey) {
    const item = droppedItems.find(
      ({ uniqueKey }) => uniqueKey === itemUniqueKey
    );
    if (item) {
      if (
        item.radioInputValues === undefined ||
        (Array.isArray(item.radioInputValues) &&
          item.radioInputValues.length === 0)
      ) {
        return true;
      }
    } else {
      return false;
    }
  }

  function findAllParentKeys(items, childKey, path = []) {
    for (const item of items) {
      if (item.uniqueKey === childKey) {
        return path;
      } else if (item.radioInputValues) {
        const result = findAllParentKeys(
          item.radioInputValues,
          childKey,
          path.concat(item.uniqueKey)
        );
        if (result) return result;
      }
    }
    return null;
  }

  function updateRadioButtonsVisibility(preview) {
    const parentKeys = findAllParentKeys(droppedItems, preview);
    const updatedRadioButtons = radioButtons.map((button) => {
      const isParent = parentKeys && parentKeys.includes(button.uniqueKey);
      if (
        isParent ||
        button.uniqueKey === preview ||
        isOrphan(button.uniqueKey)
      ) {
        return { ...button, isShow: true };
      } else {
        return { ...button, isShow: false };
      }
    });
    setRadioButtons(updatedRadioButtons);
    setShowRadioOptions(true);
  }

  const handleItemClick = (previewKey) => {
    setreportInput("");
    setPdfInput("");
    const previewfield = dragItems.find(
      (item) => item.uniqueKey === previewKey,
      setIsNewFieldDragged(true),
      setCollapse(false)
    );
    setPreview(previewfield);
    if (!previewfield.excelReportName) {
      console.error("Report name is missing for the selected field");
    }
    if (!previewfield.reportName) {
      console.error("PDF name is missing for the selected field");
    }
    setreportInput(previewfield.reportName);
    if (!previewfield.reportName && !previewfield.excelReportName) {
      setreportField(false);
      setShowInput(false);
    } else if (previewfield.reportName && !previewfield.excelReportName) {
      setreportField(true);
      setShowInput(false);
    } else if (!previewfield.reportName && previewfield.excelReportName) {
      setreportField(false);
      setShowInput(true);
    } else {
      setreportField(true);
      setShowInput(true);
    }
    setItemsSelected(previewKey);
    const selectedRadioId = radioIdSelected(droppedItems, previewKey);
    setSelectedRadioOption(selectedRadioId);

    let droppedValues = [...droppedItems];
    const index = droppedValues.findIndex(
      (input) => input.uniqueKey === previewKey
    );
    if (index !== -1) {
      const updatedRadioButtons = radioButtons.map((button) => {
        const isShow = !checkIsChildContains(
          droppedValues[index].radioInputValues,
          button.uniqueKey
        );
        return { ...button, isShow };
      });
      const updatedRadioButtons2 = droppedItems.map((button) => {
        const isShow = !checkIsChildContains(
          droppedValues[index].radioInputValues,
          button.uniqueKey
        );
        return { ...button, isShow };
      });
      setRadioButtons(updatedRadioButtons);
      setShowRadioOptions(true);
    } else {
      if (!preview.allInputValues) {
        const updatedRadioButtons = radioButtons.map((button) => {
          const isShow = true;
          return { ...button, isShow };
        });
        setRadioButtons(updatedRadioButtons);
      } else {
        updateRadioButtonsVisibility(previewKey);
      }
    }
  };
  const radioValues = dragItems.find(
    (item) => item.fieldType === "radio"
  )?.allInputValues;

  const handleDragStop = (layout) => {
    const sortedLayout = [...layout].sort((a, b) => a.y - b.y);
  
    setDroppedItems((prevItems) => {
      // Group items by page to ensure they remain on the correct page
      const groupedByPage = prevItems.reduce((acc, item) => {
        if (!acc[item.page]) acc[item.page] = [];
        acc[item.page].push(item);
        return acc;
      }, {});
  
      // Process only the current page's items for reordering
      const currentPageItems = groupedByPage[page] || [];
      const reorderedCurrentPageItems = sortedLayout.map((layoutItem) => {
        return currentPageItems.find((item) => item.uniqueKey === layoutItem.i);
      });
  
      // Merge the reordered current page items with other pages' items
      groupedByPage[page] = reorderedCurrentPageItems;
      
      // Flatten the grouped items back into an array
      const reorderedAllItems = Object.values(groupedByPage).flat().map((item, index) => ({
        ...item,
        queNo: index + 1, // Maintain sequential numbering across pages
      }));
  
      console.log("Reordered all items with queNo:", reorderedAllItems);
      return reorderedAllItems;
    });
  
    setDragItems((prevDragItems) => {
      const groupedDragItemsByPage = prevDragItems.reduce((acc, item) => {
        if (!acc[item.page]) acc[item.page] = [];
        acc[item.page].push(item);
        return acc;
      }, {});
  
      const currentPageDragItems = groupedDragItemsByPage[page] || [];
      const reorderedCurrentPageDragItems = sortedLayout.map((layoutItem) => {
        return currentPageDragItems.find((item) => item.uniqueKey === layoutItem.i);
      });
  
      groupedDragItemsByPage[page] = reorderedCurrentPageDragItems;
  
      const reorderedAllDragItems = Object.values(groupedDragItemsByPage).flat().map((item, index) => ({
        ...item,
        queNo: index + 1,
      }));
  
      return reorderedAllDragItems;
    });
  };
  

  return (
    <>
      <Joyride
        steps={workflowmaker}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
            zIndex: 1000,
          },
          tooltip: {
            borderRadius: "10px",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
        }}
      />
      <div
        ref={drop}
        className={`droparea rounded-md border border-gray-300 shadow-lg bg-white  overflow-y-auto  ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
        style={{ width: "50%", height: "1300px" }}
      >
        <div
          className="flex w-full justify-end items-end"
          onClick={triggerVersion}
        >
          <div className="p-1 relative group">
            <label
              className={`bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <BsThreeDots />
            </label>
            <div
              className={`absolute z-50 top-full right-full border border-blue-600  w-auto p-3 font-semibold bg-white text-blue-500 text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center space-x-2  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <TbVersionsFilled className="text-md text-blue-600" />
              <span>Versions</span>
            </div>
          </div>
        </div>
        <GridLayout
          className="layout w-full"
          layout={layout}
          cols={cols}
          rowHeight={20}
          width={containerWidth}
          isResizable={false}
          autoSize
          compactType="vertical" // Try this instead of null
          // preventCollision={true}
          onDragStop={handleDragStop}
        >
          {dragItems.map((item, idx) => {
            if (item.page !== page) {
              return null;
            }
            return (
              <div
                key={item.uniqueKey}
                data-grid={layout.find((l) => l.i === item.uniqueKey)}
                className={`border-2  max-w-xs  md:max-w-xs lg:max-w-md xl:max-w-xl rounded-md   ${
                  itemSelected === item.uniqueKey
                    ? "border-green-400 "
                    : item.excelReportName
                    ? "border-blue-400 "
                    : "border-red-400"
                }  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }transition-all duration-300 ease-in-out hover:shadow-2xl hover:scale-110`}
                onClick={() => handleItemClick(item.uniqueKey)}
              >
                <div className="mx-3 flex items-center">
                  <div className="w-full flex">
                    <div className="mr-2 text-xs font-bold text-gray-800 dark:text-gray-300">
                      {item.queNo}.
                    </div>
                    {item.fieldType === "text" && (
                      <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-800 dark:text-gray-300 mb-1"
                          htmlFor={item.id}
                        >
                          {item.fieldName}
                        </label>
                        <input
                          id={item.id}
                          className={`${
                            theme === "dark"
                              ? "dark:bg-gray-800 dark:border-gray-600 dark:text-gray-200"
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : "bg-white"
                          } border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-gray-900 text-xs rounded-lg shadow-sm block w-full p-3 transition duration-300 ease-in-out hover:border-blue-400 focus:outline-none`}
                          type="text"
                          placeholder="Enter your input"
                        />
                      </div>
                    )}
                    {item.fieldType === "date" && (
                       <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white mb-1"
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}{" "}
                        </label>
                        <input
                          id={item.id} // Matching the input's id with the label's htmlFor
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="date" /* ... */
                        />
                      </div>
                    )}
                    {item.fieldType === "time" && (
                        <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white mb-1"
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}{" "}
                        </label>
                        <input
                          id={item.id} // Matching the input's id with the label's htmlFor
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="time" /* ... */
                        />
                      </div>
                    )}
                    {item.fieldType === "textarea" && (
                      <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white mb-1"
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="textarea" /* ... */
                        />
                      </div>
                    )}
                    {item.fieldType === "scan" && (
                         <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white mb-1"
                          htmlFor={item.id}
                        >
                          {item.fieldName}
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="textarea" /* ... */
                        />
                      </div>
                    )}
                    {item.fieldType === "number" && (
                        <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white mb-1"
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}{" "}
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number" /* ... */
                        />
                      </div>
                    )}
                    {item.fieldType === "email" && (
                         <div className="w-full">
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white mb-1" 
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}{" "}
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="email"
                        />
                      </div>
                    )}
                    {item.fieldType === "file" && (
                      <div>
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white"
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}{" "}
                        </label>
                        <input type="file" disabled className="text-xs" />
                      </div>
                    )}
                    {item.fieldType === "checkbox" && (
                      <div>
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white"
                          htmlFor={item.uniqueKey} // Assuming each item has a unique uniqueKey
                        >
                          {item.fieldName}{" "}
                        </label>
                        {item.allInputValues &&
                        item.allInputValues.length > 0 ? (
                          item.allInputValues.map((inputItem) => (
                            <div key={inputItem.inputKey}>
                              <input
                                type="checkbox"
                                id={inputItem.inputKey} // Assuming each inputItem has a unique inputKey
                                value={inputItem.inputValue} // Assuming each inputItem has an inputValue
                              />
                              <label
                                htmlFor={inputItem.inputKey}
                                className="ml-2 text-xs"
                              >
                                {inputItem.inputValue}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p className="text-xs">
                            No checkbox options available
                          </p>
                        )}
                      </div>
                    )}
                    {item.fieldType === "radio" && (
                      <div>
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white"
                          htmlFor={item.id} // Assuming each item has a unique id
                        >
                          {item.fieldName}{" "}
                        </label>
                        {item.allInputValues &&
                        item.allInputValues.length > 0 ? (
                          item.allInputValues.map(
                            (input, index) =>
                              input.inputValue !== "" && (
                                <div
                                  key={`${item.uniqueKey}-${index}`}
                                  className="flex items-center space-x-2 text-xs"
                                >
                                  <input
                                    type="radio"
                                    id={`${item.uniqueKey}-input-${index}`}
                                    fieldName={`${item.uniqueKey}-options`}
                                    value={input.inputValue}
                                    className="text-xs my-1"
                                  />
                                  <label
                                    htmlFor={`${item.uniqueKey}-input-${index}`}
                                  >
                                    {input.inputValue}
                                  </label>
                                </div>
                              )
                          )
                        ) : (
                          <p></p>
                        )}
                      </div>
                    )}
                    {item.fieldType === "select" && (
                      <div>
                        <label
                          className="block text-xs font-medium text-gray-700 dark:text-white"
                          htmlFor={item.uniqueKey}
                        >
                          {item.fieldName}
                        </label>
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          id={item.uniqueKey}
                        >
                          {item.allInputValues ? (
                            item.allInputValues.map((inputItem) => (
                              <option
                                key={inputItem.inputKey}
                                value={inputItem.inputValue}
                              >
                                {inputItem.inputValue}
                              </option>
                            ))
                          ) : (
                            <option value="">No options available</option>
                          )}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </GridLayout>
        {dragItems == 0 && (
          <div
            className={`ml-2 mr-8 border-2 border-blue-800 p-8 rounded-md  ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className={` ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }flex items-center justify-center gap-2 p-6 w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 `}
              >
                <MdOutlineCopyAll className="text-2xl" />
                <h6 className="text-xs text-gray-500">
                  Drag your elements to second page from left panel
                </h6>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DroppableArea;
