import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import CommonInputField from "../../components/common/CommonInputField";
import CommonRadio from "../../components/common/CommonRadio";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import {
  fetchOrganizations,
  createUser,
  getUserInfoAPI,
  updateUser,
} from "../../service/User";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaArrowLeft } from "react-icons/fa";
import translations from "../../components/common/Translations";
const AddUser = () => {
  const { userId } = useParams();
  const [organizations, setOrganizations] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialUserInfo, setInitialUserInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const { theme, langMode } = useContext(AppContext);
  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    userType: "",
    email: "",
    phone: "",
    areaCode: "",
    organization: "",
    canAddEditActivityTemplates: undefined,
    canAddEditLeaderboard: undefined,
    canCreateDashboard: undefined,
    canModifyGuidelines: undefined,
    canAddEditUser: undefined,
    canAddLabelsToActivityTemplates: undefined,
    canAccessAssetManagement: undefined,
    // canAddEditInventory: undefined,
    // canAddEditProtectedAccount: undefined,
    // canTaskManage: undefined,
    userVisibility: [],
  });
  const [countryDetail, setCountryDetail] = useState([
    { name: "Afghanistan", length: 9, countryCode: "+93" },
    { name: "Albania", length: 8, countryCode: "+355" },
    { name: "Algeria", length: 9, countryCode: "+213" },
    { name: "Andorra", length: 6, countryCode: "+376" },
    { name: "Angola", length: 9, countryCode: "+244" },
    { name: "Argentina", length: 10, countryCode: "+54" },
    { name: "Armenia", length: 8, countryCode: "+374" },
    { name: "Australia", length: 9, countryCode: "+61" },
    { name: "Austria", length: 12, countryCode: "+43" },
    { name: "Azerbaijan", length: 9, countryCode: "+994" },
    { name: "Bahamas", length: 7, countryCode: "+1 242" },
    { name: "Bahrain", length: 8, countryCode: "+973" },
    { name: "Bangladesh", length: 10, countryCode: "+880" },
    { name: "Barbados", length: 10, countryCode: "+1 246" },
    { name: "Belarus", length: 9, countryCode: "+375" },
    { name: "Belgium", length: 9, countryCode: "+32" },
    { name: "Belize", length: 7, countryCode: "+501" },
    { name: "Benin", length: 8, countryCode: "+229" },
    { name: "Bhutan", length: 8, countryCode: "+975" },
    { name: "Bolivia", length: 8, countryCode: "+591" },
    { name: "Bosnia and Herzegovina", length: 8, countryCode: "+387" },
    { name: "Botswana", length: 7, countryCode: "+267" },
    { name: "Brazil", length: 10, countryCode: "+55" },
    { name: "Brunei", length: 7, countryCode: "+673" },
    { name: "Bulgaria", length: 8, countryCode: "+359" },
    { name: "Burkina Faso", length: 10, countryCode: "+226" },
    { name: "Burundi", length: 8, countryCode: "+257" },
    { name: "Cabo Verde", length: 7, countryCode: "+238" },
    { name: "Cambodia", length: 9, countryCode: "+855" },
    { name: "Cameroon", length: 9, countryCode: "+237" },
    { name: "Canada", length: 10, countryCode: "+1" },
    { name: "Central African Republic", length: 10, countryCode: "+236" },
    { name: "Chad", length: 8, countryCode: "+235" },
    { name: "Chile", length: 9, countryCode: "+56" },
    { name: "China", length: 11, countryCode: "+86" },
    { name: "Colombia", length: 10, countryCode: "+57" },
    { name: "Comoros", length: 7, countryCode: "+269" },
    { name: "Congo (Congo-Brazzaville)", length: 10, countryCode: "+242" },
    { name: "Costa Rica", length: 8, countryCode: "+506" },
    { name: "Croatia", length: 9, countryCode: "+385" },
    { name: "Cuba", length: 8, countryCode: "+53" },
    { name: "Cyprus", length: 8, countryCode: "+357" },
    { name: "Czech Republic", length: 9, countryCode: "+420" },
    { name: "Denmark", length: 8, countryCode: "+45" },
    { name: "Djibouti", length: 7, countryCode: "+253" },
    { name: "Dominica", length: 10, countryCode: "+1 767" },
    { name: "Dominican Republic", length: 10, countryCode: "+1 809" },
    { name: "Ecuador", length: 9, countryCode: "+593" },
    { name: "Egypt", length: 10, countryCode: "+20" },
    { name: "El Salvador", length: 8, countryCode: "+503" },
    { name: "Equatorial Guinea", length: 9, countryCode: "+240" },
    { name: "Eritrea", length: 8, countryCode: "+291" },
    { name: "Estonia", length: 7, countryCode: "+372" },
    { name: "Eswatini", length: 8, countryCode: "+268" },
    { name: "Ethiopia", length: 9, countryCode: "+251" },
    { name: "Fiji", length: 7, countryCode: "+679" },
    { name: "Finland", length: 12, countryCode: "+358" },
    { name: "France", length: 9, countryCode: "+33" },
    { name: "Gabon", length: 8, countryCode: "+241" },
    { name: "Gambia", length: 7, countryCode: "+220" },
    { name: "Georgia", length: 9, countryCode: "+995" },
    { name: "Germany", length: 11, countryCode: "+49" },
    { name: "Ghana", length: 9, countryCode: "+233" },
    { name: "Greece", length: 10, countryCode: "+30" },
    { name: "Grenada", length: 10, countryCode: "+1 473" },
    { name: "Guatemala", length: 8, countryCode: "+502" },
    { name: "Guinea", length: 9, countryCode: "+224" },
    { name: "Guinea-Bissau", length: 7, countryCode: "+245" },
    { name: "Guyana", length: 10, countryCode: "+592" },
    { name: "Haiti", length: 8, countryCode: "+509" },
    { name: "Honduras", length: 8, countryCode: "+504" },
    { name: "Hungary", length: 9, countryCode: "+36" },
    { name: "Iceland", length: 7, countryCode: "+354" },
    { name: "India", length: 10, countryCode: "+91" },
    { name: "Indonesia", length: 10, countryCode: "+62" },
    { name: "Iran", length: 10, countryCode: "+98" },
    { name: "Iraq", length: 10, countryCode: "+964" },
    { name: "Ireland", length: 7, countryCode: "+353" },
    { name: "Israel", length: 9, countryCode: "+972" },
    { name: "Italy", length: 10, countryCode: "+39" },
    { name: "Jamaica", length: 10, countryCode: "+1 876" },
    { name: "Japan", length: 10, countryCode: "+81" },
    { name: "Jordan", length: 9, countryCode: "+962" },
    { name: "Kazakhstan", length: 10, countryCode: "+7" },
    { name: "Kenya", length: 10, countryCode: "+254" },
    { name: "Kiribati", length: 7, countryCode: "+686" },
    { name: "Kuwait", length: 8, countryCode: "+965" },
    { name: "Kyrgyzstan", length: 9, countryCode: "+996" },
    { name: "Laos", length: 8, countryCode: "+856" },
    { name: "Latvia", length: 8, countryCode: "+371" },
    { name: "Lebanon", length: 8, countryCode: "+961" },
    { name: "Lesotho", length: 8, countryCode: "+266" },
    { name: "Liberia", length: 7, countryCode: "+231" },
    { name: "Libya", length: 9, countryCode: "+218" },
    { name: "Liechtenstein", length: 10, countryCode: "+423" },
    { name: "Lithuania", length: 9, countryCode: "+370" },
    { name: "Luxembourg", length: 9, countryCode: "+352" },
    { name: "Madagascar", length: 9, countryCode: "+261" },
    { name: "Malawi", length: 10, countryCode: "+265" },
    { name: "Malaysia", length: 10, countryCode: "+60" },
    { name: "Maldives", length: 7, countryCode: "+960" },
    { name: "Mali", length: 8, countryCode: "+223" },
    { name: "Malta", length: 8, countryCode: "+356" },
    { name: "Marshall Islands", length: 7, countryCode: "+692" },
    { name: "Mauritania", length: 8, countryCode: "+222" },
    { name: "Mauritius", length: 10, countryCode: "+230" },
    { name: "Mexico", length: 10, countryCode: "+52" },
    { name: "Micronesia", length: 7, countryCode: "+691" },
    { name: "Moldova", length: 8, countryCode: "+373" },
    { name: "Monaco", length: 8, countryCode: "+377" },
    { name: "Mongolia", length: 8, countryCode: "+976" },
    { name: "Montenegro", length: 8, countryCode: "+382" },
    { name: "Morocco", length: 10, countryCode: "+212" },
    { name: "Mozambique", length: 9, countryCode: "+258" },
    { name: "Myanmar (formerly Burma)", length: 9, countryCode: "+95" },
    { name: "Namibia", length: 9, countryCode: "+264" },
    { name: "Nauru", length: 7, countryCode: "+674" },
    { name: "Nepal", length: 10, countryCode: "+977" },
    { name: "Netherlands", length: 10, countryCode: "+31" },
    { name: "New Zealand", length: 9, countryCode: "+64" },
    { name: "Nicaragua", length: 8, countryCode: "+505" },
    { name: "Niger", length: 8, countryCode: "+227" },
    { name: "Nigeria", length: 10, countryCode: "+234" },
    { name: "North Korea", length: 9, countryCode: "+850" },
    { name: "North Macedonia", length: 8, countryCode: "+389" },
    { name: "Norway", length: 8, countryCode: "+47" },
    { name: "Oman", length: 8, countryCode: "+968" },
    { name: "Pakistan", length: 10, countryCode: "+92" },
    { name: "Palau", length: 7, countryCode: "+680" },
    { name: "Palestine", length: 10, countryCode: "+970" },
    { name: "Panama", length: 7, countryCode: "+507" },
    { name: "Papua New Guinea", length: 10, countryCode: "+675" },
    { name: "Paraguay", length: 9, countryCode: "+595" },
    { name: "Peru", length: 9, countryCode: "+51" },
    { name: "Philippines", length: 10, countryCode: "+63" },
    { name: "Poland", length: 9, countryCode: "+48" },
    { name: "Portugal", length: 9, countryCode: "+351" },
    { name: "Qatar", length: 8, countryCode: "+974" },
    { name: "Romania", length: 10, countryCode: "+40" },
    { name: "Russia", length: 10, countryCode: "+7" },
    { name: "Rwanda", length: 10, countryCode: "+250" },
    { name: "Saint Kitts and Nevis", length: 10, countryCode: "+1 869" },
    { name: "Saint Lucia", length: 10, countryCode: "+1 758" },
    {
      name: "Saint Vincent and the Grenadines",
      length: 10,
      countryCode: "+1 784",
    },
    { name: "Samoa", length: 7, countryCode: "+685" },
    { name: "San Marino", length: 10, countryCode: "+378" },
    { name: "Sao Tome and Principe", length: 7, countryCode: "+239" },
    { name: "Saudi Arabia", length: 9, countryCode: "+966" },
    { name: "Senegal", length: 9, countryCode: "+221" },
    { name: "Serbia", length: 8, countryCode: "+381" },
    { name: "Seychelles", length: 7, countryCode: "+248" },
    { name: "Sierra Leone", length: 8, countryCode: "+232" },
    { name: "Singapore", length: 8, countryCode: "+65" },
    { name: "Slovakia", length: 9, countryCode: "+421" },
    { name: "Slovenia", length: 8, countryCode: "+386" },
    { name: "Solomon Islands", length: 7, countryCode: "+677" },
    { name: "Somalia", length: 8, countryCode: "+252" },
    { name: "South Africa", length: 10, countryCode: "+27" },
    { name: "South Korea", length: 10, countryCode: "+82" },
    { name: "South Sudan", length: 9, countryCode: "+211" },
    { name: "Spain", length: 9, countryCode: "+34" },
    { name: "Sri Lanka", length: 10, countryCode: "+94" },
    { name: "Sudan", length: 9, countryCode: "+249" },
    { name: "Suriname", length: 7, countryCode: "+597" },
    { name: "Sweden", length: 10, countryCode: "+46" },
    { name: "Switzerland", length: 9, countryCode: "+41" },
    { name: "Syria", length: 9, countryCode: "+963" },
    { name: "Taiwan", length: 9, countryCode: "+886" },
    { name: "Tajikistan", length: 9, countryCode: "+992" },
    { name: "Tanzania", length: 10, countryCode: "+255" },
    { name: "Thailand", length: 9, countryCode: "+66" },
    { name: "Timor-Leste", length: 9, countryCode: "+670" },
    { name: "Togo", length: 8, countryCode: "+228" },
    { name: "Tonga", length: 7, countryCode: "+676" },
    { name: "Trinidad and Tobago", length: 10, countryCode: "+1 868" },
    { name: "Tunisia", length: 8, countryCode: "+216" },
    { name: "Turkey", length: 10, countryCode: "+90" },
    { name: "Turkmenistan", length: 8, countryCode: "+993" },
    { name: "Tuvalu", length: 7, countryCode: "+688" },
    { name: "Uganda", length: 10, countryCode: "+256" },
    { name: "Ukraine", length: 9, countryCode: "+380" },
    { name: "United Arab Emirates", length: 9, countryCode: "+971" },
    { name: "United Kingdom", length: 10, countryCode: "+44" },
    { name: "United States", length: 10, countryCode: "+1" },
    { name: "Uruguay", length: 8, countryCode: "+598" },
    { name: "Uzbekistan", length: 9, countryCode: "+998" },
    { name: "Vanuatu", length: 7, countryCode: "+678" },
    { name: "Vatican City", length: 10, countryCode: "+379" },
    { name: "Venezuela", length: 10, countryCode: "+58" },
    { name: "Vietnam", length: 10, countryCode: "+84" },
    { name: "Yemen", length: 9, countryCode: "+967" },
    { name: "Zambia", length: 10, countryCode: "+260" },
    { name: "Zimbabwe", length: 9, countryCode: "+263" },
  ]);

  const [selectedUserType, setSelectedUserType] = useState("");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("User Name is required"),
    userType: Yup.string().required("User Type is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const GetUserInfo = () => {
    getUserInfoAPI(userId)
      .then((response) => {
        const user = response.data.body.value;
        setUserInfo(user);
        setInitialUserInfo(user);
        setInitialValues({
          name: user.name || "",
          userType: user.userType || "",
          email: user.email || "",
          phone: user.phone || "",
          areaCode: user.areaCode || "",
          organization: user.organization.organizationId || "",
          canAddEditActivityTemplates: String(user.canAddEditActivityTemplates),
          canAddEditLeaderboard: String(user.canAddEditLeaderboard),
          canCreateDashboard: String(user.canCreateDashboard),
          canModifyGuidelines: String(user.canModifyGuidelines),
          canAddEditUser: String(user.canAddEditUser),
          // canAddEditInventory: String(user.canAddEditInventory),
          // canAddEditProtectedAccount: String(user.canAddEditProtectedAccount),
          // canTaskManage: String(user.canTaskManage),
          canAddLabelsToActivityTemplates: String(
            user.canAddLabelsToActivityTemplates
          ),
          canAccessAssetManagement: String(user.canAccessAssetManagement),
          userVisibility: user.userVisibility || [],
        });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if (userId) {
      GetUserInfo();
    }
  }, [userId]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = Cookies.get("accessToken");
    if (!token) {
      toast.error("Token is not present");
      return;
    }
    const isPhoneValid = validatePhoneNumber(values.phone);
    if (!isPhoneValid) {
      toast.error("Invalid phone number. Please check and try again.");
      return;
    }
    console.log(values);
    try {
      let response;
      if (userId) {
        response = await updateUser(
          {
            userId: Number(userId),
            ...values,
            userVisibility: initialValues.userVisibility,
            organization: { organizationId: values.organization },
          },
          token
        );
      } else {
        response = await createUser(
          {
            ...values,
            userVisibility: initialValues.userVisibility,
            organization: { organizationId: values.organization },
          },
          token
        );
      }
      if (response.header.code === 600) {
        if (userId) {
          toast.success("User updated successfully");
          navigate("/all-users");
        } else {
          toast.success("User created successfully");
          navigate("/all-users");
          resetForm();
          setInitialValues((prev) => ({ ...prev, userVisibility: [] }));
        }
      } else if (response.header.code === 650) {
        toast.error("Server Error");
      } else if (response.header.code === 612) {
        toast.error(response.body.value);
      } else {
        toast.error(response.body.value);
      }
    } catch (error) {
      console.error("Error handling user:", error);
      toast.error("Failed to handle user");
    } finally {
      setSubmitting(false);
    }
  };

  const handleCheckboxChange = (e, setFieldValue, values) => {
    const { value, checked } = e.target;
    const updatedUserVisibility = checked
      ? [...values.userVisibility, value]
      : values.userVisibility.filter((item) => item !== value);
    setFieldValue("userVisibility", updatedUserVisibility);
  };

  const fetchOrganizationsData = async () => {
    const token = Cookies.get("accessToken");
    try {
      const data = await fetchOrganizations(token);
      setOrganizations(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchOrganizationsData();
  }, []);

  useEffect(() => {
    const userType = Cookies.get("userType");
    setIsSuperAdmin(userType === "super_admin");
    setIsAdmin(userType === "admin");
  });

  const handleGoBack = () => {
    navigate("/all-users");
  };

  const validatePhoneNumber = (phone) => {
    let numericPhone = phone.replace(/[^0-9]/g, "");
    const defaultCountry = { name: "Oman", length: 8, countryCode: "968" };
    let country = countryDetail.find((c) =>
      numericPhone.startsWith(c.countryCode.replace(/[^0-9]/g, ""))
    );
    if (!country) {
      country = defaultCountry;
      numericPhone = defaultCountry.countryCode + numericPhone;
    }
    const phoneNumberLength =
      numericPhone.length - country.countryCode.replace(/[^0-9]/g, "").length;
    if (phoneNumberLength === country.length) {
      setPhoneError("");
      return true;
    } else {
      setPhoneError(
        `${country.name} phone numbers should be exactly ${country.length} digits long.`
      );
      return false;
    }
  };

  return (
    <>
      <div className="flex gap-2 mt-2">
        <div
          onClick={handleGoBack}
          className={` p-2 rounded-full border cursor-pointer w-8 
${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
${theme === "high-contrast" ? "high-contrast border border-gray-600 " : ""} 
${
  theme !== "dark" && theme !== "high-contrast"
    ? "border-gray-400 hover:bg-gray-200"
    : ""
}`}
        >
          <FaArrowLeft
            className={`h-3 w-3 ${
              theme === "dark"
                ? "text-white"
                : theme === "high-contrast"
                ? "text-black"
                : "text-gray-700"
            }`}
          />
        </div>
        <h4
          className={`uppercase mt-1 text-sm font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          {userId
            ? translations[langMode].updateuser
            : translations[langMode].addUser}
        </h4>
      </div>
      <div className="w-full">
        <div
          className={`px-5 mt-5  w-full bg-white ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          <h3
            className={`text-sm text-gray-500 font-bold py-4 ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            {translations[langMode].userdetail}
          </h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <React.Fragment>
                <Form>
                  <div className="w-3/5">
                    <div className=" mt-3 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-4">
                      <div className="sm:col-span-2">
                        <Field
                          type="text"
                          name="name"
                          label={translations[langMode].username}
                          component={CommonInputField}
                          value={values.name}
                          onChange={(e) =>
                            setFieldValue("name", e.target.value)
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <Field
                          className={`px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2  ${
                            theme === "dark"
                              ? "dark-mode border rounded-full"
                              : theme === "high-contrast"
                              ? "high-contrast border rounded-full"
                              : ""
                          }`}
                          name="userType"
                          label="User Type"
                          component="select"
                          id="floating_outlined_1"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue("userType", value);
                            setSelectedUserType(value);
                          }}
                        >
                          <option value="">
                            {translations[langMode].userType}
                          </option>
                          <option value="admin">Admin</option>
                          <option value="office_user">Office user</option>
                          <option value="field_agent">Field agent</option>
                        </Field>
                      </div>
                      <div className="sm:col-span-2">
                        <Field
                          type="text"
                          name="email"
                          label={translations[langMode].Email}
                          component={CommonInputField}
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <PhoneInput
                          country={"om"}
                          dropdownStyle={{
                            backgroundColor: theme === "dark" ? "#000" : "#fff",
                            color: theme === "dark" ? "#fff" : "#000",
                          }}
                          buttonStyle={{
                            backgroundColor:
                              theme === "dark" ? "#000 hover:bg-black" : "#fff",
                          }}
                          style={{
                            border:
                              theme === "dark"
                                ? "1px solid #333"
                                : "1px solid gray", // Change border color
                            borderRadius: "5px",
                            backgroundColor: theme === "dark" ? "#000" : "#fff", // Change background based on theme
                            color: theme === "dark" ? "#fff" : "#000", // Change text color based on theme
                          }}
                          value={values.phone}
                          onChange={(phone) => {
                            // Set phone value with custom validation
                            const isValid = validatePhoneNumber(phone);
                            if (isValid) {
                              setFieldValue("phone", phone);
                            }
                          }}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                            style: {
                              height: "49px",
                              width: "100%",
                              fontSize: "16px",
                              border:
                                theme === "dark"
                                  ? "1px solid #555"
                                  : "1px solid gray", // Inner border color based on theme
                              backgroundColor:
                                theme === "dark" ? "#000" : "#fff", // Input background color
                              color: theme === "dark" ? "#fff" : "#000", // Input text color
                            },
                          }}
                        />
                        {phoneError && (
                          <div
                            className="text-xs"
                            style={{ color: "red", marginTop: "2px" }}
                          >
                            {phoneError}
                          </div>
                        )}
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      {isSuperAdmin && (
                        <div className="sm:col-span-2">
                          <Field
                            className={`px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2  ${
                              theme === "dark"
                                ? "dark-mode border rounded-full"
                                : theme === "high-contrast"
                                ? "high-contrast border rounded-full"
                                : ""
                            }`}
                            name="organization"
                            label="Organization"
                            component="select"
                            id="floating_outlined_1"
                            value={values.organization}
                            onChange={(e) => {
                              setFieldValue("organization", e.target.value);
                            }}
                          >
                            <option value="">
                              {translations[langMode].selectOrg}
                            </option>
                            {organizations.map((org) => (
                              <option
                                key={org.organizationId}
                                value={org.organizationId}
                              >
                                {org.organizationName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="organizationId"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      )}
                    </div>
                    { selectedUserType !== "field_agent" && (
                      <>
                        {/* {isSuperAdmin && ( */}
                        <div className="py-2">
                          <h1
                            className={`${
                              theme === "dark"
                                ? "dark-mode  "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            } font-bold text-gray-600 text-sm`}
                          >
                            {" "}
                            {translations[langMode].previllage}
                          </h1>
                          <div className="grid grid-cols-3 gap-2 text-gray-600 py-2">
                            <CommonRadio
                              name="canAddEditActivityTemplates"
                              que={translations[langMode].addeditActivity}
                            />
                            <CommonRadio
                              name="canAddLabelsToActivityTemplates"
                              que={translations[langMode].addnewlabel}
                            />
                            <CommonRadio
                              name="canAddEditUser"
                              que={translations[langMode].addEdituser}
                            />
                            <CommonRadio
                              name="canAccessAssetManagement"
                              que={translations[langMode].canasset}
                            />
                            <div>
                              <span
                                className={`text-sm font-semibold ${
                                  theme === "dark"
                                    ? "dark-mode  "
                                    : theme === "high-contrast"
                                    ? "high-contrast  "
                                    : ""
                                }`}
                              >
                                {translations[langMode].uservisibility}
                              </span>
                              <div className="flex items-center mb-4 mt-2">
                                <input
                                  id="office-user-checkbox"
                                  type="checkbox"
                                  name="userVisibility"
                                  value="office_user"
                                  checked={values.userVisibility.includes(
                                    "office_user"
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300
                                       rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                                       dark:ring-offset-gray-800 focus:ring-2 ${
                                         theme === "dark"
                                           ? "text-blue-600 "
                                           : theme === "high-contrast"
                                           ? "high-contrast  "
                                           : ""
                                       }`}
                                />
                                <label
                                  htmlFor="office-user-checkbox"
                                  className={`ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 ${
                                    theme === "dark"
                                      ? "dark-mode  "
                                      : theme === "high-contrast"
                                      ? "high-contrast  "
                                      : ""
                                  }`}
                                >
                                  Office User
                                </label>
                              </div>
                              <div className="flex items-center mb-4">
                                <input
                                  id="field-user-checkbox"
                                  type="checkbox"
                                  name="userVisibility"
                                  value="field_agent"
                                  checked={values.userVisibility.includes(
                                    "field_agent"
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 
                                      rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 
                                      focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${
                                        theme === "dark"
                                          ? "text-blue-600 "
                                          : theme === "high-contrast"
                                          ? "high-contrast  "
                                          : ""
                                      }`}
                                />
                                <label
                                  htmlFor="field-user-checkbox"
                                  className={`ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 ${
                                    theme === "dark"
                                      ? "dark-mode  "
                                      : theme === "high-contrast"
                                      ? "high-contrast  "
                                      : ""
                                  }`}
                                >
                                  Field User
                                </label>
                              </div>
                            </div>
                            <CommonRadio
                              name="canModifyGuidelines"
                              que={translations[langMode].modifyguidline}
                            />
                            <CommonRadio
                              name="canCreateDashboard"
                              que={translations[langMode].createDashboar}
                            />
                            {/* <CommonRadio
                              name="canTaskManage"
                              que={translations[langMode].canTaskManage}
                            />
                            <CommonRadio
                              name="canAddEditProtectedAccount"
                              que={
                                translations[langMode]
                                  .canAddEditProtectedAccount
                              }
                            />
                            <CommonRadio
                              name="canAddEditInventory"
                              que={translations[langMode].canAddEditInventory}
                            /> */}
                          </div>
                        </div>
                        {/* )} */}
                      </>
                    )}
                    {/* {selectedUserType !== "field_agent" && (
                      <>
                        {isAdmin && (
                          <div className="px-5 py-3">
                            <h1 className="text-xs">
                              {" "}
                              {translations[langMode].previllage}
                            </h1>
                            <div className="grid grid-cols-3 gap-8 text-gray-600 py-2">
                              <CommonRadio
                                name="canAddEditActivityTemplates"
                                que={translations[langMode].addeditActivity}
                              />
                              <CommonRadio
                                name="canAddLabelsToActivityTemplates"
                                que={translations[langMode].addnewlabel}
                              />
                              <CommonRadio
                                name="canAddEditUser"
                                que={translations[langMode].addEdituser}
                              />
                              <CommonRadio
                                name="canAccessAssetManagement"
                                que={translations[langMode].canasset}
                              />
                              <div>
                                <span className="text-sm font-semibold">
                                  {translations[langMode].uservisibility}
                                </span>
                                <div className="flex items-center mb-4 mt-5">
                                  <input
                                    id="office-user-checkbox"
                                    type="checkbox"
                                    name="userVisibility"
                                    value="office_user"
                                    onChange={handleCheckboxChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    htmlFor="office-user-checkbox"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    Office User
                                  </label>
                                </div>
                                <div className="flex items-center mb-4">
                                  <input
                                    id="field-user-checkbox"
                                    type="checkbox"
                                    name="userVisibility"
                                    value="field_agent"
                                    onChange={handleCheckboxChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    htmlFor="field-user-checkbox"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    Field Agent
                                  </label>
                                </div>
                              </div>
                              <CommonRadio
                                name="canModifyGuidelines"
                                que={translations[langMode].modifyguidline}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )} */}
                  </div>
                  <div className="">
                    <div className="sm:col-span-4">
                      <hr className="w-full border-gray-300" />
                    </div>
                    <div className="sm:col-span-4 pt-2 flex justify-end items-center">
                      <button
                        type="button"
                        onClick={handleGoBack}
                        className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        {translations[langMode].cancle}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        {userId
                          ? translations[langMode].update
                          : translations[langMode].add}
                      </button>
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddUser;
