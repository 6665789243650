import React, { useContext, useEffect, useState, useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AppContext from "../../context/AppContext";
import { IoIosNotifications, IoMdLogOut } from "react-icons/io";
import { IoSettings } from "react-icons/io5";
import dummyImg from "../../assets/dummy.webp";
import lightmodeImg from "../../assets/Bluefield.png";
import darkmodeImg from "../../assets/logo.png";
import OrganizationFilterSelect from "../common/task/OrganizationFilterSelect";
import ActivitySelect from "../common/task/ActivitySelect";
import { getUnreadMsgCount } from "../../service/Notification";
import Logout from "../common/Logout";

export const MainNav = () => {
  const [showModal, setShowModal] = useState(false);
  const [userType, setUserType] = useState(null);
  const [totalUnreadMsgCount, setTotalUnreadMsgCount] = useState(0);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const context = useContext(AppContext);
  const {
    theme,
    profilePic,
    setProfilePic,
    workflowName,
    setWorkflowName,
    selectedOrgId,
    setSelectedOrgId,
    setSelectedOrgName,
    selectedOrgName,
    runTour,
    menu,
  } = context;
  const user = Cookies.get("userName");
  const navbarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const showActivitySelect = location.pathname !== "/export";
  useEffect(() => {
    const profilePic = Cookies.get("profilePic") || dummyImg;
    setProfilePic(profilePic !== "null" ? profilePic : dummyImg);
    const userTypeCookie = Cookies.get("userType");
    setUserType(userTypeCookie);
  }, []);

  useEffect(() => {
    const fetchUnreadMsgCount = async () => {
      try {
        const count = await getUnreadMsgCount();
        setTotalUnreadMsgCount(count.body.value.unreadMsgCount);
      } catch (error) {
        console.error("Failed to fetch unread message count:", error);
      }
    };
    fetchUnreadMsgCount();
  }, []);

  const handleLogout = () => setShowModal(true);
  const handleViewNotifications = () => navigate("/notification");
  const handleSettings = () => navigate("/settings");
  const formatUserType = (userType) => {
    if (!userType) return "Unknown";
    switch (userType) {
      case "super_admin":
        return "Super Admin";
      case "admin":
        return "Admin";
      case "office_user":
        return "Office User";
      default:
        return userType
          .replace(/_/g, " ")
          .replace(/^./, (str) => str.toUpperCase());
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavbarOpen(false);
      }
    };

    if (isNavbarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavbarOpen]);
  return (
    <>
      <Disclosure
        as="nav"
        ref={navbarRef}
        className={`bg-white w-full shadow-md ${
          theme === "dark" ? "dark-mode" : ""
        }`}
      >
        {({ open }) => (
          <>
            <div className="w-full px-4 sm:px-6 lg:px-8 border-b">
              <div className="flex h-16 justify-between items-center">
                <div className="flex items-center">
                  <Link to="/dashboard">
                    {theme === "dark" ? (
                     <div className="inline-flex gap-1">
                       <img
                        src={darkmodeImg}
                        alt="Logo"
                        className={`h-10 ${
                          theme === "dark" ? "dark-mode-img" : ""
                        }`}
                      />
                      <span className="text-3xl font-semibold">Blufield</span>
                     </div>
                    ) : (
                      <img
                        src={lightmodeImg}
                        alt="Logo"
                        className={`h-10`}
                      />
                    )}
                  </Link>
                </div>
                <div className="hidden lg:flex md:items-center gap-1">
                  {userType === "super_admin" && (
                    <OrganizationFilterSelect
                      selectedOrgName={selectedOrgName}
                      setSelectedOrgId={setSelectedOrgId}
                      setSelectedOrgName={setSelectedOrgName}
                      setWorkflowName={setWorkflowName}
                    />
                  )}
                  {showActivitySelect && (
                    <ActivitySelect
                      setWorkflowName={setWorkflowName}
                      workflowName={workflowName}
                      selectedOrgId={selectedOrgId}
                    />
                  )}
                  <div className="flex items-center gap-4 ml-2">
                    <div className="relative ">
                      <img
                        className={`w-10 h-10 p-1 rounded-full cursor-pointer ring-2 ring-gray-300 ${
                          theme === "dark"
                            ? "bg-white"
                            : theme === "high-contrast"
                            ? "bg-white"
                            : ""
                        }`}
                        src={profilePic}
                        alt="Avatar"
                        onClick={() => navigate("/user-profile")}
                      />
                      <span className="absolute top-0 right-0 w-3.5 h-3.5 bg-green-400 rounded-full border border-white" />
                    </div>
                    <div className="text-sm">
                      <span className="font-bold">{user}</span>
                      <span
                        className={`block text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "  "
                            : ""
                        }`}
                      >
                        {formatUserType(userType)}
                      </span>
                    </div>
                    <div
                      className="relative group "
                      onClick={handleViewNotifications}
                    >
                      <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                        {totalUnreadMsgCount}
                      </span>
                      <IoIosNotifications className="notification h-8 w-8 filter brightness-75 hover:text-blue-500" />
                      <div
                        role="tooltip"
                        className="absolute right-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                      >
                        Notification
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                    <div
                      className="relative group seting"
                      onClick={handleSettings}
                    >
                      <IoSettings className=" h-7 w-7 filter brightness-75 hover:text-blue-500" />
                      <div
                        role="tooltip"
                        className="absolute right-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                      >
                        Settings
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                    <div className="relative group" onClick={handleLogout}>
                      <IoMdLogOut className="h-7 w-7 filter brightness-75 hover:text-blue-500" />
                      <div
                        role="tooltip"
                        className="absolute right-full  ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                      >
                        Logout
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:hidden inline-flex items-center gap-4 justify-center">
                  <div className="relative">
                    <img
                      className={`w-10 h-10  p-1 rounded-full cursor-pointer ring-2 ring-gray-30 ${
                        theme === "dark"
                          ? "bg-white"
                          : theme === "high-contrast"
                          ? "bg-white"
                          : ""
                      }`}
                      src={profilePic}
                      alt="Avatar"
                      onClick={() => navigate("/user-profile")}
                    />
                    <span className="absolute top-0 right-0 w-3.5 h-3.5 bg-green-400 rounded-full border border-white" />
                  </div>
                  <div className="">
                    <span
                      className={` ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      } font-bold text-sm`}
                    >
                      {user}
                    </span>
                    <span
                      className={`block text-gray-700 text-xs  ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "  "
                          : ""
                      }`}
                    >
                      {formatUserType(userType)}
                    </span>
                  </div>
                  <Disclosure.Button
                    onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                    className="inline-flex items-center justify-center p-2 text-gray-400  hover:text-red-600"
                  >
                    <span className="sr-only">Open main menu</span>
                    {isNavbarOpen ? (
                      <XMarkIcon className="h-6 w-6" />
                    ) : (
                      <Bars3Icon className="h-6 w-6" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel
              className={`lg:hidden absolute z-20   bg-black bg-opacity-70 rounded-b-md flex justify-end right-6 overflow-hidden transition-all duration-300 ease-in-out max-h-0
             ${isNavbarOpen
                  ? "max-h-screen border-b border-r border-l"
                  : "max-h-0"
                }`}
              static
            >
              <div className="p-5 flex justify-end gap-3">
                {userType === "super_admin" && (
                  <OrganizationFilterSelect
                    selectedOrgName={selectedOrgName}
                    setSelectedOrgId={setSelectedOrgId}
                    setSelectedOrgName={setSelectedOrgName}
                    setWorkflowName={setWorkflowName}
                  />
                )}
                {showActivitySelect && (
                  <ActivitySelect
                    setWorkflowName={setWorkflowName}
                    workflowName={workflowName}
                    selectedOrgId={selectedOrgId}
                  />
                )}
                <div className="mt-3 flex gap-3 ">
                  <div
                    className="relative group"
                    onClick={handleViewNotifications}
                  >
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                      {totalUnreadMsgCount}
                    </span>
                    <IoIosNotifications className="text-white notification h-8 w-8 hover:text-blue-500" />
                    <div
                      role="tooltip"
                      className="absolute right-full top-0 ml-2 z-50 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-900 text-white text-sm rounded-lg p-2 shadow-lg"
                    >
                      Notification
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>

                  <div className="relative group" onClick={handleSettings}>
                    <IoSettings className="text-white setting h-7 w-7 hover:text-blue-500" />
                    <div
                      role="tooltip"
                      className="absolute  right-full top-0 ml-2 z-60 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-900 text-white text-sm rounded-lg p-2 shadow-lg"
                    >
                      Settings
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                  <div className="relative group" onClick={handleLogout}>
                    <IoMdLogOut className="h-7 w-7 text-white hover:text-blue-500" />
                    <div
                      role="tooltip"
                      className="absolute  right-full top-0 ml-2 z-60 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-900 text-white text-sm rounded-lg p-2 shadow-lg"
                      style={{ zIndex: 99999 }}
                    >
                      Logout
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Logout setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};
