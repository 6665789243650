import React from "react";
import { AiFillSound } from "react-icons/ai";
import { DragDropFile } from "../../common/DragDropFile";

const ConfigurationSetting = () => {
  return (
    <>
      <div className="py-5">
        <h3 className="text-sm text-gray-500 font-bold">Notification Sound</h3>
        <div className="flex items-center p-2 gap-2">
          <AiFillSound />
          <div className="w-60 bg-gray-200 rounded-full h-1.5  dark:bg-gray-700">
            <div
              className="bg-blue-800 h-1.5 rounded-full dark:bg-blue-500"
              style={{ width: "45%" }}
            ></div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-sm text-gray-500 font-bold">Configurations</h3>
        <div className="flex items-center mt-5 gap-6">
          <div>
            <span className="text=sm text-gray-400">Terms and Conditions</span>
            <DragDropFile
              filelabel={"Drag and drop Terms and Condition file"}
            />
          </div>
          <div>
            <span className="text=sm text-gray-400">Privacy Acceptance</span>
            <DragDropFile filelabel={"Drag and drop Privacy Acceptance file"} />
          </div>
        </div>
        <div className="bg-gray-100 w-80 p-4 flex items-center gap-6">
          <span className="text-sm font-semibold text-gray-500">
            TermsandCondition.pdf
          </span>
          <span className="text-sm underline font-semibold text-blue-500">
            view
          </span>
        </div>
      </div>
    </>
  );
};

export default ConfigurationSetting;
