import React, { Fragment, useContext, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import translations from "../../../components/common/Translations";
import AppContext from "../../../context/AppContext";
import { FaPlusCircle } from "react-icons/fa";
import { toast } from "react-toastify";

export const GetFieldFolder = ({
  open,
  setOpen,
  fields,
  setFields,
  taskExcelColumns,
  setTaskExcelColumns,
}) => {
  const cancelButtonRef = useRef(null);
  const { theme , langMode } = useContext(AppContext);

  // Function to handle checkbox state change
  const handleCheckboxChange = (taskDetailsMetadataId) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.taskDetailsMetadataId === taskDetailsMetadataId
          ? { ...field, isReport: !field.isReport }
          : field
      )
    );
  };
  const handleAddClick = () => {
    const updatedFields = fields.map((field) => ({
      ...field,
      isReport: field.isReport,
    }));
    setTaskExcelColumns(updatedFields); // Update parent state
    setOpen(false); // Close modal
    localStorage.setItem("selectedFields", JSON.stringify(updatedFields)); // Save to localStorage
    toast.warn("Update the workflow to save these additional fields.");
    console.log(updatedFields);
  };

  // Load saved fields from localStorage when modal is opened
  useEffect(() => {
    if (open) {
      const savedFields = localStorage.getItem("selectedFields");
      if (savedFields) {
        const parsedFields = JSON.parse(savedFields);
        setFields((prevFields) =>
          prevFields.map((field) => {
            const savedField = parsedFields.find(
              (f) => f.taskDetailsMetadataId === field.taskDetailsMetadataId
            );
            return savedField ? { ...field, isReport: savedField.isReport } : field;
          })
        );
      }
    }
  }, [open, setFields]);


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}  >
                <div
                  className={`bg-white rounded-lg shadow ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : "" }`} >
                  <div className="flex items-center justify-between p-2 md:p-5 rounded-t dark:border-gray-600">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Add fields
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setOpen(false)}>
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="px-5 grid grid-cols-2 gap-5 h-auto max-h-[300px] overflow-y-auto">
                    {fields.map((field) => (
                      <div
                        key={field.taskDetailsMetadataId}
                        className="flex items-center" >
                        <input
                          type="checkbox"
                          id={`field-${field.taskDetailsMetadataId}`}
                          checked={field.isReport}
                          onChange={() =>
                            handleCheckboxChange(field.taskDetailsMetadataId)
                          }
                          className="mr-2"
                        />
                        <label
                          htmlFor={`field-${field.taskDetailsMetadataId}`}
                          className="text-sm text-gray-900 dark:text-white"   >
                          {field.fieldName}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="p-5">
                    <button
                      type="button"
                      onClick={handleAddClick}
                      className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-xs inline-flex gap-2" >
                     <FaPlusCircle size={14}/> {translations[langMode].add}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
