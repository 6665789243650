import React, { useState, useContext, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { IoEllipsisVerticalCircle } from "react-icons/io5";
import { RiGroupFill } from "react-icons/ri";
import CreateOrganization from "./CreateOrgnization";
import {
  fetchOrgnizationData,
  fetchOrganizationById,
} from "../../service/User";
import Updateorgnization from "./Updateorgnization";
import { RxCross2 } from "react-icons/rx";
import { Spinner } from "../../Spinner";
import translations from "../common/Translations";
const OrgnizationList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalValue, setTotalValue] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [editUserId, setEditUserId] = useState(null); // Track which user's popup to show
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;
  const { theme, langMode } = useContext(AppContext); // Use toggleTheme from contex
  const [open, setOpen] = useState(false);
  const [orgnizationData, setOrgnizationData] = useState([""]);
  const [orgnizationId, setOrgnizationID] = useState();
  const [editOrganizationname, setEditOrganizationName] = useState("");
  const [editOrganizationimg, setEditOrganizationImg] = useState(null);
  const [updateopen, setUpdateOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const Popup = ({ onClose, onEdit, position, userId }) => {
    const adjustedPosition = {
      x: position.x - 80,
      y: position.y,
    };

    return (
      <div
        className="fixed bg-opacity-50 flex items-center justify-center top-2"
        style={{
          top: adjustedPosition.y,
          left: adjustedPosition.x < 0 ? 0 : adjustedPosition.x,
          width: "100px",
        }}
      >
        <div
          className={`bg-white rounded p-3 shadow-lg relative ${
            theme === "dark"
              ? "dark-mode border "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
          style={{ width: "80%" }}
        >
          <button
            className={`absolute top-0 right-0 p-1 text-sm ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
            onClick={onClose}
          >
            <RxCross2 />
          </button>
          <button
            className={`text-black px-2 text-sm py-1  ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
            onClick={() => onEdit(userId)}
          >
            {translations[langMode].edit}
          </button>
        </div>
      </div>
    );
  };

  const handleEditPopup = (Id, position) => {
    setOrgnizationID(Id);
    setPopupPosition(position);
    setShowEdit(true);
  };

  const closeEditPopup = () => {
    setShowEdit(false);
    setEditUserId(null);
  };
  const editInfo = async () => {
    setShowEdit(false);
    try {
      const ResponseData = await fetchOrganizationById(orgnizationId);
      setEditOrganizationName(ResponseData.data.body.value.organizationName);
      setEditOrganizationImg(ResponseData.data.body.value.logo);
      setUpdateOpen(true);
    } catch (error) {
      console.error("Error fetching organization data:", error);
      toast.error("Failed to fetch organization data");
    }
  };

  const tableHeaders = [
    "Organization Id",
    "Organization Name",
    "Organization Logo",
  ];

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchorgnizationlist = async () => {
    try {
      setLoading(true);
      const responseData = await fetchOrgnizationData(currentPage, pageSize);
      setOrgnizationData(responseData.data.body.value.organizationList);
      setTotalValue(responseData.data.body.value.totalSize);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching organization data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchorgnizationlist();
  }, [currentPage, pageSize]);

  return (
    <div className="w-full ">
      <div className="flex justify-between mt-2">
        <h4
          className={` text-sm uppercase font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          {translations[langMode].org}
        </h4>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <RiGroupFill className="w-5 h-5 mr-2" />{" "}
            {translations[langMode].createOrg}
          </button>
        </div>
      </div>
      <div
        className={`px-5  bg-white  ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="relative overflow-x-auto  sm:rounded-lg ">
          <div className="px-5 py-5 flex justify-between items-center">
            <div className="text-sm text-gray-400 flex items-center gap-4">
              <label htmlFor="pageSize">{translations[langMode].rows}:</label>
              <select
                id="pageSize"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value={5}>5</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-sm text-gray-400">
                {`${startIndex + 1}-${Math.min(
                  endIndex,
                  totalItems
                )} of ${totalItems} items`}
              </p>
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
                className={`p-2 ${
                  currentPage === 0 ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className={`p-2 ${
                  currentPage === totalPages
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
          <table
            className={`w-full text-sm text-left rtl:text-right mt-2
              ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
          >
            <thead
              className={`text-sm bg-gray-100 text-gray-800 
                ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
            >
              <tr>
                <th scope="col"></th>
                {tableHeaders.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 whitespace-nowrap text-left text-xs font-medium uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody className="overflow-y-auto max-h-[300px] h-auto ">
              {orgnizationData && orgnizationData.length > 0 ? (
                orgnizationData.map((orgnization, index) => (
                  <tr
                    key={index}
                    className={`bg-white ml-10 text-sm border-b justify-between    ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    } `}
                  >
                    <td className="w-2 p-2"></td>
                    <td className="w-2 py-2 px-6 text-center text-sm ">
                      {orgnization.organizationId}
                    </td>
                    <td className="w-2 py-2  px-6 ">
                      <span className="rounded-full flex px-4 py-1 ml-5 text-sm text-center text-blue-600 bg-blue-500 bg-opacity-15">
                        {" "}
                        {orgnization.organizationName}
                      </span>
                    </td>
                    <td className="w-2 py-3 px-6 text-center  ">
                      {orgnization.logo ? (
                        <img
                          src={orgnization.logo}
                          alt={orgnization.organizationName}
                          className="h-10 w-10 ml-8 border-2 border-gray-400 rounded-full p-1"
                        />
                      ) : (
                        "No Logo"
                      )}
                    </td>
                    <td className="pl-6 py-2 text-right">
                      <div className="flex justify-end items-center">
                        <IoEllipsisVerticalCircle
                          size={20}
                          className="cursor-pointer text-blue-600"
                          onClick={(event) =>
                            handleEditPopup(orgnization.organizationId, {
                              x: event.clientX,
                              y: event.clientY,
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length + 1} className="text-center">
                    {translations[langMode].noOrg}
                  </td>
                </tr>
              )}
              {loading && <Spinner />}
            </tbody>
          </table>
        </div>
      </div>
      {showEdit && (
        <Popup
          onClose={closeEditPopup}
          onEdit={editInfo}
          position={popupPosition}
          userId={editUserId}
        />
      )}
      <CreateOrganization
        open={open}
        setOpen={setOpen}
        fetchorgnizationlist={fetchorgnizationlist}
      />
      <Updateorgnization
        setUpdateOpen={setUpdateOpen}
        fetchorgnizationlist={fetchorgnizationlist}
        updateopen={updateopen}
        setEditOrganizationName={setEditOrganizationName}
        editOrganizationname={editOrganizationname}
        setEditOrganizationImg={setEditOrganizationImg}
        editOrganizationimg={editOrganizationimg}
        orgnizationId={orgnizationId}
      />
    </div>
  );
};
export default OrgnizationList;
