import React, { useContext, useEffect, useState } from "react";
import FilterSelect from "../common/FilterSelect";
import { fetchDynamicWorkflows } from "../../service/UserDashboard";
import { toast } from "react-toastify";
import {
  createCustomeRule,
  deleteCustomRule,
  fetchCustomRules,
  updateCustomRule,
} from "../../service/Configuration";
import { RxCross2 } from "react-icons/rx";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import { IoMdAddCircle } from "react-icons/io";
import translations from "../common/Translations";

const highlightText = (text) => {
  if (typeof text !== "string") {
    text = "";
  }
  const stringRegex = /(".*?"|'.*?')/g;
  const keywordRegex =
    /\b(const|let|var|function|return|if|else|for|while|true|false|null|in|and)\b/g;
  const numberRegex = /\b(\d+)\b/g;
  const operatorRegex = /(\+|\-|\*|\/|\=|\!|\&|\||\?|\:|\%)/g;
  const tokens = text.split(/(\s+)/);
  const wrapSpan = (token, regex, className) => {
    return token.replace(regex, `<span class="${className}">$1</span>`);
  };
  const highlightedTokens = tokens.map((token) => {
    if (stringRegex.test(token)) {
      return wrapSpan(token, stringRegex, "text-green-500");
    } else if (keywordRegex.test(token)) {
      return wrapSpan(token, keywordRegex, "text-blue-500");
    } else if (numberRegex.test(token)) {
      return wrapSpan(token, numberRegex, "text-purple-500");
    } else if (operatorRegex.test(token)) {
      return wrapSpan(token, operatorRegex, "text-red-500");
    } else {
      return token;
    }
  });
  return highlightedTokens.join("");
};

const HighlightedTextarea = ({ value, onChange }) => {
  return (
    <div className="relative">
      <div
        className="absolute inset-0 m-0 overflow-auto p-3 pointer-events-none whitespace-pre-wrap break-words"
        aria-hidden="true"
        dangerouslySetInnerHTML={{ __html: highlightText(value) }}
      />
      <textarea
        value={value}
        onChange={onChange}
        rows={5}
        className="relative rounded-md w-full h-full shadow-inner bg-transparent border-1 p-3 outline-none text-transparent   caret-black"
      />
    </div>
  );
};

const CustomVariable = () => {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("");
  const [createrule, setCreaterule] = useState(false);
  const [criteria, setCriteria] = useState("");
  const [CustomRules, setCustomRules] = useState([]);
  const [ruleid, setRuleId] = useState();
  const { theme, selectedOrgId, langMode, workflowId } = useContext(AppContext);
  const handlCreateRule = () => {
    if (!workflowId) {
      toast.error("Please select a workflow before adding rule");
      return;
    }
    setCreaterule((prevShowCriteria) => !prevShowCriteria);
  };

  // const handleWorkflowChange = (selectedName) => {
  //   const selectedWorkflow = workflows.find(
  //     (workflow) => workflow.workflowName === selectedName
  //   );
  //   if (selectedWorkflow) {
  //     setSelectedWorkflowName(selectedWorkflow.workflowName);
  //     setSelectedWorkflowId(selectedWorkflow.workflowId);
  //   }
  // };
  // useEffect(() => {
  //   if (selectedOrgId) {
  //     const loadWorkflows = async () => {
  //       try {
  //         const workflows = await fetchDynamicWorkflows(selectedOrgId);
  //         setWorkflows(Array.isArray(workflows) ? workflows : []);
  //       } catch (error) {
  //         console.error("Error loading workflows:", error);
  //         setWorkflows([]); // Set to an empty array on error
  //       } };
  //     loadWorkflows();
  //    }}, [selectedOrgId]);

  const loadCustomRules = async (reset = false) => {
    setLoading(true);
    setError(null);
    try {
      const rules = await fetchCustomRules(workflowId);
      if (!rules) {
        setCustomRules([]);
      } else {
        setCustomRules(Array.isArray(rules) ? rules : [rules]);
      }
    } catch (error) {
      console.error("Error loading custom rules:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (workflowId) {
      loadCustomRules();
    }
  }, [workflowId]);

  const handleSaveRule = async () => {
    if (!criteria) {
      toast.warn("Please fill all fields before saving");
      return;
    }
    try {
      const responseData = await createCustomeRule(criteria, workflowId);
      if (responseData.header.code === 600) {
        setCriteria("");
        toast.success("Rule created successfully");
        loadCustomRules()
      } else {
        toast.error(responseData.body.value);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteRule = async (ruleid) => {
    try {
      const response = await deleteCustomRule(ruleid);
      if (response.header.code === 600) {
        toast.success("Rule deleted successfully");
        setCustomRules((prevRules) =>
          prevRules.filter((rule) => rule.id !== ruleid)
        );
        loadCustomRules()
      } else {
        toast.error("Failed to delete rule");
      }
    } catch (error) {
      console.error("Error deleting completion rule:", error);
      toast.error("Failed to delete rule");
    }
  };
  const handleRuleChange = (e, index) => {
    const updatedRules = [...CustomRules];
    updatedRules[index].criteria = e.target.value;
    setCustomRules(updatedRules);
  };

  const handleUpdateChanges = async (ruleid, criteria) => {
    try {
      const response = await updateCustomRule(ruleid, criteria, workflowId);
      if (response.header.code === 600) {
        toast.success("Rule updated successfully");
        loadCustomRules(); // Reload the rules after updating
      } else {
        toast.error("Failed to update rule");
      }
    } catch (error) {
      console.error("Error updating completion rule:", error);
      toast.error("Failed to update rule");
    }
  };
  return (
    <>
      <div className="flex items-center">
        <div className="grow border-b border-dashed border-gray-400"></div>
        <span className="shrink px-1 pb-1 text-teal-600">
          <button
            type="button"
            onClick={handlCreateRule}
            className={`px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-100 bg-gray-700 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? " border bg-black text-white"
                : ""
            }`}
          >
            {createrule ? "Hide Criteria" : "Add New Criteria"}
          </button>
        </span>
        <div className="grow border-b  border-dashed border-gray-400"></div>
      </div>
      {createrule && (
        <div className="p-4">
          <div
            className={`bg-white border-2 border-blue-300 shadow-2xl rounded-md py-2 p-4 ${
              theme === "dark"
                ? "dark-mode border  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            <div className="py-2">
              <label
                for="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {translations[langMode].criteria}
              </label>
              <HighlightedTextarea
                value={criteria}
                onChange={(e) => setCriteria(e.target.value)}
              />
            </div>
            <button
              onClick={handleSaveRule}
              className=" text-sm px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-600"
            >
              {translations[langMode].save}
            </button>
          </div>
        </div>
      )}
      {error && <p>Error loading completion rules: {error.message}</p>}
      {!loading && !error && CustomRules.length === 0 && (
        <p className="text-center">{translations[langMode].norule}</p>
      )}
      {!loading && !error && CustomRules.length > 0 ? (
        <div className="">
          {CustomRules.map((rule, index) => (
            <div
              className={`bg-white border-2 border-blue-300 shadow-2xl  w-full px-10 my-5 rounded-md p-5 ${
                theme === "dark"
                  ? "dark-mode border  "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              } `}
              key={index}
            >
              <RxCross2
                className="float-right"
                onClick={() => handleDeleteRule(rule.id)}
              />
              <div className="grid grid-cols-4 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="rule-criteria"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {translations[langMode].criteria}
                  </label>
                  <div className="mt-2.5">
                    <HighlightedTextarea
                      value={rule.criteria}
                      onChange={(e) => handleRuleChange(e, index)}
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={() =>
                  handleUpdateChanges(
                    rule.id,
                    rule.criteria,
                  )
                }
                className="text-sm px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-600"
              >
                {translations[langMode].update}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">{translations[langMode].notcustomerule}</p>
      )}
    </>
  );
};

export default CustomVariable;
