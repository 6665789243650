import React, { useState } from "react";
import { useContext } from "react";
import AppContext from "../../../../context/AppContext";
import {  FaChartArea, FaChartBar, FaChartPie } from "react-icons/fa";
import { MdRadar } from "react-icons/md";

export default function ChartModal({ showModal, setShowModal, setChartView }) {
  const context = useContext(AppContext);
  const [selectedChart, setSelectedChart] = useState(null);
  const { setView, theme, langMode } = context;

  const translations = {
    en: {
      chartModal: "Select Chart Type",
    },
    ar: {
      chartModal: "حدد نوع الرسم البياني",
    },
  };

  const chartInfo = {
    PIECHART: {
      name: "Pie Chart",
      icon: <FaChartPie className="h-16 w-16 mb-2 text-yellow-400" />, // Use icons
      verify: "chart",
    },
    BARCHART: {
      name: "Bar Chart",
      icon: <FaChartBar className="h-16 w-16 mb-2 text-blue-800" />, // Use icons
      verify: "graph",
    },
    AREACHART: {
      name: "Area Chart",
      icon: <FaChartArea className="h-16 w-16 mb-2 text-green-500" />, // Use icons
      verify: "area",
    },
    RADARCHART: {
      name: "Radar Chart",
      icon: <MdRadar className="h-16 w-16 mb-2 text-black-500" />, // Use icons
      verify: "radar",
    },
  };

  const handleChangeView = (verify) => {
    setView(verify);
    setChartView(verify);
    setShowModal(false);
    setSelectedChart(verify);
  };

  return (
    <>
      {showModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-black bg-opacity-50 outline-none">
          <div className="relative w-[500px] max-w-2xl mx-auto z-50 bg-black bg-opacity-50">
            <div
              className={`relative rounded-lg shadow-lg z-50 bg-white ${
                theme === "dark"
                  ? "dark-mode border"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <div className="flex items-center justify-between p-4 border-b">
                <h3
                  className={`text-md font-semibold ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : "text-gray-700"
                  }`}
                >
                  {translations[langMode].chartModal}
                </h3>
                <button
                  className="text-gray-500 hover:text-red-600 transition-colors"
                  onClick={() => setShowModal(false)}
                >
                  <span className="text-2xl">×</span>
                </button>
              </div>
              <div className="px-5 py-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                  {Object.entries(chartInfo).map(
                    ([key, { name, icon, verify }]) => (
                      <div
                        key={key}
                        className={`cursor-pointer flex w-[220px] gap-4 items-center bg-white border-2 rounded-lg p-2 shadow-md hover:shadow-lg transition-shadow duration-300 ${
                          selectedChart === verify
                            ? "border-blue-600 shadow-lg"
                            : "border-gray-200 hover:border-blue-300"
                        } ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                        onClick={() => handleChangeView(verify)}
                      >
                        {icon} {/* Display icon */}
                        <span
                          className={`text-md font-medium text-nowrap ${
                            selectedChart === verify
                              ? "text-blue-500"
                              : "text-gray-700"
                          } ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : ""
                          }`}
                        >
                          {name}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
