import React, { useState, useContext, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import UserToolbar from "../../components/users/UserToolbar";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { IoEllipsisVerticalCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { fetchUserList, updateUserStatus } from "../../service/User";
import { RxCross2 } from "react-icons/rx";
import { Spinner } from "../../Spinner";
import translations from "../../components/common/Translations";

const UserList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [userList, setUerList] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchArecodeTerm, setSearchArecodeTerm] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;
  const { theme, selectedOrgId, langMode ,userType} = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const Popup = ({ onClose, onEdit, position, userId }) => {
    const adjustedPosition = {
      x: position.x - 80,
      y: position.y,
    };
    return (
      <div
        className="fixed bg-opacity-50 flex items-center justify-center top-2"
        style={{
          top: adjustedPosition.y,
          left: adjustedPosition.x < 0 ? 0 : adjustedPosition.x,
          width: "100px",
        }}
      >
       <div
          className={`bg-white rounded p-3 shadow-lg relative ${
            theme === "dark"
              ? "dark-mode border "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
          style={{ width: "80%" }}
        >
          <button
            className="absolute top-0 right-0 p-1 text-sm"
            onClick={onClose}
          >
            <RxCross2 />
          </button>
          <button
            className={`text-black px-2 text-sm py-1 rounded ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
            onClick={() => onEdit(userId)}
          >
            {translations[langMode].edit}
          </button>
        </div>
      </div>
    );
  };

  const handleEditPopup = (userId, position) => {
    setEditUserId(userId);
    setPopupPosition(position);
    setShowEdit(true);
  };

  const closeEditPopup = () => {
    setShowEdit(false);
    setEditUserId(null);
  };
  const editInfo = (userId) => {
    const updateUserUrl = `/add-user/${userId}`;
    navigate(updateUserUrl);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(0);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    fetchData(0, pageSize, filters, term);
  };
  const handleAreacodeSearch = (areacodeterm) => {
    setSearchArecodeTerm(areacodeterm);
    fetchData(0, pageSize, filters, areacodeterm);
  };

  useEffect(() => {}, [currentPage]);

  const tableHeaders = [
    "User Id",
    // "Area Code",
    "User Type",
    "User Name",
    "Email",
    "Phone",
    ...(userType === "super_admin" ? ["Action"] : []),
  ];

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (selectedOrgId) {
        setPageSize(5); // Ensure the page size is reset to 5
        setCurrentPage(0); // Reset to page 0
        fetchData(0, 5, filters, selectedOrgId); // Fetch data with new settings
    }
}, [selectedOrgId, filters]);


  useEffect(() => {
    fetchData(
      currentPage,
      pageSize,
      filters,
      searchTerm,
      searchArecodeTerm,
      selectedOrgId
    );
  }, [
    currentPage,
    pageSize,
    filters,
    searchTerm,
    searchArecodeTerm,
    selectedOrgId,
  ]);

  const fetchData = async (
    currentPage,
    pageSize,
    filters,
    searchTerm,
    searchAreacodeTerm,
    selectedOrgId
  ) => {
    try {
      setLoading(true);
      const data = await fetchUserList(
        currentPage,
        pageSize,
        filters,
        searchTerm,
        searchAreacodeTerm,
        selectedOrgId
      );
      setUerList(data.userList);
      setTotalValue(data.totalNumberOfUsers);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch users");
      setLoading(false);
    }
  };
  useEffect(() => {}, [userList]);

  const [userStatusOptions] = useState([
    { label: "Enable", value: "Enable" },
    { label: "Disable", value: "Disable" },
  ]);

  const handleActionChange = async (selectedAction, userId) => {
    const updatedStatus = selectedAction === "Enable" ? true : false;
    try {
      const success = await updateUserStatus(userId, updatedStatus);
      if (success) {
        const updatedUserData = userList.map((user) =>
          user.userId === userId ? { ...user, userStatus: updatedStatus } : user
        );
        setUerList(updatedUserData);
        toast.success("User status updated successfully");
      } else {
        throw new Error("Failed to update user status");
      }
    } catch (error) {
      toast.error("Failed to update user status");
    }
  };

  return (
    <>
      <div
        className={`py-5 w-full h-full   ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {" "}
        <h4
          className={`${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          } mt-2 text-sm uppercase font-semibold text-gray-800 `}
        >
          {translations[langMode].userlist}
        </h4>
        <div
          className={`${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          } w-full sm:w-auto py-5 mt-5 px-4 bg-white  sm:rounded-lg`}
        >
          <UserToolbar
            onFilterChange={handleFilterChange}
            onSearch={handleSearch}
            onAreaSearch={handleAreacodeSearch}
          />
 <div className=" flex justify-between items-center mt-3">
            <div className="text-sm text-gray-400 flex items-center gap-2">
              <label htmlFor="pageSize">Rows per page:</label>
              <select
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value={5}>5</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>
            </div>
            <div>
              <p className="text-sm text-gray-400 flex items-center gap-4">
                {`${startIndex + 1}-${Math.min(
                  endIndex,
                  totalItems
                )} of ${totalItems} items`}
                <IoIosArrowBack
                  onClick={goToPreviousPage}
                  disabled={currentPage === 0}
                />
                <IoIosArrowForward
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                />
              </p>
            </div>
          </div>
          <div className="overflow-x-auto ">
            <table
              className={`min-w-full text-sm text-left rtl:text-right py-5 overflow-y-auto mt-3 h-[300px] ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <thead
                className={`text-md font-medium  text-gray-800 text-left rtl:text-right ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <tr
                  className={`${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  } bg-gray-100`}
                >
                  <th scope="col"></th>
                  {tableHeaders.map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider whitespace-nowrap"
                    >
                      {header}
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              {loading && <Spinner />}
              <tbody>
                {userList && userList.length > 0 ? (
                  userList?.map((user, index) => (
                    <tr
                      className={`bg-white text-xs border-b   ${
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      <td className="w-2 p-2"></td>
                      <td className="px-6 py-2">
                        <div className="flex items-center">
                          <div
                            className={`h-2.5 w-2.5 rounded-full ${
                              user.userStatus ? "bg-green-500" : "bg-red-500"
                            } me-2`}
                          ></div>
                          {user.userId}
                        </div>
                      </td>
                      {/* <td className="px-6 py-2 whitespace-nowrap">
                        {user.areaCode}
                      </td> */}
                      <td className="px-6 py-2 whitespace-nowrap">
                        <span
                          className={`text-xs font-medium px-2.5 py-0.5 rounded-full ${
                            user.userType === "field_agent"
                              ? "bg-yellow-500 bg-opacity-30 text-yellow-500"
                              : user.userType === "super_admin"
                              ? "bg-red-400 bg-opacity-30 text-red-500"
                              : user.userType === "office_user"
                              ? "bg-purple-400 bg-opacity-30 text-purple-500"
                              : "bg-blue-400 bg-opacity-30 text-blue-500"
                          }`}
                        >
                          {user.userType}
                        </span>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        {user.name}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        {user.email}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        {user.phone}
                      </td>
                      {userType === "super_admin" && (
                        <td className="px-6 py-2">
                          <select
                            className={`p-1 text-xs border rounded ${
                              theme === "dark"
                                ? "dark-mode"
                                : theme === "high-contrast"
                                ? "high-contrast"
                                : ""
                            }`}
                            value={user.userStatus ? "Enable" : "Disable"}
                            onChange={(e) =>
                              handleActionChange(e.target.value, user.userId)
                            }
                          >
                            {userStatusOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                className={`text-xs ${
                                  theme === "dark"
                                    ? "dark-mode"
                                    : theme === "high-contrast"
                                    ? "high-contrast"
                                    : ""
                                }`}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>
                      )}
                      <td className="px-6 py-2">
                        <div className="flex items-center">
                          <IoEllipsisVerticalCircle
                            size={20}
                            className="text-blue-600 hover:text-blue-800 cursor-pointer"
                            onClick={(event) =>
                              handleEditPopup(user.userId, {
                                x: event.clientX,
                                y: event.clientY,
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={tableHeaders.length + 1}
                      className="text-center"
                    >
                      No users found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
         
        </div>
      </div>
      {showEdit && (
        <Popup
          onClose={closeEditPopup}
          onEdit={editInfo}
          position={popupPosition}
          userId={editUserId}
        />
      )}
    </>
  );
};
export default UserList;
