// api.js

import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const addGuideline = async (token, workflowId, fileType, fileUrl) => {
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/guideline/add`,
      {
        workflowId,
        fileType,
        fileUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
    
      return response.data;
    } else {
      throw new Error("Failed to upload guideline");
    }
  } catch (error) {
    console.error("Error uploading guideline:", error);
    throw error;
  }
};

export const fetchGuidelines = async (token, workflowId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}api/v1/guideline/get?workflowId=${workflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data.body.value; // Adjust according to your API response structure
    } else {
      throw new Error("Failed to fetch guidelines");
    }
  } catch (error) {
    console.error("Error fetching guidelines:", error);
    throw error;
  }
};

export const deleteGuideline = async (token, guidelineId) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}api/v1/guideline/delete?guidelineId=${guidelineId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      toast.success("file deleted")
      return true;
    } else {
      throw new Error("Failed to delete guideline");
    }
  } catch (error) {
    console.error("Error deleting guideline:", error);
    throw error;
  }
};
