import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;
export const fetchCompletedTasks = async (selectedOrgId, fromDate, toDate) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${baseURL}api/v1/task/get-user-completed-tasks?organizationId=${selectedOrgId}&startDate=${fromDate}&endDate=${toDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.body?.value) {
      return response.data.body.value;
    } else {
      console.error("Unexpected data format:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchDynamicWorkflows = async (selectedOrgId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(
      `${baseURL}api/v1/workflow/get-workflows-dashboard?organizationId=${selectedOrgId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData.body.value;
  } catch (error) {
    console.error("Error fetching workflows:", error);
    return [];
  }
};

export const fetchDashboardNames = async (idForParam, token) => {
  try {
    const response = await axios.get(
      `${baseURL}api/v1/activity-dashboard/get-dashboard-names`,
      {
        params: {
          organizationId: idForParam,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("Error fetching dashboard names:", error);
    return [];
  }
};

export const fetchWorkflows = async (idForParam, token) => {
  try {
    const response = await axios.get(
      `${baseURL}api/v1/workflow/get-workflows-dashboard`,
      {
        params: {
          organizationId: idForParam,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("Error fetching workflows:", error);
    return [];
  }
};

export const fetchOrganizations = async () => {
  try {
    const response = await axios.get(`${baseURL}api/v1/organizations`);
    return response.data;
  } catch (error) {
    console.error("Error loading organizations:", error);
    return [];
  }
};

//create dashboard

export const createDashboard = (formData, id) => {
  const token = Cookies.get("accessToken");

  return axios.post(
    `${baseURL}api/v1/activity-dashboard/create-dashboard`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchTaskPriorityCount = async (workflowId) => {
  if (!workflowId) {
    console.warn("workflowId is required to fetch task priority count");
    return {};
  }

  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(
      `${baseURL}api/v1/task/task-priority-count?workflowId=${workflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData.body.value !== "Server Error" ? jsonData.body.value : {};
  } catch (error) {
    console.error("Error fetching data:", error);
    return {};
  }
};

export const fetchTaskStatusCount = async (workflowId) => {
  if (!workflowId) {
    console.warn("workflowId is required to fetch task priority count");
    return {};
  }

  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(
      `${baseURL}api/v1/task/task-status-count?workflowId=${workflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData.body.value !== "Server Error" ? jsonData.body.value : {};
  } catch (error) {
    console.error("Error fetching data:", error);
    return {};
  }
};

export const fetchUserCount = async (organizationId) => {
  const token = Cookies.get("accessToken");
  const userType = Cookies.get("userType");

  // Construct the URL based on userType
  let url = `${baseURL}api/v1/user/user-count`;

  url += `?organizationId=${organizationId}`;

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return {};
  }
};

//activyt question
export const getFieldNames = async (dashboardNameInput) => {
  const token = Cookies.get("accessToken");

  if (!dashboardNameInput) return [];

  try {
    const response = await axios.get(
      `${baseURL}api/v1/workflow/get-field-names`,
      {
        params: {
          activityDashboardId: dashboardNameInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("Error fetching dashboard names:", error);
    return [];
  }
};

// Fetch dashboard data
export const fetchDashboardData = async (dashboardNameInput, roleInput) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/activity-dashboard/get-dashboard`,
      {
        params: {
          activityDashboardId: dashboardNameInput,
          role: roleInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching graph data:", error);
    throw error;
  }
};

export const createGraphMetric = async (
  dashboardNameInput,
  selectedChart,
  selectedFieldQuestionId,
  roleInput
) => {
  const token = Cookies.get("accessToken");
  const formData = {
    activityDashboardId: +dashboardNameInput,
    graphType: selectedChart,
    formFieldId: +selectedFieldQuestionId,
    role: roleInput,
  };

  try {
    const response = await axios.post(
      `${baseURL}api/v1/activity-dashboard/create-graph-metric`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating dashboard:", error);
    throw error;
  }
};

// Delete a dashboard
export const deleteDashboard = async (dashboardNameInput, roleInput) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/activity-dashboard/delete-dashboard`,
      {
        params: {
          activityDashboardId: dashboardNameInput,
          role: roleInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting dashboard:", error);
    throw error;
  }
};

// Delete a graph metric
export const deleteGraphMetric = async (graphMetricId, roleForDelete) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/activity-dashboard/delete-graph-metric`,
      {
        params: {
          graphMetricId: graphMetricId,
          role: roleForDelete,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting graph metric:", error);
    throw error;
  }
};
