import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { fetchAssetData, updateAssetData } from "../../service/Asset";
import { FaArrowLeft } from "react-icons/fa";
import translations from "../../components/common/Translations";

const UpdateAssrt = () => {
  const { assertId } = useParams();
  const [id, setId] = useState("");
  const [Assrtid, setAssertId] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [AssertType, setAssertType] = useState("Honeywell_Mobile");
  const [AssertName, setAssertName] = useState("");
  const [AssertBarcode, setAssertBarcode] = useState("");
  const [AssertStatus, setAssertStatus] = useState("");
  const [Description, setDescription] = useState("");
  const [photoUrl, setPhotoUrl] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const { theme, langMode } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const data = await fetchAssetData(assertId);
        if (data.header.code === 600) {
          setId(data.body.value.id);
          setAssertId(data.body.value.assertId);
          setOrganizationId(data.body.value.organizationId);
          setAssertType(data.body.value.assertType);
          setAssertName(data.body.value.assertName);
          setAssertBarcode(data.body.value.assertBarcode);
          setAssertStatus(data.body.value.assertStatus);
          setDescription(data.body.value.description);
          setPhotoUrl(data.body.value.assertImg);
          setDocumentUrl(data.body.value.assertDoc);
        } else {
          console.error("Error:", data.body.error); // Handle error response if needed
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      }
    };

    fetchAsset();
  }, [assertId]);

  const handleSelectChange = (event) => {
    setAssertType(event.target.value);
  };
  const handleAssertNameChange = (event) => {
    setAssertName(event.target.value);
  };
  const handleAssertBarcodeChange = (event) => {
    setAssertBarcode(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleAssertStatusChange = (event) => {
    setAssertStatus(event.target.value);
  };

  const handleAddAssert = async () => {
    const formData = {
      id: id,
      assertId: Assrtid,
      assertType: AssertType,
      organizationId,
      assertName: AssertName,
      assertBarcode: AssertBarcode,
      assertStatus: AssertStatus,
      assertImg: photoUrl,
      description: Description, // Ensure this matches the API endpoint
      assertDoc: documentUrl, // Ensure this matches the API endpoint
    };
    try {
      const data = await updateAssetData(formData);
      if (data.header.code === 600) {
        toast.success("Asset Update Succesfully");
        navigate("/asset");
      }
    } catch (error) {
      console.error("Failed to add vehicle:", error);
    }
  };

  const handleCancel = () => {
    setAssertType("");
    setAssertName("");
    setDescription("");
    setDocumentUrl(null);
    setPhotoUrl(null);
    setAssertBarcode("");
  };
  const handleGoBack = () => {
    navigate("/asset");
  };
  return (
    <div className=" w-full">
      <div className="flex ">
        <div
          onClick={handleGoBack}
          className={`p-2 top-30 absolute rounded-full border cursor-pointer w-8 m-3 ml-5 mt-4
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "high-contrast border border-gray-600"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }
  `}
        >
          <FaArrowLeft
            className={`h-3 w-3 ${
              theme === "dark"
                ? "text-white"
                : theme === "high-contrast"
                ? "text-black"
                : "text-gray-700"
            }`}
          />
        </div>
        <h4 className={`ml-20 mt-6 uppercase text-sm font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}>
          {translations[langMode].updateAsset}
        </h4>
      </div>
      <div className="w-full py-5 h-full">
        <div
          className={`px-5 py-5 bg-white  ${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          <div className="w-1/2 ">
            {/* <h3 className="text-sm text-gray-500 font-bold">Update Asset</h3> */}
            <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <div className="relative">
                  <input
                    type="text"
                    value={id}
                    disabled
                    className={` ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                  />
                  <label
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  >
                    Id
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="relative">
                  <input
                    type="text"
                    id="Assertid"
                    value={Assrtid}
                    readOnly
                    className={` ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                  />
                  <label
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  >
                    Assert ID
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="relative">
                  <select
                    id="vehical_dropdown"
                    value={AssertType}
                    onChange={handleSelectChange} // Update the state on selection change
                    className={`block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    <option value="Honeywell_Mobile">Honeywell_Mobile</option>
                    <option value="Electric_Meter">Electric_Meter</option>
                    <option value="Water_Meter">Water_Meter</option>
                  </select>
                  <label
                    htmlFor="vehical_dropdown"
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-6 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    Assert Type
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="relative">
                  <input
                    type="text"
                    id="assetname"
                    value={AssertName}
                    onChange={handleAssertNameChange}
                    className={` ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                  />
                  <label
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  >
                    Assert Name
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="relative">
                  <input
                    type="text"
                    id="AssertName"
                    value={AssertBarcode}
                    onChange={handleAssertBarcodeChange}
                    className={` ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                  />
                  <label
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  >
                    Assert Barcode
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="relative">
                  <input
                    type="text"
                    id="AssertStatus"
                    value={AssertStatus}
                    onChange={handleAssertStatusChange}
                    className={` ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                  />
                  <label
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  >
                    Assert Status
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <textarea
                  id="message"
                  rows="4"
                  value={Description}
                  onChange={handleDescriptionChange}
                  className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Description"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="">
            <div className="sm:col-span-4">
              <hr className="w-full border-gray-300" />
            </div>
            <div className="sm:col-span-4 pt-5 flex justify-end items-center">
              <button
                type="button"
                onClick={handleCancel}
                className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                {translations[langMode].cancle}
              </button>
              <button
                type="button"
                onClick={handleAddAssert}
                className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                {translations[langMode].Update}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAssrt;
