import React from "react";
import { TbLoader } from "react-icons/tb";

export const Spinner = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-50 bg-opacity-50">
      <TbLoader className="animate-spin text-gray-600" size={64}  style={{animation:"spins 5s linear infinite"}}/>
    </div>
  );
};
