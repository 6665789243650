import React from 'react'

const FieldDisplay = ({ field }) => {
  const renderField = () => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
      case "textarea":
      case "date":
      case "time":
      case "file":
        return (
          <input
            type={field.fieldType}
            placeholder={field.fieldName}
            className="w-[200px] p-2 border border-gray-300 rounded-md"
          />
        );
      case "textarea":
        return (
          <textarea
            type={field.fieldType}
            placeholder={field.fieldName}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        );
      case "select":
        return (
          <select className="w-full p-2 border border-gray-300 rounded-md">
            {field.allInputValues?.map((option) => (
              <option key={option.id} value={option.inputValue}>
                {option.inputValue}
              </option>
            ))}
          </select>
        );
      case "checkbox":
      case "radio":
        return (
          <div className="flex flex-col space-y-2">
            {field.allInputValues?.map((input) => (
              <label key={input.id} className="inline-flex items-center">
                <input
                  type={field.fieldType}
                  name={field.fieldName}
                  value={input.inputValue}
                  className="text-blue-600"
                />
                <span className="ml-2">{input.inputValue}</span>
              </label>
            ))}
          </div>
        );
      default:
        return (
          <input
            type="text"
            placeholder="Undefined field type"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        );
    }
  };

  return (
    <div className="mb-4">
      <h3 className="mb-1 font-semibold">{field.fieldName}</h3>
      {renderField()}
    </div>
  );
};

export default FieldDisplay;
