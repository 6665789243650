import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import AddChart from "./AddChart";
import AddDashboardModal from "./AddDashboardModal";
import {
  fetchDashboardNames,
  fetchWorkflows,
} from "../../../service/UserDashboard.js";
import { RiDashboard2Fill } from "react-icons/ri";
import Joyride from "react-joyride";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoBagHandleSharp } from "react-icons/io5";
import { CgExport } from "react-icons/cg";
import AppContext from "../../../context/AppContext.js";
import translations from "../../common/Translations.js";

const DASHBOARDS = {
  USER: "user",
  ACTIVITY: "activity",
};
const ActivityDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [selectedrole, setSelectedRole] = useState("");
  const { selectedOrgId, langMode, runTour, activityDash,userType } =
    useContext(AppContext);
  const [dashboardNames, setDashboardNames] = useState([]);
  const [dashboardNameInput, setDashboardNameInput] = useState("");
  const { theme } = useContext(AppContext);
  const [roleInput, setRoleInput] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const GoExport = () => {
    Cookies.set("viewMode", "dashboard");
    navigate("/pdf-generator");
  };
  const idForParam = userType === "super_admin" ? selectedOrgId : null;
  const handleDashboardNameChange = (event) => {
    const selectedDashboardId = event.target.value;
    const selectedRole =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-role"
      );
    setDashboardNameInput(selectedDashboardId);
    setRoleInput(selectedRole);
  };

  const handleFetchDashboardNames = () => {
    if (userType === "super_admin") {
      if (selectedOrgId) {
        fetchDashboardNames(idForParam, Cookies.get("accessToken"))
          .then((dashboardNames) => {
            setDashboardNames(dashboardNames);
          })
          .catch(() => {
            setDashboardNames([]);
          });
      }
    } else {
      fetchDashboardNames(idForParam, Cookies.get("accessToken"))
        .then((dashboardNames) => {
          setDashboardNames(dashboardNames);
        })
        .catch(() => {
          setDashboardNames([]);
        });
    }
  };

  const fetchActivityNames = async () => {
    try {
      const workflows = await fetchWorkflows(
        idForParam,
        Cookies.get("accessToken")
      );
      setData(workflows);
    } catch (error) {
      console.error("Error fetching workflows:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    if (
      !userPrivileges.includes("canCreateDashboard") &&
      userType !== "super_admin"
    ) {
      toast.error(
        " You don't have the necessary permissions to view this page."
      );
      return;
    }
    if (userType === "super_admin") {
      if (selectedOrgId) {
        setIsModalOpen(true);
        fetchActivityNames();
      } else {
        toast.warning("Please select organization");
      }
    } else {
      setIsModalOpen(true);
      fetchActivityNames();
    }
  };
  const gouserDashbord = () => {
    navigate("/dashboard");
  };
  const userPrivileges = JSON.parse(Cookies.get("userPrivileges") || "[]");
  return (
    <>
      <Joyride
        steps={activityDash}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
            zIndex: 1000,
          },
          tooltip: {
            borderRadius: "10px",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
        }}
      />
      <div
        className={`w-full ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : "bg-gray-50 "
        } `}
      >
        <div className="flex flex-wrap justify-between gap-4 py-4 w-full">
          <div className="flex">
            <button
              type="button"
              onClick={gouserDashbord}
              className={`${
                theme === "dark"
                  ? "dark-mode border hover:bg-gray-600"
                  : theme === "high-contrast"
                  ? "high-contrast border border-gray-400 hover:bg-gray-300"
                  : " "
              }  px-4 py-2 text-sm font-medium flex bg-gray-200 text-gray-600 hover:bg-gray-300 items-center text-nowrap gap-2 rounded-l-lg focus:ring-2 focus:ring-blue-700 transition-all`}
              aria-label="User Dashboard"
            >
              <FaUserCircle className="h-5 w-5" />
              {translations[langMode].userdashboard}
            </button>
            <button
              type="button"
              className={`${
                theme === "dark"
                  ? "bg-blue-800 text-white hover:bg-blue-700 border"
                  : theme === "high-contrast"
                  ? "bg-blue-800 text-white hover:bg-blue-700 border"
                  : " "
              }  px-4 py-2 text-sm font-medium flex items-center bg-blue-800 text-white hover:bg-blue-700 text-nowrap gap-2 rounded-r-lg focus:ring-2 focus:ring-blue-700 transition-all`}
              aria-label="Activity Dashboard"
            >
              <IoBagHandleSharp className="h-5 w-5" />
              {translations[langMode].activitydashboard}
            </button>
          </div>
          <button
            type="button"
            onClick={GoExport}
            className=" px-6 gap-3 h-8 items-center inline-flex text-xs text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-500 font-medium rounded-lg transition-all"
          >
            <span className="bg-white rounded-full p-1">
              <CgExport size={12} className="text-yellow-500" />
            </span>
            {translations[langMode].pdf}
          </button>
        </div>
        <div className="flex flex-wrap justify-between items-center gap-4">
          <div className="flex items-center gap-5 ml-5">
            <div className="dashboardname">
              <label
                htmlFor="organizationId"
                className={`block text-sm mb-2 font-medium text-gray-900 ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
                {translations[langMode].dashboardName}
              </label>
              <select
                id="dashboardName"
                name="dashboardName"
                value={dashboardNameInput}
                onChange={handleDashboardNameChange}
                onClick={handleFetchDashboardNames}
                className={`w-full max-w-xs border selectdashboardname border-gray-300 text-gray-900 text-sm 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 
                     ${
                       theme === "dark"
                         ? "dark-mode "
                         : theme === "high-contrast"
                         ? "high-contrast  "
                         : ""
                     }`}>
                <option value="">Select</option>
                {dashboardNames.map((dashboard) => (
                  <option
                    key={dashboard.activityDashboardId}
                    value={dashboard.activityDashboardId}
                    data-role={dashboard.role}
                  >
                    {dashboard.dashboardName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end ml-10">
            <button
              type="button"
              onClick={showModal}
              className="createdashboard text-white h-9  py-2.5 px-1.5 bg-blue-800 mt-5 gap-1 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-xs text-center inline-flex items-center"
            >
              <RiDashboard2Fill size={14} />{" "}
              {translations[langMode].addnewDashboard}
            </button>
          </div>
        </div>
        <div className="mt-5 p-5 text-center">
          {!dashboardNameInput && (
            <h2
              className={`text-sm font-semibold text-gray-900 ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              {translations[langMode].chartMessage}
            </h2>
          )}
        </div>
        {isModalOpen && (
          <AddDashboardModal
            onClose={handleCloseModal}
            organizations={organizations}
            setOrganizations={setOrganizations}
            selectedOrganizationId={selectedOrganizationId}
            setSelectedOrganizationId={setSelectedOrganizationId}
            selectedWorkflowId={selectedWorkflowId}
            setSelectedWorkflowId={setSelectedWorkflowId}
            dashboardName={dashboardName}
            setDashboardName={setDashboardName}
            selectedrole={selectedrole}
            setSelectedRole={setSelectedRole}
            selectedOrgId={selectedOrgId}
            data={data}
          />
        )}
        <AddChart
          selectedOrgId={selectedOrgId}
          dashboardNames={dashboardNames}
          setDashboardNames={setDashboardNames}
          dashboardNameInput={dashboardNameInput}
          setDashboardNameInput={setDashboardNameInput}
          roleInput={roleInput}
        />
      </div>
    </>
  );
};

export default ActivityDashboard;
