import axios from 'axios';
import Cookies from "js-cookie";
import moment from 'moment';

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;

/**
 * Create or update a protected account.
 * @param {Object} protectedAcc - The protected account data.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Object} The response data from the API.
 */
export const createProtectedAccount = async (protectedAcc, accessToken) => {
  try {
    const response = await axios.post(
      `${baseURL}api/v1/account-protection/create-or-update`,
      protectedAcc,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating protected account:', error);
    throw error;
  }
};

/**
 * Fetch all protected accounts.
 * @param {string} accessToken - The access token for authentication.
 * @param {number} page - The page number for pagination.
 * @param {number} size - The number of items per page.
 * @param {string} workflowId - The workflow ID.
 * @returns {Object} The response data from the API.
 */
export const fetchProtectedAccounts = async (accessToken, page, size, selectedOrgId) => {
  try {
    const response = await axios.get(
      `${baseURL}api/v1/account-protection/getAll`, 
      {
        params: {
          page: page,
          size: size,
          organizationId: selectedOrgId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response || !response.data || response.data.header.code !== 600) {
      throw new Error('Failed to fetch protected accounts');
    }

    return response.data.body.value;
  } catch (error) {
    console.error('Error fetching protected accounts:', error);
    throw error;
  }
};

/**
 * Fetch a particular protected account.
 * @param {string} accessToken - The access token for authentication.
 * @param {string} editUserId - The ID of the account to be edited.
 * @returns {Object} The response data from the API.
 */
export const fetchParticularAccount = async (accessToken, editUserId) => {
  try {
    const response = await axios.get(
      `${baseURL}api/v1/account-protection/get`, 
      {
        params: {
          id: editUserId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response || !response.data || response.data.header.code !== 600) {
      throw new Error('Failed to fetch protected account');
    }

    return response.data.body.value;
  } catch (error) {
    console.error('Error fetching protected account:', error);
    throw error;
  }
};

/**
 * Delete selected protected accounts.
 * @param {Array} selectedRecords - The IDs of the accounts to be deleted.
 * @returns {Object} The response data from the API.
 */
export const deleteProtectedAccounts = async (selectedRecords) => {
  const token = Cookies.get("accessToken");  
  try {
    const response = await axios.delete(`${baseURL}api/v1/account-protection/delete`, {
      data: selectedRecords,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting protected accounts:', error);
    throw error;
  }
};

/**
 * Export a report of protected accounts.
 * @param {string} selectedOrgId - The selected workflow ID.
 * @param {string} startDate - The start date for the report.
 * @param {string} endDate - The end date for the report.
 * @returns {Object} The response data from the API.
 */
export const fetchExportProtected = async (workflowId, selectedOrgId, startDate, endDate ) => {
  const token = Cookies.get("accessToken");

  const formattedStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
  const formattedEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");

  try {
    const response = await axios.get(
      `${baseURL}api/v1/account-protection/generate-report`,
      {
        params: {
          workflowId: workflowId,
          organizationId:selectedOrgId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
         
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error exporting report:', error);
    throw error;
  }
};
