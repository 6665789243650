import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;
export const fetchUserList = async (
  currentPage,
  pageSize,
  filters,
  searchTerm,
  searchAreacodeTerm,
  selectedOrgId
) => {
  const token = Cookies.get("accessToken");
  const userType = Cookies.get("userType");
  let userTypeFilter = "";
  if (filters.userType && filters.userType !== "Everything") {
    userTypeFilter = `&userType=${filters.userType}`;
  }

  const searchQuery = searchTerm
    ? `&userName=${encodeURIComponent(searchTerm)}`
    : "";
  const areaCodeQuery = searchAreacodeTerm
    ? `&areaCode=${encodeURIComponent(searchAreacodeTerm)}`
    : "";

  let organizationQuery = "";
  if (userType === "super_admin" && selectedOrgId) {
    organizationQuery = `&organizationId=${selectedOrgId}`;
  }

  try {
    const response = await axios.get(
      `${baseURL}api/v1/user/get-users?pageNo=${currentPage}&size=${pageSize}${organizationQuery}${areaCodeQuery}${userTypeFilter}${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const updateUserStatus = async (userId, status) => {
  const token = Cookies.get("accessToken");
  const userIdList = [{ userId, status }];
  try {
    const response = await axios.post(
      `${baseURL}api/v1/user/update-status`,
      { userIdList },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.header.code === 600) {
      return true;
    } else {
      throw new Error("Failed to update user status");
    }
  } catch (error) {
    console.error("Error updating user status:", error.message);
    throw error;
  }
};

export const createOrganization = async (orgName, logoUrl) => {
  const accessToken = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${baseURL}api/v1/organization/create`,
      { organizationName: orgName, logo: logoUrl },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating organization:", error);
    throw error;
  }
};

export const fetchOrganizations = async (accessToken) => {
  try {
    const response = await axios.get(
      `${baseURL}api/v1/organization/get-list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response || !response.data || response.data.header.code !== 600) {
      throw new Error("Failed to fetch organizations");
    }
    return response.data.body.value;
  } catch (error) {
    console.error("Error fetching organizations:", error);
    throw error;
  }
};



export const getUserInfoAPI = (userId) => {
  const token = Cookies.get("accessToken");
  return axios.get(
    `${baseURL}api/v1/user/user-detail`,
    {
      params: { userId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const createUser = async (userData, token) => {
  try {
    const response = await axios.post(
      `${baseURL}api/v1/user/create`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};
export const updateUser = async (userData, token) => {
  try {
    const response = await axios.post(
      `${baseURL}api/v1/user/create`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
  }
};

export const updateProfileInfo = async (userId, name, email, phone) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${baseURL}api/v1/user/update-profile`,
      {
        userId,
        name,
        email,
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
//get userinfo
export const getUserInfo = async () => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${baseURL}api/v1/user/user-detail`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

//upload profile picture
export const updateProfilePicture = async (url) => {
  const token = Cookies.get("accessToken");

  try {
    const userId = Cookies.get("userId");
    const response = await axios.post(
      `${baseURL}api/v1/user/profile-pic`,
      {
        userId,
        profilePic: url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    let profilePic = Cookies.get("profilePic");

    if (profilePic) {
      Cookies.remove("profilePic");
    }

    Cookies.set("profilePic", url);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating profile picture:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

//get list of orgnization
export const fetchOrgnizationData = async (currentPage, pageSize) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/organization/list/get-all?page=${currentPage}&size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

//get org info by id
export const fetchOrganizationById = async (orgnizationId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/organization/get?id=${orgnizationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw error;
  }
};
//update org
export const UpdateOrganizationData = async (
  editOrganizationname,
  newLogoUrl,
  orgnizationId
) => {
  const accessToken = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${baseURL}api/v1/organization/create`,
      {
        organizationId: orgnizationId,
        organizationName: editOrganizationname,
        logo: newLogoUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating organization:", error);
    throw error;
  }
};
