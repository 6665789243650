import React from 'react';
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import { RxCross2 } from 'react-icons/rx';
export default function Modal({
  open,
  setOpen,
  value,
  setModalValue,
  clone,
  getFormData,
  fetchData,
  loadData,
  accountFetch,
  accountNo,
  pdfLink,
}) {
  const cancelButtonRef = useRef(null);
  const handleClone = async () => {
    try {
      const token = Cookies.get("accessToken");
      const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/create-workflow`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(getFormData()),
      });
      const jsonData = await response.json();
      if (jsonData.header.code === 600) {
        loadData()
      } else {
      }
    } catch (err) {
     }
    setOpen(false);
  };

  const importfile = () => {
    setModalValue("");
    setOpen(false);
  };

  const handlePopupClose = () => {
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-10 ${pdfLink ? 'w-[80%]' : 'sm:max-w-lg'}`}>
                {accountFetch ? (
                  <>
                    <div className="justify-between items-center flex m-5 font-bold text-base">
                      <label className="ml-48">Account No</label>
                      <span className="cursor-pointer" onClick={handlePopupClose}>
                        <RxCross2/>
                      </span>
                    </div>
                    <div className="flex px-36 gap-16 h-[500px] overflow-auto my-2">
                      <div className="w-1/2">
                        {accountNo
                          .filter((_, index) => index % 2 === 0)
                          .map((number, index) => (
                            <div key={index} className="py-2">
                              {number}
                            </div>
                          ))}
                      </div>
                      <div className="w-1/2">
                        {accountNo
                          .filter((_, index) => index % 2 !== 0)
                          .map((number, index) => (
                            <div key={index} className="py-2">
                              {number}
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : pdfLink ? (
                  <>
                    <iframe
                      src={pdfLink ? `${pdfLink}` : ""}
                      width="100%"
                      height="550px"
                      title="PDF Viewer"
                    />
                  </>
                ) : (
                  <>
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {clone ? "Are you sure you want to clone?" : "Please check"}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">{value}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          if (clone) {
                            handleClone();
                          } else {
                            importfile();
                          }
                        }}
                        ref={cancelButtonRef}
                      >
                        OK
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
