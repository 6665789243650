import React, { useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import CommonInputField from "../common/CommonInputField";
import { createProtectedAccount } from "../../service/Security";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import translations from "../common/Translations";

export const CreateAccount = () => {
  const [accountNo, setAccountNo] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isPermanentProtection, setIsPermanentProtection] = useState(false);
  const [isAccountProtectionForAll, setIsAccountProtectionForAll] =
    useState(false);
  const [workflowIds, setWorkflowIds] = useState([]);
  const [remarks, setRemarks] = useState("");
  const { particularAccount, isEditRequest, selectedOrgId, theme, langMode } =
    useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isEditRequest && particularAccount) {
      setAccountNo(particularAccount.accountNo || "");
      setIsPermanentProtection(particularAccount.permanent || false);
      setFromDate(particularAccount.fromDate || "");
      setToDate(particularAccount.toDate || "");
      setRemarks(particularAccount.remarks || "");
      setIsAccountProtectionForAll(
        particularAccount.isAccountProtectionForAll || false
      );
      setWorkflowIds(particularAccount.workflowIds || []);
    }
  }, [isEditRequest, particularAccount]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const addProtectedaccount = async () => {
    if (!remarks) {
      toast.error("Add remark !");
      return;
    }
    const token = Cookies.get("accessToken");
    const proctedAcc = {
      id: particularAccount.id ? particularAccount.id : null,
      accountNo: accountNo,
      permanent: isPermanentProtection,
      fromDate: isPermanentProtection ? null : formatDate(fromDate),
      toDate: isPermanentProtection ? null : formatDate(toDate),
      editRequest: isEditRequest ? isEditRequest : false,
      organizationId: selectedOrgId,
      remarks: remarks,
    };

    try {
      const data = await createProtectedAccount(proctedAcc, token);
      if (data.header.code === 600) {
        if (!isEditRequest) {
          toast.success("Protected Account Created");
          setAccountNo("");
          setFromDate("");
          setToDate("");
          setIsPermanentProtection(false);
          setIsAccountProtectionForAll(false);
          setWorkflowIds([]);
          setRemarks(""); // Reset remarks
          navigate("/security");
        } else {
          toast.success("Protected Account Updated !");
          navigate("/security");
        }
      } else {
        toast.error(data.body.value);
      }
    } catch (error) {
      toast.error("Error creating protected account");
    }
  };

  const handleGoBack = () => {
    navigate("/security");
  };
  return (
    <>
      <div className="flex">
        <div
          onClick={handleGoBack}
          className="p-2 absolute top-30  rounded-full border border-gray-400 hover:bg-gray-200 cursor-pointer w-8 m-3"
        >
          <FaArrowLeft className="h-3 w-3" />
        </div>
        <h4 className={`${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          } ml-16 mt-5 uppercase text-sm font-semibold text-gray-800`}>
          {isEditRequest
            ? translations[langMode].editacc
            : translations[langMode].createacc}
        </h4>
      </div>
      <div
        className={`${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        } w-full py-3`}>
        <div
          className={`bg-white py-6 mt-6 rounded-lg shadow-lg ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}>
          <div className="w-full sm:w-3/4 md:w-2/3 mx-auto">
            <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-5">
              <div className="w-full">
                <CommonInputField
                  label={translations[langMode].accno}
                  value={
                    accountNo
                      ? accountNo
                      : particularAccount.accountNo
                      ? particularAccount.accountNo
                      : ""
                  }
                  onChange={(e) => setAccountNo(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {translations[langMode].remark}{" "}
                  <span className="text-red-500">*</span>
                </label>
                <textarea
                  className={`form-textarea required:border border-gray-300 dark:border-gray-600 rounded-md w-full h-24 mt-1 px-3 py-2 text-gray-700 dark:text-gray-300 
      ${
        !remarks
          ? "border-red-500"
          : ""
      }
      ${
        theme === "dark"
          ? "dark-mode "
          : theme === "high-contrast"
          ? "high-contrast"
          : ""
      }`}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder={translations[langMode].enterremark}
                />
                {!remarks && (
                  <p className="text-red-500 text-sm mt-1">
                    Remarks are required.
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    className="rounded text-blue-600 focus:ring-blue-500"
                    checked={
                      isPermanentProtection
                        ? isPermanentProtection
                        : particularAccount.permanent
                        ? particularAccount.permanent
                        : false
                    }
                    onChange={(e) => setIsPermanentProtection(e.target.checked)}
                  />
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    {translations[langMode].permanent}
                  </span>
                </label>
                {!isPermanentProtection && (
                  <div className="flex space-x-4">
                    <input
                      type="text"
                      className={`form-input border border-gray-300 dark:border-gray-600 rounded-md w-36 h-10 px-3 text-sm dark:bg-gray-800 dark:text-gray-300 ${
                        theme === "dark" ? "dark-mode" : ""
                      }`}
                      value={fromDate}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.target.type = "text";
                        }
                      }}
                      onChange={(e) => setFromDate(e.target.value)}
                      placeholder={translations[langMode].fromdate}
                    />
                    <input
                      type="text"
                      className={`form-input border border-gray-300 dark:border-gray-600 rounded-md w-36 h-10 px-3 text-sm dark:bg-gray-800 dark:text-gray-300 ${
                        theme === "dark" ? "dark-mode" : ""
                      }`}
                      value={toDate}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.target.type = "text";
                        }
                      }}
                      onChange={(e) => setToDate(e.target.value)}
                      placeholder={translations[langMode].todate}
                    />
                  </div>
                )}
              </div>
            </div>
            <hr className="my-5 border-gray-300 dark:border-gray-600" />
            <div className="flex justify-end items-center">
              <button
                type="button"
                className="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
              >
                {translations[langMode].cancel}
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm"
                onClick={addProtectedaccount}
              >
                {isEditRequest
                  ? translations[langMode].update
                  : translations[langMode].save}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
