import React, { useState, useEffect, useCallback, useContext } from "react";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { fetchTaskForm, saveTask, fetchFieldsValue } from "../../service/Task";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import translations from "../../components/common/Translations";

const CreateTask = () => {
  const [accountNo, setAccountNo] = useState("");
  const [taskData, setTaskData] = useState({});
  const [inputFields, setInputFields] = useState({
    mandatory: [],
    nonMandatory: [],
  });
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const context = useContext(AppContext);
  const { workflowId, theme, langMode, selectedOrgId } = context;
  const navigate = useNavigate();

  const handleGoBack = () => {
    const navigationPath =
      Cookies.get("viewmode") === "map" ? "/maptask" : "/task-manage";
    navigate(navigationPath);
    Cookies.remove("viewmode");
  };

  const handleActivityChange = async () => {
    try {
      const responseData = await fetchTaskForm(workflowId);
      const responseValue = responseData.body.value;

      if (responseValue) {
        const { mandatoryFields, notMandatoryFields } = responseValue;
        setInputFields({
          mandatory: mandatoryFields || [],
          nonMandatory: notMandatoryFields || [],
        });

        const accountField = (mandatoryFields || []).find(
          (field) => field.fieldName === "Account No."
        );
        if (accountField) {
          setAccountNo(taskData[accountField.fieldName] || "");
        }
      } else {
        console.error("API response data is invalid:", responseValue);
      }

      if (responseData.header.code === 607) {
        toast.error("No data available");
        setInputFields({ mandatory: [], nonMandatory: [] });
      }
    } catch (error) {
      console.error("Error in handleActivityChange:", error);
    }
  };

  useEffect(() => {
    handleActivityChange();
  }, [workflowId]);

  const handleInputChange = (fieldName, value) => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      [fieldName]: value,
    }));
    if (fieldName === "Account No.") {
      setAccountNo(value);
    }
  };

  const handleSaveTask = async () => {
    if (
      inputFields.mandatory.length === 0 &&
      inputFields.nonMandatory.length === 0
    ) {
      toast.error(translations[langMode].noFieldsAvailable);
    } else {
      try {
        const response = await saveTask(workflowId, taskData, accountNo);
        if (response.header.code === 600) {
          toast.success("Task Created Successfully");
          setTaskData({});
          setInputFields([]);
          setAccountNo("");
          handleGoBack();
        } else {
          toast.error(response.body.value);
        }
      } catch (error) {
        console.error("Error in handleSaveTask:", error);
      }
    }
  };

  return (
    <div className="w-full">
      <div className="flex gap-3 mt-2">
        <div
          onClick={handleGoBack}
          className={`p-2 rounded-full border cursor-pointer w-8 
            ${
              theme === "dark"
                ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
                : ""
            }
            ${
              theme === "high-contrast"
                ? "high-contrast border border-gray-600"
                : ""
            }
            ${
              theme !== "dark" && theme !== "high-contrast"
                ? "border-gray-400 hover:bg-gray-200"
                : ""
            }`}
        >
          <FaArrowLeft
            className={`h-3 w-3 ${
              theme === "dark"
                ? "text-white"
                : theme === "high-contrast"
                ? "text-black"
                : "text-gray-700"
            }`}
          />
        </div>
        <h4
          className={` mt-1 uppercase text-sm font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          {translations[langMode].createnewtask}
        </h4>
      </div>
      <div
        className={`px-5 py-2 mt-2 bg-white  ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {!workflowId &&
        inputFields.mandatory.length === 0 &&
        inputFields.nonMandatory.length === 0 ? (
          <p className="text-red-500 font-semibold mt-10 text-center">
            {translations[langMode].selectMsg}
          </p>
        ) : inputFields.mandatory.length === 0 &&
          inputFields.nonMandatory.length === 0 ? (
          <p className="text-red-500 font-semibold mt-10 text-center">
           This activity does not have fields.
          </p>
        ) : (
          <div className="">
            <h3 className="text-md font-bold">Mandatory Fields</h3>
            <div className="grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-8 sm:grid-cols-4 py-2">
              {inputFields.mandatory.map((field, index) => (
                <div className="sm:col-span-2" key={`mandatory_${index}`}>
                  <div className="relative">
                    <label
                      htmlFor={`input_${field.taskDetailsMetadataId}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {field.fieldName}
                    </label>
                    <input
                      id={`input_${field.taskDetailsMetadataId}`}
                      type={
                        field.fieldType === "LONG" ||
                        field.fieldType === "DOUBLE"
                          ? "number"
                          : "text"
                      }
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={taskData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleInputChange(field.fieldName, e.target.value)
                      }
                      required
                    />
                    {errors[field.fieldName] && (
                      <p className="text-red-500 text-sm">
                        {errors[field.fieldName]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <h3 className="text-md font-bold mt-5">Non-Mandatory Fields</h3>
            <div className="grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-8 sm:grid-cols-4 py-2">
              {inputFields.nonMandatory.map((field, index) => (
                <div className="sm:col-span-2" key={`nonMandatory_${index}`}>
                  <div className="relative">
                    <label
                      htmlFor={`input_${field.taskDetailsMetadataId}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {field.fieldName}
                    </label>
                    <input
                      id={`input_${field.taskDetailsMetadataId}`}
                      type={field.fieldType === "DOUBLE" ? "number" : "text"}
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={taskData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleInputChange(field.fieldName, e.target.value)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div>
          <div className="pt-5 flex justify-end items-center">
            <button
              type="button"
              onClick={handleGoBack}
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              {translations[langMode].cancel}
            </button>
            <button
              type="button"
              onClick={handleSaveTask}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {translations[langMode].create}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTask;
