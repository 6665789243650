import React, { useEffect } from "react";
import {
  FaRegClone,
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import { MdCheck } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import ValidationPreview from "./ValidationPreview";

const FieldDetails = ({
  preview,
  theme,
  isEdit,
  isClone,
  langMode,
  handleCloneElement,
  handleRemoveElement,
  handleInputChange,
  handleToggleChange,
  pdfMandatory,
  handlePdfInput,
  addPdffield,
  pdfInput,
  showInput,
  reportMandatory,
  reportField,
  reportInput,
  handleReportInput,
  addReportfield,
  Translations,
  shouldRenderSelectOption,
  groupedRadioButtons,
  collapse,
  collapsedGroups,
  toggleCollapse,
  selectedRadioId,
  handleRadioBtnBelongs,
  setSelectedRadioId,
  shouldRenderSelectOptionEdit,
  isEditShow,
  setIsEditShow,
  radioButtons,
  tempEditValues,
  setTempEditValues,
  handleRadioOptions,
  handleRadioDeleteOptions,
  isNewFieldDragged,
  displaySelectedValue,
  question,
  inputValues,
  tempInputValue,
  setTempInputValue,
  handleAddInput,
  handleRemoveInput,
  toast,
  setCurrentValidation,
  setValidationModal,
  setValidationId,
  validationId,
  validationFormValues,
  setIsChecked,
  getHeadline,
  reporticon,
  setReportIcon,
  pdficon,
  setPdfIcon,
}) => {
  return (
    <div
      className={`block max-w-sm p-3 mt-2 bg-white border border-gray-300 rounded-md shadow-lg  transition-all duration-300 ${
        theme === "dark"
          ? "dark-mode "
          : theme === "high-contrast"
          ? "high-contrast"
          : ""
      }`}
    >
      <div className="flex justify-end mb-4">
        <div className="flex justify-end space-x-2">
          <div className="relative group">
            <FaRegClone
              onClick={() => {
                handleCloneElement(preview.cloneName, preview.fieldName);
              }}
              style={{ cursor: "pointer" }}
              className="mr-5"
            />
            <div
              role="tooltip"
              className="absolute text-nowrap right-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
            >
              Clone
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
          <div className="relative group">
            <IoMdClose
              onClick={() => {
                handleRemoveElement(preview.uniqueKey, preview.id);
              }}
              style={{ cursor: "pointer" }}
            />
            <div
              role="tooltip"
              className="absolute text-nowrap right-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
            >
              Delete Field
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
        </div>
      </div>
      <div key={preview.uniqueKey} className="mb-6">
        <label
          htmlFor={`input-${preview.uniqueKey}`}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
        ></label>
        <input
          type="text"
          id={`input-${preview.uniqueKey}`}
          defaultValue={preview.fieldName || "Default Text"}
          className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md
                           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                             dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                               theme === "dark"
                                 ? "dark-mode "
                                 : theme === "high-contrast"
                                 ? "high-contrast"
                                 : ""
                             }`}
          onChange={(event) => handleInputChange(event, preview.uniqueKey)}
        />
        <ValidationPreview
          setCurrentValidation={setCurrentValidation}
          setValidationModal={setValidationModal}
          setValidationId={setValidationId}
          previewKey={preview.uniqueKey}
          validationId={validationId}
          validationFormValues={validationFormValues}
        />
        <div className="border rounded-md border-blue-300 p-2 mt-2">
          {isEdit ? (
            <>
              <div className="flex lg:flex-row flex-col justify-center  lg:items-center  lg:justify-between">
                <div className="relative inline-block w-10 mr-2 align-middle select-none my-1">
                  <label className=" text-xs font-semibold whitespace-nowrap">
                    {Translations[langMode].mandatory}
                  </label>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={preview.isMandatory}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) =>
                      handleToggleChange(event, preview.uniqueKey)
                    }
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div className="relative inline-block w-10 mr-2 align-middle select-none">
                  <label className=" text-xs font-semibold whitespace-nowrap ">
                    {Translations[langMode].excel}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={preview.excelReportName && showInput}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) => pdfMandatory(event, preview.uniqueKey)}
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded
                                 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>
              {showInput && (
                <div className="flex justify-start items-center py-3 space-x-2">
                  <input
                    type="text"
                    placeholder="Enter Excel Name.."
                    className={`bg-white border border-gray-300 text-gray-900 text-sm 
                                  rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full 
                                  p-2.5 transition duration-300 ease-in-out transform hover:scale-105  
                                   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                     theme === "dark"
                                       ? "dark-mode "
                                       : theme === "high-contrast"
                                       ? "high-contrast"
                                       : ""
                                   }`}
                    value={
                      pdfInput !== undefined
                        ? pdfInput
                        : preview.excelReportName
                    } // Explicitly check state
                    onChange={handlePdfInput}
                  />
                  <GiCheckMark
                    onClick={() => addPdffield(preview.uniqueKey)}
                    className={`cursor-pointer transition duration-300 ease-in-out transform hover:scale-110  ${
                      pdfInput || preview.excelReportName
                        ? "text-green-500 hover:text-green-700"
                        : "text-red-500 hover:text-red-700"
                    }`}
                    size={20}
                  />
                </div>
              )}
              <div>
                <div className="relative inline-block w-10 mr-2 align-middle select-none">
                  <label className="font-semibold whitespace-nowrap  text-xs">
                    {Translations[langMode].pdf}
                  </label>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={reportField}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) =>
                      reportMandatory(event, preview.uniqueKey)
                    }
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                {reportField && (
                  <div className="flex justify-start items-center py-3 space-x-2">
                    <input
                      type="text"
                      placeholder="Enter PDF Name..."
                      className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md
                                   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 transition 
                                   duration-300 ease-in-out transform hover:scale-105   dark:focus:ring-blue-500
                                    dark:focus:border-blue-500  ${
                                      theme === "dark"
                                        ? "dark-mode "
                                        : theme === "high-contrast"
                                        ? "high-contrast"
                                        : ""
                                    }`}
                      value={
                        reportInput !== undefined
                          ? reportInput
                          : preview.reportName
                      } // Explicitly check state
                      onChange={handleReportInput}
                    />
                    <GiCheckMark
                      onClick={() => addReportfield(preview.uniqueKey)}
                      className={`cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 ${
                        reportInput || preview.reportName
                          ? "text-green-500 hover:text-green-700"
                          : "text-red-500 hover:text-red-700"
                      }`}
                      size={20}
                    />
                  </div>
                )}
              </div>
            </>
          ) : isClone ? (
            <>
              <div className="flex lg:flex-row flex-col justify-center  lg:items-center  lg:justify-between">
                <div className="relative inline-block w-10 mr-2 align-middle select-none my-3">
                  <label className="font-semibold whitespace-nowrap  text-xs">
                    {Translations[langMode].mandatory}
                  </label>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={preview.isMandatory}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) =>
                      handleToggleChange(event, preview.uniqueKey)
                    }
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div className="relative inline-block w-10 mr-2 align-middle select-none">
                  <label className="font-semibold whitespace-nowrap  text-xs">
                    {Translations[langMode].excel}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={showInput}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) => pdfMandatory(event, preview.uniqueKey)}
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>
              {showInput && (
                <div className="flex justify-start items-center py-3 space-x-2">
                  <input
                    type="text"
                    placeholder="Enter Excel Name.."
                    className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md
                                 focus:ring-blue-500 focus:border-blue-500 block w-full 
                                 p-2.5 transition duration-300 ease-in-out transform hover:scale-105 
                                   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                     theme === "dark"
                                       ? "dark-mode "
                                       : theme === "high-contrast"
                                       ? "high-contrast"
                                       : ""
                                   }`}
                    value={
                      pdfInput !== undefined
                        ? pdfInput
                        : preview.excelReportName
                    } // Explicitly check state
                    onChange={handlePdfInput}
                  />
                  <GiCheckMark
                    onClick={() => addPdffield(preview.uniqueKey)}
                    className={`cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 ${
                      pdfInput || preview.excelReportName
                        ? "text-green-500 hover:text-green-700"
                        : "text-red-500 hover:text-red-700"
                    }`}
                    size={20}
                  />
                </div>
              )}
              <div>
                <div className="relative inline-block w-10 mr-2 align-middle select-none">
                  <label className="font-semibold whitespace-nowrap  text-xs">
                    {Translations[langMode].pdf}
                  </label>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={reportField}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) =>
                      reportMandatory(event, preview.uniqueKey)
                    }
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                {reportField && (
                  <div className="flex justify-start items-center py-3 space-x-2">
                    <input
                      type="text"
                      placeholder="Enter PDF Name..."
                      className={`bg-white border border-gray-300 text-gray-900 text-sm 
                                  rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                  transition duration-300 ease-in-out transform hover:scale-105  
                                   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                     theme === "dark"
                                       ? "dark-mode "
                                       : theme === "high-contrast"
                                       ? "high-contrast"
                                       : ""
                                   }`}
                      value={
                        reportInput !== undefined
                          ? reportInput
                          : preview.reportName
                      } // Explicitly check state
                      onChange={handleReportInput}
                    />
                    <GiCheckMark
                      onClick={() => addReportfield(preview.uniqueKey)}
                      className={`cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 ${
                        reportInput || preview.reportName
                          ? "text-green-500 hover:text-green-700"
                          : "text-red-500 hover:text-red-700"
                      }`}
                      size={20}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex lg:flex-row flex-col justify-center  lg:items-center  lg:justify-between">
                <div className="mandtory relative inline-block w-10 mr-2 align-middle select-none my-3">
                  <label className="font-semibold whitespace-nowrap  text-xs">
                    {Translations[langMode].mandatory}
                  </label>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={preview.isMandatory}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) =>
                      handleToggleChange(event, preview.uniqueKey)
                    }
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div className="excel relative inline-block w-10 mr-2 align-middle select-none">
                  <label className="font-semibold whitespace-nowrap  text-xs">
                    {" "}
                    {Translations[langMode].excel}
                  </label>
                  <span className="text-red-500 ml-1">*</span>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={showInput}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) => pdfMandatory(event, preview.uniqueKey)}
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>
              {showInput && (
                <div className="flex justify-start items-center py-3  space-x-2">
                  <input
                    type="text"
                    placeholder="Enter Excel Name..."
                    className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md
                                 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 transition 
                                 duration-300 ease-in-out transform hover:scale-105   dark:focus:ring-blue-500
                                  dark:focus:border-blue-500  ${
                                    theme === "dark"
                                      ? "dark-mode "
                                      : theme === "high-contrast"
                                      ? "high-contrast"
                                      : ""
                                  }`}
                    value={pdfInput || preview.excelReportName}
                    onChange={handlePdfInput}
                  />
                  <GiCheckMark
                    onClick={() =>
                      addPdffield(preview.uniqueKey, preview.excelReportName)
                    }
                    className={`cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 ${
                      pdficon
                        ? "text-green-500 hover:text-green-700"
                        : "text-red-500 hover:text-red-700"
                    }`}
                    size={20}
                  />
                </div>
              )}
              <div>
                <div className="pdf relative inline-block w-10 mr-2 align-middle select-none">
                  <label className="font-semibold text-xs whitespace-nowrap">
                    {Translations[langMode].pdf}
                  </label>
                  <input
                    fieldName={`toggle-${preview.uniqueKey}`}
                    checked={reportField}
                    id={`green-checkbox toggle-${preview.uniqueKey}`}
                    type="checkbox"
                    onChange={(event) =>
                      reportMandatory(event, preview.uniqueKey)
                    }
                    class="w-4 ml-2 h-4 text-green-600 bg-white border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                {reportField && (
                  <div className="flex justify-start items-center py-3  space-x-2">
                    <input
                      type="text"
                      placeholder="Enter PDF Name..."
                      className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-md
                                   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                    transition duration-300 ease-in-out transform hover:scale-105  
                                     dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                       theme === "dark"
                                         ? "dark-mode "
                                         : theme === "high-contrast"
                                         ? "high-contrast"
                                         : ""
                                     }`}
                      value={reportInput || preview.reportName}
                      onChange={handleReportInput}
                    />
                    <GiCheckMark
                      onClick={() =>
                        addReportfield(preview.uniqueKey, preview.reportName)
                      }
                      className={`cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 ${
                        reporticon
                          ? "text-green-500 hover:text-green-700"
                          : "text-red-500 hover:text-red-700"
                      }`}
                      size={20}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isNewFieldDragged && displaySelectedValue && (
          <div className="mt-3 border rounded-md border-blue-300 p-3">
            <p className="font-semibold text-sm text-blue-500">
              {Translations[langMode].selectedOption}:
            </p>
            <span className="text-sm font-semibold block">
              {Translations[langMode].Que}: {question}
            </span>
            <span className="text-sm font-semibold block">
              {Translations[langMode].option}: {displaySelectedValue}
            </span>
          </div>
        )}
        {shouldRenderSelectOption && (
          <div className="mt-2 border rounded-md border-blue-300 p-3">
            <p className="font-semibold text-sm text-blue-500 p-3">
              {Translations[langMode].selectoption}
            </p>
            <div className="overflow-y-auto max-h-[400px] h-auto px-2">
              {Object.keys(groupedRadioButtons).map((uniqueKey) => (
                <div
                  key={uniqueKey}
                  className={`mb-4 rounded-md p-2 mt-2  ${
                    shouldRenderSelectOptionEdit &&
                    uniqueKey === preview.uniqueKey
                      ? ""
                      : "border border-blue-500"
                  }`}
                >
                  <h3
                    className="text-xs font-semibold mb-2 flex items-center cursor-pointer justify-between"
                    onClick={() => toggleCollapse(uniqueKey)}
                  >
                    {uniqueKey !== preview.uniqueKey
                      ? getHeadline(uniqueKey)
                      : ""}
                    {!(
                      shouldRenderSelectOptionEdit &&
                      uniqueKey === preview.uniqueKey
                    ) && (
                      <span className="ml-2">
                        {collapse[uniqueKey] && collapsedGroups[uniqueKey] ? (
                          <FaChevronDown className="h-3" />
                        ) : (
                          <FaChevronUp className="h-3" />
                        )}
                      </span>
                    )}
                  </h3>
                  <div
                    className={`transition-max-height transition-all ease-in-out duration-300 overflow-hidden ${
                      collapse[uniqueKey] && !collapsedGroups[uniqueKey]
                        ? "max-h-screen"
                        : "max-h-0"
                    }`}
                  >
                    {groupedRadioButtons[uniqueKey].map((input) => {
                      const isChecked = selectedRadioId === input.id;

                      return input.uniqueKey !== preview.uniqueKey ? (
                        <div
                          key={input.id}
                          className="flex items-center gap-3 px-3 mt-2.5"
                        >
                          <input
                            type="text"
                            value={input.value}
                            className={`bg-white border border-gray-400 text-gray-900 text-sm
                     rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                     dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                       theme === "dark"
                         ? "dark-mode"
                         : theme === "high-contrast"
                         ? "high-contrast"
                         : ""
                     }`}
                            readOnly
                          />
                          <label className="relative">
                            <input
                              type="radio"
                              checked={isChecked}
                              name={`radio-${input.id}`}
                              value={input.value}
                              onChange={(e) => {
                                handleRadioBtnBelongs(
                                  input.uniqueKey,
                                  input.id,
                                  e.target.value,
                                  preview,
                                  input.radioKey
                                );
                                setSelectedRadioId(input.id);
                                setIsChecked(true);
                              }}
                              className="appearance-none w-4 h-4 border border-gray-500 rounded-full checked:bg-blue-600"
                            />
                            {isChecked && (
                              <div className="absolute inset-0 flex items-center justify-center">
                                <div
                                  className={`w-1 h-1 bg-white rounded-full ${
                                    theme === "dark"
                                      ? "dark-mode"
                                      : theme === "high-contrast"
                                      ? "high-contrast"
                                      : ""
                                  }`}
                                ></div>
                              </div>
                            )}
                          </label>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {shouldRenderSelectOptionEdit && (
          <div>
            <div className="py-3">
              <button
                onClick={() => setIsEditShow((val) => !val)}
                className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
              >
                {Translations[langMode].editInputvalue}
              </button>
            </div>
            {isEditShow && (
              <>
                {radioButtons.map((input) =>
                  input.uniqueKey === preview.uniqueKey &&
                  input.value !== "" ? (
                    <div
                      key={input.id}
                      className="flex flex-col lg:flex-row items-center space-x-2.5 mt-2.5"
                    >
                      <input
                        type="text"
                        value={
                          tempEditValues[`${input.uniqueKey}-${input.id}`] ||
                          input.value
                        }
                        onChange={(e) =>
                          setTempEditValues((prevValues) => ({
                            ...prevValues,
                            [`${input.uniqueKey}-${input.id}`]: e.target.value,
                          }))
                        }
                        className={`bg-white border border-gray-300 text-gray-900 text-sm 
                                      rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                        dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                          theme === "dark"
                                            ? "dark-mode "
                                            : theme === "high-contrast"
                                            ? "high-contrast"
                                            : ""
                                        }`}
                      />
                      <>
                        <MdCheck
                          onClick={() => {
                            const editValue =
                              tempEditValues[
                                `${input.uniqueKey}-${input.id}`
                              ] || input.value;
                            handleRadioOptions(
                              input.id,
                              editValue,
                              preview.uniqueKey
                            );
                          }}
                        />
                        <RxCross2
                          onClick={() => {
                            const editValue =
                              tempEditValues[
                                `${input.uniqueKey}-${input.id}`
                              ] || input.value;
                            handleRadioDeleteOptions(
                              input.id,
                              input.radioKey,
                              editValue,
                              preview.uniqueKey
                            );
                          }}
                        />
                      </>
                    </div>
                  ) : null
                )}
              </>
            )}
          </div>
        )}

        <div>
          <div>
            {preview.fieldType === "select" ||
            preview.fieldType === "radio" ||
            preview.fieldType === "checkbox" ? (
              <>
                <div className="flex flex-col lg:flex-row justify-between item-center py-2">
                  <label className="text-xs">
                    {Translations[langMode].addremove}
                  </label>
                  <div className="flex gap-2">
                    <FaPlus
                      size={14}
                      onClick={() => {
                        handleAddInput(preview.uniqueKey, preview.page);
                      }}
                    />
                    <FaMinus size={14} onClick={handleRemoveInput} />
                  </div>
                </div>
                {inputValues.map((input) =>
                  input.uniqueKey === preview.uniqueKey ? (
                    <div
                      key={input.id}
                      className="flex items-center space-x-2.5 mt-2.5"
                    >
                      <input
                        type="text"
                        value={
                          tempInputValue[`${input.uniqueKey}-${input.id}`] ||
                          input.value
                        }
                        onChange={(e) =>
                          setTempInputValue((prevValues) => ({
                            ...prevValues,
                            [`${input.uniqueKey}-${input.id}`]: e.target.value,
                          }))
                        }
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                      <GiCheckMark
                        onClick={() => {
                          const editValue =
                            tempInputValue[`${input.uniqueKey}-${input.id}`] ||
                            input.value;

                          if (!editValue.trim()) {
                            toast.error("Input field cannot be empty!");
                            return;
                          }
                          handleRadioOptions(
                            input.id,
                            editValue,
                            preview.uniqueKey
                          );
                        }}
                      />
                    </div>
                  ) : null
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldDetails;
