import React, { useContext, useEffect, useState, useRef } from "react";
import { FaUser } from "react-icons/fa6";
import AppContext from "../../../context/AppContext";
import { fetchFieldAgentMap } from "../../../service/Task";

const FieldAgentList = ({ onFieldAgentsLoaded }) => {
  const [allFieldAgents, setAllFieldAgents] = useState([]);
  const [displayedAgents, setDisplayedAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const listRef = useRef();
  const { selectedOrgId, theme } = useContext(AppContext);

  const fetchAgents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchFieldAgentMap(selectedOrgId);
      if (response.header.code === 650) {
        setError("No field agents found");
      } else {
        setAllFieldAgents(response.body.value);
        setDisplayedAgents(response.body.value.slice(0, 10)); // Show first 10 records initially
        if (onFieldAgentsLoaded) onFieldAgentsLoaded(response.body.value); // Pass data to parent
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, [selectedOrgId, onFieldAgentsLoaded]);

  const loadMoreAgents = () => {
    setDisplayedAgents((prev) => [
      ...prev,
      ...allFieldAgents.slice(prev.length, prev.length + 10),
    ]);
  };

  const handleScroll = () => {
    if (
      listRef.current.scrollTop + listRef.current.clientHeight >=
      listRef.current.scrollHeight
    ) {
      if (!loading && displayedAgents.length < allFieldAgents.length) {
        loadMoreAgents();
      }
    }
  };

  useEffect(() => {
    const currentListRef = listRef.current;
    if (currentListRef) {
      currentListRef.addEventListener("scroll", handleScroll);
      return () => currentListRef.removeEventListener("scroll", handleScroll);
    }
  }, [displayedAgents, loading, allFieldAgents]);

  if (loading && !displayedAgents.length)
    return <div>Loading field agents...</div>;
  if (error) return <div>{error}</div>;
  if (!displayedAgents.length) return <div>No field agents found</div>;

  return (
    <div
      ref={listRef}
      className={`bg-white overflow-x-auto overflow-y-auto 
       border rounded-lg p-3  ${
         theme === "dark"
           ? "dark-mode "
           : theme === "high-contrast"
           ? "high-contrast  "
           : ""
       }`}
      style={{ zIndex: "9999" }}
    >
      <h3
        className={` ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        } text-gray-700 text-sm`}
      >
        Field Agents
      </h3>
      <ul className="max-h-[130px] h-auto ">
        {displayedAgents.map((agent, index) => (
          <li
            className="border rounded-md p-3 m-2 flex items-center w-full text-xs "
            key={agent.id}
          >
            <FaUser className="mr-2" />
            {agent.name}
          </li>
        ))}
      </ul>
      {loading && <div>Loading more agents...</div>}
    </div>
  );
};

export default FieldAgentList;
