import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import {
  createAssignmentRule,
  assignmentRules,
  updateFunction,
} from "../../service/Configuration";
import { RxCross2 } from "react-icons/rx";
import { fetchFieldAgentMap } from "../../service/Task";
import HighlightedTextarea from "./HighlightedTextarea";
import { deleteAssignmentRule } from "../../service/Configuration";
import { Spinner } from "../../Spinner";
import translations from "../common/Translations";
const AssignmentRules = () => {
  const [selectedUserName, setSelectedUserName] = useState(0);
  const [error, setError] = useState(null);
  const [assignmentList, setAssignmentList] = useState([]);
  const {
    workflowId,
    setFieldAgentList,
    fieldAgentList,
    setSelectedUserId,
    selectedOrgId,
  } = useContext(AppContext);
  const [showCriteria, setshowCriteria] = useState(false);
  const [criteria, setCriteria] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const { theme, langMode } = useContext(AppContext);
  const ruleNameRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null); // Track which dropdown is open
  const handleFieldAgentSelect = (agentId) => {
    if (selectedAgents.includes(agentId)) {
      setSelectedAgents(selectedAgents.filter((id) => id !== agentId));
    } else {
      setSelectedAgents([...selectedAgents, agentId]);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ruleName = ruleNameRef.current.value;
    try {
      const responseData = await createAssignmentRule(
        workflowId,
        criteria,
        ruleName,
        isActive,
        selectedAgents
      );
      if (responseData.header.code === 600) {
        toast.success("Rule created successfully!");
        ruleNameRef.current.value = "";
        setCriteria("");
        setSelectedUserName("");
        fetchAssignmentRules();
        setSelectedAgents([]); // Reset selected checkboxes
        setDropdownOpen(false);
      } else {
        toast.error(`Error: ${responseData.body.value}`);
        ruleNameRef.current.value = "";
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(`Error submitting form: ${error.message}`);
    }
  };

  const fetchAssignmentRules = async () => {
    if (!workflowId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const data = await assignmentRules(
        false,
        workflowId,
        page,
        size,
        setIsLoading,
        setAssignmentList,
        setHasMore,
        setError
      );
    } catch (error) {
      console.error("Error fetching assignment rules:", error);
    }
  };
  useEffect(() => {
    fetchAssignmentRules();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;
      if (bottom && !isLoading && hasMore) {
        setPage((prevPage) => prevPage + 1);
        fetchAssignmentRules();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore, page]);

  useEffect(() => {
    if (page > 0) {
      fetchAssignmentRules();
    }
  }, [page]);

  useEffect(() => {
    if (selectedOrgId && workflowId) {
      setAssignmentList([]);
      setPage(0);
      setHasMore(true);
      fetchAssignmentRules();
    }
  }, [selectedOrgId, workflowId]);

  const handlAddCriteria = () => {
    if (!workflowId) {
      toast.error("Please select a workflow before adding criteria.");
      return;
    }
    setshowCriteria((prevShowCriteria) => !prevShowCriteria);
  };
  const handleUpdate = async (assignment) => {
    const updatedUserIdList = assignment.userId;
    try {
      await updateFunction(
        assignment,
        workflowId,
        fetchAssignmentRules,
        toast,
        setDropdownOpen,
        updatedUserIdList
      );
      setOpenDropdownIndex(null);
    } catch (error) {
      console.error("Error handling update:", error);
    }
  };
  useEffect(() => {
    const fetchFieldAgents = async () => {
      try {
        const agents = await fetchFieldAgentMap(selectedOrgId);
        setFieldAgentList(agents.body.value);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    fetchFieldAgents();
  }, [selectedOrgId]);

  useEffect(() => {}, [fieldAgentList]);
  const handleAssigneeChange = (selectedId) => {
    setSelectedUserId(selectedId);
    const selectedAgent = fieldAgentList.find(
      (agent) => agent.userId === selectedId
    );
    if (selectedAgent) {
      setSelectedUserName(selectedAgent.name);
    }
  };
  const handleToggle = (index) => {
    setIsActive((prevIsActive) => !prevIsActive);
    if (index !== undefined && index >= 0) {
      const updatedAssignments = [...assignmentList];
      updatedAssignments[index].activeStatus =
        !updatedAssignments[index].activeStatus;
      setAssignmentList(updatedAssignments);
    }
  };

  const handleRuleChange = (e, index) => {
    const updatedRules = [...assignmentList];
    updatedRules[index].rule = e.target.value;
    setAssignmentList(updatedRules);
  };

  const handleDeleteRule = async (ruleid) => {
    try {
      const response = await deleteAssignmentRule(ruleid);
      if (response.header.code === 600) {
        toast.success("Rule deleted successfully");
        setAssignmentList((prevRules) =>
          prevRules.filter((rule) => rule.id !== ruleid)
        );
      } else {
        toast.error("Failed to delete rule");
      }
    } catch (error) {
      console.error("Error deleting completion rule:", error);
      toast.error("Failed to delete rule");
    }
  };
  const handleDropdownToggle = (dropdownIndex) => {
    if (openDropdownIndex === dropdownIndex) {
      setOpenDropdownIndex(null);
    } else {
      setOpenDropdownIndex(dropdownIndex);
    }
  };
  return (
    <>
      <div className="p-4">
        <div className="flex items-center">
          <div className="grow border-b border-dashed border-gray-400"></div>
          <span className="shrink px-1 pb-1 text-teal-600">
            <button
              type="button"
              onClick={handlAddCriteria}
              className={`px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-100 bg-gray-700 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? " border bg-black text-white"
                  : ""
              }`}
            >
              {showCriteria ? "Hide Criteria" : "Add New Criteria"}
            </button>
          </span>
          <div className="grow border-b  border-dashed border-gray-400"></div>
        </div>
        {showCriteria && (
          <form onSubmit={handleSubmit} className="p-4">
            <div
              className={`bg-white border-2 border-blue-300 shadow-2xl rounded-md  py-5 p-4 ${
                theme === "dark"
                  ? "dark-mode  "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="mb-4">
                  <label className="block text-sm font-semibold text-gray-900 dark:text-white">
                    {translations[langMode].rulename}
                  </label>
                  <input
                    id="areaCode"
                    ref={ruleNameRef}
                    className={`w-60 border border-gray-300 text-gray-900 text-sm rounded-lg
                       focus:ring-blue-500 focus:border-blue-500 block  p-2.5  ${
                         theme === "dark"
                           ? "dark-mode  "
                           : theme === "high-contrast"
                           ? "high-contrast  "
                           : ""
                       }`}
                  />
                </div>
                <div>
                  <label
                    for="assignTo"
                    className="block  text-sm font-medium text-gray-900 dark:text-white"
                  ></label>
                  <div>
                    <label
                      htmlFor="assignTo"
                      className="block text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      {translations[langMode].assignTo}
                    </label>
                    <div className={`relative`}>
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownOpen(!dropdownOpen);
                        }}
                        className={`bg-white p-2 border  text-sm border-gray-300 rounded-md ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      >
                        Select Field Agents{" "}
                        {selectedAgents.length > 0 &&
                          `(${selectedAgents.length})`}
                      </button>
                      {dropdownOpen && (
                        <div
                          className={`absolute bg-white border border-gray-300 rounded mb-1 z-50 shadow-lg overflow-y-auto max-h-[200px] h-auto ${
                            theme === "dark" ? "dark-mode" : ""
                          }`}
                          style={{ bottom: "100%" }}
                        >
                          {fieldAgentList.map((agent) => (
                            <div
                              key={agent.userId}
                              className="flex items-center p-2"
                            >
                              <input
                                id={`agent-checkbox-${agent.userId}`}
                                type="checkbox"
                                checked={selectedAgents.includes(agent.userId)}
                                onChange={() =>
                                  handleFieldAgentSelect(agent.userId)
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                              />
                              <label
                                htmlFor={`agent-checkbox-${agent.userId}`}
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {agent.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="first-name"
                    className={`block text-sm font-semibold leading-6 text-gray-900 ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {translations[langMode].activedeactive}
                  </label>
                  <div className="">
                    <input
                      type="checkbox"
                      id="toggle-switch"
                      name="toggle-switch"
                      checked={isActive}
                      onChange={handleToggle}
                      className="hidden"
                    />
                    <label
                      htmlFor="toggle-switch"
                      className={`block w-20 px-2 rounded-full border-0  py-2 text-gray-900 shadow-sm  cursor-pointer ${
                        isActive ? "bg-blue-800" : "bg-indigo-300"
                      }`}
                    >
                      <span
                        className={`block w-10 h-5 bg-white rounded-full shadow-md transform transition-transform ${
                          isActive ? "translate-x-5" : "translate-x-0"
                        }`}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className={`block text-sm font-semibold leading-6 text-gray-900 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  {translations[langMode].criteria}
                </label>
                <div className="">
                  <HighlightedTextarea
                    criteria={criteria}
                    setCriteria={setCriteria}
                    value={criteria}
                    onChange={(e) => setCriteria(e.target.value)}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="text-sm px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-600"
              >
                {translations[langMode].submit}
              </button>
            </div>
          </form>
        )}
        {Array.isArray(assignmentList) &&
          (assignmentList.length > 0 ? (
            <ul className="p-4 mt-10 ">
              {assignmentList.map((assignment, index) => (
                <li
                  key={assignment.id}
                  className={`mb-4 p-4 bg-white border-2 border-blue-300 shadow-2xl rounded-md ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <RxCross2
                    className="float-right"
                    onClick={() => handleDeleteRule(assignment.id)}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                    <div className="">
                      <label
                        htmlFor={`ruleName-${index}`}
                        className="block text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        {translations[langMode].rulename}
                      </label>
                      <input
                        id={`ruleName-${index}`}
                        value={assignment.ruleName}
                        onChange={(e) => {
                          const updatedAssignments = [...assignmentList];
                          updatedAssignments[index].ruleName = e.target.value;
                          setAssignmentList(updatedAssignments);
                        }}
                        className={`w-60 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor={`assignTo-${index}`}
                        className="block text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        {translations[langMode].assignTo}
                      </label>
                      <div className="relative">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDropdownToggle(index); // Toggle dropdown based on the index
                          }}
                          className={`bg-white p-2 text-xs border border-gray-300 rounded ${
                            theme === "dark"
                              ? "dark-mode"
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : ""
                          }`}
                        >
                          Select Field Agents
                          {assignment.userId.length > 0 &&
                            `(${assignment.userId.length})`}
                        </button>
                        {openDropdownIndex === index && (
                          <div
                            className={`absolute bg-white border border-gray-300 rounded mb-1 overflow-y-auto shadow-lg z-50 max-h-[200px] h-auto ${
                              theme === "dark" ? "dark-mode" : ""
                            }`}
                            style={{ bottom: "100%" }}
                          >
                            {/* {fieldAgentList.map((agent) => (
                              <div
                                key={agent.userId}
                                className="flex items-center p-2" >
                                <input
                                  id={`agent-checkbox-${agent.userId}`}
                                  type="checkbox"
                                  checked={assignment.userId.includes(
                                    agent.userId
                                  )}
                                  onChange={() => {
                                    const updatedAssignments = [
                                      ...assignmentList,
                                    ];
                                    const currentAssignment =
                                      updatedAssignments[index];
                                    if (
                                      currentAssignment.userId.includes(
                                        agent.userId
                                      )
                                    ) {
                                      currentAssignment.userId =
                                        currentAssignment.userId.filter(
                                          (id) => id !== agent.userId
                                        );
                                    } else {
                                      currentAssignment.userId.push(
                                        agent.userId
                                      );
                                    }
                                    setAssignmentList(updatedAssignments);
                                  }}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                                />
                                <label
                                  htmlFor={`agent-checkbox-${agent.userId}`}
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"  >
                                  {agent.name}
                                </label>
                              </div>
                            ))} */}

                            {fieldAgentList.map((agent) => (
                              <div
                                key={agent.userId}
                                className="flex items-center p-2"
                              >
                                <input
                                  id={`agent-checkbox-${agent.userId}`}
                                  type="checkbox"
                                  checked={
                                    selectedAgents.includes(agent.userId) ||
                                    assignment.userId.includes(agent.userId)
                                  }
                                  onChange={() => {
                                    const updatedAssignments = [
                                      ...assignmentList,
                                    ];
                                    const currentAssignment =
                                      updatedAssignments[index];

                                    if (
                                      currentAssignment.userId.includes(
                                        agent.userId
                                      )
                                    ) {
                                      // Remove the agent if already selected
                                      currentAssignment.userId =
                                        currentAssignment.userId.filter(
                                          (id) => id !== agent.userId
                                        );
                                    } else {
                                      // Add the agent if not yet selected
                                      currentAssignment.userId.push(
                                        agent.userId
                                      );
                                    }

                                    setAssignmentList(updatedAssignments);
                                  }}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                                />
                                <label
                                  htmlFor={`agent-checkbox-${agent.userId}`}
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {agent.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor={`activeStatus-${index}`}
                        className={`block text-sm font-semibold leading-6 text-gray-900 ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      >
                        {translations[langMode].activedeactive}
                      </label>
                      <div className="">
                        <input
                          type="checkbox"
                          id={`toggle-switch-${index}`}
                          name={`toggle-switch-${index}`}
                          checked={assignment.activeStatus}
                          onChange={() => handleToggle(index)}
                          className="hidden"
                        />
                        <label
                          htmlFor={`toggle-switch-${index}`}
                          className={`block w-20 px-2 rounded-full border-0 py-2 text-gray-900 shadow-sm cursor-pointer ${
                            assignment.activeStatus
                              ? "bg-blue-800"
                              : "bg-indigo-300"
                          }`}
                        >
                          <span
                            className={`block w-10 h-5 bg-white rounded-full shadow-md transform transition-transform ${
                              assignment.activeStatus
                                ? "translate-x-5"
                                : "translate-x-0"
                            }`}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="rule-criteria"
                      className={`block text-sm font-semibold leading-6 text-gray-900 ${
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      {translations[langMode].criteria}
                    </label>
                    <div className="">
                      <HighlightedTextarea
                        value={assignment.rule}
                        onChange={(e) => handleRuleChange(e, index)}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleUpdate(assignment)}
                    className=" text-sm px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-600"
                  >
                    {translations[langMode].update}
                  </button>
                </li>
              ))}
              {isLoading && (
                <p>
                  Loading...
                  <Spinner />{" "}
                </p>
              )}
              {!hasMore && <p>{translations[langMode].norule}</p>}
            </ul>
          ) : (
            <>
              {isLoading && (
                <p className="text-center justify-center items-center">
                  {" "}
                  {translations[langMode].norule}{" "}
                </p>
              )}
              {!hasMore && (
                <p className="text-center justify-center items-center">
                  {" "}
                  {translations[langMode].norule}
                </p>
              )}
            </>
          ))}
      </div>
    </>
  );
};
export default AssignmentRules;
