import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const deleteinventory = async (selectedItems) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.delete(`${baseURL}api/v1/meter-inventory/delete`, {
      headers: { Authorization: `Bearer ${token}` },
      data: selectedItems, // Use the `data` key for the request body
    });

    return response.data;
  } catch (error) {
    console.error("Error deleting inventory:", error.response?.data || error.message);
    throw error; // Rethrow the error to be handled by the calling function
  }
};


export const fetchInventory = async (
  currentPage,
  pageSize,
  fromDate,
  toDate,
  meterBarCode,
  toLocation,
  selectedOrgId
) => {
  const token = Cookies.get("accessToken");

  const params = new URLSearchParams({
    page: currentPage,
    size: pageSize,
    organizationId: selectedOrgId,
  });

  const formatDateTime = (date) => {
    if (!date) return "";
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formattedFromDate = formatDateTime(fromDate);
  const formattedToDate = formatDateTime(toDate);

  if (formattedFromDate) params.append("fromDate", formattedFromDate);
  if (formattedToDate) params.append("toDate", formattedToDate);
  if (meterBarCode) params.append("meterBarCode", meterBarCode);
  if (toLocation) params.append("toLocation", toLocation);

  try {
    const response = await axios.get(
      `${baseURL}api/v1/meter-inventory/get-list?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("API Response:", response.data);

    return response.data?.body?.value || [];
  } catch (error) {
    console.error("Error fetching inventory data:", error);
    return [];
  }
};

export const getMeterInventory = async (barcode, selectedOrgId) => {
  const token = Cookies.get("accessToken");
  const userType = Cookies.get("userType");

  try {
    let params = {
      meterBarCode: barcode,
    };

    if (userType === "super_admin") {
      params.organizationId = selectedOrgId;
    }

    const response = await axios.get(`${baseURL}api/v1/meter-inventory/get`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching meter inventory:", error);
    throw error;
  }
};

export const updateInventory = async (meterDetails) => {
  const token = Cookies.get("accessToken");
  const filteredDetails = Object.fromEntries(
    Object.entries(meterDetails).filter(([key, value]) => value !== null)
  );

  try {
    const response = await axios.post(
      `${baseURL}api/v1/meter-inventory/update-meter-record`,
      { ...filteredDetails },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.header.code === 600) {
      return true;
    } else {
      throw new Error("Failed to update meter record");
    }
  } catch (error) {
    console.error("Error updating meter record:", error.message);
    throw error;
  }
};

export const generateInventoryReport = async (
  selectedOrgId,
  startDate,
  endDate
) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/meter-inventory/generate-report`,
      {
        params: {
          organizationId: selectedOrgId,
          startDate: `${startDate}T00:00:00`,
          endDate: `${endDate}T00:00:00`,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while generating report:", error);
    throw error;
  }
};
