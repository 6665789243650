import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AssignmentRules from "../../components/configurations/AssignmentRules";
import { CompletionRules } from "../../components/configurations/CompletionRules";
import CustomVariable from "../../components/configurations/CustomVariable";
import AppContext from "../../context/AppContext";
import translations from "../../components/common/Translations";

const ConfigurationMain = () => {
  const [activeTab, setActiveTab] = useState("assignment");
  const { theme, langMode } = useContext(AppContext);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className={`${
      theme === "dark"
        ? "dark-mode  "
        : theme === "high-contrast"
        ? "high-contrast  "
        : ""
    } flex flex-col w-full`}>
      <h4 className={`m-5 text-sm uppercase font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}>
        {translations[langMode].configuration}
      </h4>
      <div className="flex flex-col w-full ">
        <ul
          className={`flex justify-center gap-6 text-sm font-medium  ${
            theme === "dark"
              ? "border-gray-600 text-white"
              : theme === "high-contrast"
              ? "high-contrast"
              : "text-gray-700 border-gray-300"
          }`}
        >
          <li>
            <Link
              to=""
              className={`inline-flex justify-center px-6 py-2 transition-all duration-300 w-full whitespace-nowrap ${
                activeTab === "completion"
                  ? theme === "dark"
                    ? "text-blue-500 border-blue-600 border-b-4 rounded-t-md"
                    : "text-blue-700 border-blue-700 border-b-4 rounded-t-md"
                  : "hover:text-blue-500"
              }`}
              onClick={() => handleTabClick("completion")}
            >
              {translations[langMode].complitionrule}
            </Link>
          </li>
          <li>
            <Link
              to=""
              className={`inline-flex justify-center px-6 py-2 transition-all duration-300 w-full whitespace-nowrap ${
                activeTab === "assignment"
                  ? theme === "dark"
                    ? "text-blue-500 border-blue-600 border-b-4 rounded-t-md"
                    : "text-blue-700 border-blue-700 border-b-4 rounded-t-md"
                  : "hover:text-blue-500"
              }`}
              onClick={() => handleTabClick("assignment")}
            >
              {translations[langMode].assignment}
            </Link>
          </li>
          <li>
            <Link
              to=""
              className={`inline-flex justify-center px-6 py-2 transition-all duration-300 w-full whitespace-nowrap ${
                activeTab === "custom"
                  ? theme === "dark"
                    ? "text-blue-500 border-blue-600 border-b-4 rounded-t-md"
                    : "text-blue-700 border-blue-700 border-b-4 rounded-t-md"
                  : "hover:text-blue-500"
              }`}
              onClick={() => handleTabClick("custom")}
            >
              {translations[langMode].customerule}
            </Link>
          </li>
        </ul>
       <div
          className={`p-2 mt-1  w-full ${
            theme === "dark"
              ? " border text-white border-gray-700"
              : theme === "high-contrast"
              ? "high-contrast"
              : " text-gray-700 "
          }`}
        >
          {activeTab === "completion" && <CompletionRules />}
          {activeTab === "assignment" && <AssignmentRules />}
          {activeTab === "custom" && <CustomVariable />}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationMain;
