import React, { useContext } from "react";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import translations from "../../components/common/Translations";

const DynamicPage = ({ page, setPage, dragItems }) => {
  const { theme, langMode } = useContext(AppContext);
  const handleIncrement = () => {
    let containsItem = dragItems.findIndex((item) => item.page === page);
    if (containsItem >= 0) {
      setPage((prevPage) => prevPage + 1);
    } else {
      toast.warning(
        "Add at least one element to the current page before moving to the next page"
      );
    }
  };
  const handleDecrement = () => {
    setPage((prevPage) => {
      return prevPage > 1 ? prevPage - 1 : prevPage;
    });
  };
  return (
    <>
      <div
        className={`block w-full p-1 ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="flex flex-col lg:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2.5">
          <div className="flex items-start text-sm font-semibold text-nowrap">
            Pages :
          </div>
          <div className="flex gap-1 items-center p-2 w-full">
            <button
              onClick={handleDecrement}
              className="mt-1  w-14  bg-blue-800 hover:bg-blue-700 text-white font-semibold py-2 text-xs  lg:text-sm rounded-l-md focus:outline-none"
            >
              {translations[langMode].previous}
            </button>
            <input
              type="text"
              value={page}
              readOnly
              className="w-12 text-sm flex text-center bg-gray-50 border border-gray-300 text-gray-900  rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
            />
            <button
              onClick={handleIncrement}
              className="mt-1 text-xs lg:text-sm w-14  bg-blue-800 hover:bg-blue-700 text-white font-semibold py-2  rounded-r-md focus:outline-none"
            >
              {translations[langMode].next}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicPage;
