import { ErrorMessage, Field, Form, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import loginImage from "../../assets/loginImage.png";
import OtpVerification from "./OtpVerification";
import ResetPasswordPage from "./ResetPasswordPage";
import AppContext from "../../context/AppContext";
import { generateToken } from "../../Push_notification/Notification";
import {
  loginUser,
  storeFcmToken,
  forgotPassword,
} from "../../service/AuthService";
import { RiLoader3Fill } from "react-icons/ri";

const registrationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(2, "Password must be at least 8 characters")
    .required("Password is required"),
});
const LoginPage = () => {
  const { user, setUser, theme } = useContext(AppContext);
  const [resetEmail, setResetEmail] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleForgotPasswordMode = () => {
    setForgotPasswordMode(!forgotPasswordMode);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const responseData = await loginUser(values);
      const rememberCheckbox = document.getElementById("remember");
      const isChecked = rememberCheckbox.checked;
      if (isChecked) {
        window.localStorage.setItem("isLoggedIn", true);
      }
      if (responseData.header.code === 600) {
        const userDetails = responseData.body.value.userDetails;
        setUser(userDetails);
        sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
        navigate("/dashboard");
        localStorage.setItem("justLoggedIn", "true");
        setLoading(false);
        const { accessToken } = responseData.body.value;
        localStorage.setItem("accessToken", accessToken);
        const expires = new Date();
        expires.setTime(expires.getTime() + 7 * 60 * 60 * 1000);
        Cookies.set("accessToken", accessToken, { expires });
        Cookies.set("userType", userDetails.userType, {
          secure: true,
          sameSite: "strict",
        });
        Cookies.set("userId", userDetails.userId);
        Cookies.set("userName", userDetails.name);
        Cookies.set("userVisibility", userDetails.userVisibility);
        Cookies.set("userEmail", userDetails.email, { httpOnly: true });
        Cookies.set("profilePic", userDetails.profilePic);
        Cookies.set("firstTimeLogin", userDetails.firstTimeLogin);
        const token = await generateToken();
        await storeFcmToken(token, accessToken);
      } else if (responseData.header.code === 612) {
        toast.error("Email id or password wrong");
        setLoading(false);
      } else if (responseData.header.code === 613) {
        setResetEmail(values.email);

        setResetPassword(true);
      } else if (responseData.header.code === 608) {
        toast.error("Access Denied");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgotPassword = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const responseData = await forgotPassword(values.email);
      if (responseData.header.code === 600) {
        setResetEmail(values.email);
        toast.success("Check your email for OTP");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      {!resetEmail && !resetPassword && (
        <div className="bg-gray-50 flex justify-center items-center h-screen">
          <div className="lg:p-36 md:p-52 p-32 w-full lg:w-1/2 h-screen">
            <h1 className="text-4xl text-blue-800 font-bold mb-4">BluField</h1>
            <p className="text-md text-gray-500 font-semibold">
              Utilities & <br /> Tasks Management Solutions
            </p>
            <div className="mt-10">
              {!forgotPasswordMode ? (
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={registrationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-semibold leading-6 text-gray-400"
                        >
                          Username / Email Address
                        </label>
                        <div className="mt-2.5">
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            className={`w-full border border-gray-300 rounded-md py-2 px-3 shadow-sm focus:outline-none
                               focus:border-blue-500   ${
                                 theme === "dark"
                                   ? "text-black"
                                   : theme === "high-contrast"
                                   ? "text-black"
                                   : ""
                               }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-rose-500"
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="password"
                          className="block text-sm font-semibold leading-6 text-gray-400"
                        >
                          Password
                        </label>
                        <div className="relative">
                          <Field
                            name="password"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            className={`w-full border border-gray-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-blue-500 
                               ${
                              theme === "dark"
                                ? "text-black"
                                : theme === "high-contrast"
                                ? "text-black"
                                : ""
                            }`}
                          />
                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute text-sm text-gray-400 top-3.5 right-3.5"
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </button>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-rose-500"
                        />
                      </div>
                      <div className="flex justify-between">
                        <div className="mb-4 flex items-center">
                          <input
                            type="checkbox"
                            id="remember"
                            name="remember"
                            className="text-blue-500"
                          />
                          <label
                            htmlFor="remember"
                            className="text-gray-600 ml-2"
                          >
                            Remember Me
                          </label>
                        </div>
                        <div className="mb-6 text-gray-400">
                          <Link
                            to=""
                            onClick={toggleForgotPasswordMode}
                            className="underline"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="bg-blue-800 hover:bg-blue-600 text-white rounded-md text-center justify-center py-2 px-4 w-full inline-flex items-center gap-2"
                        disabled={isSubmitting}
                      >
                        Login
                        {loading && <RiLoader3Fill />}
                      </button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Invalid email")
                      .required("Email is required"),
                  })}
                  onSubmit={handleForgotPassword}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-2">
                        <label
                          htmlFor="forgotEmail"
                          className="block text-sm font-semibold leading-6 text-gray-400"
                        >
                          Enter your email address to reset password
                        </label>
                        <div className="mt-2.5">
                          <Field
                            type="email"
                            name="email"
                            id="forgotEmail"
                            className={`  ${
                              theme === "dark"
                                ? "text-black"
                                : theme === "high-contrast"
                                ? "text-black"
                                : ""
                            } w-full border border-gray-300 rounded-md py-2 px-3 shadow-sm focus:outline-none
                             focus:border-blue-50`}
                          />
                          <ErrorMessage name="email" component="div" />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="bg-blue-800 hover:bg-blue-600 text-white rounded-md text-center justify-center py-2 px-4 w-full inline-flex items-center gap-2"
                        disabled={isSubmitting}
                      >
                        Get OTP
                        {loading && <RiLoader3Fill />}
                      </button>
                      <div className="mt-6 justify-center text-gray-400">
                        <Link
                          to=""
                          onClick={toggleForgotPasswordMode}
                          className="underline"
                        >
                          Back
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <div className="w-1/2 h-screen hidden lg:block">
            <img
              src={loginImage}
              alt="Placeholder Image"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      )}
      {resetEmail && !resetPassword && (
        <>
          <OtpVerification emaill={resetEmail} />
          <button onClick={() => setResetPassword(true)}>
            Proceed to Reset Password
          </button>
        </>
      )}
      {resetPassword && <ResetPasswordPage emaill={resetEmail} />}
    </>
  );
};

export default LoginPage;
