import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const approveRejectNotification = async (
  referenceId,
  notificationId,
  isApproved
) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/task/web/request-for-extension`,
      {
        taskId: referenceId,
        notificationId: notificationId,
        isApproved: isApproved,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while approving/rejecting:", error);
    throw error;
  }
};

export const generateReport = async (referenceId, notificationId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/report/generate-report`,
      {
        referenceId: parseInt(referenceId, 10),
        notificationId: notificationId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while generating report:", error);
    throw error;
  }
};

export const fetchNotifications = async (page, pageSize) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${BASE_URL}api/v1/notification/get-all?page=${page}&size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.body &&
      Array.isArray(response.data.body.value.notificationList)
    ) {
      const newNotifications = response.data.body.value.notificationList;
      const totalUnreadMsgCount = response.data.body.value.totalUnreadMsgCount;
      return { notifications: newNotifications, totalUnreadMsgCount };
    } else {
      console.error(
        "API response does not contain expected data structure:",
        response.data
      );
      return { notifications: [], totalUnreadMsgCount: 0 };
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return { notifications: [], totalUnreadMsgCount: 0 };
  }
};

export const getUnreadMsgCount = async () => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${BASE_URL}api/v1/notification/get-unread-msg-count`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unread message count:", error);
    
  }
};