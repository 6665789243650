import React from 'react'
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAI8tZx3Y6Z8ripS8InGHdVpI47hzns7nw",
    authDomain: "bluefieldoman-web.firebaseapp.com",
    projectId: "bluefieldoman-web",
    storageBucket: "bluefieldoman-web.appspot.com",
    messagingSenderId: "983987095951",
    appId: "1:983987095951:web:3eca468cc76c5f043323fa",
    measurementId: "G-5BZE2QHC49"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        const token = await getToken(messaging, {
            vapidKey:
                "BI8zdW0RdZvYo7RpFH0dv6C7W2jv6nWia1EIMTeYGe_qyRPHZKvGQJ7i-T3uBcPWhTlWkeeD0zwmbFBkQBIwXHs"
        })
        console.log(token)
        return token;

    }
}
export { messaging };