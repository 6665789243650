import React, {
  useContext,
  useState,
  Fragment,
  useRef,
} from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { fetchExportData } from "../../service/Task";
import MultipleActivitySelect from "../../components/common/MultipleActivitySelect";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
const translations = {
  en: {
    export: "Export",
    exportData: " Export Report",
    downloading: "Downloading...",
  },
  ar: {
    export: "يصدّر",
    exportData: "تقرير التصدير",
    downloading: "جاري التحميل...",
  },
};
const ExportData = ({ openModal, setOpenModal }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [startDate, setStartDate] = useState(formatDate(yesterday));
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [selectedOption, setSelectedOption] = useState([]);
  const context = useContext(AppContext);
  const { theme, langMode } = context;
  const cancelButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    console.log(selectedOption, "selectedoption");
    if (selectedOption) {
      setIsLoading(true); 
      fetchExportData(selectedOption, startDate, endDate)
        .then((response) => {
          setIsLoading(false); 
          if (response.data.header.code === 607) {
            toast.error("No Records are Available");
          } else {
            const s3Link = response.data.body.value.reportUrl;
            const link = document.createElement("a");
            link.href = s3Link;
            link.setAttribute("download", "report.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          setIsLoading(false);
        });
    } else {
      toast.error("Please select the activity");
    }
  };
  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => !isLoading && setOpenModal(false)}  >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0" >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity `}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`} >
                <div className="h-[400px]">
                  <div className="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                    <h3
                      className={`px-5 text-sm font-semibold text-gray-900 ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {translations[langMode].exportData}
                    </h3>
                    {!isLoading && ( 
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="small-modal"
                        onClick={() => setOpenModal(false)}
                      >
                        <RxCross2 />
                        <span className="sr-only">Close modal</span>
                      </button>
                    )}
                  </div>
                  <div className="px-10 ">
                    <MultipleActivitySelect
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                  </div>
                  <div
                    className={`px-10 my-5 pb-14 flex gap-64  ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`} >
                    <div className="gap-5 flex">
                      <div className="flex gap-4">
                       <div className="flex flex-col">
                          <label className="uppercase text-xs">From Date</label>
                          <input
                          type="text"
                          className={`form-input border pt-1 border-gray-400 rounded-md w-36 h-9 ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                          }`}
                          value={startDate ? formatDate(startDate) : ""}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = "text";
                            }
                          }}
                          onChange={(e) => setStartDate(e.target.value)}
                          placeholder="From date"
                        />
                       </div>
                       <div className="flex flex-col">
                       <label className="uppercase text-xs">To Date</label>
                        <input
                          type="text"
                          className={`form-input border pt-1 border-gray-400 rounded-md w-36 h-9 ${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                          }`}
                          value={endDate ? formatDate(endDate) : ""}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = "text";
                            }
                          }}
                          onChange={(e) => setEndDate(e.target.value)}
                          placeholder="To date"
                        />
                        </div>
                        <button
                          onClick={fetchData}
                          type="button"
                          disabled={isLoading} 
                          className="px-3 py-2 text-xs h-9 mt-4 font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                          {isLoading
                            ? translations[langMode].downloading
                            : translations[langMode].export}
                        </button>
                     
                      </div>
                     
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ExportData;
