import React, { useContext, useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import AppContext from "../../context/AppContext";
import translations from "./Translations";

const DateRangePicker = ({ onDateSelect, showDatePicker, setShowDatePicker }) => {
  const { theme, langMode } = useContext(AppContext);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  const getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split("T")[0];
  };

  const [fromDate, setFromDate] = useState(getYesterdayDate());
  const [toDate, setToDate] = useState(getCurrentDate());
  const pickerRef = useRef(null); 

  const handleDateChange = () => {
    onDateSelect(fromDate, toDate);
    setShowDatePicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowDatePicker]);

  return (
    <div
      ref={pickerRef} 
      className={`relative ${
        theme === "dark"
          ? "dark-mode"
          : theme === "high-contrast"
          ? "high-contrast"
          : ""
      }`}
    >
      <button
        className="p-6 py-2 w-24 h-9 justify-between gap-5 mt-1 border text-gray-500 rounded-md flex"
        onClick={() => setShowDatePicker(!showDatePicker)}
      >
        <span
          className={`text-sm text-gray-800 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          {translations[langMode].date}
        </span>
        <span className="pointer-events-none">
          <IoIosArrowDown
            className={`${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            } h-4 w-5 text-gray-700`}
            aria-hidden="true"
          />
        </span>
      </button>
      {showDatePicker && (
        <div
          className={`${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          } absolute left-0 mt-2 p-4 bg-white border rounded-md shadow-lg`}
        >
          <div className="flex flex-col gap-2">
            <label>
              {translations[langMode].from}:
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className={`${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                } ml-2 text-sm border-gray-300 rounded-md`}
              />
            </label>
            <label>
              {translations[langMode].to}:
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className={`${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                } ml-2 border-gray-300 text-sm rounded-md`}
              />
            </label>
            <button
              className="mt-2 px-4 py-1 bg-blue-800 text-white rounded-md"
              onClick={handleDateChange}
            >
              {translations[langMode].apply}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
