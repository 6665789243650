import React, { useContext, useEffect, useState } from "react";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import ActivityQuestions from "./ActivityQuestions";
import ListChartModal from "./ListChartModal";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import DeletePermission from "../../suspence/DeletePermission";
import AppContext from "../../../context/AppContext";
import AreaChart from "../charts/AreaChart";
import RadarChart from "../charts/RadarChart";
import {fetchDashboardData ,createGraphMetric,deleteDashboard ,deleteGraphMetric} from "../../../service/UserDashboard"
import translations from "../../common/Translations";
const AddChart = ({
  dashboardNameInput,
  setDashboardNameInput,
  roleInput,
}) => {
  const { theme  ,langMode} = useContext(AppContext);
  const [isListChartModalOpen, setIsListChartModalOpen] = useState(false);
  const [openActivityQueModal, setOpenActivityQueModal] = useState(false);
  const [tempMapData, setTempMapData] = useState([]);
  const [selectedFieldQuestionId, setSelectedFieldQuestionId] = useState("");
  const [roleForDelete, setRoleForDelete] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemType, setDeleteItemType] = useState("");
  const [deleteItemId, setDeleteItemId] = useState("");
  const [selectedChart, setSelectedChart] = useState();

  const showDeleteModal = (type, id) => {
    setDeleteItemType(type);
    setDeleteItemId(id);
    setDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleCloseListModal = () => {
    setIsListChartModalOpen(false);
  };
  const handleCloseQuestionsModal = () => {
    setOpenActivityQueModal(false);
  };
  const handleOpenListModal = () => {
    if (selectedFieldQuestionId) {
      setIsListChartModalOpen(true);
      setOpenActivityQueModal(false);
    } else {
      toast.warning("Select Field name");
    }
  };

  const handleSelectedChart = (chart) => {
    setSelectedChart(chart);
  };
  const handleQuestionChange = (event) => {
    setSelectedFieldQuestionId(event.target.value);
  };

  useEffect(() => {
    if (dashboardNameInput) {
      fetchData();
    }
  }, [dashboardNameInput, roleInput]);

  const fetchData = async () => {
    try {
      const responseData = await fetchDashboardData(dashboardNameInput, roleInput);
      const graphList = responseData.body.value.graphList;
      setTempMapData(graphList);
      setRoleForDelete(responseData.body.value.role);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateGraph = async (event) => {
    event.preventDefault();
    try {
      const response = await createGraphMetric(dashboardNameInput, selectedChart, selectedFieldQuestionId, roleInput);
      if (response.header.code === 600) {
        toast.success(response.body.value);
        handleCloseListModal();
        fetchData();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteDashboard = async () => {
    try {
      const response = await deleteDashboard(dashboardNameInput, roleInput);
      if (response.header.code === 600) {
        toast.success(response.body.value);
        handleCloseDeleteModal();
        setDashboardNameInput("");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteGraphMetric = async (graphMetricId) => {
    try {
      const response = await deleteGraphMetric(graphMetricId, roleForDelete);
      if (response.header.code === 600) {
        toast.success(response.body.value);
        handleCloseDeleteModal();
        fetchData();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {dashboardNameInput && (
        <div
          className={`min-h-screen shadow-2xl bg-white ${
            theme === "dark"
              ? "dark-mode border border-blue-500 "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          <div className={`${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}>
            <div className="flex justify-end">
              <button
                onClick={() => showDeleteModal("dashboard", dashboardNameInput)}
                className="py-2.5 m-1 px-2 h-8 shadow-lg border bg-red-600 border-red-600 text-xs flex items-center gap-2  rounded-lg cursor-pointer font-semibold text-white hover:bg-red-500"
              >
                <RiDeleteBin5Line /> {translations[langMode].deletDashbord}
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 py-3 px-2 ">
              {tempMapData.map((data, i) => (
                <div
                  key={i}
                  className={`bg-white w-[400px] xl:w-[380px] px-5 py-5 border border-blue-400 rounded-md shadow-2xl ${
                    theme === "dark"
                      ? "dark-mode border rounded-md"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex justify-end">
                    <RiDeleteBin5Line
                      className="text-red-600 cursor-pointer "
                      onClick={() =>
                        showDeleteModal("graph", data.graphMetricId)
                      }
                    />
                  </div>
                  <div>
                    {data.graphType === "graph" && (
                      <BarChart
                        tempData={data}
                        chartName={
                          data.graphValueList[0]?.question || "No Name"
                        }
                      />
                    )}
                    {data.graphType === "chart" && (
                      <PieChart
                        tempData={data}
                        chartName={
                          data.graphValueList[0]?.question || "No Name"
                        }
                      />
                    )}
                    {data.graphType === "area" && (
                      <AreaChart
                        tempData={data}
                        chartName={
                          data.graphValueList[0]?.question || "No Name"
                        }
                      />
                    )}
                    {data.graphType === "radar" && (
                      <RadarChart
                        tempData={data}
                        chartName={
                          data.graphValueList[0]?.question || "No Name"
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
              <button
                onClick={() => setOpenActivityQueModal(true)}
                className={`p-4 border-2 h-[400px] w-[400px] border-blue-500 border-dashed
                   rounded-lg cursor-pointer font-semibold bg-gray-50 
                    ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }   `}
              >
                Add Chart
              </button>
            </div>
          </div>
        </div>
      )}
      {isListChartModalOpen && (
        <ListChartModal
          onClose={handleCloseListModal}
          handleSelectedChart={handleSelectedChart}
          handleCreateGraph={handleCreateGraph}
        />
      )}
      {openActivityQueModal && (
        <ActivityQuestions
          onClose={handleCloseQuestionsModal}
          handleOpenListModal={handleOpenListModal}
          dashboardNameInput={dashboardNameInput}
          selectedFieldQuestionId={selectedFieldQuestionId}
          setSelectedFieldQuestionId={setSelectedFieldQuestionId}
          handleQuestionChange={handleQuestionChange}
        />
      )}
      {deleteModalOpen && deleteItemType === "dashboard" && (
        <DeletePermission
          deleteFunction={handleDeleteDashboard}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete this dashboard?"
        />
      )}
      {deleteModalOpen && deleteItemType === "graph" && (
        <DeletePermission
          deleteFunction={() =>
            handleDeleteGraphMetric(deleteItemId, roleInput)
          }
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete this graph metric?"
        />
      )}
    </>
  );
};
export default AddChart;
