import React, { useContext, useEffect, useState, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaEdit, FaHardHat } from "react-icons/fa";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchTasks, updateTaskPriority } from "../../service/Task";
import { MdOutlineSecurity } from "react-icons/md";
import { createPortal } from "react-dom";

import { Spinner } from "../../Spinner";
const TaskListView = ({
  isChecked,
  setIsChecked,
  selectedtask,
  setselectedtask,
  selectedfieldAgent,
  selectedFieldAgentId,
}) => {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);

  const context = useContext(AppContext);
  const {
    workflowId,
    priority,
    active,
    setTaskData,
    taskData,
    setSelectAll,
    selectAll,
    areaCode,
    accountNo,
    selectedUserId,
    setAccountNo,
    selectedSubactivity,
    selectedOrgId,
  } = context;
  const previousPriority = useRef(priority);
  const previousPageSize = useRef(pageSize);

  const fetchData = async (
    workflowId,
    currentPage,
    pageSize,
    priority,
    active,
    areaCode,
    accountNo,
    selectedUserId,
    selectedSubactivity
  ) => {
    if (!workflowId) {
      setTaskData([]);
      setTotalValue(0);
      return;
    }
    try {
      setLoading(true);
      const data = await fetchTasks({
        workflowId,
        currentPage,
        pageSize,
        priority,
        active,
        areaCode,
        accountNo,
        selectedUserId,
        selectedSubactivity,
      });
      setTaskData(data.taskList);
      setTotalValue(data.totalSize);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      priority !== previousPriority.current ||
      pageSize !== previousPageSize.current
    ) {
      setCurrentPage(0);
      fetchData(
        workflowId,
        0,
        pageSize,
        priority,
        active,
        areaCode,
        accountNo,
        selectedUserId,
        selectedSubactivity
      );
    } else {
      fetchData(
        workflowId,
        currentPage,
        pageSize,
        priority,
        active,
        areaCode,
        accountNo,
        selectedUserId,
        selectedSubactivity
      );
    }
    previousPriority.current = priority;
    previousPageSize.current = pageSize;
  }, [
    workflowId,
    currentPage,
    pageSize,
    priority,
    active,
    areaCode,
    accountNo,
    selectedUserId,
    selectedSubactivity,
  ]);

  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(1);
  };

  useEffect(() => {
    if ((selectedOrgId, workflowId)) {
      setTaskData([]);
      setCurrentPage(0);
      setPageSize(10);
      // setActive('Active')
      fetchData(selectedOrgId, workflowId, currentPage, active);
    }
  }, [selectedOrgId, workflowId]);

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleCheckboxChange = (index) => {
    const newData = [...taskData];
    newData[index].isChecked = !newData[index].isChecked;

    if (newData.some((task) => task.isChecked)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }

    if (newData[index].isChecked) {
      setselectedtask([...selectedtask, newData[index].taskId]);
    } else {
      setselectedtask((prevValues) =>
        prevValues.filter((value) => value !== newData[index].taskId)
      );
    }
    setTaskData(newData);
  };

  const handleSelectAllChange = () => {
    const newData = taskData.map((task) => ({
      ...task,
      isChecked: !selectAll,
    }));
    if (!selectAll) {
      const allTaskIds = newData.map((task) => task.taskId);
      setselectedtask(allTaskIds);
      setIsChecked(true);
    } else {
      setselectedtask([]);
      setIsChecked(false);
    }
    setTaskData(newData);
    setSelectAll(!selectAll);
  };

  const updateTaskPriorityHandler = async (taskId, newPriority) => {
    try {
      const responseData = await updateTaskPriority(taskId, newPriority);
      if (responseData.header.code === 600) {
        const updatedTaskData = taskData.map((task) => {
          if (task.taskId === taskId) {
            return {
              ...task,
              priority: newPriority,
            };
          }
          return task;
        });
        setTaskData(updatedTaskData);
        setselectedtask(
          updatedTaskData
            .filter((task) => task.isChecked)
            .map((task) => task.taskId)
        );
        toast.success("Task Priority Updated");
      } else {
        console.error("Failed to update priority:", responseData.statusText);
      }
    } catch (error) {
      console.error("Error updating task priority:", error);
    }
  };

  const handleTaskClick = (taskId, accountNo) => {
    navigate(`/task-detail/${taskId}`);
    setAccountNo(accountNo);
  };

  const baseHeaders = [
    "taskId",
    "Account No.",
    "Activity",
    "priority",
    "taskStatus",
    "source",
    "Area Code",
    "Office ",
  ];
  const dynamicHeaders =
    taskData && taskData.length > 0
      ? Object.keys(JSON.parse(taskData[0].taskDetails)).filter(
          (key) => !baseHeaders.includes(key.trim()) // Applying trim here
        )
      : [];

  const tableHeaders = [...baseHeaders, ...dynamicHeaders];
  const { theme } = useContext(AppContext);

  if (!Array.isArray(taskData)) {
    return <div>No tasks available.</div>;
  }
  const handleEditTask = (taskId) => {
    navigate(`/update-complete-task/${taskId}`);
  };
  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect(); // Get the position of the hovered element
    setTooltipPosition({
      top: rect.top + window.scrollY + rect.height / 2, // Adjust for scroll and vertical alignment
      left: rect.left + window.scrollX + rect.width + 10, // Adjust for horizontal alignment
    });
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const Tooltip = ({ children, position, visible }) => {
    if (!visible) return null; // Only render when visible is true

    const tooltipContent = (
      <div
        role="tooltip"
        className="absolute w-[250px] overflow-y-auto h-auto left-full ml-4 z-50 px-4 py-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-lg"
        style={position}
      >
        {children}
      </div>
    );

    return createPortal(tooltipContent, document.body);
  };
  return (
    <>
      <div
        className={`px-2  bg-white  ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="pb-2 flex justify-between items-center">
          <div className="text-sm text-gray-400 flex items-center gap-2">
            <label htmlFor="pageSize">Rows per page:</label>
            <select
              id="countries_disabled"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            <p className="text-sm text-gray-400 flex px-5 justify-end gap-4">
              {`${startIndex}-${endIndex} of ${totalItems} items`}
              <IoIosArrowBack
                className="cursor-pointer"
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
              />
              <IoIosArrowForward
                className="cursor-pointer"
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              />
            </p>
          </div>
        </div>
        <div className="sm:rounded-lg overflow-auto relative z-0">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-y-auto relative z-0">
            <thead className="text-sm text-gray-700 border-b border-gray-300 dark:text-gray-400">
              <tr
                className={`bg-gray-100    ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <th className="text-xs font-medium uppercase tracking-wider text">
                  #
                </th>
                <th className="text-gray-600 text-xs font-medium uppercase tracking-wider text">
                  Action
                </th>
                <th scope="col" className="px-2 py-3">
                  <input
                    type="checkbox"
                    className={`w-4 h-4 text-blue-800 bg-gray-100 border-gray-300 rounded focus:ring-blue-800 dark:focus:ring-blue-800 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:border-gray-600 ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </th>
                {tableHeaders.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider text-nowrap"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading && <Spinner />}
              {totalValue === 0 ? (
                <tr>
                  <td
                    colSpan={tableHeaders.length + 2}
                    className="text-gray-700 font-normal"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                taskData.map((task, index) => {
                  const taskDetailsObj = JSON.parse(task.taskDetails);
                  return (
                    <tr
                      key={index}
                      className={`bg-white text-xs  ${
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      <td className="w-2 p-2">{index + 1}</td>
                      {task.taskStatus === "Completed" ? (
                        <button
                          className="text-green-500 hover:text-green-700"
                          onClick={() => handleEditTask(task.taskId)}
                        >
                          <span className="sr-only">Edit Task</span>
                          <FaEdit size={20} className="ml-2 my-4" />
                        </button>
                      ) : (
                        <div className="w-6"></div>
                      )}
                      <td className="w-2 p-2">
                        <div className="flex items-center">
                          {!task.protected && (
                            <>
                              <input
                                id={`checkbox-table-search-${index}`}
                                type="checkbox"
                                className={`w-4 h-4 text-blue-800 bg-gray-100 border-gray-300 rounded focus:ring-blue-800
                               dark:focus:ring-blue-800 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2
                                 ${
                                   theme === "dark"
                                     ? "dark-mode  "
                                     : theme === "high-contrast"
                                     ? "high-contrast  "
                                     : ""
                                 }`}
                                checked={task.isChecked || false}
                                onChange={() => handleCheckboxChange(index)}
                              />
                              <label
                                htmlFor={`checkbox-table-search-${index}`}
                                className="sr-only"
                              >
                                checkbox
                              </label>
                            </>
                          )}
                        </div>
                      </td>
                      {tableHeaders.map((header, index) => (
                        <td
                          key={index}
                          className="px-7 py-2 text-nowrap relative"
                        >
                          {header === "Account No." ? (
                            <div className="flex ">
                              {task.protected && (
                                <MdOutlineSecurity className="mr-2 text-blue-800" />
                              )}
                              {task.accountNo}
                            </div>
                          ) : header === "taskId" ? (
                            <div className="flex">
                              <span
                                className="flex gap-2  cursor-pointer bg-green-400 bg-opacity-30 rounded-full text-green-600 px-4 py-1"
                                onClick={() =>
                                  handleTaskClick(task.taskId, task.accountNo)
                                }
                              >
                                <div
                                  className="relative group "
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <span className="pt-2 " ref={tooltipRef}>
                                    {task.taskStatus === "Assigned" && (
                                      <FaHardHat className="text-yellow-400 m-1" />
                                    )}
                                    {task.taskStatus === "Completed" && (
                                      <FaHardHat className="text-green-500 m-1" />
                                    )}
                                    {task.taskStatus === "On_Hold" && (
                                      <FaHardHat className="text-red-500 m-1" />
                                    )}
                                    {task.taskStatus === "In_Progress" && (
                                      <FaHardHat className="text-orange-500 m-1" />
                                    )}
                                  </span>

                                  <Tooltip
                                    visible={tooltipVisible}
                                    position={tooltipPosition}
                                  >
                                    {task.assignees?.length > 0 &&
                                      task.assignees.map((assignee) => (
                                        <div
                                          key={assignee.assigneeId}
                                          className="border-b border-gray-700 last:border-none py-2"
                                        >
                                          <div className="text-yellow-300">
                                            <strong>Field Agent ID:</strong>{" "}
                                            {assignee.assigneeId}
                                          </div>
                                          <div className="text-blue-300">
                                            <strong>Field Agent Name:</strong>{" "}
                                            {assignee.assigneeName}
                                          </div>
                                        </div>
                                      ))}
                                  </Tooltip>
                                </div>
                                {task.taskId}
                              </span>
                            </div>
                          ) : header === "taskStatus" ? (
                            <span
                              className={`text-xs ${
                                task.taskStatus === "Active"
                                  ? `px-4 py-1 font-semibold rounded-full text-blue-500 bg-blue-400 ${
                                      theme === "dark"
                                        ? "bg-opacity-80 text-blue-400 "
                                        : "bg-opacity-30"
                                    }`
                                  : ""
                              } ${
                                task.taskStatus === "Assigned"
                                  ? `px-4 py-1 font-semibold rounded-full text-yellow-500 bg-yellow-400 ${
                                      theme === "dark"
                                        ? "bg-opacity-40 text-yellow-400"
                                        : "bg-opacity-30"
                                    }`
                                  : ""
                              } ${
                                task.taskStatus === "On_Hold"
                                  ? `px-4 py-1 font-semibold rounded-full text-red-500 bg-red-400 ${
                                      theme === "dark"
                                        ? "bg-opacity-30 text-opacity-80"
                                        : "bg-opacity-30"
                                    }`
                                  : ""
                              } ${
                                task.taskStatus === "Completed"
                                  ? `px-4 py-1 font-semibold rounded-full text-green-500 bg-green-400 ${
                                      theme === "dark"
                                        ? "bg-opacity-30 text-green-400"
                                        : "bg-opacity-30"
                                    }`
                                  : ""
                              } ${
                                task.taskStatus === "In_Progress"
                                  ? `px-4 py-1 font-semibold rounded-full text-orange-500 bg-orange-400 ${
                                      theme === "dark"
                                        ? "bg-opacity-30 text-opacity-80"
                                        : "bg-opacity-30"
                                    }`
                                  : ""
                              } text-sm`}
                            >
                              {task.taskStatus}
                            </span>
                          ) : header === "priority" ? (
                            <select
                              value={task.priority}
                              onChange={(e) => {
                                const newPriority = e.target.value;
                                updateTaskPriorityHandler(
                                  task.taskId,
                                  newPriority
                                );
                              }}
                              className={`py-1 px-2 text-xs border rounded-md ${
                                theme === "dark"
                                  ? "dark-mode"
                                  : theme === "high-contrast"
                                  ? "high-contrast"
                                  : ""
                              }`}
                            >
                              <option value="Low_Priority">Low</option>
                              <option value="Medium_Priority">Medium</option>
                              <option value="High_Priority">High</option>
                            </select>
                          ) : header === "source" ? (
                            // Custom rendering for the source field
                            <span
                              className={`px-2 py-1 text-xs rounded-full 
                              bg-purple-300 text-purple-700
                              `}
                            >
                              {task.source}
                            </span>
                          ) : (
                            taskDetailsObj[header]
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TaskListView;
