import React, { useContext } from "react";
import { MainNav } from "./MainNav";
import AppContext from "../../context/AppContext";
import Header from "./Header";
import { useLocation } from "react-router-dom";

export const Layout = ({ children }) => {
  const { theme } = useContext(AppContext);
  const location = useLocation();
  const isMapTaskPage = location.pathname === "/maptask";

  return (
    <div
      className={`min-h-screen ${
        theme === "dark"
          ? "dark-mode"
          : theme === "high-contrast"
          ? "high-contrast"
          : theme === "light" && "bg-gray-50 "
      } `}
    >
      {location.pathname !== "/workflow-form" && <MainNav />}
      <div
        className={` ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : theme === "light" && "bg-gray-50 "
        } flex `}
      >
        {location.pathname !== "/workflow-form" && <Header />}
        <main
          className={`w-full ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : theme === "light" && "bg-gray-50 "
          } ${isMapTaskPage ? "overflow-hidden" : ""}`}
        >
          <div
            className={`  px-2 ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : theme === "light" && "bg-gray-50 "
            }`}
          >
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};
