import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = "https://dev-api.bluefield.co.in/"; // Update with your actual base URL if needed

export const fetchMarkerColors = async (workflowId) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${BASE_URL}api/v1/marker-color/get-all?workflowId=${workflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.header.code === 600) {
      return response.data.body.value;
    }
    
    throw new Error("Failed to fetch marker colors");
  } catch (error) {
    console.error("Error fetching colors:", error);
    return [];
  }
};

export const SaveColors = async (payload) => {
    const token = Cookies.get("accessToken");
  
    try {
      const response = await axios.post(
        `${BASE_URL}api/v1/marker-color/save-all`, 
        payload, // Payload should be the second parameter for POST
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.header.code === 600) {
        return response.data.body.value;
      }
  
      throw new Error("Failed to save marker colors"); // Updated error message for clarity
    } catch (error) {
      console.error("Error saving colors:", error);
      return []; // Return an empty array on error
    }
  };
  

