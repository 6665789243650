// src/api.js
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const fetchData = async (selectedOrgId, userType) => {
  const token = Cookies.get("accessToken");
  let apiUrl = `${baseURL}api/v1/workflow/get-workflow-list`;

  if (userType === "super_admin" && selectedOrgId) {
    apiUrl += `?organizationId=${selectedOrgId}`;
  }

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonData = await response.json();
    return jsonData.body.value;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkflow = async (workflowId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.delete(
      `${baseURL}api/v1/workflow/delete`,
      {
        params: { workflowId },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      const jsonData = response.data;
      if (jsonData.header.code === 600) {
        return jsonData.body.value;
      } else {
        throw new Error(jsonData.body.value);
      }
    } else {
      throw new Error(`Received non-200 status code: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFormData = async (workflowId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/workflow/get-workflow-metadata`,
      {
        params: { workflowId },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = response.data;
    if (data.header.code === 600) {
      const updatedFieldsMetadata = data.body.value.fieldsMetadata.map(
        (field) => {
          const { workflowId, id, ...rest } = field;
          return rest;
        }
      );
      const originalWorkflowName = data.body.value.workflowName;
      const copiedWorkflowName = `${originalWorkflowName}_v1`;
      return {
        ...data.body.value,
        workflowName: copiedWorkflowName,
        fieldsMetadata: updatedFieldsMetadata,
        workflowId: undefined,
      };
    } else {
      throw new Error("Failed to fetch resources: " + data.body.error);
    }
  } catch (error) {
    throw new Error("Error fetching form data: " + error.message);
  }
};


export const fetchWorkflowData = async (workflowId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/workflow/get-workflow-metadata`,
      {
        params: { workflowId },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    if (data.header.code === 600) {
      return {
        workflowTimer: data.body.value.timer,
        workflowFields: data.body.value.fieldsMetadata,
        existWorkflowName: data.body.value.workflowName,
        existPdfTitleName: data.body.value.pdfTitle,
        existDragItems: data.body.value.dragItems,
        existRadioButtons: data.body.value.radioButtons,
      };
    } else {
      throw new Error("Failed to fetch data: " + data.body.error);
    }
  } catch (error) {
    throw new Error("Error fetching workflow data: " + error.message);
  }
};

export const getWorkflows = async (organizationId, userType) => {
  const token = Cookies.get("accessToken");
  const params = userType === "super_admin" ? { organizationId } : {};
  const response = await axios.get(
    `${baseURL}api/v1/workflow/get-workflows`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );
  return response.data;
};

export const cloneWorkflow = async (workflowName, selectedTimer, droppedItems, selectedOrgId, title) => {
  const token = Cookies.get("accessToken");
  const dataToSend = {
    timer: selectedTimer,
    workflowName: workflowName,
    pdfTitle: title,
    fieldsMetadata: droppedItems,
    organizationId: selectedOrgId,
  };
  const url = `${baseURL}api/v1/workflow/create-workflow`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataToSend),
    });

    const jsonData = await response.json();
    return jsonData
  } catch (err) {
    throw new Error("Error cloning workflow: " + err.message);
  }
};

export const createWorkflow = async (workflowData) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/create-workflow`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(workflowData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      toast.error(
        `Failed to create workflow: ${errorData.message || "Unknown error"}`
      );
      return;
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (err) {
    toast.error(`Error creating workflow: ${err.message}`);
    return null;
  }
};

export const fetchWorkflowHistory = async (workflowId, pages, size) => {
  const token = Cookies.get("accessToken");

  if (!workflowId) {
    toast.info("Create Workflow");
    return null;
  }

  const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/get-workflow-history?workflowId=${workflowId}&page=${pages}&size=${size}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorMessage = await response.json();
      toast.error(`Failed to fetch versions: ${errorMessage.message || "Unknown error"}`);
      return null;
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (err) {
    toast.error(`Error fetching workflow history: ${err.message}`);
    return null;
  }
};


export const updateWorkflow = async (workflowData) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(workflowData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      toast.error(
        `Failed to update workflow: ${errorData.message || "Unknown error"}`
      );
      return null;
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (err) {
    toast.error(`Error updating workflow: ${err.message}`);
    return null;
  }
};


export const getFieldNames = async (workflowId) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/task-meta/workflow/get-field-names?workflowId=${workflowId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Assuming you need the data from the response
  } catch (error) {
    console.error('Error fetching field names:', error);
    throw error; // Re-throwing to handle errors in the component
  }
};