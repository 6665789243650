import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppState from "./context/AppState";
import Router from "./routes/Router";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../src/assets/fonts/font.css'

function App() {
  const navigate = useNavigate();
  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      return null;
    }
  };
  const checkTokenExpiry = () => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      const decodedToken = parseJwt(accessToken); 
      const isExpired = new Date() > new Date(decodedToken.exp * 1000);
      return isExpired;
    }
    return true; 
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkTokenExpiry()) {
        const cookies = Cookies.get();
        for (const cookie in cookies) {
          Cookies.remove(cookie);
        }
        window.localStorage.removeItem("isLoggedIn");
        navigate('/');
      }
    }, 60000); 
    return () => clearInterval(interval);
  }, [navigate]);

  useEffect(()=>{
    const NoToken = ()=>{
      const accessToken = Cookies.get('accessToken');
      if(!accessToken){
        window.localStorage.removeItem("isLoggedIn");
        navigate("/")
      }
    }
    NoToken(); 
  }, [navigate]);

  const [user, setUser] = useState(null);
  return (
    <>
      <AppState>
        <Router  user={user} />
        <ToastContainer />
      </AppState>
    </>
  );
}

export default App;
