import Cookies from "js-cookie";
import React, { useContext, useEffect, useState, useRef } from "react";
import AppContext from "../../../context/AppContext";
import { getWorkflows } from "../../../service/workflow";
import classNames from "classnames";
import translations from "../Translations";

const ActivitySelect = ({ setWorkflowName, selectedOrgId }) => {
  const [data, setData] = useState([]);
  const { setWorkflowId, userType,  } = useContext(AppContext);
  const { selectedOption, setSelectedOption } = useContext(AppContext);
  const { theme, langMode } = useContext(AppContext);
  const [selectedActivityName, setSelectedActivityName] = useState("");
  const selectRef = useRef(null);

  // Retrieve selected activity only on initial load
  useEffect(() => {
    const storedOption = localStorage.getItem("selectedActivity");
    if (storedOption) {
      setSelectedOption(storedOption);
      setWorkflowId(storedOption);
    }
  }, []); // Empty dependency to run only once

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getWorkflows(selectedOrgId, userType);
        const workflows = response.body.value;
        if (Array.isArray(workflows)) {
          setData(workflows);
          const workflowMap = workflows.reduce((map, workflow) => {
            map[workflow.workflowId] = workflow.workflowName;
            return map;
          }, {});
          setWorkflowName(workflowMap);

          const selectedWorkflow = workflows.find(
            (workflow) => workflow.workflowId === selectedOption
          );
          setSelectedActivityName(
            selectedWorkflow ? selectedWorkflow.workflowName : ""
          );
        } else {
          console.error("Expected an array but got:", workflows);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching workflows:", error);
      }
    };
    fetchData();
  }, [selectedOrgId]);

  const handleOptionChange = (event) => {
    const workflowId = event.target.value;
    setSelectedOption(workflowId);
    setWorkflowId(workflowId);
    localStorage.setItem("selectedActivity", workflowId);

    const selectedWorkflow = data.find(
      (workflow) => workflow.workflowId === workflowId
    );
    setSelectedActivityName(
      selectedWorkflow ? selectedWorkflow.workflowName : ""
    );
  };

  return (
    <>
    <select
        id="activities"
        ref={selectRef}
        className={classNames(
          "select cursor-pointer w-56 max-w-sm text-center text-black text-md rounded-xl appearance-none h-10 px-2.5 bg-transparent",
          theme === "dark" ? "dark-mode" : theme === "high-contrast" ? "high-contrast" : "border-gray-500"
        )}
        onChange={handleOptionChange}
        value={selectedOption || ""}
        aria-label="Activity Selection"
      >
        <option value="" disabled={!selectedOption}>
          {translations[langMode].select}
        </option>
        {data.map((workflow) => (
          <option key={workflow.workflowId} value={workflow.workflowId}>
            {workflow.workflowName}
          </option>
        ))}
      </select>
    </>
  );
};

export default ActivitySelect;
