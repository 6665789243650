import React, { useContext, useState } from "react";
import AppContext from "../../../context/AppContext";
import { RxCross2 } from "react-icons/rx";
import {
  FaChartArea,
  FaChartBar,
  FaChartPie,
} from "react-icons/fa";
import { MdRadar } from "react-icons/md";
const ListChartModal = ({
  onClose,
  handleSelectedChart,
  handleCreateGraph,
}) => {
  const [selectedChart, setSelectedChart] = useState(null);
  const { theme } = useContext(AppContext);

  const chartInfo = {
    PIECHART: {
      name: "Pie Chart",
      icon: <FaChartPie className="h-16 w-16 mb-2 text-yellow-400" />, // Use icons
      verify: "chart",
    },
    BARCHART: {
      name: "Bar Chart",
      icon: <FaChartBar className="h-16 w-16 mb-2 text-blue-800" />, // Use icons
      verify: "graph",
    },
    AREACHART: {
      name: "Area Chart",
      icon: <FaChartArea className="h-16 w-16 mb-2 text-green-500" />, // Use icons
      verify: "area",
    },
    RADARCHART: {
      name: "Radar Chart",
      icon: <MdRadar className="h-16 w-16 mb-2 text-black-500" />, // Use icons
      verify: "radar",
    },
  };

  const handleChartClick = (verify) => {
    setSelectedChart(verify);
    handleSelectedChart(verify);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div
        className={`bg-white p-5 w-[500px] rounded-md relative max-w-2xl z-50 mx-auto ${
          theme === "high-contrast" ? "high-contrast" : ""
        }  ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
        style={{ maxHeight: "90vh" }}
      >
        <span
          className="absolute top-4 right-4 text-gray-600 dark:text-gray-300 cursor-pointer text-2xl"
          onClick={onClose}
        >
          <RxCross2 />
        </span>
        <h5
          className={`text-md font-semibold text-center mb-4 ${
            theme === "dark" ? "text-gray-300" : "text-gray-800"
          } ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          Select Chart
        </h5>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
          {Object.entries(chartInfo).map(([key, { name, icon, verify }]) => (
            <div
              key={key}
              onClick={() => handleChartClick(verify)}
              className={`cursor-pointer flex gap-7 w-[220px] items-center bg-white border-2 rounded-lg p-3 shadow-md hover:shadow-lg transition-shadow duration-300 ${
                selectedChart === verify
                  ? "border-blue-600 shadow-lg"
                  : "border-gray-200 hover:border-blue-300"
              } ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              {icon} {/* Display icon */}
              <span
                className={`text-md font-medium text-nowrap ${
                  selectedChart === verify ? "text-blue-500" : "text-gray-700"
                } ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                {name}
              </span>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-6">
          <button
            type="button"
            className="px-6 py-2 bg-blue-700  text-white rounded-md font-normal hover:bg-blue-600 transition-colors"
            onClick={handleCreateGraph}
          >
            Create Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListChartModal;
