import React, { useState , useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "../user/static-charts/ChartModal";
import AppContext from "../../../context/AppContext";

const BarChart = ({ tempData, chartName }) => {
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("bar");
  const { theme } = useContext(AppContext);

  const filteredData =
    tempData?.graphValueList?.filter((item) => item.value || "Not answered") || [];
  const categories = filteredData.map((item) => item.value);
  const data = filteredData.map((item) => item.frequency);
  const colors = ["#EF5A6F", "#3A1078", "#399918", "#FFD433", "#EBD3F8"];
  const options = {
    chart: {
      stacked: true,
    },
    xaxis: {
      categories: categories.length > 0 ? categories : ["Default Label"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "14px",
            },
            value: {
              fontSize: "14px",
            },
          },
        },
      },
    },
   dataLabels: {
      enabled: true,
      style: {
        fontSize: "9px",
        colors:
          theme === "dark"
            ? ["#fff"]
            : theme === "high-contrast"
            ? ["#000"]
            : ["#fff"], // Adjusting text color based on theme
      },
    },
    legend: {
      show: true, // Hide legend
      position: "bottom",
      labels: {
        colors:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333", // Adjusting legend label color
      },
    },
    tooltip: {
      enabled: true,
      theme: theme === "dark" ? "dark" : "light",
      style: {
        fontSize: "12px",
        background:
          theme === "dark"
            ? "#333"
            : theme === "high-contrast"
            ? "#fff"
            : "#f4f4f4", // Adjusting tooltip background color
        color:
          theme === "dark"
            ? "#fff"
            : theme === "high-contrast"
            ? "#000"
            : "#333", // Adjusting tooltip text color
      },
    },
    toolbar: {
      show: false,
    },
    colors: colors,
  };

  const series = [
    {
      name: "Frequency",
      data: data,
    },
  ];

  const ChartView = () => (
    <div id="chart">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "donut",
          },
          toolbar: {
            show: false,
          },
          labels: categories,
          plotOptions: {
            pie: {
              expandOnClick: true,
            },
          },
          dataLabels: {
            enabled: true, // Hide data labels
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#fff"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
        }}
        series={data}
        type="donut"
        width="350"
      />
    </div>
  );

  const GraphView = () => (
    <div id="graph">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true, // Hide data labels
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#333"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
        }}
        series={series}
        type="bar"
        width="360"
      />
    </div>
  );

  const AreaChartView = () => (
    <div id="area-chart">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          dataLabels: {
            enabled: true, // Hide data labels
            style: {
              fontSize: "9px",
              colors:
                theme === "dark"
                  ? ["#fff"]
                  : theme === "high-contrast"
                  ? ["#000"]
                  : ["#333"], // Adjusting text color based on theme
            },
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
            labels: {
              colors:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting legend label color
            },
          },
          tooltip: {
            enabled: true,
            theme: theme === "dark" ? "dark" : "light",
            style: {
              fontSize: "12px",
              background:
                theme === "dark"
                  ? "#333"
                  : theme === "high-contrast"
                  ? "#fff"
                  : "#f4f4f4", // Adjusting tooltip background color
              color:
                theme === "dark"
                  ? "#fff"
                  : theme === "high-contrast"
                  ? "#000"
                  : "#333", // Adjusting tooltip text color
            },
          },
        }}
        series={series}
        type="area"
        width="360"
      />
    </div>
  );
  const RadarChartView = () => {
    const radarOptions = {
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: categories || ["Category 1", "Category 2", "Category 3"],
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
      colors: colors,
    };

    return (
      <div id="radar">
        <ReactApexChart
          options={radarOptions}
          series={series}
          type="radar"
          width="350"
        />
      </div>
    );
  };
  return (
    <div className="stacked-column-chart ">
      {data.length === 0 ? (
        <div className="flex items-center justify-center text-center text-gray-500">
          No Records
        </div>
      ) : (
        <>
          <div className="flex justify-between my-5">
            <h3 className="text-sm font-semibold">{chartName}</h3>
            <button
              type="button"
              className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
              onClick={() => setShowModal(true)}
            >
              <IoEye className="w-5 h-5 mr-2" />
            </button>
          </div>
          {chartView === "chart" ? (
            <ChartView />
          ) : chartView === "area" ? (
            <AreaChartView />
          ) : chartView === "radar" ? (
            <RadarChartView />
          ) : (
            <GraphView />
          )}
        </>
      )}
      <div className="mixed-chart  mt-2">
        {" "}
        <ChartModal
          showModal={showModal}
          setShowModal={setShowModal}
          setChartView={setChartView}
        />
      </div>
    </div>
  );
};

export default BarChart;
