import React, { useState, useRef, useEffect, useContext } from "react";
import AWS from "aws-sdk";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  addGuideline,
  fetchGuidelines,
  deleteGuideline,
} from "../../service/Guidlines";
import AppContext from "../../context/AppContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoCloudUpload } from "react-icons/io5";
import DeletePermission from "../../components/suspence/DeletePermission";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Spinner } from "../../Spinner";
import translations from "../../components/common/Translations";

const Guidline = () => {
  const [uploadFile, setuploadFile] = useState(null);
  const [fileLinks, setFileLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const { selectedOrgId, theme, langMode, workflowId, userType } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handlefileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileType = event.target.files[0].type;
      if (fileType === "application/pdf" || fileType.startsWith("video/")) {
        setLoading(true);
        uploadFileToS3(event.target.files[0], fileType);
      } else {
        toast.error("Please upload PDF or video files only.");
      }
    }
  };
  const uploadGuideline = async (url, fileType, workflowId) => {
    const token = Cookies.get("accessToken");
    if (!token) {
      toast.error("Access token is missing");
      setLoading(false);
      return;
    }
    try {
      await addGuideline(token, workflowId, fileType, url);
      toast.success("Guideline uploaded successfully");
    } catch (err) {
      console.error("Error fetching files:", err);
      setError("Error fetching files");
    } finally {
      setLoading(false);
    }
  };

  async function uploadFileToS3(loader) {
    if (loading) {
      return;
    }
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_REGION,
    });
    let contentType = "application/octet-stream";
    let fileType = "other";
    if (loader.type === "application/pdf") {
      contentType = "application/pdf";
      fileType = "pdf";
    } else if (loader.type.startsWith("video/")) {
      contentType = loader.type;
      fileType = "video";
    } else {
      toast.error("Unsupported file type.");
      setLoading(false);
      return;
    }

    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${Date.now()}_${loader.name}`,
      Body: loader,
      ContentType: contentType,
    };
    try {
      const uploadData = await s3.upload(uploadParams).promise();
      const fileUrl = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${uploadParams.Key}`;
      setuploadFile(fileUrl);
      setFileLinks((prevFileLinks) => {
        const newFileLinks = [...(prevFileLinks || []), { fileUrl, fileType }];
        return newFileLinks;
      });
      try {
        await uploadGuideline(fileUrl, fileType, workflowId);
      } catch (error) {}
    } catch (error) {
      toast.error("Failed to upload file.");
    } finally {
      setLoading(false);
    }
  }
  const noImg =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  async function fetchFiles(workflowId) {
    setLoading(true);
    const token = Cookies.get("accessToken");
    try {
      const guidelines = await fetchGuidelines(token, workflowId);
      setFileLinks(guidelines);
    } catch (err) {
      setError("Error fetching files");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFiles(workflowId);
  }, [workflowId]);

  const handleShowDelete = (fileId) => {
    setFileToDelete(fileId);
    setShowDeleteModal(true);
  };
  const handleDeleteFile = async (fileId) => {
    const token = Cookies.get("accessToken");
    try {
      await deleteGuideline(token, fileId);
      setFileLinks((prevFileLinks) =>
        prevFileLinks.filter((file) => file.id !== fileId)
      );
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const navigateToWorkflow = () => {
    navigate("/workflow");
  };

  // src/components/VideoPreview.js
  const VideoPreview = ({ file }) => (
    <>
      <video controls className="w-40 h-24 mb-2">
        <source src={file.fileUrl} type={file.fileType} />
      </video>
      <a
        href={file.fileUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-600"
      >
        View Video
      </a>
    </>
  );

  // src/components/PDFPreview.js
  const PDFPreview = ({ file, index }) => (
    <>
      <div className="w-40 h-24 mb-2">
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(
            file.fileUrl || noImg
          )}&embedded=true`}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
          title={`file-${index}`}
        ></iframe>
      </div>
      <a
        href={`https://docs.google.com/viewer?url=${encodeURIComponent(
          file.fileUrl
        )}&embedded=true`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-600"
      >
        View PDF
      </a>
    </>
  );

  return (
    <div className="">
      <div className="w-full gap-2">
        <div className=" flex items-center justify-between md:justify-start p-2 gap-3">
          <div
            onClick={navigateToWorkflow}
            className={`p-2  rounded-full border cursor-pointer w-8 
            ${
              theme === "dark"
                ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
                : ""
            } 
            ${
              theme === "high-contrast"
                ? "high-contrast border border-gray-600"
                : ""
            } 
            ${
              theme !== "dark" && theme !== "high-contrast"
                ? "border-gray-400 hover:bg-gray-200"
                : ""
            }`}
          >
            <FaArrowLeft
              className={`h-3 w-3 ${
                theme === "dark"
                  ? "text-white"
                  : theme === "high-contrast"
                  ? "text-black"
                  : "text-gray-700"
              }`}
            />
          </div>
          <h4
            className={` ${
              theme === "dark"
                ? "dark-mode border "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            } uppercase text-sm  font-semibold text-gray-800`}
          >
            {translations[langMode].guidline}
          </h4>
        </div>
        <div className="min-h-screen md:flex mt-2">
          <div
            className={`p-6 bg-white text-medium h-[550px] overflow-y-auto text-gray-500 shadow-2xl rounded-md border border-blue-400 w-full ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            {userType === "super_admin" && (
              <>
                {!selectedOrgId && (
                  <div className={`text-gray-700 mb-4 `}></div>
                )}
              </>
            )}
            {selectedOrgId && !workflowId && (
              <div
                className={`text-gray-700 mb-4 text-center ${
                  theme === "dark"
                    ? "dark-mode  "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
                {translations[langMode].pleaseselect}
              </div>
            )}
            {selectedOrgId && workflowId && (
              <div className="flex gap-5">
                <div className="link-content hide-scrollbar flex">
                  {loading ? (
                    <Spinner />
                  ) : error ? (
                    <p>{error}</p>
                  ) : Array.isArray(fileLinks) && fileLinks.length > 0 ? (
                    <div className="flex flex-wrap justify-around items-start gap-4">
                      {fileLinks.map((file, index) =>
                        file.fileType === "pdf" || file.fileType === "video" ? (
                          <div
                            key={file.id || index} // Use a unique key if available
                            className={`file-preview bg-white shadow-lg rounded-lg overflow-hidden ${
                              theme === "dark"
                                ? "dark-mode border rounded-md"
                                : theme === "high-contrast"
                                ? "high-contrast"
                                : ""
                            }`}
                          >
                            <div className="p-4">
                              {file.fileType === "pdf" ? (
                                <PDFPreview file={file} index={index} />
                              ) : (
                                <VideoPreview file={file} />
                              )}
                            </div>
                            <div
                              className="dots relative flex flex-col items-end pr-5 pb-2 gap-1 cursor-pointer"
                              onClick={() => handleShowDelete(file.id)}
                            >
                              <RiDeleteBin2Line className="text-red-500" />
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  ) : (
                    <p
                      className={
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }
                    >
                      No files available
                    </p>
                  )}
                </div>
                <div onClick={handleDivClick} className="cursor-pointer">
                  <div className="border border-dashed border-blue-500 rounded-md w-[200px] h-[180px] flex flex-col items-center justify-center hover:border-blue-700 transition-all duration-300">
                    <IoCloudUpload className="h-10 w-10 mb-2 text-blue-600" />
                    <span className="text-sm text-blue-600">
                      Drag and drop to upload
                    </span>
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      accept=".pdf, .mp4"
                      onChange={handlefileUpload}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeletePermission
          onClose={() => setShowDeleteModal(false)}
          deleteFunction={() => handleDeleteFile(fileToDelete)}
          onDeleteText="Are sure want to delete file ?"
        />
      )}
    </div>
  );
};
export default Guidline;
