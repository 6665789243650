import React, { useContext, useEffect, useState, useRef } from "react";
import AppContext from "../../context/AppContext";
import { getWorkflows } from "../../service/workflow";
import { IoIosArrowDown } from "react-icons/io";
import translations from "./Translations";

const MultipleActivitySelect = ({
  getWorkflowId,
  selectedOption,
  setSelectedOption,
}) => {
  const [data, setData] = useState([]);
  const { selectedActivityNames, setSelectedActivityNames, theme, selectedOrgId, setWorkflowId, langMode, userType ,resetactivity, setResetActivity} =
    useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [WorkflowName, setWorkflowName] = useState({});
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (Array.isArray(getWorkflowId)) {
      setSelectedOption(getWorkflowId);
    }
  }, [getWorkflowId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getWorkflows(selectedOrgId, userType);
        const workflows = response.body.value;
        if (Array.isArray(workflows)) {
          setData(workflows);
          const workflowMap = workflows.reduce((map, workflow) => {
            map[workflow.workflowId] = workflow.workflowName;
            return map;
          }, {});
          setWorkflowName(workflowMap);
          const selectedWorkflowNames = workflows
            .filter((workflow) => getWorkflowId.includes(workflow.workflowId))
            .map((workflow) => workflow.workflowName);
          setSelectedActivityNames(selectedWorkflowNames);
        } else {
          console.error("Expected an array but got:", workflows);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching workflows:", error);
      }
    };
    fetchData();
  }, [selectedOrgId, getWorkflowId]);

  const handleOptionChange = (workflowId) => {
    let updatedSelectedOptions;
    if (selectedOption.includes(workflowId)) {
      updatedSelectedOptions = selectedOption.filter((id) => id !== workflowId);
    } else {
      updatedSelectedOptions = [...selectedOption, workflowId];
    }
    setSelectedOption(updatedSelectedOptions);
    setWorkflowId(updatedSelectedOptions);
    const selectedWorkflowNames = data
      .filter((workflow) =>
        updatedSelectedOptions.includes(workflow.workflowId)
      )
      .map((workflow) => workflow.workflowName);
    setSelectedActivityNames(selectedWorkflowNames);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close dropdown if clicked outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log(selectedOption, "selectedOption");
  }, [selectedOption]);

  return (
    <div
      className={`relative ${
        theme === "dark"
          ? "dark-mode"
          : theme === "high-contrast"
          ? "high-contrast"
          : ""
      }`}
      ref={dropdownRef}
    >
      <div
        className={`flex bg-white border text-nowrap h-10 text-sm w-56 border-gray-500
           text-gray-900 rounded-lg px-2 py-2 cursor-pointer transition-all  focus:outline-none ${
             theme === "dark"
               ? "dark-mode "
               : theme === "high-contrast"
               ? "high-contrast  "
               : ""
           } ${selectedActivityNames.length > 0 ? "w-60" : ""}`}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {selectedActivityNames.length > 0
          ? `${selectedActivityNames.length} Workflow(s) selected`
          : translations[langMode].select}

        <IoIosArrowDown
          size={20}
          className={` ${
            selectedActivityNames.length > 0 ? "ml-10" : "ml-20"
          }  text-gray-700`}
        />
      </div>
      {dropdownOpen && (
        <div
          className={`absolute z-10 mt-1 w-52 bg-white border border-gray-300 rounded-lg
           shadow-lg overflow-auto max-h-60 ${
             theme === "dark"
               ? "dark-mode "
               : theme === "high-contrast"
               ? "high-contrast  "
               : ""
           }`}
        >
          {data.map((workflow) => (
            <label
              key={workflow.workflowId}
              className="flex items-center p-2 cursor-pointer  text-sm"
            >
              <input
                type="checkbox"
                value={workflow.workflowId}
                checked={selectedOption.includes(workflow.workflowId)}
                onChange={() => handleOptionChange(workflow.workflowId)}
                className="mr-3 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              {workflow.workflowName}
            </label>
          ))}
        </div>
      )}
      {selectedActivityNames.length > 0 && (
        <div className="mt-3 text-xs h-32 overflow-y-auto">
          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 w-[350px]">
            {selectedActivityNames.map((activityName, index) => (
              <li key={index} className="text-sm text-gray-800">
                <span className="bg-blue-100 text-blue-800 text-xs rounded-md p-2 inline-flex items-center">
                  {activityName}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default MultipleActivitySelect;
