// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Manrope-Medium.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'MediumFont';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/font.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,4DAAkD;IAClD,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,sBAAsB;IACtB,+DAAqD;IACrD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'MediumFont';\n    src: url('./Manrope-Medium.woff2') format('woff2');\n    font-weight: normal;\n    font-style: normal;\n  }\n  @font-face {\n    font-family: 'Manrope';\n    src: url('./Manrope-Medium.woff2') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
