// s3Operations.js

import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});

export async function deleteS3Object(objectKey) {
  try {
    const deleteParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: objectKey,
    };
    await s3.deleteObject(deleteParams).promise();
  } catch (error) {
    console.error("Error deleting object:", error);
  }
}

export async function uploadFileToS3(file) {
  const uploadParams = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: file.name,
    Body: file,
  };
  try {
    const uploadData = await s3.upload(uploadParams).promise();
    const url = uploadData.Location;
    return url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}
export const deleteS3Objects = async (fileUrl) => {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: fileUrl.name,
    Body: fileUrl,
   
  };

  try {
    await s3.deleteObject(params).promise();
  } catch (error) {
    console.error('Error deleting file from S3:', error);
    throw error;
  }
};