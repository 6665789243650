import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
const translation={
  en:{
   validation:"Add Validation"
  },
  ar:{
  validation:"إضافة التحقق من الصحة"
  }
}
const ValidationPreview = ({
  setCurrentValidation,
  setValidationModal,
  setValidationId,
  previewKey,
  validationId,
  validationFormValues,
}) => {
  const handleValidationModal = (uniqueKey) => {
    setCurrentValidation({
      validationId: validationId,
      validationCode: "",
      errorMessage: "",
      uniqueKey: uniqueKey,
    });
    setValidationModal(true);
    setValidationId((prev) => prev + 1);
  };
  const {theme,langMode} = useContext(AppContext)
  return (
    <div
      className={`block max-w-sm p-2 mt-3 bg-white border border-gray-200 rounded-lg shadow
         ${
           theme === "dark"
             ? "dark-mode "
             : theme === "high-contrast"
             ? "high-contrast"
             : ""
         }`}
    >
      <div className="flex items-center justify-center space-x-2.5">
        <div className="flex justify-between content-center item-center  p-2">
          <div>
            <button
              onClick={() => {
                handleValidationModal(previewKey);
              }}
              className="mt-1 whitespace-nowrap text-xs px-2 ml-1 bg-blue-800 hover:bg-blue-600 text-white font-semibold py-2  rounded-lg focus:outline-none"
            >
              {translation[langMode].validation}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationPreview;
