"use client";
import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { toast } from "react-toastify"; 
import {
  permanantdeletefunction,
  softdeletefunction,
} from "../../service/Task";

export const DeleteOption = ({ fetchData, deleteOption, setDeleteOption, fileid }) => {
  const [deletionType, setDeletionType] = useState(null); 
  const [confirmModalOpen, setConfirmModalOpen] = useState(false); 

  const handleApply = () => {
    if (deletionType) {
      setConfirmModalOpen(true); 
    } else {
      toast.error("Please select a deletion type."); 
    }
  };

  const handleDelete = async () => {
    try {
      if (deletionType === "soft") {
        await softdeletefunction(fileid);
      } else if (deletionType === "permanent") {
        await permanantdeletefunction(fileid);
      }
      fetchData(); 
      setDeleteOption(false); 
      setDeletionType(null)
    } catch (error) {
      toast.error("Failed to delete the file.");
    } finally {
      setConfirmModalOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={deleteOption}
        onClose={() => setDeleteOption(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 mt-2"
                    >
                      Delete Tasks
                    </Dialog.Title>
                    <div className="mt-5 flex flex-col">
                      <div className="flex gap-2">
                        <input
                          type="radio"
                          className="mt-2.5"
                          checked={deletionType === "soft"}
                          onChange={() => setDeletionType("soft")}
                        />
                        <label className="mt-2">Do soft deletion</label>
                      </div>
                      <div className="flex gap-2">
                        <input
                          type="radio"
                          className="mt-2.5"
                          checked={deletionType === "permanent"}
                          onChange={() => setDeletionType("permanent")}
                        />
                        <label className="mt-2">Do permanent deletion</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={handleApply}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Apply
                </button>
                <button
                  type="button"
                  onClick={() => setDeleteOption(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Confirm Deletion
                  </Dialog.Title>
                  <p className="mt-2 text-sm text-gray-500">
                    Are you sure you want to {deletionType} delete this file? This action
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={() => setConfirmModalOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
};
