import React, { useContext } from "react";
import DashboardMenus from "./DashboardMenus.js";
import AppContext from "../../context/AppContext.js";
const Header = () => {
  const { theme } = useContext(AppContext);
  return (
    <header
      className={`${
        theme === "dark"
          ? "dark-mode"
          : theme === "high-contrast"
          ? "high-contrast"
          : ""
      }`}
    >
      <div
        className={`${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div
          className={`flex items-center gap-4 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          <DashboardMenus />
        </div>
      </div>
    </header>
  );
};

export default Header;
