import React, { useState, useEffect, useCallback, useContext } from "react";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { fetchTaskForm, saveTask, fetchFieldsValue } from "../../service/Task";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"
import translations from "../../components/common/Translations";

const requiredFields = [
  "Account No.",
  "Activity",
  "Latitude",
  "Longitude",
  "Area Code",
  "Area"
];
const CreateTask = () => {
  const [accountNo, setAccountNo] = useState("");
  const [taskData, setTaskData] = useState({});
  const [inputFields, setInputFields] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const context = useContext(AppContext);
  const { workflowId, theme, langMode } = context;
  const navigate = useNavigate();
  const handleGoBack = () => {
    const navigationPath =
     Cookies.get("viewmode") === "map" ? "/maptask" : "/task-manage";
    navigate(navigationPath);
    Cookies.remove("viewmode");
  };

  const handleActivityChange = async () => {
    try {
      const responseData = await fetchTaskForm(workflowId);
      const responseValue = responseData.body.value;
      if (Array.isArray(responseValue)) {
        setInputFields(responseValue);
        const accountField = responseValue.find(
          (field) => field.fieldName === "Account No."
        );
        if (accountField) {
          setAccountNo(taskData[accountField.fieldName] || "");
        }
      } else {
        console.error("API response data is not an array:", responseValue);
      }
      if (responseData.header.code === 607) {
        toast.error("No data available");
        setInputFields([]);
      }
    } catch (error) {
      console.error("Error in handleActivityChange:", error);
    }
  };

  const debouncedAccountFetch = useCallback(
    debounce(async (accountNo) => {
      try {
        const responseData = await fetchFieldsValue(accountNo);
        const responseValue = responseData.body.value;
        if (responseValue) {
          setInputValues(responseValue);
        }
        if (responseData.header.code === 607) {
          toast.error("No data available");
          setInputFields([]);
        }
      } catch (error) {
        console.error("Error in accountFetch:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleActivityChange();
  }, [workflowId]);

  useEffect(() => {
    if (inputValues) {
      setTaskData((prevTaskData) => ({
        ...prevTaskData,
        "Area": inputValues.Area || '',
        "Area Code": inputValues["Area Code"] || '',
        "Latitude": inputValues.Latitude || '',
        "Longitude": inputValues.Longitude || '',
        "Account No.": inputValues["Account No."] || prevTaskData["Account No."],
      }));
    }
  }, [inputValues]);

  const handleInputChange = (fieldName, value) => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      [fieldName]: value,
    }));
    if (fieldName === "Account No.") {
      setAccountNo(value);
      if (value.length > 5) {
        debouncedAccountFetch(value); // Use debounced function
      }
    }
  };
  const validateFields = () => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!taskData[field]) {
        newErrors[field] = `${field} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSaveTask = async () => {
    if (validateFields()) {
      try {
        const response = await saveTask(workflowId, taskData, accountNo);
        if (response.header.code === 600) {
          toast.success("Task Created Successfully");
          setTaskData({});
          setInputFields([]);
          setAccountNo("");
          handleGoBack()
        } else {
          toast.error(response.body.value);
        }
      } catch (error) {
        console.error("Error in handleSaveTask:", error);
      }
    } else {
      toast.error("Please fill in the required fields.");
    }
  };
  
  return (
      <div className="w-full py-5">
        <div className="flex gap-16">
          <div
            onClick={handleGoBack}
            className={`p-2 absolute  top-30 rounded-full border cursor-pointer w-8  ml-5
            ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""}
            ${
              theme === "high-contrast"
                ? "high-contrast border border-gray-600"
                : ""
            }
            ${
              theme !== "dark" && theme !== "high-contrast"
                ? "border-gray-400 hover:bg-gray-200"
                : ""
            }`}>
            <FaArrowLeft
              className={`h-3 w-3 ${
                theme === "dark"
                  ? "text-white"
                  : theme === "high-contrast"
                  ? "text-black"
                  : "text-gray-700"
              }`}
            />
          </div>
          <h4 className={`ml-16 mt-1 uppercase text-sm font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}>
            {translations[langMode].createnewtask}
          </h4>
        </div>
        <div
          className={`px-5 py-5 mt-5  bg-white  ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""  }`} >
          {!workflowId && inputFields.length === 0 ? (
            <p className="text-red-500 font-semibold mt-10 text-center">
              {translations[langMode].selectMsg}
            </p>
          ) : (
            <div className="w-2/2">
              <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-8">
                {inputFields.map((field, index) => (
                  <div className="sm:col-span-2" key={index}>
                    <div className="relative">
                      <input
                        id={`input_${index}`}
                        value={taskData[field.fieldName] || ""}
                        onChange={(e) =>
                          handleInputChange(field.fieldName, e.target.value)
                        }
                        className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                      />
                      <label className={`absolute text-md text-gray-700 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${theme === "dark" ? "dark-mode" : theme === "high-contrast" ? "high-contrast" : ""}`}>
                        {field.fieldName}
                        {requiredFields.includes(field.fieldName) && <span className="text-red-500 text-2xl">*</span>}
                      </label>
                      {errors[field.fieldName] && <p className="text-red-500 text-xs mt-1">{errors[field.fieldName]}</p>}
                  
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div>
            <hr className="w-full border-gray-300" />
            <div className="pt-5 flex justify-end items-center">
              <button
                type="button"
                onClick={handleGoBack}
                className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                {translations[langMode].cancel}
              </button>
              <button
                type="button"
                onClick={handleSaveTask}
                className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                {translations[langMode].create}
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CreateTask;
