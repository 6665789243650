import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { addVehical } from "../../service/Asset";
import { FaArrowLeft } from "react-icons/fa";
import translations from "../../components/common/Translations";

const AddVehical = () => {
  const [vehicleType, setVehicleType] = useState("");
  const [kilometerUsed, setkilometerUsed] = useState("");
  const { theme, selectedOrgId, langMode } = useContext(AppContext);
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    setVehicleType(event.target.value);
  };
  const handleKilometerChange = (event) => {
    setkilometerUsed(event.target.value);
  };
  const handleAddVehicle = async () => {
    if (!kilometerUsed || !vehicleType) {
      toast.error("Please fill all field before adding vehical");
    }
    const formData = {
      vehicleType: vehicleType,
      kilometerUsed: parseInt(kilometerUsed),
      organizationId: selectedOrgId,
    };
    try {
      await addVehical(formData);
      setVehicleType("");
      setkilometerUsed("");
      navigate("/asset");
    } catch (error) {}
  };
  const handleCancel = () => {
    setVehicleType("");
    setkilometerUsed("");
  };
  const handleGoBack = () => {
    navigate("/asset");
  };
  return (
    <>
      <div
        onClick={handleGoBack}
        className={`p-2 absolute  top-30 rounded-full border cursor-pointer w-8 m-3 ml-5 mt-4
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "high-contrast border border-gray-600"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }
  `}
      >
        <FaArrowLeft
          className={`h-3 w-3 ${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`}
        />
      </div>
      <div className="w-full py-5  h-full">
        <h4 className={`ml-16 mt-1 text-sm uppercase font-semibold text-gray-800${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}>
          {translations[langMode].addVehical}
        </h4>
        <div
          className={`px-5 py-2 mt-5 h-full bg-white ${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          <div className="w-1/2">
            {/* <h3 className="text-sm text-gray-500 font-bold">Add New Vehical</h3> */}
            <div className=" mt-10 grid grid-cols-1 gap-x-8 gap-y-5 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <div className="relative">
                  <select
                    id="vehical_dropdown"
                    value={vehicleType}
                    onChange={handleSelectChange}
                    className={`${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent
                   rounded-md border-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="SUV">SUV</option>
                    <option value="Hatchback">Hatchback</option>
                  </select>
                  <label
                    htmlFor="vehical_dropdown"
                    className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 
                  top-2 z-6 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  >
                    Vehicle Type
                  </label>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="sm:col-span-2">
                  <div className="relative">
                    <input
                      type="text"
                      id="kilometerUsed"
                      value={kilometerUsed}
                      onChange={handleKilometerChange}
                      className={`${
                        theme === "dark"
                          ? "dark-mode  "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                    />
                    <label
                      className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                    >
                      Kilometers
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-40">
            <div className="sm:col-span-4">
              <hr className="w-full border-gray-300" />
            </div>
            <div className="sm:col-span-4 pt-5 flex justify-end items-center">
              <button
                type="button"
                onClick={handleCancel}
                className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                {translations[langMode].cancle}
              </button>
              <button
                type="button"
                onClick={handleAddVehicle}
                className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                {translations[langMode].add}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVehical;
