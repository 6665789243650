import React, { useState, useEffect, useContext } from "react";
import FilterSelect from "../common/FilterSelect";
import AppContext from "../../context/AppContext";
import translations from "../common/Translations";

const ReportToolbar = ({ onFiltersChange }) => {
  const [ReportType, setReportType] = useState("");
  const [UserType, setUserType] = useState("");
  const [Status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { theme , langMode } = useContext(AppContext);

  const reportType = [
    { id: "", value: "Default" },
    { id: "VEHICLES", value: "Vehicles" },
    { id: "INVENTORY", value: "Inventory" },
  ];

  const userType = [
    { id: "", value: "Default" },
    { id: "admin", value: "Admin" },
    { id: "office_user", value: "Office_user" },
  ];

  const status = [
    { id: "", value: "Default" },
    { id: "0", value: "Pending" },
    { id: "1", value: "Ready To Download" },
  ];

  useEffect(() => {
    onFiltersChange({
      ReportType,
      UserType,
      Status,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    });
  }, [ReportType, UserType, Status, fromDate, toDate, onFiltersChange]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleReportType = (value) => {
    if (value === "Default") {
      setReportType("");
    } else {
      setReportType(value);
    }
  };

  const handleUserTypeChange = (value) => {
    if (value === "Default") {
      setUserType("");
    } else {
      setUserType(value);
    }
  };
  const handleStatusChange = (value) => {
    if (value === "Default") {
      setStatus("");
    } else {
      const selectedStatus = status.find((status) => status.value === value);
      setStatus(selectedStatus ? selectedStatus.id : "");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
    <div className="flex flex-col sm:flex-row gap-2 w-full lg:w-auto">
      <div className={`pb-4 ${theme === "dark" ? "dark-mode" : theme === "high-contrast" ? "high-contrast" : ""}`}></div>
  
      {/* Report Type Filter */}
      <FilterSelect
        options={reportType}
        defaultText={translations[langMode].reportType}
        value={ReportType}
        onChange={handleReportType}
        className="w-full md:w-36"
      />
  
      {/* User Type Filter */}
      <FilterSelect
        options={userType}
        defaultText={translations[langMode].userType}
        value={UserType}
        onChange={handleUserTypeChange}
        className="w-full md:w-36"
      />
  
      {/* Status Filter */}
      <FilterSelect
        options={status}
        defaultText={translations[langMode].status}
        value={status.find((s) => s.id === Status)?.value || ""}
        onChange={handleStatusChange}
        className="w-full md:w-36"
      />
  
      {/* From Date Input */}
      <div className="px-1  w-full md:w-36 mt-1">
        <div className="flex items-center">
          <input
            type="text"
            className={`text-sm form-input border  border-gray-400 rounded-md w-full h-8 ${
              theme === "dark" ? "dark-mode border-gray-200 placeholder-white" : theme === "high-contrast" ? "high-contrast" : ""
            }`}
            value={fromDate ? formatDate(fromDate) : ""}
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => {
              if (!e.target.value) {
                e.target.type = "text";
              }
            }}
            onChange={(e) => setFromDate(e.target.value)}
            placeholder={translations[langMode].fromdate}
          />
        </div>
      </div>
  
      {/* To Date Input */}
      <div className=" px-1 w-full md:w-36 mt-1">
        <div className="flex items-center">
          <input
            type="text"
            className={`text-sm form-input border  border-gray-400 rounded-md w-full h-8 ${
              theme === "dark" ? "dark-mode border-gray-200 placeholder-white" : theme === "high-contrast" ? "high-contrast" : ""
            }`}
            value={toDate ? formatDate(toDate) : ""}
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => {
              if (!e.target.value) {
                e.target.type = "text";
              }
            }}
            onChange={(e) => setToDate(e.target.value)}
            placeholder={translations[langMode].todate}
          />
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default ReportToolbar;
