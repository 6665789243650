import React, { useContext, useState } from "react";
import TaskListToolbar from "../../components/task/TaskListToolbar";
import TaskListView from "../../components/task/TaskListView";
import AppContext from "../../context/AppContext";
import translations from "../../components/common/Translations";

const TaskManageMain = () => {
  const [openTab, setOpenTab] = useState(2);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedtask, setselectedtask] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [workflowName, setWorkflowName] = useState([]);
  const { theme, langMode } = useContext(AppContext);
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(0);
  };
  return (
    <div className="w-full relative ">
      <h4 className={`mt-3 text-sm uppercase font-semibold text-gray-800 ${
            theme === "dark"
              ? "dark-mode  "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}>
        {translations[langMode].listvbiew}
      </h4>
      <div
        className={`px-3 py-2 w-full    bg-white ${
          theme === "dark"
            ? "dark-mode  "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}  >
        <TaskListToolbar
          openTab={openTab}
          setOpenTab={setOpenTab}
          isChecked={isChecked}
          selectedtask={selectedtask}
          setIsChecked={setIsChecked}
          onFilterChange={handleFilterChange}
          setWorkflowName={setWorkflowName}
         
        />
        <div className="">
          <TaskListView
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            selectedtask={selectedtask}
            setselectedtask={setselectedtask}
            
          />
        </div>
      </div>
    </div>
  );
};

export default TaskManageMain;
