import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import AppContext from "../../context/AppContext";
import Modal from "../../components/modals/Modal";
import { fetchPdfData } from "../../service/Task";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const translation = {
  en: {
    pdf: "Export PDF",
  },
  ar: {
    pdf: "تصدير ملف PDF",
  },
};
function PdfGenerator() {
  const [pdfData, setPdfData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [AccountNo, setAccountNo] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedPdfLink, setSelectedPdfLink] = useState("");
  const context = useContext(AppContext);
  const { workflowId } = context;
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalValue);
  const totalItems = totalValue;
  const { theme, langMode } = useContext(AppContext);
  const [hasShownToast, setHasShownToast] = useState(false); // State to track toast display

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const formatDate = (date) => date.toISOString().split("T")[0];
    setFromDate(formatDate(yesterday));
    setToDate(formatDate(today));
  }, []);

  const getData = async () => {
    try {
      const data = await fetchPdfData(
        workflowId,
        AccountNo,
        currentPage,
        pageSize,
        fromDate,
        toDate
      );
      setPdfData(data.taskPdfList);
      setTotalValue(data?.count || 0);
    } catch (error) {}
  };
  useEffect(() => {
    // Check if workflowId is missing and show toast only once
    if (!workflowId) {
      if (!hasShownToast) {
        toast.warn("Select Workflow first");
        setHasShownToast(true); // Mark that the toast has been shown
      }
      return; // Prevent data fetching when workflowId is missing
    }

    // If workflowId is valid, proceed to fetch data and reset toast state
    setHasShownToast(false);
    getData();
  }, [workflowId, AccountNo, currentPage, pageSize, fromDate, toDate]);

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };

  const handleExportClick = (pdfLink) => {
    setSelectedPdfLink(pdfLink);
    setOpen(true);
  };

  const handleFromDate = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDate = (event) => {
    setToDate(event.target.value);
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    const navigationPath =
      Cookies.get("viewMode") === "worklog" ? "/worklog" : "/dashboard";
    navigate(navigationPath);
    Cookies.remove("viewMode");
  };

  return (
    <div>
      <div className="w-full">
        <div className="flex p-2 gap-5">
          <div
            onClick={handleGoBack}
            className={`p-2 py-2 mt-3 rounded-full border cursor-pointer w-8 h-8
${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
${theme === "high-contrast" ? "high-contrast border border-gray-600" : ""} 
${
  theme !== "dark" && theme !== "high-contrast"
    ? "border-gray-400 hover:bg-gray-200"
    : ""
}`}
          >
            <FaArrowLeft
              className={`h-3 w-3 ${
                theme === "dark"
                  ? "text-white"
                  : theme === "high-contrast"
                  ? "text-black"
                  : "text-gray-700"
              }`}
            />
          </div>
          <h4
            className={`mt-5 text-sm uppercase font-semibold text-gray-800 ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            {translation[langMode].pdf}
          </h4>
        </div>
        <div
          className={`bg-white py-5  ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast "
              : ""
          }`}
        >
          <div className="flex gap-4 px-5">
            <div className="flex flex-col ">
              <label
                className={`text-xs font-semibold uppercase tracking-wider mb-1 text-gray-700 dark:text-gray-300 ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
                From Date
              </label>
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDate}
                className={`w-full p-2 rounded-md border-2 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  theme === "dark"
                    ? "bg-gray-800 text-white border-gray-700"
                    : theme === "high-contrast"
                    ? "bg-black text-yellow-200 border-yellow-500"
                    : "bg-white text-gray-900 border-gray-300"
                }`}
              />
            </div>
            <div className="flex flex-col ">
              <label
                className={`text-xs font-semibold uppercase tracking-wider mb-1 text-gray-700 dark:text-gray-300 ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
                To Date
              </label>
              <input
                type="date"
                value={toDate}
                onChange={handleToDate}
                className={`w-full p-2 rounded-md border-2 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  theme === "dark"
                    ? "bg-gray-800 text-white border-gray-700"
                    : theme === "high-contrast"
                    ? "bg-black text-yellow-200 border-yellow-500"
                    : "bg-white text-gray-900 border-gray-300"
                }`}
              />
            </div>
            <div className="flex flex-col">
            <label
                className={`text-xs font-semibold uppercase tracking-wider mb-1 text-gray-700 dark:text-gray-300 ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
               Account No
              </label>
              <input
                className={`${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast "
                    : ""
                } w-full p-2 h-10  rounded-md text-sm text-gray-800`}
                type="text"
                placeholder="Search by account no.."
                value={AccountNo}
                onChange={handleAccountNoChange}
              />
            </div>
          </div>
          <div className="px-5 py-5 flex justify-between items-center">
            <div className="text-sm text-gray-400 flex items-center gap-4">
              <label htmlFor="pageSize">Rows per page:</label>
              <select
                id="pageSize"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
            <div>
              <p className="text-sm text-gray-400 flex items-center gap-4">
                {`${startIndex + 1}-${Math.min(
                  endIndex,
                  totalItems
                )} of ${totalItems} items`}
                <button
                  onClick={goToPreviousPage}
                  disabled={currentPage === 0}
                  className={`p-2 rounded-lg ${
                    currentPage === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-200"
                  }`}
                >
                  <IoIosArrowBack />
                </button>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                  className={`p-2 rounded-lg ${
                    currentPage === totalPages
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-200"
                  }`}
                >
                  <IoIosArrowForward />
                </button>
              </p>
            </div>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg  overflow-y-auto">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead
                class={`text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <tr>
                  <th scope="col" class="px-6 py-3 flex  gap-3 ">
                    <span className="mt-3"> Account No</span>
                  </th>
                  <th scope="col" class="px-6 py-3">
                    <div class="flex items-center">Visit Date</div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                    <div class="flex items-center">Visit Time</div>
                  </th>
                  <th scope="col" class="px-6 py-3 flex gap-3">
                    <FaCloudDownloadAlt
                      size={20}
                      className="mt-1 text-gray-800"
                    />{" "}
                    <span className="mt-1">Export PDF</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pdfData.map((item) => (
                  <tr
                    key={item.id}
                    class={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    <th
                      scope="row"
                      class={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      } `}
                    >
                      {item.accountNo}
                    </th>
                    <td class="px-6 py-4">{item.visitDate}</td>
                    <td class="px-6 py-4">{item.visitTime}</td>
                    <td class="px-6 py-4 ">
                      {item.pdfLink ? (
                        <div onClick={() => handleExportClick(item.pdfLink)}>
                          <button className="py-2 px-5 rounded-md w-20 bg-yellow-400 hover:bg-yellow-500 text-white">
                            Export
                          </button>
                        </div>
                      ) : (
                        <span className="text-gray-800">No PDF available</span>
                      )}
                    </td>
                  </tr>
                ))}
                {totalValue === 0 && (
                  <div className="flex justify-center text-center items-center">
                    <span className="text-gray-800 text-center ">
                      No PDF available
                    </span>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {open && (
        <Modal pdfLink={selectedPdfLink} setOpen={setOpen} open={open} />
      )}
    </div>
  );
}

export default PdfGenerator;
