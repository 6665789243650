import React, {
  useContext,
  useState,
  useEffect,
} from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { fetchExportData, fetchReportList } from "../../service/Task"; // Add `fetchReportList` API call
import MultipleActivitySelect from "../../components/common/MultipleActivitySelect";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../Spinner";
import { IoRefresh } from "react-icons/io5";
import translations from "../../components/common/Translations";

const ExportData = () => {
  const context = useContext(AppContext);
  const { theme, langMode ,setSelectedActivityNames } = context;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const [startDate, setStartDate] = useState(formatDate(new Date()));
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [selectedOption, setSelectedOption] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5); // Number of rows per page
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchReportListData = async () => {
    setIsLoading(true);
    try {
      const { reportRequestList, totalSize } = await fetchReportList(
        currentPage,
        pageSize
      );
      setReportData(reportRequestList || []);
      setTotalValue(totalSize || 0);
      setSelectedOption([]);
    } catch (error) {
      console.error("Error fetching report list:", error);
      toast.error("Failed to fetch reports");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchReportListData();
  }, [currentPage, pageSize]);
  // Trigger report request
  const fetchData = async () => {
    if (!selectedOption.length) {
      toast.error("Please select at least one activity");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetchExportData(
        selectedOption,
        startDate,
        endDate
      );
      if (response.data.header.code === 600) {
        toast.success(response.data.body.value);
        fetchReportListData();
        setSelectedActivityNames([]);
      } else {
        toast.error(response.data.body.value);
      }
    } catch (error) {
      console.error("Error submitting report request:", error);
      toast.error("Failed to submit report request");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportListData(); // Fetch report list on component mount
  }, []);

  useEffect(() => {
    fetchReportListData(); // Initial fetch for report list
  }, []);
  return (
    <div>
      {isLoading && <Spinner/>}
      <div className="flex m-2">
        <div
          onClick={() => navigate("/worklog")}
          className={`p-2 rounded-full border cursor-pointer w-8 h-8 ${
            theme === "dark"
              ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
              : "border-gray-400 hover:bg-gray-200"
          }`}
        >
          <FaArrowLeft
            className={theme === "dark" ? "text-white" : "text-gray-700"}
          />
        </div>
        <h3
          className={`px-5 text-sm mt-2 font-semibold ${
            theme === "dark" ? "text-white" : "text-gray-900"
          }`}
        >
          {translations[langMode].exportreport}
        </h3>
      </div>

      <div className="bg-white">
        <div className="flex flex-col gap-3 px-3 py-2 bg-white">
          <MultipleActivitySelect
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
         <div className="flex justify-between">
         <div className="flex gap-4">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="rounded-md"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="rounded-md"
            />
            <button
              onClick={fetchData}
              disabled={isLoading}
              className="px-3 py-1 bg-blue-800 text-white rounded hover:bg-blue-900 text-sm "
            >
              {isLoading ? "Processing..." : "Request Report"}
            </button>
          </div>
          <button className="bg-green-700 text-white py-2 px-2 h-9 inline-flex gap-2 rounded-md text-sm" onClick={fetchReportListData}>
            {" "}
            <IoRefresh size={20} />
            {translations[langMode].refresh}
          </button>
         </div>
        </div>
        <div className="px-5 py-2 flex justify-between items-center">
          <div className="text-sm text-gray-400 flex items-center gap-4">
            <label htmlFor="pageSize">{translations[langMode].rows}</label>
            <select
              id="pageSize"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div>
            <p className="text-sm text-gray-400 flex items-center gap-4">
              {`${startIndex + 1}-${Math.min(
                endIndex,
                totalItems
              )} of ${totalItems} items`}
              <IoIosArrowBack
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
              />{" "}
              <IoIosArrowForward
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              />
            </p>
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right mt-3 text-gray-500 dark:text-gray-400 gap-3 overflow-x-auto px-7 mx-5">
          <thead
            className={`text-sm bg-gray-100 text-gray-800 dark:text-gray-400 ${
              theme === "dark" ? "dark-mode" : ""
            }`}
          >
            <tr>
              <th className="px-3 py-3">No.</th>
              <th className="px-3 py-3">Workflow Names</th>
              <th className="px-3 py-3">Report Link</th>
              <th className="px-3 py-3">Requested At</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : totalValue === 0 ? (
              <tr>
                <td colSpan="5" className="text-center">
                  No Report Data available
                </td>
              </tr>
            ) : (
              reportData.map((report, index) => {
                const rowNumber = currentPage * pageSize + index + 1;
                return (
                  <tr
                    key={index}
                    className={`bg-white text-xs border-b ${
                      theme === "dark" ? "dark-mode" : ""
                    }`}
                  >
                    <td className="px-3">{rowNumber}</td>
                    <td className="px-2 py-4 max-w-xs break-words whitespace-normal">{report.workflowNames}</td>

                    <td className="px-3 py-4">
                      {report.reportAvailable ? (
                        report.reportLink ? (
                          <a
                            href={report.reportLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-yellow-400 text-white px-2 py-2 rounded-md"
                          >
                            {translations[langMode].download}
                          </a>
                        ) : (
                          <button
                            className="bg-gray-200 text-gray-700 px-2 py-2 rounded-md"
                            disabled
                          >
                            Report is Generating...
                          </button>
                        )
                      ) : (
                        <>No record Available</>
                      )}
                    </td>
                    <td className="px-3 py-4">
                      {new Date(report.createdAt).toLocaleString()}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExportData;
